/* eslint-disable @typescript-eslint/ban-types */
//#region IMPORT

import { Component, Inject, Injectable, LOCALE_ID, ViewChild } from "@angular/core";
import { TableModel } from "src/app/models/bases/table.model";
import { SessionService } from "src/app/services/session.service";
import { ActivatedRoute, Router } from "@angular/router";
import { EbudgetingService } from "src/app/services/ebudgeting.service";
import { Location } from "@angular/common";
import { StringConstant } from "src/app/constants/string.constant";
import { EBudgetingReportPageModel } from "src/app/models/ebudgetingreportpage.model";
import { EBudgetingReportExcelPageModel } from "src/app/models/ebudgetingreportexcelpage.model";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { ResponseModel } from "src/app/models/response.model";
import { Platform } from "@angular/cdk/platform";
import { MatDateFormats, NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import * as xlsx from "xlsx";
import { BaseAuthourizeDetailComponent } from "../../bases/baseauthourizedetail.component";
import { TableControlIncludeComponent } from "../../includes/tablecontrol.include/tablecontrol.include.component";

//#endregion


//#region CONST

export const appDateFormats: MatDateFormats =
{
	parse:
	{
		dateInput: { month: "short", year: "numeric", day: "numeric" },
	},
	display:
	{
		dateInput: "input",
		monthYearLabel: { year: "numeric", month: "numeric" },
		dateA11yLabel: { year: "numeric", month: "long", day: "numeric"
		},
		monthYearA11yLabel: { year: "numeric", month: "long" },
	}
};

//#endregion


//#region CLASS DECLARATION

@Injectable() export class AppDateAdapter extends NativeDateAdapter
{
	constructor(@Inject(LOCALE_ID) public locale: string)
	{
		super(locale, new Platform(locale));
	}

	format(date: Date, displayFormat: Object): string
	{
		if (displayFormat === "input")
		{
			let stringDay: string = date.getDate().toString();
			stringDay = +stringDay < 10 ? "0" + stringDay : stringDay;
			let stringMonth: string = (date.getMonth() + 1).toString();
			stringMonth = +stringMonth < 10 ? "0" + stringMonth : stringMonth;
			const numberYear = date.getFullYear();
			return `${stringDay}/${stringMonth}/${numberYear}`;
		}
		return date.toDateString();
	}
}

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-reportebudgeting",
		templateUrl: "./reportebudgeting.component.html",
		styleUrls: ["./reportebudgeting.component.sass"],
		providers:
		[
			{provide: DateAdapter, useClass: AppDateAdapter},
			{provide: MAT_DATE_FORMATS, useValue: appDateFormats}
		]
	}
)

//#endregion


//#region CLASS

export class ReportebudgetingComponent extends BaseAuthourizeDetailComponent
{

	//#region  DECLARATION

	@ViewChild("TableControlIncludeComponentReport", {static: false}) private _componentTableControlIncludeReport!: TableControlIncludeComponent;

	public _arrayModelEBudgetingAccountReportPage: Array<EBudgetingReportPageModel>;
	public _arrayModelEBudgetingDivisionReportPage: Array<EBudgetingReportPageModel>;
	public _arrayModelEBudgetingBudgetReportTable: Array<EBudgetingReportPageModel>;
	public _arrayModelEBudgetingBudgetReportTableForRequest: Array<EBudgetingReportPageModel>;
	public _arrayModelEBudgetingBudgetReportExcel: Array<EBudgetingReportExcelPageModel>;

	public _modelTableReport: TableModel;
	public _modelEBudgetingReportPageRequest: EBudgetingReportPageModel;
	public _modelEBudgetingReportExcelPageRequest: EBudgetingReportExcelPageModel;

	public _matrixExportExcel: any = [];

	public _dateMin: Date = new Date(new Date().getFullYear(), 0, 1);
	public _dateMax: Date = new Date(new Date().getFullYear(), 11, 31);

	//#endregion


	//#region  CONSTRUCTOR

	constructor(routeActivated: ActivatedRoute, location: Location, serviceSession: SessionService, public router: Router, private _serviceEBudgeting: EbudgetingService)
	{
		super(routeActivated, location, serviceSession, router);
		this._modelTableReport = new TableModel();
		this._arrayModelEBudgetingAccountReportPage = [];
		this._arrayModelEBudgetingDivisionReportPage = [];
		this._arrayModelEBudgetingBudgetReportTable = [];
		this._arrayModelEBudgetingBudgetReportTableForRequest = [];
		this._arrayModelEBudgetingBudgetReportExcel = [];
		this._modelEBudgetingReportPageRequest = new EBudgetingReportPageModel();
		this._modelEBudgetingReportExcelPageRequest = new EBudgetingReportExcelPageModel();
	}

	//#endregion


	//#region INITIALIZER

	ngOnInit(): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		if(this._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_WHOLESALEMAKER))
		{
			this.callSelectAccountForEBudgetingWholesaleMaker(this, () => {});
		}
		else if(this._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_WHOLESALECHECKER))
		{
			this.callSelectAccountForEBudgetingWholesaleChecker(this, () => {});
		}
		else if(this._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_WHOLESALESIGNER))
		{
			this.callSelectAccountForEBudgetingWholesaleSigner(this, () => {});
		}
		else if(this._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_RITMIKMAKER))
		{
			this.callSelectAccountForEBudgetingRitmikMaker(this, () => {});
		}
		else if(this._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_RITMIKCHECKER))
		{
			this.callSelectAccountForEBudgetingRitmikChecker(this, () => {});
		}
		else if(this._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_RITMIKSIGNER))
		{
			this.callSelectAccountForEBudgetingRitmikSigner(this, () => {});
		}
	}

	//#endregion


	//#region WEB SERVICE

	//#region MAKER

	/* WHOLESALE - START */

	private callSelectAccountForEBudgetingWholesaleMaker(componentCurrent: ReportebudgetingComponent, success: () => void): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);
		const modelEbudgetingReportPageForRequest: EBudgetingReportPageModel = new EBudgetingReportPageModel();

		this._serviceEBudgeting.selectAccountForEBudgetingWholesaleMakerForReport
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const arrayModelEbudgetingReportPage = JSON.parse(modelResponse.Data);

						let modelEbudgetingReportPageTemp: EBudgetingReportPageModel = new EBudgetingReportPageModel();

						for(const modelEbudgetingReportPage of arrayModelEbudgetingReportPage)
						{
							modelEbudgetingReportPageTemp = new EBudgetingReportPageModel();
							modelEbudgetingReportPageTemp.setModelFromObject(modelEbudgetingReportPage);
							componentCurrent._arrayModelEBudgetingAccountReportPage.push(modelEbudgetingReportPageTemp);
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}

					success();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}

				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectAccountForEBudgetingWholesaleMaker(componentCurrent, () => { }); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelEbudgetingReportPageForRequest);
	}

	private callSelectListForEBudgetingWholesaleMaker(componentCurrent: ReportebudgetingComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		let modelResponseRequest: ResponseModel = new ResponseModel();
		const modelEBudgetingReportPageRequest: EBudgetingReportPageModel = new EBudgetingReportPageModel();
		modelEBudgetingReportPageRequest.setModelFromObject(componentCurrent._modelEBudgetingReportPageRequest);
		modelResponseRequest = modelEBudgetingReportPageRequest.validateSelectAndExport();

		if(modelResponseRequest.State === ENUM_RESPONSE_STATE.Success)
		{
			componentCurrent._modelTableReport.Search = JSON.stringify(modelEBudgetingReportPageRequest);

			this._serviceEBudgeting.selectListForEBudgetingWholesaleMakerForReport
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._modelTableReport.setModelFromString(modelResponse.Data);

							if (componentCurrent._modelTableReport.Result !== undefined)
							{
								const arrayModelEbudgetingReportPageTemp = JSON.parse(componentCurrent._modelTableReport.Result);
								componentCurrent._modelTableReport.Result = undefined;
								componentCurrent._arrayModelEBudgetingBudgetReportTable = [];

								let modelEbudgetingReportPage: EBudgetingReportPageModel;

								for (const modelEbudgetingReportPageTemp of arrayModelEbudgetingReportPageTemp)
								{
									modelEbudgetingReportPage = new EBudgetingReportPageModel();
									modelEbudgetingReportPage.setModelFromObject(modelEbudgetingReportPageTemp);
									componentCurrent._arrayModelEBudgetingBudgetReportTable.push(modelEbudgetingReportPage);
								}

								componentCurrent._componentTableControlIncludeReport.setButtonState();
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectListForEBudgetingWholesaleMaker(componentCurrent); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, componentCurrent._modelTableReport);
		}
		else
		{
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseRequest, () => { });
		}

	}

	public callSelectListForEBudgetingWholesaleMakerExport(componentCurrent: ReportebudgetingComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		let modelResponseRequest: ResponseModel = new ResponseModel();
		const modelEBudgetingReportPageRequest: EBudgetingReportPageModel = new EBudgetingReportPageModel();
		modelEBudgetingReportPageRequest.setModelFromObject(componentCurrent._modelEBudgetingReportPageRequest);
		modelResponseRequest = modelEBudgetingReportPageRequest.validateSelectAndExport();

		if(modelResponseRequest.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceEBudgeting.selectListForEBudgetingWholesaleMakerForReportExcel
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							const arrayModelEBudgetingReportExcelTemp = JSON.parse(modelResponse.Data);
							componentCurrent._arrayModelEBudgetingBudgetReportExcel = [];

							let modelEBudgetingReportExcelPage: EBudgetingReportExcelPageModel;

							for (const modelGratificationExcelTemp of arrayModelEBudgetingReportExcelTemp)
							{
								modelEBudgetingReportExcelPage = new EBudgetingReportExcelPageModel();
								modelEBudgetingReportExcelPage.setModelFromObject(modelGratificationExcelTemp);
								modelEBudgetingReportExcelPage.Period = new Date(modelGratificationExcelTemp.Period);
								componentCurrent._arrayModelEBudgetingBudgetReportExcel.push(modelEBudgetingReportExcelPage);
							}

							modelResponse.Data = undefined;

							componentCurrent._matrixExportExcel = [];
							componentCurrent._matrixExportExcel[0] = [];
							let numberIndexRowExcel: number = 0;

							/* SET HEADER EXCEL - START */

							componentCurrent._matrixExportExcel[numberIndexRowExcel] = [];
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("No");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Periode");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Budget");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Budget Terpakai");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Pengajuan Belum Terealisasi - UANG MUKA");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Ditransfer Kembali");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Saldo");

							/* SET HEADER EXCEL - END */

							if (componentCurrent._arrayModelEBudgetingBudgetReportExcel !== undefined && componentCurrent._arrayModelEBudgetingBudgetReportExcel.length > 0)
							{
								for(const modelEBudgetingReportExcelBinding of componentCurrent._arrayModelEBudgetingBudgetReportExcel)
								{
									numberIndexRowExcel++;
									componentCurrent._matrixExportExcel[numberIndexRowExcel] = [];

									/* COLUMN "No" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(numberIndexRowExcel);

									/* COLUMN "Periode" */
									if(modelEBudgetingReportExcelBinding.Period !== undefined)
									{
										const newDate: Date = new Date(modelEBudgetingReportExcelBinding.Period);
										componentCurrent._matrixExportExcel[numberIndexRowExcel].push(newDate);
									}
									else
									{
										componentCurrent._matrixExportExcel[numberIndexRowExcel].push("-");
									}

									/* COLUMN "Budget" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelEBudgetingReportExcelBinding.BudgetAllocation?.convertToCurrency());

									/* COLUMN "Budget Terpakai" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelEBudgetingReportExcelBinding.UsedBudget?.convertToCurrency());

									/* COLUMN "Pengajuan Belum Terealisasi - UANG MUKA" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelEBudgetingReportExcelBinding.RemainingShortfallPayment?.convertToCurrency());

									/* COLUMN "Saldo" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelEBudgetingReportExcelBinding.BudgetBalance?.convertToCurrency());
								}

								/* generate worksheet */
								const workSheet: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(componentCurrent._matrixExportExcel, { cellDates: true, dateNF: "dd/mm/yyyy" });

								/* generate filename */
								let stringDuration: string = "Range";
								let stringDivision: string = "Division";
								let stringAccount: string = "Account";

								if(componentCurrent._modelEBudgetingReportPageRequest.StartPeriod !== undefined && componentCurrent._modelEBudgetingReportPageRequest.StartPeriod !== null && componentCurrent._modelEBudgetingReportPageRequest.EndPeriod !== undefined && componentCurrent._modelEBudgetingReportPageRequest.EndPeriod !== null)
								{
									stringDuration = "" + componentCurrent._modelEBudgetingReportPageRequest.StartPeriod.convertForDisplayShortForExcel() + "-" + componentCurrent._modelEBudgetingReportPageRequest.EndPeriod.convertForDisplayShortForExcel();
								}
								else
								{

								}

								if(componentCurrent._modelEBudgetingReportPageRequest.Division !== undefined && componentCurrent._modelEBudgetingReportPageRequest.Division !== null && componentCurrent._modelEBudgetingReportPageRequest.Division !== "")
								{
									stringDivision = "" + componentCurrent._modelEBudgetingReportPageRequest.Division;
								}
								else
								{

								}

								if(componentCurrent._modelEBudgetingReportPageRequest.Account !== undefined && componentCurrent._modelEBudgetingReportPageRequest.Account !== null && componentCurrent._modelEBudgetingReportPageRequest.Account !== "")
								{
									const numberIndex = componentCurrent._modelEBudgetingReportPageRequest.Account.indexOf("|");
									stringAccount = "" + componentCurrent._modelEBudgetingReportPageRequest.Account.slice(numberIndex+2);
								}
								else
								{

								}

								const stringFileName: string = stringDivision + "_" + stringAccount + "_" + stringDuration + ".xlsx";

								/* generate workbook and add the worksheet */
								const workBook: xlsx.WorkBook = xlsx.utils.book_new();
								xlsx.utils.book_append_sheet(workBook, workSheet, "Report_Budget");

								/* save to file */
								xlsx.writeFile(workBook, stringFileName);

								const modelResponseExcel: ResponseModel = new ResponseModel();
								modelResponseExcel.MessageTitle = "Ekspor Excel";
								modelResponseExcel.MessageContent = "Pengunduhan data excel sukses! Silahkan tunggu.";
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { });
							}
							else if(componentCurrent._arrayModelEBudgetingBudgetReportExcel !== undefined && componentCurrent._arrayModelEBudgetingBudgetReportExcel.length <= 0)
							{
								const modelResponseValidationError: ResponseModel = new ResponseModel();
								modelResponseValidationError.MessageTitle = "Ekspor Excel";
								modelResponseValidationError.MessageContent = "Data dalam file excel kosong! Silahkan hubungi pengembang!";
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseValidationError, () => { });
							}
							else
							{

							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectListForEBudgetingWholesaleMakerExport(componentCurrent); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, modelEBudgetingReportPageRequest);
		}
		else
		{
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseRequest, () => { });
		}
	}

	/* WHOLESALE - END */

	/* RITMIK - START */

	private callSelectAccountForEBudgetingRitmikMaker(componentCurrent: ReportebudgetingComponent, success: () => void): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);
		const modelEbudgetingReportPageForRequest: EBudgetingReportPageModel = new EBudgetingReportPageModel();

		this._serviceEBudgeting.selectAccountForEBudgetingRitmikMakerForReport
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const arrayModelEbudgetingReportPage = JSON.parse(modelResponse.Data);

						let modelEbudgetingReportPageTemp: EBudgetingReportPageModel = new EBudgetingReportPageModel();

						for(const modelEbudgetingReportPage of arrayModelEbudgetingReportPage)
						{
							modelEbudgetingReportPageTemp = new EBudgetingReportPageModel();
							modelEbudgetingReportPageTemp.setModelFromObject(modelEbudgetingReportPage);
							componentCurrent._arrayModelEBudgetingAccountReportPage.push(modelEbudgetingReportPageTemp);
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}

					success();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}

				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectAccountForEBudgetingRitmikMaker(componentCurrent, () => { }); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelEbudgetingReportPageForRequest);
	}

	private callSelectListForEBudgetingRitmikMaker(componentCurrent: ReportebudgetingComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		let modelResponseRequest: ResponseModel = new ResponseModel();
		const modelEBudgetingReportPageRequest: EBudgetingReportPageModel = new EBudgetingReportPageModel();
		modelEBudgetingReportPageRequest.setModelFromObject(componentCurrent._modelEBudgetingReportPageRequest);
		modelResponseRequest = modelEBudgetingReportPageRequest.validateSelectAndExport();

		if(modelResponseRequest.State === ENUM_RESPONSE_STATE.Success)
		{
			componentCurrent._modelTableReport.Search = JSON.stringify(modelEBudgetingReportPageRequest);

			this._serviceEBudgeting.selectListForEBudgetingRitmikMakerForReport
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._modelTableReport.setModelFromString(modelResponse.Data);

							if (componentCurrent._modelTableReport.Result !== undefined)
							{
								const arrayModelEbudgetingReportPageTemp = JSON.parse(componentCurrent._modelTableReport.Result);
								componentCurrent._modelTableReport.Result = undefined;
								componentCurrent._arrayModelEBudgetingBudgetReportTable = [];

								let modelEbudgetingReportPage: EBudgetingReportPageModel;

								for (const modelEbudgetingReportPageTemp of arrayModelEbudgetingReportPageTemp)
								{
									modelEbudgetingReportPage = new EBudgetingReportPageModel();
									modelEbudgetingReportPage.setModelFromObject(modelEbudgetingReportPageTemp);
									componentCurrent._arrayModelEBudgetingBudgetReportTable.push(modelEbudgetingReportPage);
								}

								componentCurrent._functionUserInterface.updateLoadingProgress();
								componentCurrent._componentTableControlIncludeReport.setButtonState();
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectListForEBudgetingRitmikMaker(componentCurrent); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, componentCurrent._modelTableReport);
		}
		else
		{
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseRequest, () => { });
		}

	}

	public callSelectListForEBudgetingRitmikMakerExport(componentCurrent: ReportebudgetingComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		let modelResponseRequest: ResponseModel = new ResponseModel();
		const modelEBudgetingReportPageRequest: EBudgetingReportPageModel = new EBudgetingReportPageModel();
		modelEBudgetingReportPageRequest.setModelFromObject(componentCurrent._modelEBudgetingReportPageRequest);
		modelResponseRequest = modelEBudgetingReportPageRequest.validateSelectAndExport();

		if(modelResponseRequest.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceEBudgeting.selectListForEBudgetingRitmikMakerForReportExcel
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							const arrayModelEBudgetingReportExcelTemp = JSON.parse(modelResponse.Data);
							componentCurrent._arrayModelEBudgetingBudgetReportExcel = [];

							let modelEBudgetingReportExcelPage: EBudgetingReportExcelPageModel;

							for (const modelGratificationExcelTemp of arrayModelEBudgetingReportExcelTemp)
							{
								modelEBudgetingReportExcelPage = new EBudgetingReportExcelPageModel();
								modelEBudgetingReportExcelPage.setModelFromObject(modelGratificationExcelTemp);
								modelEBudgetingReportExcelPage.Period = new Date(modelGratificationExcelTemp.Period);
								componentCurrent._arrayModelEBudgetingBudgetReportExcel.push(modelEBudgetingReportExcelPage);
							}

							modelResponse.Data = undefined;

							componentCurrent._matrixExportExcel = [];
							componentCurrent._matrixExportExcel[0] = [];
							let numberIndexRowExcel: number = 0;

							/* SET HEADER EXCEL - START */

							componentCurrent._matrixExportExcel[numberIndexRowExcel] = [];
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("No");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Periode");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Budget");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Budget Terpakai");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Pengajuan Belum Terealisasi - UANG MUKA");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Ditransfer Kembali");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Saldo");

							/* SET HEADER EXCEL - END */

							if (componentCurrent._arrayModelEBudgetingBudgetReportExcel !== undefined && componentCurrent._arrayModelEBudgetingBudgetReportExcel.length > 0)
							{
								for(const modelEBudgetingReportExcelBinding of componentCurrent._arrayModelEBudgetingBudgetReportExcel)
								{
									numberIndexRowExcel++;
									componentCurrent._matrixExportExcel[numberIndexRowExcel] = [];

									/* COLUMN "No" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(numberIndexRowExcel);

									/* COLUMN "Periode" */
									if(modelEBudgetingReportExcelBinding.Period !== undefined)
									{
										const newDate: Date = new Date(modelEBudgetingReportExcelBinding.Period);
										componentCurrent._matrixExportExcel[numberIndexRowExcel].push(newDate);
									}
									else
									{
										componentCurrent._matrixExportExcel[numberIndexRowExcel].push("-");
									}

									/* COLUMN "Budget" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelEBudgetingReportExcelBinding.BudgetAllocation?.convertToCurrency());

									/* COLUMN "Budget Terpakai" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelEBudgetingReportExcelBinding.UsedBudget?.convertToCurrency());

									/* COLUMN "Pengajuan Belum Terealisasi - UANG MUKA" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelEBudgetingReportExcelBinding.RemainingShortfallPayment?.convertToCurrency());

									/* COLUMN "Saldo" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelEBudgetingReportExcelBinding.BudgetBalance?.convertToCurrency());
								}

								/* generate worksheet */
								const workSheet: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(componentCurrent._matrixExportExcel, { cellDates: true, dateNF: "dd/mm/yyyy" });

								/* generate filename */
								let stringDuration: string = "Range";
								let stringDivision: string = "Division";
								let stringAccount: string = "Account";

								if(componentCurrent._modelEBudgetingReportPageRequest.StartPeriod !== undefined && componentCurrent._modelEBudgetingReportPageRequest.StartPeriod !== null && componentCurrent._modelEBudgetingReportPageRequest.EndPeriod !== undefined && componentCurrent._modelEBudgetingReportPageRequest.EndPeriod !== null)
								{
									stringDuration = "" + componentCurrent._modelEBudgetingReportPageRequest.StartPeriod.convertForDisplayShortForExcel() + "-" + componentCurrent._modelEBudgetingReportPageRequest.EndPeriod.convertForDisplayShortForExcel();
								}
								else
								{

								}

								if(componentCurrent._modelEBudgetingReportPageRequest.Division !== undefined && componentCurrent._modelEBudgetingReportPageRequest.Division !== null && componentCurrent._modelEBudgetingReportPageRequest.Division !== "")
								{
									stringDivision = "" + componentCurrent._modelEBudgetingReportPageRequest.Division;
								}
								else
								{

								}

								if(componentCurrent._modelEBudgetingReportPageRequest.Account !== undefined && componentCurrent._modelEBudgetingReportPageRequest.Account !== null && componentCurrent._modelEBudgetingReportPageRequest.Account !== "")
								{
									const numberIndex = componentCurrent._modelEBudgetingReportPageRequest.Account.indexOf("|");
									stringAccount = "" + componentCurrent._modelEBudgetingReportPageRequest.Account.slice(numberIndex+2);
								}
								else
								{

								}

								const stringFileName: string = stringDivision + "_" + stringAccount + "_" + stringDuration + ".xlsx";

								/* generate workbook and add the worksheet */
								const workBook: xlsx.WorkBook = xlsx.utils.book_new();
								xlsx.utils.book_append_sheet(workBook, workSheet, "Report_Budget");

								/* save to file */
								xlsx.writeFile(workBook, stringFileName);

								const modelResponseExcel: ResponseModel = new ResponseModel();
								modelResponseExcel.MessageTitle = "Ekspor Excel";
								modelResponseExcel.MessageContent = "Pengunduhan data excel sukses! Silahkan tunggu.";
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { });

								componentCurrent._functionUserInterface.updateLoadingProgress();
							}
							else if(componentCurrent._arrayModelEBudgetingBudgetReportExcel !== undefined && componentCurrent._arrayModelEBudgetingBudgetReportExcel.length <= 0)
							{
								const modelResponseValidationError: ResponseModel = new ResponseModel();
								modelResponseValidationError.MessageTitle = "Ekspor Excel";
								modelResponseValidationError.MessageContent = "Data dalam file excel kosong! Silahkan hubungi pengembang!";
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseValidationError, () => { });
							}
							else
							{

							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectListForEBudgetingRitmikMakerExport(componentCurrent); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, modelEBudgetingReportPageRequest);
		}
		else
		{
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseRequest, () => { });
		}
	}

	/* RITMIK - END */

	//#endregion

	//#region CHECKER

	/* WHOLESALE - START */

	private callSelectAccountForEBudgetingWholesaleChecker(componentCurrent: ReportebudgetingComponent, success: () => void): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);
		const modelEbudgetingReportPageForRequest: EBudgetingReportPageModel = new EBudgetingReportPageModel();

		this._serviceEBudgeting.selectAccountForEBudgetingWholesaleCheckerForReport
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const arrayModelEbudgetingReportPage = JSON.parse(modelResponse.Data);

						let modelEbudgetingReportPageTemp: EBudgetingReportPageModel = new EBudgetingReportPageModel();

						for(const modelEbudgetingReportPage of arrayModelEbudgetingReportPage)
						{
							modelEbudgetingReportPageTemp = new EBudgetingReportPageModel();
							modelEbudgetingReportPageTemp.setModelFromObject(modelEbudgetingReportPage);
							componentCurrent._arrayModelEBudgetingAccountReportPage.push(modelEbudgetingReportPageTemp);
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}

					success();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}

				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectAccountForEBudgetingWholesaleChecker(componentCurrent, () => { }); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelEbudgetingReportPageForRequest);
	}

	private callSelectListForEBudgetingWholesaleChecker(componentCurrent: ReportebudgetingComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelTableReport.Search = JSON.stringify(componentCurrent._modelEBudgetingReportPageRequest);

		this._serviceEBudgeting.selectListForEBudgetingWholesaleCheckerForReport
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTableReport.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelTableReport.Result !== undefined)
						{
							const arrayModelEbudgetingReportPageTemp = JSON.parse(componentCurrent._modelTableReport.Result);
							componentCurrent._modelTableReport.Result = undefined;
							componentCurrent._arrayModelEBudgetingBudgetReportTable = [];

							let modelEbudgetingReportPage: EBudgetingReportPageModel;

							for (const modelEbudgetingReportPageTemp of arrayModelEbudgetingReportPageTemp)
							{
								modelEbudgetingReportPage = new EBudgetingReportPageModel();
								modelEbudgetingReportPage.setModelFromObject(modelEbudgetingReportPageTemp);
								componentCurrent._arrayModelEBudgetingBudgetReportTable.push(modelEbudgetingReportPage);
							}

							componentCurrent._functionUserInterface.updateLoadingProgress();
							componentCurrent._componentTableControlIncludeReport.setButtonState();
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}

					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectListForEBudgetingWholesaleChecker(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelTableReport);
	}

	public callSelectListForEBudgetingWholesaleCheckerExport(componentCurrent: ReportebudgetingComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		let modelResponseRequest: ResponseModel = new ResponseModel();
		const modelEBudgetingReportPageRequest: EBudgetingReportPageModel = new EBudgetingReportPageModel();
		modelEBudgetingReportPageRequest.setModelFromObject(componentCurrent._modelEBudgetingReportPageRequest);
		modelResponseRequest = modelEBudgetingReportPageRequest.validateSelectAndExport();

		if(modelResponseRequest.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceEBudgeting.selectListForEBudgetingWholesaleCheckerForReportExcel
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							const arrayModelEBudgetingReportExcelTemp = JSON.parse(modelResponse.Data);
							componentCurrent._arrayModelEBudgetingBudgetReportExcel = [];

							let modelEBudgetingReportExcelPage: EBudgetingReportExcelPageModel;

							for (const modelGratificationExcelTemp of arrayModelEBudgetingReportExcelTemp)
							{
								modelEBudgetingReportExcelPage = new EBudgetingReportExcelPageModel();
								modelEBudgetingReportExcelPage.setModelFromObject(modelGratificationExcelTemp);
								modelEBudgetingReportExcelPage.Period = new Date(modelGratificationExcelTemp.Period);
								componentCurrent._arrayModelEBudgetingBudgetReportExcel.push(modelEBudgetingReportExcelPage);
							}

							modelResponse.Data = undefined;

							componentCurrent._matrixExportExcel = [];
							componentCurrent._matrixExportExcel[0] = [];
							let numberIndexRowExcel: number = 0;

							/* SET HEADER EXCEL - START */

							componentCurrent._matrixExportExcel[numberIndexRowExcel] = [];
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("No");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Periode");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Budget");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Budget Terpakai");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Pengajuan Belum Terealisasi - UANG MUKA");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Ditransfer Kembali");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Saldo");

							/* SET HEADER EXCEL - END */

							if (componentCurrent._arrayModelEBudgetingBudgetReportExcel !== undefined && componentCurrent._arrayModelEBudgetingBudgetReportExcel.length > 0)
							{
								for(const modelEBudgetingReportExcelBinding of componentCurrent._arrayModelEBudgetingBudgetReportExcel)
								{
									numberIndexRowExcel++;
									componentCurrent._matrixExportExcel[numberIndexRowExcel] = [];

									/* COLUMN "No" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(numberIndexRowExcel);

									/* COLUMN "Periode" */
									if(modelEBudgetingReportExcelBinding.Period !== undefined)
									{
										const newDate: Date = new Date(modelEBudgetingReportExcelBinding.Period);
										componentCurrent._matrixExportExcel[numberIndexRowExcel].push(newDate);
									}
									else
									{
										componentCurrent._matrixExportExcel[numberIndexRowExcel].push("-");
									}

									/* COLUMN "Budget" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelEBudgetingReportExcelBinding.BudgetAllocation?.convertToCurrency());

									/* COLUMN "Budget Terpakai" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelEBudgetingReportExcelBinding.UsedBudget?.convertToCurrency());

									/* COLUMN "Pengajuan Belum Terealisasi - UANG MUKA" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelEBudgetingReportExcelBinding.RemainingShortfallPayment?.convertToCurrency());

									/* COLUMN "Saldo" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelEBudgetingReportExcelBinding.BudgetBalance?.convertToCurrency());
								}

								/* generate worksheet */
								const workSheet: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(componentCurrent._matrixExportExcel, { cellDates: true, dateNF: "dd/mm/yyyy" });

								/* generate filename */
								let stringDuration: string = "Range";
								let stringDivision: string = "Division";
								let stringAccount: string = "Account";

								if(componentCurrent._modelEBudgetingReportPageRequest.StartPeriod !== undefined && componentCurrent._modelEBudgetingReportPageRequest.StartPeriod !== null && componentCurrent._modelEBudgetingReportPageRequest.EndPeriod !== undefined && componentCurrent._modelEBudgetingReportPageRequest.EndPeriod !== null)
								{
									stringDuration = "" + componentCurrent._modelEBudgetingReportPageRequest.StartPeriod.convertForDisplayShortForExcel() + "-" + componentCurrent._modelEBudgetingReportPageRequest.EndPeriod.convertForDisplayShortForExcel();
								}
								else
								{

								}

								if(componentCurrent._modelEBudgetingReportPageRequest.Division !== undefined && componentCurrent._modelEBudgetingReportPageRequest.Division !== null && componentCurrent._modelEBudgetingReportPageRequest.Division !== "")
								{
									stringDivision = "" + componentCurrent._modelEBudgetingReportPageRequest.Division;
								}
								else
								{

								}

								if(componentCurrent._modelEBudgetingReportPageRequest.Account !== undefined && componentCurrent._modelEBudgetingReportPageRequest.Account !== null && componentCurrent._modelEBudgetingReportPageRequest.Account !== "")
								{
									const numberIndex = componentCurrent._modelEBudgetingReportPageRequest.Account.indexOf("|");
									stringAccount = "" + componentCurrent._modelEBudgetingReportPageRequest.Account.slice(numberIndex+2);
								}
								else
								{

								}

								const stringFileName: string = stringDivision + "_" + stringAccount + "_" + stringDuration + ".xlsx";

								/* generate workbook and add the worksheet */
								const workBook: xlsx.WorkBook = xlsx.utils.book_new();
								xlsx.utils.book_append_sheet(workBook, workSheet, "Report_Budget");

								/* save to file */
								xlsx.writeFile(workBook, stringFileName);

								const modelResponseExcel: ResponseModel = new ResponseModel();
								modelResponseExcel.MessageTitle = "Ekspor Excel";
								modelResponseExcel.MessageContent = "Pengunduhan data excel sukses! Silahkan tunggu.";
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { });

								componentCurrent._functionUserInterface.updateLoadingProgress();
							}
							else if(componentCurrent._arrayModelEBudgetingBudgetReportExcel !== undefined && componentCurrent._arrayModelEBudgetingBudgetReportExcel.length <= 0)
							{
								const modelResponseValidationError: ResponseModel = new ResponseModel();
								modelResponseValidationError.MessageTitle = "Ekspor Excel";
								modelResponseValidationError.MessageContent = "Data dalam file excel kosong! Silahkan hubungi pengembang!";
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseValidationError, () => { });
							}
							else
							{

							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectListForEBudgetingWholesaleCheckerExport(componentCurrent); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, modelEBudgetingReportPageRequest);
		}
		else
		{
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseRequest, () => { });
		}
	}

	/* WHOLESALE - END */

	/* RITMIK - START */

	private callSelectAccountForEBudgetingRitmikChecker(componentCurrent: ReportebudgetingComponent, success: () => void): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);
		const modelEbudgetingReportPageForRequest: EBudgetingReportPageModel = new EBudgetingReportPageModel();

		this._serviceEBudgeting.selectAccountForEBudgetingRitmikCheckerForReport
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const arrayModelEbudgetingReportPage = JSON.parse(modelResponse.Data);

						let modelEbudgetingReportPageTemp: EBudgetingReportPageModel = new EBudgetingReportPageModel();

						for(const modelEbudgetingReportPage of arrayModelEbudgetingReportPage)
						{
							modelEbudgetingReportPageTemp = new EBudgetingReportPageModel();
							modelEbudgetingReportPageTemp.setModelFromObject(modelEbudgetingReportPage);
							componentCurrent._arrayModelEBudgetingAccountReportPage.push(modelEbudgetingReportPageTemp);
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}

					success();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}

				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectAccountForEBudgetingRitmikChecker(componentCurrent, () => { }); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelEbudgetingReportPageForRequest);
	}

	private callSelectListForEBudgetingRitmikChecker(componentCurrent: ReportebudgetingComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelTableReport.Search = JSON.stringify(componentCurrent._modelEBudgetingReportPageRequest);

		this._serviceEBudgeting.selectListForEBudgetingRitmikCheckerForReport
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTableReport.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelTableReport.Result !== undefined)
						{
							const arrayModelEbudgetingReportPageTemp = JSON.parse(componentCurrent._modelTableReport.Result);
							componentCurrent._modelTableReport.Result = undefined;
							componentCurrent._arrayModelEBudgetingBudgetReportTable = [];

							let modelEbudgetingReportPage: EBudgetingReportPageModel;

							for (const modelEbudgetingReportPageTemp of arrayModelEbudgetingReportPageTemp)
							{
								modelEbudgetingReportPage = new EBudgetingReportPageModel();
								modelEbudgetingReportPage.setModelFromObject(modelEbudgetingReportPageTemp);
								componentCurrent._arrayModelEBudgetingBudgetReportTable.push(modelEbudgetingReportPage);
							}

							componentCurrent._functionUserInterface.updateLoadingProgress();
							componentCurrent._componentTableControlIncludeReport.setButtonState();
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}

					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectListForEBudgetingRitmikChecker(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelTableReport);
	}

	public callSelectListForEBudgetingRitmikCheckerExport(componentCurrent: ReportebudgetingComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		let modelResponseRequest: ResponseModel = new ResponseModel();
		const modelEBudgetingReportPageRequest: EBudgetingReportPageModel = new EBudgetingReportPageModel();
		modelEBudgetingReportPageRequest.setModelFromObject(componentCurrent._modelEBudgetingReportPageRequest);
		modelResponseRequest = modelEBudgetingReportPageRequest.validateSelectAndExport();

		if(modelResponseRequest.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceEBudgeting.selectListForEBudgetingRitmikCheckerForReportExcel
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							const arrayModelEBudgetingReportExcelTemp = JSON.parse(modelResponse.Data);
							componentCurrent._arrayModelEBudgetingBudgetReportExcel = [];

							let modelEBudgetingReportExcelPage: EBudgetingReportExcelPageModel;

							for (const modelGratificationExcelTemp of arrayModelEBudgetingReportExcelTemp)
							{
								modelEBudgetingReportExcelPage = new EBudgetingReportExcelPageModel();
								modelEBudgetingReportExcelPage.setModelFromObject(modelGratificationExcelTemp);
								modelEBudgetingReportExcelPage.Period = new Date(modelGratificationExcelTemp.Period);
								componentCurrent._arrayModelEBudgetingBudgetReportExcel.push(modelEBudgetingReportExcelPage);
							}

							modelResponse.Data = undefined;

							componentCurrent._matrixExportExcel = [];
							componentCurrent._matrixExportExcel[0] = [];
							let numberIndexRowExcel: number = 0;

							/* SET HEADER EXCEL - START */

							componentCurrent._matrixExportExcel[numberIndexRowExcel] = [];
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("No");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Periode");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Budget");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Budget Terpakai");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Pengajuan Belum Terealisasi - UANG MUKA");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Ditransfer Kembali");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Saldo");

							/* SET HEADER EXCEL - END */

							if (componentCurrent._arrayModelEBudgetingBudgetReportExcel !== undefined && componentCurrent._arrayModelEBudgetingBudgetReportExcel.length > 0)
							{
								for(const modelEBudgetingReportExcelBinding of componentCurrent._arrayModelEBudgetingBudgetReportExcel)
								{
									numberIndexRowExcel++;
									componentCurrent._matrixExportExcel[numberIndexRowExcel] = [];

									/* COLUMN "No" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(numberIndexRowExcel);

									/* COLUMN "Periode" */
									if(modelEBudgetingReportExcelBinding.Period !== undefined)
									{
										const newDate: Date = new Date(modelEBudgetingReportExcelBinding.Period);
										componentCurrent._matrixExportExcel[numberIndexRowExcel].push(newDate);
									}
									else
									{
										componentCurrent._matrixExportExcel[numberIndexRowExcel].push("-");
									}

									/* COLUMN "Budget" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelEBudgetingReportExcelBinding.BudgetAllocation?.convertToCurrency());

									/* COLUMN "Budget Terpakai" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelEBudgetingReportExcelBinding.UsedBudget?.convertToCurrency());

									/* COLUMN "Pengajuan Belum Terealisasi - UANG MUKA" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelEBudgetingReportExcelBinding.RemainingShortfallPayment?.convertToCurrency());

									/* COLUMN "Saldo" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelEBudgetingReportExcelBinding.BudgetBalance?.convertToCurrency());
								}

								/* generate worksheet */
								const workSheet: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(componentCurrent._matrixExportExcel, { cellDates: true, dateNF: "dd/mm/yyyy" });

								/* generate filename */
								let stringDuration: string = "Range";
								let stringDivision: string = "Division";
								let stringAccount: string = "Account";

								if(componentCurrent._modelEBudgetingReportPageRequest.StartPeriod !== undefined && componentCurrent._modelEBudgetingReportPageRequest.StartPeriod !== null && componentCurrent._modelEBudgetingReportPageRequest.EndPeriod !== undefined && componentCurrent._modelEBudgetingReportPageRequest.EndPeriod !== null)
								{
									stringDuration = "" + componentCurrent._modelEBudgetingReportPageRequest.StartPeriod.convertForDisplayShortForExcel() + "-" + componentCurrent._modelEBudgetingReportPageRequest.EndPeriod.convertForDisplayShortForExcel();
								}
								else
								{

								}

								if(componentCurrent._modelEBudgetingReportPageRequest.Division !== undefined && componentCurrent._modelEBudgetingReportPageRequest.Division !== null && componentCurrent._modelEBudgetingReportPageRequest.Division !== "")
								{
									stringDivision = "" + componentCurrent._modelEBudgetingReportPageRequest.Division;
								}
								else
								{

								}

								if(componentCurrent._modelEBudgetingReportPageRequest.Account !== undefined && componentCurrent._modelEBudgetingReportPageRequest.Account !== null && componentCurrent._modelEBudgetingReportPageRequest.Account !== "")
								{
									const numberIndex = componentCurrent._modelEBudgetingReportPageRequest.Account.indexOf("|");
									stringAccount = "" + componentCurrent._modelEBudgetingReportPageRequest.Account.slice(numberIndex+2);
								}
								else
								{

								}

								const stringFileName: string = stringDivision + "_" + stringAccount + "_" + stringDuration + ".xlsx";

								/* generate workbook and add the worksheet */
								const workBook: xlsx.WorkBook = xlsx.utils.book_new();
								xlsx.utils.book_append_sheet(workBook, workSheet, "Report_Budget");

								/* save to file */
								xlsx.writeFile(workBook, stringFileName);

								const modelResponseExcel: ResponseModel = new ResponseModel();
								modelResponseExcel.MessageTitle = "Ekspor Excel";
								modelResponseExcel.MessageContent = "Pengunduhan data excel sukses! Silahkan tunggu.";
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { });

								componentCurrent._functionUserInterface.updateLoadingProgress();
							}
							else if(componentCurrent._arrayModelEBudgetingBudgetReportExcel !== undefined && componentCurrent._arrayModelEBudgetingBudgetReportExcel.length <= 0)
							{
								const modelResponseValidationError: ResponseModel = new ResponseModel();
								modelResponseValidationError.MessageTitle = "Ekspor Excel";
								modelResponseValidationError.MessageContent = "Data dalam file excel kosong! Silahkan hubungi pengembang!";
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseValidationError, () => { });
							}
							else
							{

							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectListForEBudgetingRitmikCheckerExport(componentCurrent); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, modelEBudgetingReportPageRequest);
		}
		else
		{
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseRequest, () => { });
		}
	}

	/* RITMIK - END */

	//#endregion

	//#region SIGNER

	/* WHOLESALE - START */

	private callSelectAccountForEBudgetingWholesaleSigner(componentCurrent: ReportebudgetingComponent, success: () => void): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);
		const modelEbudgetingReportPageForRequest: EBudgetingReportPageModel = new EBudgetingReportPageModel();

		this._serviceEBudgeting.selectAccountForEBudgetingWholesaleSignerForReport
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const arrayModelEbudgetingReportPage = JSON.parse(modelResponse.Data);

						let modelEbudgetingReportPageTemp: EBudgetingReportPageModel = new EBudgetingReportPageModel();

						for(const modelEbudgetingReportPage of arrayModelEbudgetingReportPage)
						{
							modelEbudgetingReportPageTemp = new EBudgetingReportPageModel();
							modelEbudgetingReportPageTemp.setModelFromObject(modelEbudgetingReportPage);
							componentCurrent._arrayModelEBudgetingAccountReportPage.push(modelEbudgetingReportPageTemp);
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}

					success();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}

				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectAccountForEBudgetingWholesaleSigner(componentCurrent, () => { }); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelEbudgetingReportPageForRequest);
	}

	private callSelectListForEBudgetingWholesaleSigner(componentCurrent: ReportebudgetingComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelTableReport.Search = JSON.stringify(componentCurrent._modelEBudgetingReportPageRequest);

		this._serviceEBudgeting.selectListForEBudgetingWholesaleSignerForReport
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTableReport.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelTableReport.Result !== undefined)
						{
							const arrayModelEbudgetingReportPageTemp = JSON.parse(componentCurrent._modelTableReport.Result);
							componentCurrent._modelTableReport.Result = undefined;
							componentCurrent._arrayModelEBudgetingBudgetReportTable = [];

							let modelEbudgetingReportPage: EBudgetingReportPageModel;

							for (const modelEbudgetingReportPageTemp of arrayModelEbudgetingReportPageTemp)
							{
								modelEbudgetingReportPage = new EBudgetingReportPageModel();
								modelEbudgetingReportPage.setModelFromObject(modelEbudgetingReportPageTemp);
								componentCurrent._arrayModelEBudgetingBudgetReportTable.push(modelEbudgetingReportPage);
							}

							componentCurrent._functionUserInterface.updateLoadingProgress();
							componentCurrent._componentTableControlIncludeReport.setButtonState();
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}

					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectListForEBudgetingWholesaleSigner(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelTableReport);
	}

	public callSelectListForEBudgetingWholesaleSignerExport(componentCurrent: ReportebudgetingComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		let modelResponseRequest: ResponseModel = new ResponseModel();
		const modelEBudgetingReportPageRequest: EBudgetingReportPageModel = new EBudgetingReportPageModel();
		modelEBudgetingReportPageRequest.setModelFromObject(componentCurrent._modelEBudgetingReportPageRequest);
		modelResponseRequest = modelEBudgetingReportPageRequest.validateSelectAndExport();

		if(modelResponseRequest.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceEBudgeting.selectListForEBudgetingWholesaleSignerForReportExcel
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							const arrayModelEBudgetingReportExcelTemp = JSON.parse(modelResponse.Data);
							componentCurrent._arrayModelEBudgetingBudgetReportExcel = [];

							let modelEBudgetingReportExcelPage: EBudgetingReportExcelPageModel;

							for (const modelGratificationExcelTemp of arrayModelEBudgetingReportExcelTemp)
							{
								modelEBudgetingReportExcelPage = new EBudgetingReportExcelPageModel();
								modelEBudgetingReportExcelPage.setModelFromObject(modelGratificationExcelTemp);
								modelEBudgetingReportExcelPage.Period = new Date(modelGratificationExcelTemp.Period);
								componentCurrent._arrayModelEBudgetingBudgetReportExcel.push(modelEBudgetingReportExcelPage);
							}

							modelResponse.Data = undefined;

							componentCurrent._matrixExportExcel = [];
							componentCurrent._matrixExportExcel[0] = [];
							let numberIndexRowExcel: number = 0;

							/* SET HEADER EXCEL - START */

							componentCurrent._matrixExportExcel[numberIndexRowExcel] = [];
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("No");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Periode");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Budget");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Budget Terpakai");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Pengajuan Belum Terealisasi - UANG MUKA");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Ditransfer Kembali");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Saldo");

							/* SET HEADER EXCEL - END */

							if (componentCurrent._arrayModelEBudgetingBudgetReportExcel !== undefined && componentCurrent._arrayModelEBudgetingBudgetReportExcel.length > 0)
							{
								for(const modelEBudgetingReportExcelBinding of componentCurrent._arrayModelEBudgetingBudgetReportExcel)
								{
									numberIndexRowExcel++;
									componentCurrent._matrixExportExcel[numberIndexRowExcel] = [];

									/* COLUMN "No" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(numberIndexRowExcel);

									/* COLUMN "Periode" */
									if(modelEBudgetingReportExcelBinding.Period !== undefined)
									{
										const newDate: Date = new Date(modelEBudgetingReportExcelBinding.Period);
										componentCurrent._matrixExportExcel[numberIndexRowExcel].push(newDate);
									}
									else
									{
										componentCurrent._matrixExportExcel[numberIndexRowExcel].push("-");
									}

									/* COLUMN "Budget" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelEBudgetingReportExcelBinding.BudgetAllocation?.convertToCurrency());

									/* COLUMN "Budget Terpakai" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelEBudgetingReportExcelBinding.UsedBudget?.convertToCurrency());

									/* COLUMN "Pengajuan Belum Terealisasi - UANG MUKA" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelEBudgetingReportExcelBinding.RemainingShortfallPayment?.convertToCurrency());

									/* COLUMN "Saldo" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelEBudgetingReportExcelBinding.BudgetBalance?.convertToCurrency());
								}

								/* generate worksheet */
								const workSheet: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(componentCurrent._matrixExportExcel, { cellDates: true, dateNF: "dd/mm/yyyy" });

								/* generate filename */
								let stringDuration: string = "Range";
								let stringDivision: string = "Division";
								let stringAccount: string = "Account";

								if(componentCurrent._modelEBudgetingReportPageRequest.StartPeriod !== undefined && componentCurrent._modelEBudgetingReportPageRequest.StartPeriod !== null && componentCurrent._modelEBudgetingReportPageRequest.EndPeriod !== undefined && componentCurrent._modelEBudgetingReportPageRequest.EndPeriod !== null)
								{
									stringDuration = "" + componentCurrent._modelEBudgetingReportPageRequest.StartPeriod.convertForDisplayShortForExcel() + "-" + componentCurrent._modelEBudgetingReportPageRequest.EndPeriod.convertForDisplayShortForExcel();
								}
								else
								{

								}

								if(componentCurrent._modelEBudgetingReportPageRequest.Division !== undefined && componentCurrent._modelEBudgetingReportPageRequest.Division !== null && componentCurrent._modelEBudgetingReportPageRequest.Division !== "")
								{
									stringDivision = "" + componentCurrent._modelEBudgetingReportPageRequest.Division;
								}
								else
								{

								}

								if(componentCurrent._modelEBudgetingReportPageRequest.Account !== undefined && componentCurrent._modelEBudgetingReportPageRequest.Account !== null && componentCurrent._modelEBudgetingReportPageRequest.Account !== "")
								{
									const numberIndex = componentCurrent._modelEBudgetingReportPageRequest.Account.indexOf("|");
									stringAccount = "" + componentCurrent._modelEBudgetingReportPageRequest.Account.slice(numberIndex+2);
								}
								else
								{

								}

								const stringFileName: string = stringDivision + "_" + stringAccount + "_" + stringDuration + ".xlsx";

								/* generate workbook and add the worksheet */
								const workBook: xlsx.WorkBook = xlsx.utils.book_new();
								xlsx.utils.book_append_sheet(workBook, workSheet, "Report_Budget");

								/* save to file */
								xlsx.writeFile(workBook, stringFileName);

								const modelResponseExcel: ResponseModel = new ResponseModel();
								modelResponseExcel.MessageTitle = "Ekspor Excel";
								modelResponseExcel.MessageContent = "Pengunduhan data excel sukses! Silahkan tunggu.";
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { });

								componentCurrent._functionUserInterface.updateLoadingProgress();
							}
							else if(componentCurrent._arrayModelEBudgetingBudgetReportExcel !== undefined && componentCurrent._arrayModelEBudgetingBudgetReportExcel.length <= 0)
							{
								const modelResponseValidationError: ResponseModel = new ResponseModel();
								modelResponseValidationError.MessageTitle = "Ekspor Excel";
								modelResponseValidationError.MessageContent = "Data dalam file excel kosong! Silahkan hubungi pengembang!";
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseValidationError, () => { });
							}
							else
							{

							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectListForEBudgetingWholesaleSignerExport(componentCurrent); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, modelEBudgetingReportPageRequest);
		}
		else
		{
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseRequest, () => { });
		}
	}

	/* WHOLESALE - END */

	/* RITMIK - START */

	private callSelectAccountForEBudgetingRitmikSigner(componentCurrent: ReportebudgetingComponent, success: () => void): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);
		const modelEbudgetingReportPageForRequest: EBudgetingReportPageModel = new EBudgetingReportPageModel();

		this._serviceEBudgeting.selectAccountForEBudgetingRitmikSignerForReport
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const arrayModelEbudgetingReportPage = JSON.parse(modelResponse.Data);

						let modelEbudgetingReportPageTemp: EBudgetingReportPageModel = new EBudgetingReportPageModel();

						for(const modelEbudgetingReportPage of arrayModelEbudgetingReportPage)
						{
							modelEbudgetingReportPageTemp = new EBudgetingReportPageModel();
							modelEbudgetingReportPageTemp.setModelFromObject(modelEbudgetingReportPage);
							componentCurrent._arrayModelEBudgetingAccountReportPage.push(modelEbudgetingReportPageTemp);
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}

					success();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}

				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectAccountForEBudgetingRitmikSigner(componentCurrent, () => { }); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelEbudgetingReportPageForRequest);
	}

	private callSelectListForEBudgetingRitmikSigner(componentCurrent: ReportebudgetingComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelTableReport.Search = JSON.stringify(componentCurrent._modelEBudgetingReportPageRequest);

		this._serviceEBudgeting.selectListForEBudgetingRitmikSignerForReport
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTableReport.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelTableReport.Result !== undefined)
						{
							const arrayModelEbudgetingReportPageTemp = JSON.parse(componentCurrent._modelTableReport.Result);
							componentCurrent._modelTableReport.Result = undefined;
							componentCurrent._arrayModelEBudgetingBudgetReportTable = [];

							let modelEbudgetingReportPage: EBudgetingReportPageModel;

							for (const modelEbudgetingReportPageTemp of arrayModelEbudgetingReportPageTemp)
							{
								modelEbudgetingReportPage = new EBudgetingReportPageModel();
								modelEbudgetingReportPage.setModelFromObject(modelEbudgetingReportPageTemp);
								componentCurrent._arrayModelEBudgetingBudgetReportTable.push(modelEbudgetingReportPage);
							}

							componentCurrent._functionUserInterface.updateLoadingProgress();
							componentCurrent._componentTableControlIncludeReport.setButtonState();
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}

					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectListForEBudgetingRitmikSigner(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelTableReport);
	}

	public callSelectListForEBudgetingRitmikSignerExport(componentCurrent: ReportebudgetingComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		let modelResponseRequest: ResponseModel = new ResponseModel();
		const modelEBudgetingReportPageRequest: EBudgetingReportPageModel = new EBudgetingReportPageModel();
		modelEBudgetingReportPageRequest.setModelFromObject(componentCurrent._modelEBudgetingReportPageRequest);
		modelResponseRequest = modelEBudgetingReportPageRequest.validateSelectAndExport();

		if(modelResponseRequest.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceEBudgeting.selectListForEBudgetingRitmikSignerForReportExcel
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							const arrayModelEBudgetingReportExcelTemp = JSON.parse(modelResponse.Data);
							componentCurrent._arrayModelEBudgetingBudgetReportExcel = [];

							let modelEBudgetingReportExcelPage: EBudgetingReportExcelPageModel;

							for (const modelGratificationExcelTemp of arrayModelEBudgetingReportExcelTemp)
							{
								modelEBudgetingReportExcelPage = new EBudgetingReportExcelPageModel();
								modelEBudgetingReportExcelPage.setModelFromObject(modelGratificationExcelTemp);
								modelEBudgetingReportExcelPage.Period = new Date(modelGratificationExcelTemp.Period);
								componentCurrent._arrayModelEBudgetingBudgetReportExcel.push(modelEBudgetingReportExcelPage);
							}

							modelResponse.Data = undefined;

							componentCurrent._matrixExportExcel = [];
							componentCurrent._matrixExportExcel[0] = [];
							let numberIndexRowExcel: number = 0;

							/* SET HEADER EXCEL - START */

							componentCurrent._matrixExportExcel[numberIndexRowExcel] = [];
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("No");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Periode");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Budget");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Budget Terpakai");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Pengajuan Belum Terealisasi - UANG MUKA");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Ditransfer Kembali");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("Saldo");

							/* SET HEADER EXCEL - END */

							if (componentCurrent._arrayModelEBudgetingBudgetReportExcel !== undefined && componentCurrent._arrayModelEBudgetingBudgetReportExcel.length > 0)
							{
								for(const modelEBudgetingReportExcelBinding of componentCurrent._arrayModelEBudgetingBudgetReportExcel)
								{
									numberIndexRowExcel++;
									componentCurrent._matrixExportExcel[numberIndexRowExcel] = [];

									/* COLUMN "No" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(numberIndexRowExcel);

									/* COLUMN "Periode" */
									if(modelEBudgetingReportExcelBinding.Period !== undefined)
									{
										const newDate: Date = new Date(modelEBudgetingReportExcelBinding.Period);
										componentCurrent._matrixExportExcel[numberIndexRowExcel].push(newDate);
									}
									else
									{
										componentCurrent._matrixExportExcel[numberIndexRowExcel].push("-");
									}

									/* COLUMN "Budget" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelEBudgetingReportExcelBinding.BudgetAllocation?.convertToCurrency());

									/* COLUMN "Budget Terpakai" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelEBudgetingReportExcelBinding.UsedBudget?.convertToCurrency());

									/* COLUMN "Pengajuan Belum Terealisasi - UANG MUKA" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelEBudgetingReportExcelBinding.RemainingShortfallPayment?.convertToCurrency());

									/* COLUMN "Saldo" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelEBudgetingReportExcelBinding.BudgetBalance?.convertToCurrency());
								}

								/* generate worksheet */
								const workSheet: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(componentCurrent._matrixExportExcel, { cellDates: true, dateNF: "dd/mm/yyyy" });

								/* generate filename */
								let stringDuration: string = "Range";
								let stringDivision: string = "Division";
								let stringAccount: string = "Account";

								if(componentCurrent._modelEBudgetingReportPageRequest.StartPeriod !== undefined && componentCurrent._modelEBudgetingReportPageRequest.StartPeriod !== null && componentCurrent._modelEBudgetingReportPageRequest.EndPeriod !== undefined && componentCurrent._modelEBudgetingReportPageRequest.EndPeriod !== null)
								{
									stringDuration = "" + componentCurrent._modelEBudgetingReportPageRequest.StartPeriod.convertForDisplayShortForExcel() + "-" + componentCurrent._modelEBudgetingReportPageRequest.EndPeriod.convertForDisplayShortForExcel();
								}
								else
								{

								}

								if(componentCurrent._modelEBudgetingReportPageRequest.Division !== undefined && componentCurrent._modelEBudgetingReportPageRequest.Division !== null && componentCurrent._modelEBudgetingReportPageRequest.Division !== "")
								{
									stringDivision = "" + componentCurrent._modelEBudgetingReportPageRequest.Division;
								}
								else
								{

								}

								if(componentCurrent._modelEBudgetingReportPageRequest.Account !== undefined && componentCurrent._modelEBudgetingReportPageRequest.Account !== null && componentCurrent._modelEBudgetingReportPageRequest.Account !== "")
								{
									const numberIndex = componentCurrent._modelEBudgetingReportPageRequest.Account.indexOf("|");
									stringAccount = "" + componentCurrent._modelEBudgetingReportPageRequest.Account.slice(numberIndex+2);
								}
								else
								{

								}

								const stringFileName: string = stringDivision + "_" + stringAccount + "_" + stringDuration + ".xlsx";

								/* generate workbook and add the worksheet */
								const workBook: xlsx.WorkBook = xlsx.utils.book_new();
								xlsx.utils.book_append_sheet(workBook, workSheet, "Report_Budget");

								/* save to file */
								xlsx.writeFile(workBook, stringFileName);

								const modelResponseExcel: ResponseModel = new ResponseModel();
								modelResponseExcel.MessageTitle = "Ekspor Excel";
								modelResponseExcel.MessageContent = "Pengunduhan data excel sukses! Silahkan tunggu.";
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { });

								componentCurrent._functionUserInterface.updateLoadingProgress();
							}
							else if(componentCurrent._arrayModelEBudgetingBudgetReportExcel !== undefined && componentCurrent._arrayModelEBudgetingBudgetReportExcel.length <= 0)
							{
								const modelResponseValidationError: ResponseModel = new ResponseModel();
								modelResponseValidationError.MessageTitle = "Ekspor Excel";
								modelResponseValidationError.MessageContent = "Data dalam file excel kosong! Silahkan hubungi pengembang!";
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseValidationError, () => { });
							}
							else
							{

							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectListForEBudgetingRitmikSignerExport(componentCurrent); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, modelEBudgetingReportPageRequest);
		}
		else
		{
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseRequest, () => { });
		}
	}

	/* RITMIK - END */

	//#endregion

	//#endregion


	//#region FUNCTION

	public callSelectListForEbudgeting(): void
	{
		if(this._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_WHOLESALEMAKER))
		{
			this.callSelectListForEBudgetingWholesaleMaker(this);
		}
		else if(this._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_WHOLESALECHECKER))
		{
			this.callSelectListForEBudgetingWholesaleChecker(this);
		}
		else if(this._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_WHOLESALESIGNER))
		{
			this.callSelectListForEBudgetingWholesaleSigner(this);
		}
		else if(this._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_RITMIKMAKER))
		{
			this.callSelectListForEBudgetingRitmikMaker(this);
		}
		else if(this._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_RITMIKCHECKER))
		{
			this.callSelectListForEBudgetingRitmikChecker(this);
		}
		else if(this._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_RITMIKSIGNER))
		{
			this.callSelectListForEBudgetingRitmikSigner(this);
		}
	}

	public callSelectListForEBudgetingExport(): void
	{
		if(this._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_WHOLESALEMAKER))
		{
			this.callSelectListForEBudgetingWholesaleMakerExport(this);
		}
		else if(this._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_WHOLESALECHECKER))
		{
			this.callSelectListForEBudgetingWholesaleCheckerExport(this);
		}
		else if(this._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_WHOLESALESIGNER))
		{
			this.callSelectListForEBudgetingWholesaleSignerExport(this);
		}
		else if(this._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_RITMIKMAKER))
		{
			this.callSelectListForEBudgetingRitmikMakerExport(this);
		}
		else if(this._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_RITMIKCHECKER))
		{
			this.callSelectListForEBudgetingRitmikCheckerExport(this);
		}
		else if(this._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_RITMIKSIGNER))
		{
			this.callSelectListForEBudgetingRitmikSignerExport(this);
		}
	}

	//#endregion


	//#region SETTER

	setEventEmitterSelect(modelTableUpdate: TableModel): void
	{
		this._modelTableReport = modelTableUpdate;

		if(this._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_WHOLESALEMAKER))
		{
			this.callSelectListForEBudgetingWholesaleMaker(this);
		}
		else if(this._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_WHOLESALECHECKER))
		{
			this.callSelectListForEBudgetingWholesaleChecker(this);
		}
		else if(this._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_WHOLESALESIGNER))
		{
			this.callSelectListForEBudgetingWholesaleSigner(this);
		}
		else if(this._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_RITMIKMAKER))
		{
			this.callSelectListForEBudgetingRitmikMaker(this);
		}
		else if(this._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_RITMIKCHECKER))
		{
			this.callSelectListForEBudgetingRitmikChecker(this);
		}
		else if(this._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_RITMIKSIGNER))
		{
			this.callSelectListForEBudgetingRitmikSigner(this);
		}
	}

	//#endregion


	//#region GETTER

	getStartDate(event): void
	{
		this._modelEBudgetingReportPageRequest.StartPeriod = event.value.clearUTC();
	};

	getEndDate(event): void
	{
		this._modelEBudgetingReportPageRequest.EndPeriod = event.value.clearUTC();
	};

	//#endregion
}

//#endregion