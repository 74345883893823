//#region IMPORT

import { Pipe, PipeTransform } from "@angular/core";
import { NumberConstant } from "src/app/constants/number.constant";
import { StringConstant } from "src/app/constants/string.constant";

//#endregion


//#region PIPE

@Pipe
(
	{
	name: "convertToDateMedium"
	}
)

//#endregion


//#region CLASS

export class ConvertToDateMedium implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue: unknown): unknown
	{
		const dateDate: Date = new Date(stringValue as string);

		let stringHours: string = "00";

		if (dateDate.getHours() == null || dateDate.getHours() === undefined || dateDate.getHours() === 0)
		{

		}
		else
		{
			stringHours = dateDate.getHours().toString();

			if (stringHours.length === 1)
			{
				stringHours = `0${stringHours}`;
			}
			else
			{

			}
		}

		let stringMinutes: string = "00";

		if (dateDate.getMinutes() == null || dateDate.getMinutes() === undefined || dateDate.getMinutes() === 0)
		{

		}
		else
		{
			stringMinutes = dateDate.getMinutes().toString();

			if (stringMinutes.length === 1)
			{
				stringMinutes = `0${stringMinutes}`;
			}
			else
			{

			}
		}

		let stringSeconds: string = "00";

		if (dateDate.getSeconds() == null || dateDate.getSeconds() === undefined || dateDate.getSeconds() === 0)
		{

		}
		else
		{
			stringSeconds = dateDate.getSeconds().toString();

			if (stringSeconds.length === 1)
			{
				stringSeconds = `0${stringSeconds}`;
			}
			else
			{

			}
		}

		const stringDateShort: string = new ConvertToDateShort().transform(dateDate) as string;

		return `${stringDateShort} | ${stringHours}:${stringMinutes}:${stringSeconds}`;
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
	name: "convertToDateShort"
	}
)

//#endregion


//#region CLASS

export class ConvertToDateShort implements PipeTransform
{
	//#region FUNCTION

	transform(value: unknown): unknown
	{
		if (value == null || value === undefined || value === "")
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			const dateDate: Date = new Date(value as string);

			let stringMonth: string = (dateDate.getMonth() + 1).toString();

			if (stringMonth.length === 1)
			{
				stringMonth = `0${stringMonth}`;
			}
			else
			{

			}

			if (stringMonth === "01")
			{
				stringMonth = "Januari";
			}
			else if (stringMonth === "02")
			{
				stringMonth = "Februari";
			}
			else if (stringMonth === "03")
			{
				stringMonth = "Maret";
			}
			else if (stringMonth === "04")
			{
				stringMonth = "April";
			}
			else if (stringMonth === "05")
			{
				stringMonth = "Mei";
			}
			else if (stringMonth === "06")
			{
				stringMonth = "Juni";
			}
			else if (stringMonth === "07")
			{
				stringMonth = "Juli";
			}
			else if (stringMonth === "08")
			{
				stringMonth = "Agustus";
			}
			else if (stringMonth === "09")
			{
				stringMonth = "September";
			}
			else if (stringMonth === "10")
			{
				stringMonth = "Oktober";
			}
			else if (stringMonth === "11")
			{
				stringMonth = "November";
			}
			else if (stringMonth === "12")
			{
				stringMonth = "Desember";
			}
			else
			{
				return StringConstant.STRING_CHARACTER_DASH;
			}

			return `${dateDate.getUTCDate()} ${stringMonth} ${dateDate.getFullYear()}`;
		}
	}
}
//#endregion


//#region PIPE

@Pipe
(
	{
	name: "convertToTime"
	}
)

//#endregion


//#region CLASS

export class ConvertToTime implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue: unknown): unknown
	{
		if(stringValue === undefined || stringValue === null || stringValue === "")
		{
			return "";
		}
		else
		{
			const dateDate: Date = new Date(stringValue as string);

			let stringHours: string = "00";

			if (dateDate.getHours() == null || dateDate.getHours() === undefined || dateDate.getHours() === 0)
			{

			}
			else
			{
				stringHours = dateDate.getHours().toString();

				if (stringHours.length === 1)
				{
					stringHours = `0${stringHours}`;
				}
				else
				{

				}
			}

			let stringMinutes: string = "00";

			if (dateDate.getMinutes() == null || dateDate.getMinutes() === undefined || dateDate.getMinutes() === 0)
			{

			}
			else
			{
				stringMinutes = dateDate.getMinutes().toString();

				if (stringMinutes.length === 1)
				{
					stringMinutes = `0${stringMinutes}`;
				}
				else
				{

				}
			}

			let stringSeconds: string = "00";

			if (dateDate.getSeconds() == null || dateDate.getSeconds() === undefined || dateDate.getSeconds() === 0)
			{

			}
			else
			{
				stringSeconds = dateDate.getSeconds().toString();

				if (stringSeconds.length === 1)
				{
					stringSeconds = `0${stringSeconds}`;
				}
				else
				{

				}
			}

			return `${stringHours}:${stringMinutes}:${stringSeconds}`;
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
	name: "convertToDateView"
	}
)

//#endregion


//#region CLASS

export class ConvertToDateView implements PipeTransform
{
	//#region FUNCTION

	transform(value: unknown): unknown
	{
		if (value == null || value === undefined)
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			const dateDate: Date = new Date(value as string);

			let stringDate: string = (dateDate.getDate()).toString();

			if (stringDate.length === 1)
			{
				stringDate = `0${stringDate}`;
			}
			else
			{

			}

			let stringMonth: string = (dateDate.getMonth() + 1).toString();

			if (stringMonth.length === 1)
			{
				stringMonth = `0${stringMonth}`;
			}
			else
			{

			}

			return `${stringDate}/${stringMonth}/${dateDate.getFullYear()}`;
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
	name: "initiateWithoutUTC"
	}
)

//#endregion


//#region CLASS

export class InitiateWithoutUTC implements PipeTransform
{
	//#region FUNCTION

	transform(value: unknown): unknown
	{
		if (value == null || value === undefined)
		{
			return "";
		}
		else
		{
			const dateDate: Date = new Date(value as string).initiateWithoutUTC();

			return dateDate;
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
	name: "convertEmptyToDash"
	}
)

//#endregion


//#region CLASS

export class ConvertEmptyToDash implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: any): string
	{
		if (stringValue == null || stringValue === undefined || stringValue === "")
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
		else
		{
			return stringValue as string;
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
	name: "convertNoDivisionToCustomer"
	}
)

//#endregion


//#region CLASS

export class ConvertNoDivisionToCustomer implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: any): string
	{
		if (stringValue == null || stringValue === undefined || stringValue === "")
		{
			return "Customer";
		}
		else
		{
			return stringValue as string;
		}
	}

	//#endregion
}

//#endregion

//#region PIPE

@Pipe
(
	{
	name: "convertSeparateEnumSpace"
	}
)

//#endregion


//#region CLASS

export class ConvertSeparateEnumSpace implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: any): string
	{
		if (stringValue == null || stringValue === undefined)
		{
			return stringValue as string;
		}
		else
		{
			stringValue = stringValue.replace( /([a-z])([A-Z])/g, "$1 $2" );
			stringValue = stringValue.charAt(0).toUpperCase() + stringValue.slice(1);

			return stringValue as string;
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
	name: "convertByteForPreview"
	}
)

//#endregion


//#region CLASS

export class ConvertByteForPreview implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: any): string
	{
		if (stringValue != null && stringValue !== undefined && stringValue !== "")
		{
			const numberValue: number = parseInt(stringValue, 10);

			if (numberValue < 1024)
			{
				return numberValue.toFixed(NumberConstant.NUMBER_VALUE_DECIMAL_PREVIEW).toString() + " Bytes";
			}
			else if (numberValue < 1048576)
			{
				return (numberValue / 1024).toFixed(NumberConstant.NUMBER_VALUE_DECIMAL_PREVIEW).toString() + " Kb";
			}
			else if (numberValue < 1073741824)
			{
				return (numberValue / 1048576).toFixed(NumberConstant.NUMBER_VALUE_DECIMAL_PREVIEW).toString() + " Mb";
			}
			else
			{
				return (numberValue / 1048576).toFixed(NumberConstant.NUMBER_VALUE_DECIMAL_PREVIEW).toString() + " Gb";
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY + " Bytes";
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
	name: "convertExtensionToIcon"
	}
)

//#endregion


//#region CLASS

export class ConvertExtensionToIcon implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: any): string
	{
		if (stringValue == null || stringValue === undefined || stringValue === "")
		{
			return "../../assets/icons/sakina/icon_file_image.svg";
		}
		else if (stringValue === StringConstant.STRING_FORMAT_DOCUMENT_PDF)
		{
			return "../../assets/icons/sakina/icon_file_pdf.svg";
		}
		else if (stringValue === StringConstant.STRING_FORMAT_IMAGE_JPEG || stringValue === StringConstant.STRING_FORMAT_IMAGE_JPG || stringValue === StringConstant.STRING_FORMAT_IMAGE_PNG)
		{
			return "../../assets/icons/sakina/icon_file_image.svg";
		}
		else if (stringValue === StringConstant.STRING_FORMAT_COMPRESS_RAR || stringValue === StringConstant.STRING_FORMAT_COMPRESS_ZIP)
		{
			return "../../assets/icons/sakina/icon_file_compress.svg";
		}
		else if (stringValue === StringConstant.STRING_FORMAT_DOCUMENT_EXCEL || stringValue === StringConstant.STRING_FORMAT_DOCUMENT_EXCELX)
		{
			return "../../assets/icons/sakina/icon_file_excel.svg";
		}
		else if (stringValue === StringConstant.STRING_FORMAT_DOCUMENT_CSV)
		{
			return "../../assets/icons/sakina/icon_file_csv.svg";
		}
		else if (stringValue === StringConstant.STRING_FORMAT_DOCUMENT_WORD || stringValue === StringConstant.STRING_FORMAT_DOCUMENT_WORDX)
		{
			return "../../assets/icons/sakina/icon_file_word.svg";
		}
		else
		{
			return "../../assets/icons/sakina/icon_file_image.svg";
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
	name: "currencyValue"
	}
)

//#endregion


//#region CLASS

export class CurrencyValue implements PipeTransform
{
	//#region FUNCTION

	transform(numberValue?: any): number
	{
		const stringNumberValue = String(numberValue)[0];

		if (numberValue == null || numberValue === undefined)
		{
			return 0;
		}
		else if(stringNumberValue === "0")
		{
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			return numberValue;
		}
		else
		{
			numberValue = numberValue.toLocaleString(["ban", "id"]);

			return numberValue as number;
		}
	}

	//#endregion

}

//#endregion



//#region PIPE

@Pipe
(
    {
        name: "convertEmptyNumberToDash"
    }
)

//#endregion


//#region CLASS

export class ConvertEmptyNumberToDash implements PipeTransform
{
    //#region FUNCTION

    transform(numberValue?: any): any
    {
        if (numberValue == null || numberValue === NumberConstant.NUMBER_COMPARE_VALUE_ZERO)
        {
            return StringConstant.STRING_CHARACTER_DASH;
        }
        else
        {
            return numberValue as number;
        }
    }

    //#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertEmtpyNumberToZero"
	}
)

//#endregion


//#region CLASS

export class ConvertEmtpyNumberToZero implements PipeTransform
{
	//#region FUNCTION

	transform(numberValue?: any): number
	{
		if (numberValue == null || numberValue === undefined || numberValue === 0)
		{
			return 0;
		}
		else
		{
			return numberValue as number;
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertToMonth"
	}
)

//#endregion


//#region CLASS

export class ConvertToMonth implements PipeTransform
{
	//#region FUNCTION

	transform(numberValue: number | undefined): unknown
	{
		if (numberValue == null || numberValue === undefined)
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			let stringMonth: string = (numberValue).toString();

			if (stringMonth === "1")
			{
				stringMonth = "Januari";
			}
			else if (stringMonth === "2")
			{
				stringMonth = "Februari";
			}
			else if (stringMonth === "3")
			{
				stringMonth = "Maret";
			}
			else if (stringMonth === "4")
			{
				stringMonth = "April";
			}
			else if (stringMonth === "5")
			{
				stringMonth = "Mei";
			}
			else if (stringMonth === "6")
			{
				stringMonth = "Juni";
			}
			else if (stringMonth === "7")
			{
				stringMonth = "Juli";
			}
			else if (stringMonth === "8")
			{
				stringMonth = "Agustus";
			}
			else if (stringMonth === "9")
			{
				stringMonth = "September";
			}
			else if (stringMonth === "10")
			{
				stringMonth = "Oktober";
			}
			else if (stringMonth === "11")
			{
				stringMonth = "November";
			}
			else if (stringMonth === "12")
			{
				stringMonth = "Desember";
			}
			else
			{
				return StringConstant.STRING_CHARACTER_DASH;
			}

			return `${stringMonth}`;
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertCapitalizeEachWord"
	}
)

//#endregion


//#region CLASS

export class ConvertCapitalizeEachWord implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: any): string
	{
		if (stringValue == null || stringValue === undefined || stringValue === "")
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}

		stringValue = stringValue.toLowerCase();

		const arrayString = stringValue.split(" ");

		for (let numberIndex = 0; numberIndex < arrayString.length; numberIndex++)
		{
			arrayString[numberIndex] = arrayString[numberIndex].charAt(0).toUpperCase() + arrayString[numberIndex].slice(1);
		}

		const stringResult: string = arrayString.join(" ");

		return stringResult;

	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "currencyValueWithNull"
	}
)

//#endregion


//#region CLASS

export class CurrencyValueWithNull implements PipeTransform
{
	//#region FUNCTION

	transform(numberValue?: any): string | number
	{
		if (numberValue == null || numberValue === undefined)
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
		else if (numberValue === 0 || numberValue === "0")
		{
			return 0;
		}
		else
		{
			numberValue = numberValue.toLocaleString(["ban", "id"]);

			return numberValue as number;
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertToMaskingValue"
	}
)

//#endregion


//#region CLASS

export class ConvertToMaskingValue implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: string, numberConstantValue: number = NumberConstant.NUMBER_COUNT_VALUE_IDENTIFICATIONNUMBER): any
	{
		if (stringValue == null || stringValue === undefined || stringValue === "" || stringValue === "NaN")
		{
			return "-";
		}
		else
		{
			if(numberConstantValue === NumberConstant.NUMBER_COUNT_VALUE_IDENTIFICATIONNUMBER)
			{
				return stringValue.replaceAt(NumberConstant.NUMBER_COUNT_INDEX_IDENTIFICATIONNUMBER, "*****");
			}
			else
			{
				return stringValue;
			}
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertDateToDay"
	}
)

//#endregion


//#region CLASS

export class ConvertDateToDay implements PipeTransform
{
	//#region FUNCTION

	transform(value: unknown): unknown
	{
		if (value == null || value === undefined )
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
		else
		{
			const dateDate: Date = new Date(value as string);
			const arrayWeekday: Array<string> = new Array(7);

			arrayWeekday[0]="Sunday";
			arrayWeekday[1]="Monday";
			arrayWeekday[2]="Tuesday";
			arrayWeekday[3]="Wednesday";
			arrayWeekday[4]="Thursday";
			arrayWeekday[5]="Friday";
			arrayWeekday[6]="Saturday";

			return arrayWeekday[dateDate.getDay()];
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
    {
        name: "stripHtml"
    }
)

//#endregion


//#region CLASS

export class StripHtmlPipe implements PipeTransform
{
    //#region FUNCTION

    transform(stringValue: any): any
    {
        const div = document.createElement("div");
        div.innerHTML = stringValue;
        return div.textContent || div.innerText || "";
    }

      //#endregion

}

//#endregion