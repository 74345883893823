/* eslint-disable @typescript-eslint/naming-convention */
//#region IMPORT

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { EBudgetingReportPageModel } from "../models/ebudgetingreportpage.model";
import { EBudgetingReportExcelPageModel } from "../models/ebudgetingreportexcelpage.model";
import { EBudgetingDashboardPageModel } from "../models/ebudgetingdashboardpage.model";
import { EBudgetingBankAccountDetailPageModel } from "../models/ebudgetingbankaccountdetailpage.model";
import { EBudgetingDeclarationListPageModel } from "../models/ebudgetingdeclarationlistpage.model";
import { EBudgetingDeclarationDetailPageModel } from "../models/ebudgetingdeclarationdetailpage.model";
import { EBudgetingSubmissionDetailPageModel } from "../models/ebudgetingsubmissiondetailpage.model";
import { EBudgetingSubmissionListPageModel } from "../models/ebudgetingsubmissionlistpage.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";


//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"

	}
)

//#endregion


//#region CLASS

export class EbudgetingService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	/* REPORT PAGE - START */

	//#region SELECT

	selectAccountForEBudgetingWholesaleMakerForReport(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingReportPage: EBudgetingReportPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingReportPage), WebAddressConstant.STRING_URL_EBUDGETINGREPORTPAGE_SELECTACCOUNTFOREBUDGETINGWHOLESALEMAKER);
	}

	selectListForEBudgetingWholesaleMakerForReport(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGREPORTPAGE_SELECTLISTFOREBUDGETINGWHOLESALEMAKER);
	}

	selectListForEBudgetingWholesaleMakerForReportExcel(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingReportPage: EBudgetingReportExcelPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingReportPage), WebAddressConstant.STRING_URL_EBUDGETINGREPORTEXCELPAGE_SELECTLISTFOREBUDGETINGWHOLESALEMAKER);
	}

	selectAccountForEBudgetingWholesaleCheckerForReport(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingReportPage: EBudgetingReportPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingReportPage), WebAddressConstant.STRING_URL_EBUDGETINGREPORTPAGE_SELECTACCOUNTFOREBUDGETINGWHOLESALECHECKER);
	}

	selectListForEBudgetingWholesaleCheckerForReport(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGREPORTPAGE_SELECTLISTFOREBUDGETINGWHOLESALECHECKER);
	}

	selectListForEBudgetingWholesaleCheckerForReportExcel(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingReportPage: EBudgetingReportExcelPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingReportPage), WebAddressConstant.STRING_URL_EBUDGETINGREPORTEXCELPAGE_SELECTLISTFOREBUDGETINGWHOLESALECHECKER);
	}

	selectAccountForEBudgetingWholesaleSignerForReport(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingReportPage: EBudgetingReportPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingReportPage), WebAddressConstant.STRING_URL_EBUDGETINGREPORTPAGE_SELECTACCOUNTFOREBUDGETINGWHOLESALESIGNER);
	}

	selectListForEBudgetingWholesaleSignerForReport(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGREPORTPAGE_SELECTLISTFOREBUDGETINGWHOLESALESIGNER);
	}

	selectListForEBudgetingWholesaleSignerForReportExcel(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingReportPage: EBudgetingReportExcelPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingReportPage), WebAddressConstant.STRING_URL_EBUDGETINGREPORTEXCELPAGE_SELECTLISTFOREBUDGETINGWHOLESALESIGNER);
	}

	selectAccountForEBudgetingRitmikMakerForReport(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingReportPage: EBudgetingReportPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingReportPage), WebAddressConstant.STRING_URL_EBUDGETINGREPORTPAGE_SELECTACCOUNTFOREBUDGETINGRITMIKMAKER);
	}

	selectListForEBudgetingRitmikMakerForReport(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGREPORTPAGE_SELECTLISTFOREBUDGETINGRITMIKMAKER);
	}

	selectListForEBudgetingRitmikMakerForReportExcel(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingReportPage: EBudgetingReportExcelPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingReportPage), WebAddressConstant.STRING_URL_EBUDGETINGREPORTEXCELPAGE_SELECTLISTFOREBUDGETINGRITMIKMAKER);
	}

	selectAccountForEBudgetingRitmikCheckerForReport(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingReportPage: EBudgetingReportPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingReportPage), WebAddressConstant.STRING_URL_EBUDGETINGREPORTPAGE_SELECTACCOUNTFOREBUDGETINGRITMIKCHECKER);
	}

	selectListForEBudgetingRitmikCheckerForReport(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGREPORTPAGE_SELECTLISTFOREBUDGETINGRITMIKCHECKER);
	}

	selectListForEBudgetingRitmikCheckerForReportExcel(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingReportPage: EBudgetingReportExcelPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingReportPage), WebAddressConstant.STRING_URL_EBUDGETINGREPORTEXCELPAGE_SELECTLISTFOREBUDGETINGRITMIKCHECKER);
	}

	selectAccountForEBudgetingRitmikSignerForReport(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingReportPage: EBudgetingReportPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingReportPage), WebAddressConstant.STRING_URL_EBUDGETINGREPORTPAGE_SELECTACCOUNTFOREBUDGETINGRITMIKSIGNER);
	}

	selectListForEBudgetingRitmikSignerForReport(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGREPORTPAGE_SELECTLISTFOREBUDGETINGRITMIKSIGNER);
	}

	selectListForEBudgetingRitmikSignerForReportExcel(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingReportPage: EBudgetingReportExcelPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingReportPage), WebAddressConstant.STRING_URL_EBUDGETINGREPORTEXCELPAGE_SELECTLISTFOREBUDGETINGRITMIKSIGNER);
	}

	//#endregion

	/* REPORT PAGE - END */

	/* DASHBOARD PAGE - START */

	//#region SELECT

	selectDetailForEBudgetingWholesaleMakerForDashboard(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDashboardPage: EBudgetingDashboardPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDashboardPage), WebAddressConstant.STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTDETAILFOREBUDGETINGWHOLESALEMAKER);
	}

	selectDetailForEBudgetingWholesaleCheckerForDashboard(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDashboardPage: EBudgetingDashboardPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDashboardPage), WebAddressConstant.STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTDETAILFOREBUDGETINGWHOLESALECHECKER);
	}

	selectDetailForEBudgetingWholesaleSignerForDashboard(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDashboardPage: EBudgetingDashboardPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDashboardPage), WebAddressConstant.STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTDETAILFOREBUDGETINGWHOLESALESIGNER);
	}

	selectListForEBudgetingWholesaleMakerForDashboard(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTLISTFOREBUDGETINGWHOLESALEMAKER);
	}

	selectListForEBudgetingWholesaleCheckerForDashboard(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTLISTFOREBUDGETINGWHOLESALECHECKER);
	}

	selectListForEBudgetingWholesaleSignerForDashboard(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTLISTFOREBUDGETINGWHOLESALESIGNER);
	}

	selectChartForEBudgetingWholesaleMakerForDashboard(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTCHARTFOREBUDGETINGWHOLESALEMAKER);
	}

	selectChartForEBudgetingWholesaleCheckerForDashboard(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTCHARTFOREBUDGETINGWHOLESALECHECKER);
	}

	selectChartForEBudgetingWholesaleSignerForDashboard(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTCHARTFOREBUDGETINGWHOLESALESIGNER);
	}

	selectDetailForEBudgetingRitmikMakerForDashboard(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDashboardPage: EBudgetingDashboardPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDashboardPage), WebAddressConstant.STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTDETAILFOREBUDGETINGRITMIKMAKER);
	}

	selectDetailForEBudgetingRitmikCheckerForDashboard(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDashboardPage: EBudgetingDashboardPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDashboardPage), WebAddressConstant.STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTDETAILFOREBUDGETINGRITMIKCHECKER);
	}

	selectDetailForEBudgetingRitmikSignerForDashboard(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDashboardPage: EBudgetingDashboardPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDashboardPage), WebAddressConstant.STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTDETAILFOREBUDGETINGRITMIKSIGNER);
	}

	selectListForEBudgetingRitmikMakerForDashboard(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTLISTFOREBUDGETINGRITMIKMAKER);
	}

	selectListForEBudgetingRitmikCheckerForDashboard(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTLISTFOREBUDGETINGRITMIKCHECKER);
	}

	selectListForEBudgetingRitmikSignerForDashboard(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTLISTFOREBUDGETINGRITMIKSIGNER);
	}

	selectChartForEBudgetingRitmikMakerForDashboard(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTCHARTFOREBUDGETINGRITMIKMAKER);
	}

	selectChartForEBudgetingRitmikCheckerForDashboard(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTCHARTFOREBUDGETINGRITMIKCHECKER);
	}

	selectChartForEBudgetingRitmikSignerForDashboard(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTCHARTFOREBUDGETINGRITMIKSIGNER);
	}

	//#endregion

	/* DASHBOARD PAGE - END */

	/* BANK ACCOUNT LIST PAGE - START */

	//#region SELECT

	selectListForEBudgetingWholesaleMakerForBankAccount(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGBANKACCOUNTLISTPAGE_SELECTLISTFOREBUDGETINGWHOLESALEMAKER);
	}

	selectListForEBudgetingWholesaleCheckerForBankAccount(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGBANKACCOUNTLISTPAGE_SELECTLISTFOREBUDGETINGWHOLESALECHECKER);
	}

	selectListForEBudgetingWholesaleSignerForBankAccount(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGBANKACCOUNTLISTPAGE_SELECTLISTFOREBUDGETINGWHOLESALESIGNER);
	}

	selectListForEBudgetingRitmikMakerForBankAccount(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGBANKACCOUNTLISTPAGE_SELECTLISTFOREBUDGETINGRITMIKMAKER);
	}

	selectListForEBudgetingRitmikCheckerForBankAccount(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGBANKACCOUNTLISTPAGE_SELECTLISTFOREBUDGETINGRITMIKCHECKER);
	}

	selectListForEBudgetingRitmikSignerForBankAccount(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGBANKACCOUNTLISTPAGE_SELECTLISTFOREBUDGETINGRITMIKSIGNER);
	}

	//#endregion

	/* BANK ACCOUNT LIST PAGE - END */

	/* BANK ACCOUNT DETAIL PAGE - START */

	//#region SELECT

	submitForEBudgetingWholesaleMakerForBankAccount(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingBankAccountDetailPage: EBudgetingBankAccountDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingBankAccountDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGBANKACCOUNTDETAILPAGE_SUBMITFOREBUDGETINGWHOLESALEMAKER);
	}

	submitForEBudgetingWholesaleCheckerForBankAccount(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingBankAccountDetailPage: EBudgetingBankAccountDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingBankAccountDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGBANKACCOUNTDETAILPAGE_SUBMITFOREBUDGETINGWHOLESALECHECKER);
	}

	submitForEBudgetingWholesaleSignerForBankAccount(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingBankAccountDetailPage: EBudgetingBankAccountDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingBankAccountDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGBANKACCOUNTDETAILPAGE_SUBMITFOREBUDGETINGWHOLESALESIGNER);
	}

	submitForEBudgetingRitmikMakerForBankAccount(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingBankAccountDetailPage: EBudgetingBankAccountDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingBankAccountDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGBANKACCOUNTDETAILPAGE_SUBMITFOREBUDGETINGRITMIKMAKER);
	}

	submitForEBudgetingRitmikCheckerForBankAccount(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingBankAccountDetailPage: EBudgetingBankAccountDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingBankAccountDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGBANKACCOUNTDETAILPAGE_SUBMITFOREBUDGETINGRITMIKCHECKER);
	}

	submitForEBudgetingRitmikSignerForBankAccount(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingBankAccountDetailPage: EBudgetingBankAccountDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingBankAccountDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGBANKACCOUNTDETAILPAGE_SUBMITFOREBUDGETINGRITMIKSIGNER);
	}

	//#endregion

	/* BANK ACCOUNT DETAIL PAGE - END */

	/* DECLARATION LIST PAGE - START */

	//#region SELECT

	selectSubmitterListForEBudgetingWholesaleMakerForDeclarationList(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGWHOLESALEMAKER);
	}

	selectSubmitterListForEBudgetingWholesaleCheckerForDeclarationList(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGWHOLESALECHECKER);
	}

	selectSubmitterListForEBudgetingWholesaleSignerForDeclarationList(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGWHOLESALESIGNER);
	}

	selectSubmitterListForEBudgetingRitmikMakerForDeclarationList(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGRITMIKMAKER);
	}

	selectSubmitterListForEBudgetingRitmikCheckerForDeclarationList(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGRITMIKCHECKER);
	}

	selectSubmitterListForEBudgetingRitmikSignerForDeclarationList(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGRITMIKSIGNER);
	}

	//#endregion

	//#region DOWNLOAD

	downloadDeclarationDocumentForEBudgetingWholesaleMakerForDeclarationList(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationListPage: EBudgetingDeclarationListPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationListPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONLISTPAGE_DOWNLOADDECLARATIONDOCUMENTFOREBUDGETINGWHOLESALEMAKER);
	}

	downloadDeclarationDocumentForEBudgetingRitmikMakerForDeclarationList(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationListPage: EBudgetingDeclarationListPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationListPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONLISTPAGE_DOWNLOADDECLARATIONDOCUMENTFOREBUDGETINGRITMIKMAKER);
	}

	downloadTransferReceiptForEBudgetingWholesaleMakerForDeclarationList(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationListPage: EBudgetingDeclarationListPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationListPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONLISTPAGE_DOWNLOADTRANSFERRECEIPTFOREBUDGETINGWHOLESALEMAKER);
	}

	downloadTransferReceiptForEBudgetingRitmikMakerForDeclarationList(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationListPage: EBudgetingDeclarationListPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationListPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONLISTPAGE_DOWNLOADTRANSFERRECEIPTFOREBUDGETINGRITMIKMAKER);
	}

	//#endregion

	/* DECLARATION LIST PAGE - END */

	/* DECLARATION DETAIL PAGE - START */

	//#region SELECT

	selectSubmissionListForEBudgetingWholesaleMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTSUBMISSIONLISTFOREBUDGETINGWHOLESALEMAKER);
	}

	selectSubmitterCheckerForEBudgetingWholesaleMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTSUBMITTERCHECKERFOREBUDGETINGWHOLESALEMAKER);
	}

	selectSubmitterSignerForEBudgetingWholesaleMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTSUBMITTERSIGNERFOREBUDGETINGWHOLESALEMAKER);
	}

	selectManagerCheckerForEBudgetingWholesaleMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTMANAGERCHECKERFOREBUDGETINGWHOLESALEMAKER);
	}

	selectManagerSignerForEBudgetingWholesaleMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTMANAGERSIGNERFOREBUDGETINGWHOLESALEMAKER);
	}

	selectBankAccountForEBudgetingWholesaleMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTBANKACCOUNTFOREBUDGETINGWHOLESALEMAKER);
	}

	selectCurrencyForEBudgetingWholesaleMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTCURRENCYFOREBUDGETINGWHOLESALEMAKER);
	}

	selectBranchForEBudgetingWholesaleMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTBRANCHFOREBUDGETINGWHOLESALEMAKER);
	}

	selectAccountChargeForEBudgetingWholesaleMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTACCOUNTCHARGEFOREBUDGETINGWHOLESALEMAKER);
	}

	selectSubmissionDetailForEBudgetingWholesaleMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTSUBMISSIONDETAILFOREBUDGETINGWHOLESALEMAKER);
	}

	selectSubmissionListForEBudgetingRitmikMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTSUBMISSIONLISTFOREBUDGETINGRITMIKMAKER);
	}

	selectSubmitterCheckerForEBudgetingRitmikMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTSUBMITTERCHECKERFOREBUDGETINGRITMIKMAKER);
	}

	selectSubmitterSignerForEBudgetingRitmikMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTSUBMITTERSIGNERFOREBUDGETINGRITMIKMAKER);
	}

	selectManagerCheckerForEBudgetingRitmikMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTMANAGERCHECKERFOREBUDGETINGRITMIKMAKER);
	}

	selectManagerSignerForEBudgetingRitmikMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTMANAGERSIGNERFOREBUDGETINGRITMIKMAKER);
	}

	selectBankAccountForEBudgetingRitmikMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTBANKACCOUNTFOREBUDGETINGRITMIKMAKER);
	}

	selectCurrencyForEBudgetingRitmikMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTCURRENCYFOREBUDGETINGRITMIKMAKER);
	}

	selectBranchForEBudgetingRitmikMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTBRANCHFOREBUDGETINGRITMIKMAKER);
	}

	selectAccountChargeForEBudgetingRitmikMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTACCOUNTCHARGEFOREBUDGETINGRITMIKMAKER);
	}

	selectSubmissionDetailForEBudgetingRitmikMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTSUBMISSIONDETAILFOREBUDGETINGRITMIKMAKER);
	}

	//#endregion

	//#region INSERT

	submitForEBudgetingWholesaleMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SUBMITFOREBUDGETINGWHOLESALEMAKER);
	}

	submitForEBudgetingRitmikMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SUBMITFOREBUDGETINGRITMIKMAKER);
	}

	//#endregion

	//#region SELECT

	selectDetailForEBudgetingWholesaleMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTDETAILFOREBUDGETINGWHOLESALEMAKER);
	}

	selectDetailForEBudgetingWholesaleCheckerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTDETAILFOREBUDGETINGWHOLESALECHECKER);
	}

	selectDetailForEBudgetingWholesaleSignerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTDETAILFOREBUDGETINGWHOLESALESIGNER);
	}

	selectDetailForEBudgetingRitmikMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTDETAILFOREBUDGETINGRITMIKMAKER);
	}

	selectDetailForEBudgetingRitmikCheckerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTDETAILFOREBUDGETINGRITMIKCHECKER);
	}

	selectDetailForEBudgetingRitmikSignerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTDETAILFOREBUDGETINGRITMIKSIGNER);
	}

	//#endregion

	//#region DOWNLOAD

	downloadForEBudgetingWholesaleMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_DOWNLOADFOREBUDGETINGWHOLESALEMAKER);
	}

	downloadForEBudgetingWholesaleCheckerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_DOWNLOADFOREBUDGETINGWHOLESALECHECKER);
	}

	downloadForEBudgetingWholesaleSignerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_DOWNLOADFOREBUDGETINGWHOLESALESIGNER);
	}

	downloadForEBudgetingRitmikMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_DOWNLOADFOREBUDGETINGRITMIKMAKER);
	}

	downloadForEBudgetingRitmikCheckerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_DOWNLOADFOREBUDGETINGRITMIKCHECKER);
	}

	downloadForEBudgetingRitmikSignerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_DOWNLOADFOREBUDGETINGRITMIKSIGNER);
	}

	//#endregion

	//#region UPDATE

	reviseForEBudgetingWholesaleMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_REVISEFOREBUDGETINGWHOLESALEMAKER);
	}

	reviseForEBudgetingRitmikMakerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_REVISEFOREBUDGETINGRITMIKMAKER);
	}

	//#endregion

	//#region APPROVAL

	rejectSubmitterForEBudgetingWholesaleCheckerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_REJECTSUBMITTERFOREBUDGETINGWHOLESALECHECKER);
	}

	rejectSubmitterForEBudgetingWholesaleSignerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_REJECTSUBMITTERFOREBUDGETINGWHOLESALESIGNER);
	}

	rejectSubmitterForEBudgetingRitmikCheckerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_REJECTSUBMITTERFOREBUDGETINGRITMIKCHECKER);
	}

	rejectSubmitterForEBudgetingRitmikSignerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_REJECTSUBMITTERFOREBUDGETINGRITMIKSIGNER);
	}

	approveSubmitterForEBudgetingWholesaleCheckerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_APPROVESUBMITTERFOREBUDGETINGWHOLESALECHECKER);
	}

	approveSubmitterForEBudgetingWholesaleSignerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_APPROVESUBMITTERFOREBUDGETINGWHOLESALESIGNER);
	}

	approveSubmitterForEBudgetingRitmikCheckerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_APPROVESUBMITTERFOREBUDGETINGRITMIKCHECKER);
	}

	approveSubmitterForEBudgetingRitmikSignerForDeclarationDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingDeclarationDetailPage: EBudgetingDeclarationDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingDeclarationDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_APPROVESUBMITTERFOREBUDGETINGRITMIKSIGNER);
	}

	//#endregion

	/* DECLARATION DETAIL PAGE - END */

	/* SUBMISSION LIST PAGE - START */

	//#region SELECT

	selectSubmitterListForEBudgetingWholesaleMakerForSubmissionList(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGWHOLESALEMAKER);
	}

	selectSubmitterListForEBudgetingWholesaleCheckerForSubmissionList(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGWHOLESALECHECKER);
	}

	selectSubmitterListForEBudgetingWholesaleSignerForSubmissionList(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGWHOLESALESIGNER);
	}

	selectSubmitterListForEBudgetingRitmikMakerForSubmissionList(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGRITMIKMAKER);
	}

	selectSubmitterListForEBudgetingRitmikCheckerForSubmissionList(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGRITMIKCHECKER);
	}

	selectSubmitterListForEBudgetingRitmikSignerForSubmissionList(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGRITMIKSIGNER);
	}

	//#endregion

	//#region DOWNLOAD

	downloadSubmissionDocumentForEBudgetingWholesaleMakerForSubmissionList(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionListPage: EBudgetingSubmissionListPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionListPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONLISTPAGE_DOWNLOADTRANSFERRECEIPTFOREBUDGETINGWHOLESALEMAKER);
	}

	downloadSubmissionDocumentForEBudgetingRitmikMakerForSubmissionList(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionListPage: EBudgetingSubmissionListPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionListPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONLISTPAGE_DOWNLOADTRANSFERRECEIPTFOREBUDGETINGRITMIKMAKER);
	}

	downloadTransferReceiptForEBudgetingWholesaleMakerForSubmissionList(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionListPage: EBudgetingSubmissionListPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionListPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONLISTPAGE_DOWNLOADTRANSFERRECEIPTFOREBUDGETINGWHOLESALEMAKER);
	}

	downloadTransferReceiptForEBudgetingRitmikMakerForSubmissionList(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionListPage: EBudgetingSubmissionListPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionListPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONLISTPAGE_DOWNLOADTRANSFERRECEIPTFOREBUDGETINGRITMIKMAKER);
	}

	//#endregion

	/* SUBMISSION LIST PAGE - END */

	/* SUBMISSION DETAIL PAGE - START */

	//#region SELECT

	selectSubmitterCheckerForEBudgetingWholesaleMakerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTSUBMITTERCHECKERFOREBUDGETINGWHOLESALEMAKER);
	}

	selectSubmitterSignerForEBudgetingWholesaleMakerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTSUBMITTERSIGNERFOREBUDGETINGWHOLESALEMAKER);
	}

	selectManagerCheckerForEBudgetingWholesaleMakerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTMANAGERCHECKERFOREBUDGETINGWHOLESALEMAKER);
	}

	selectManagerSignerForEBudgetingWholesaleMakerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTMANAGERSIGNERFOREBUDGETINGWHOLESALEMAKER);
	}

	selectBankAccountForEBudgetingWholesaleMakerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTBANKACCOUNTFOREBUDGETINGWHOLESALEMAKER);
	}

	selectCurrencyForEBudgetingWholesaleMakerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTCURRENCYFOREBUDGETINGWHOLESALEMAKER);
	}

	selectAccountChargeForEBudgetingWholesaleMakerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTACCOUNTCHARGEFOREBUDGETINGWHOLESALEMAKER);
	}

	selectSubmitterCheckerForEBudgetingRitmikMakerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTSUBMITTERCHECKERFOREBUDGETINGRITMIKMAKER);
	}

	selectSubmitterSignerForEBudgetingRitmikMakerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTSUBMITTERSIGNERFOREBUDGETINGRITMIKMAKER);
	}

	selectManagerCheckerForEBudgetingRitmikMakerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTMANAGERCHECKERFOREBUDGETINGRITMIKMAKER);
	}

	selectManagerSignerForEBudgetingRitmikMakerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTMANAGERSIGNERFOREBUDGETINGRITMIKMAKER);
	}

	selectBankAccountForEBudgetingRitmikMakerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTBANKACCOUNTFOREBUDGETINGRITMIKMAKER);
	}

	selectCurrencyForEBudgetingRitmikMakerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTCURRENCYFOREBUDGETINGRITMIKMAKER);
	}

	selectAccountChargeForEBudgetingRitmikMakerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTACCOUNTCHARGEFOREBUDGETINGRITMIKMAKER);
	}

	//#endregion

	//#region INSERT

	submitForEBudgetingWholesaleMakerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SUBMITFOREBUDGETINGWHOLESALEMAKER);
	}

	submitForEBudgetingRitmikMakerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SUBMITFOREBUDGETINGRITMIKMAKER);
	}

	//#endregion

	//#region SELECT

	selectDetailForEBudgetingWholesaleMakerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTDETAILFOREBUDGETINGWHOLESALEMAKER);
	}

	selectDetailForEBudgetingWholesaleCheckerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTDETAILFOREBUDGETINGWHOLESALECHECKER);
	}

	selectDetailForEBudgetingWholesaleSignerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTDETAILFOREBUDGETINGWHOLESALESIGNER);
	}

	selectDetailForEBudgetingRitmikMakerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTDETAILFOREBUDGETINGRITMIKMAKER);
	}

	selectDetailForEBudgetingRitmikCheckerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTDETAILFOREBUDGETINGRITMIKCHECKER);
	}

	selectDetailForEBudgetingRitmikSignerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTDETAILFOREBUDGETINGRITMIKSIGNER);
	}

	//#endregion

	//#region DOWNLOAD

	downloadForEBudgetingWholesaleMakerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_DOWNLOADFOREBUDGETINGWHOLESALEMAKER);
	}

	downloadForEBudgetingWholesaleCheckerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_DOWNLOADFOREBUDGETINGWHOLESALECHECKER);
	}

	downloadForEBudgetingWholesaleSignerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_DOWNLOADFOREBUDGETINGWHOLESALESIGNER);
	}

	downloadForEBudgetingRitmikMakerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_DOWNLOADFOREBUDGETINGRITMIKMAKER);
	}

	downloadForEBudgetingRitmikCheckerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_DOWNLOADFOREBUDGETINGRITMIKCHECKER);
	}

	downloadForEBudgetingRitmikSignerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_DOWNLOADFOREBUDGETINGRITMIKSIGNER);
	}

	//#endregion

	//#region UPDATE

	reviseForEBudgetingWholesaleMakerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_REVISEFOREBUDGETINGWHOLESALEMAKER);
	}

	reviseForEBudgetingRitmikMakerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_REVISEFOREBUDGETINGRITMIKMAKER);
	}

	//#endregion

	//#region APPROVAL

	rejectSubmitterForEBudgetingWholesaleCheckerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_REJECTSUBMITTERFOREBUDGETINGWHOLESALECHECKER);
	}

	rejectSubmitterForEBudgetingWholesaleSignerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_REJECTSUBMITTERFOREBUDGETINGWHOLESALESIGNER);
	}

	rejectSubmitterForEBudgetingRitmikCheckerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_REJECTSUBMITTERFOREBUDGETINGRITMIKCHECKER);
	}

	rejectSubmitterForEBudgetingRitmikSignerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_REJECTSUBMITTERFOREBUDGETINGRITMIKSIGNER);
	}

	approveSubmitterForEBudgetingWholesaleCheckerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_APPROVESUBMITTERFOREBUDGETINGWHOLESALECHECKER);
	}

	approveSubmitterForEBudgetingWholesaleSignerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_APPROVESUBMITTERFOREBUDGETINGWHOLESALESIGNER);
	}

	approveSubmitterForEBudgetingRitmikCheckerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_APPROVESUBMITTERFOREBUDGETINGRITMIKCHECKER);
	}

	approveSubmitterForEBudgetingRitmikSignerForSubmissionDetail(interfaceGeneralService: GeneralServiceInterface, modelEBudgetingSubmissionDetailPage: EBudgetingSubmissionDetailPageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelEBudgetingSubmissionDetailPage), WebAddressConstant.STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_APPROVESUBMITTERFOREBUDGETINGRITMIKSIGNER);
	}

	//#endregion

	/* SUBMISSION DETAIL PAGE - END */
}

//#endregion
