//#region IMPORT

import { Router } from "@angular/router";
import { PartialObserver } from "rxjs";
import { BusinessModel } from "src/app/models/business.model";
import { UserModel } from "src/app/models/user.model";
import { SessionService } from "src/app/services/session.service";
import { ENUM_ANOMALY_ANALYSISMETHOD, ENUM_ANOMALY_CATEGORY, ENUM_ANOMALY_QUARTER, ENUM_CATEGORY_TYPE, ENUM_DOCUMENT_TYPE, ENUM_MONTH_TYPE, ENUM_PIC_TYPE, ENUM_TAXDOCUMENT_TYPE, ENUM_TAXREQUEST_STATUS, ENUM_YEAR_TYPE } from "src/app/constants/enum.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { EnumGeneralModel } from "src/app/models/enumgeneral.model";
import { ConvertSeparateEnumSpace } from "src/app/functions/pipes/converter.pipe";
import { NumberConstant } from "src/app/constants/number.constant";
import { BasePublicComponent } from "./basepublic.component";

//#endregion


//#region CLASS

export class BaseAuthourizeComponent extends BasePublicComponent
{
	//#region DECLARATION

	public _modelUserSignIn: UserModel;
	public _modelBusinessSignIn: BusinessModel;
	public _modelNull: any = null;

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);

		this._modelUserSignIn = new UserModel();
		this._modelBusinessSignIn = new BusinessModel();

		const observerPartialModelUserSignIn: PartialObserver<UserModel> =
		{
			next: (modelUser: UserModel) => { this._modelUserSignIn = modelUser; },
			error: () => { },
			complete: () => { }
		};

		const observerPartialModelBusinessSignIn: PartialObserver<BusinessModel> =
		{
			next: (modelBusiness: BusinessModel) => { this._modelBusinessSignIn = modelBusiness; },
			error: () => { },
			complete: () => { }
		};

		this._serviceSession._modelUserSignIn.subscribe(observerPartialModelUserSignIn);
		this._serviceSession._modelBusinessSignIn.subscribe(observerPartialModelBusinessSignIn);
	}

	//#endregion


	//#region FUNCTION

	protected signOut(): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
		this._serviceSession.clearSessionQuery();

		this._router.navigate(["signin"]);
	}

	protected signOutDownload(stringURL: string): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
		this._serviceSession.clearSessionQuery();

		this._router.navigate(["signin", stringURL]);
	}

	//#endregion


	//#region CHECK

	protected checkURL(stringURLPath: string): boolean
	{
		return this._router.url.indexOf(stringURLPath) > -1;
	}

	//#endregion


	//#region GENERAL

	public generate5YearLater(numberYear: number): Array<number>
	{
		const arrayNumberYear: Array<number> = [];
		const numberMinimunYear: number = numberYear - NumberConstant.NUMBER_COMPARE_VALUE_FIVE;

		for (let numberYearCalc: number = numberYear; numberYearCalc >= numberMinimunYear; numberYearCalc--)
		{
			arrayNumberYear.push(numberYearCalc);
		}

		return arrayNumberYear;
	}

	public getListEnumByName(stringEnumName: string): Array<EnumGeneralModel>
	{
		let enumSelected: any;

		if(stringEnumName === StringConstant.STRING_ENUM_AUDIT_ANOMALYQUARTER)
		{
			enumSelected = ENUM_ANOMALY_QUARTER;
		}
		else if(stringEnumName === StringConstant.STRING_ENUM_ANOMALY_ANALYSISMETHOD)
		{
			enumSelected = ENUM_ANOMALY_ANALYSISMETHOD;
		}
		else if(stringEnumName === StringConstant.STRING_ENUM_ANOMALY_CATEGORY)
		{
			enumSelected = ENUM_ANOMALY_CATEGORY;
		}
		else if(stringEnumName === StringConstant.STRING_ENUM_DOCUMENT_TYPE)
		{
			enumSelected = ENUM_DOCUMENT_TYPE;
		}
		else if(stringEnumName === StringConstant.STRING_ENUM_CATEGORY_TYPE)
		{
			enumSelected = ENUM_CATEGORY_TYPE;
		}
		else if(stringEnumName === StringConstant.STRING_ENUM_TAXDOCUMENT_TYPE)
		{
			enumSelected = ENUM_TAXDOCUMENT_TYPE;
		}
		else if(stringEnumName === StringConstant.STRING_ENUM_PIC_TYPE)
		{
			enumSelected = ENUM_PIC_TYPE;
		}
		else if(stringEnumName === StringConstant.STRING_ENUM_MONTH_TYPE)
		{
			enumSelected = ENUM_MONTH_TYPE;
		}
		else if(stringEnumName === StringConstant.STRING_ENUM_YEAR_TYPE)
		{
			enumSelected = ENUM_YEAR_TYPE;
		}
		else if(stringEnumName === StringConstant.STRING_ENUM_TAXREQUEST_STATUS)
		{
			enumSelected = ENUM_TAXREQUEST_STATUS;
		}

		if(enumSelected)
		{
			const arrayObjectEnumGeneral = Object.keys(enumSelected)
				.filter(
					stringKey => !Number.isNaN(Number(enumSelected[stringKey])))
				.flatMap(
					stringKey => (
						[
							{ ID: enumSelected[stringKey], Name: stringKey }
						]
					)
				);

			const arrayModelEnumGeneral: Array<EnumGeneralModel> = [];
			arrayObjectEnumGeneral.forEach(
				objectEnumGeneralLoop =>
				{
					const pipeConvertSeparateEnumSpace: ConvertSeparateEnumSpace = new ConvertSeparateEnumSpace();
					const modelEnumGeneral: EnumGeneralModel = new EnumGeneralModel();
					modelEnumGeneral.ID = objectEnumGeneralLoop.ID;
					modelEnumGeneral.Name = pipeConvertSeparateEnumSpace.transform(objectEnumGeneralLoop.Name);

					arrayModelEnumGeneral.push(modelEnumGeneral);
				}
			);

			return arrayModelEnumGeneral;
		}
		else
		{
			return [];
		}

	}

	public getListArrayEnumByName(enumSelected: any): Array<EnumGeneralModel>
	{
		if(enumSelected)
		{
			const arrayObjectEnumGeneral = Object.keys(enumSelected)
				.filter(
					stringKey => !Number.isNaN(Number(enumSelected[stringKey])))
				.flatMap(
					stringKey => (
						[
							{ ID: enumSelected[stringKey], Name: stringKey }
						]
					)
				);

			const arrayModelEnumGeneral: Array<EnumGeneralModel> = [];
			arrayObjectEnumGeneral.forEach(
				objectEnumGeneralLoop =>
				{
					const pipeConvertSeparateEnumSpace: ConvertSeparateEnumSpace = new ConvertSeparateEnumSpace();
					const modelEnumGeneral: EnumGeneralModel = new EnumGeneralModel();
					modelEnumGeneral.ID = objectEnumGeneralLoop.ID;
					modelEnumGeneral.Name = pipeConvertSeparateEnumSpace.transform(objectEnumGeneralLoop.Name);

					arrayModelEnumGeneral.push(modelEnumGeneral);
				}
			);

			return arrayModelEnumGeneral;
		}
		else
		{
			return [];
		}

	}

	//#endregion


	//#region SETTER

	protected setEditorConfig(): CKEDITOR.config
	{
		const arrayStringFont: Array<string> = ["tahoma"];
		const configEditor: CKEDITOR.config =
    {
    	uiColor: "#ffffff",
    	toolbarGroups:
      [
      	{ name: "basicstyles", groups: [ "basicstyles"]},
      	{ name: "paragraph", groups: ["list"] }
      ],
    	skin: "moono",
    	resize_enabled: false,
    	removePlugins: "elementspath,save,magicline",
    	extraPlugins: "divarea,smiley,justify,indentblock,colordialog",
    	colorButton_foreStyle:
      {
      	element: "font",
      	attributes: { color: "#(color)" },
      },
    	height: 188,
    	removeDialogTabs: "image:advanced;link:advanced",
    	removeButtons: "Subscript,Superscript,Anchor,Source,Table",
    	format_tags: "p;h1;h2;h3;pre;div",
    };

		configEditor.font_names = "";
		configEditor.contentsCss = ["body { font-size : 10px}"];
		configEditor.fontSize_defaultLabel = "tahoma";
		configEditor.fontSize_sizes = "10px";

		for (let numberIndex = 0; numberIndex < arrayStringFont.length; numberIndex++)
		{
			configEditor.font_names = configEditor.font_names + ";" + arrayStringFont[numberIndex];
			arrayStringFont[numberIndex] = "http://fonts.googleapis.com/css?family=" + arrayStringFont[numberIndex].replace("", "+");
		}

		configEditor.contentsCss = ["/js/ckeditor/contents.css"].concat(
			arrayStringFont
		);

		return configEditor;
	}

	//#endregion
}

//#endregion