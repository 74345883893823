//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { BusinessModel } from "../models/business.model";
import { UserModel } from "../models/user.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion

//#region

@Injectable
(
	{
	providedIn: "root"
	}
)

//#endregion

//#region CLASS

export class BusinessService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion

	//#region SELECT

	selectBusinessByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_BUSINESS_SELECTBUSINESSRBYEMAIL);
	}

	selectChecker(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		// return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_HUMANRESOURCE_SELECTCHECKER);
	}

	selectSigner(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		// return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_HUMANRESOURCE_SELECTSIGNER);
	}

	//#endregion


	//#region BUSINESS

	verifyBusinessByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_BUSINESS_VERIFYBYBUSINESS);
	}

	signOut(interfaceGeneralService: GeneralServiceInterface, modelBusiness: BusinessModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelBusiness), WebAddressConstant.STRING_URL_BUSINESS_SIGNOUT);
	}

	//#endregion
}
