//#region IMPORT

import { ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { ConvertByteForPreview } from "../functions/pipes/converter.pipe";
import { GeneralModel } from "./bases/general.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class EBudgetingDeclarationDetailDocumentModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for EBudgeting Declaration Detail Page model, used for e-Budgeting declaration module.
		Author: Billy Johannes.
		Created on : Friday, 07 June 2024. 				Updated on : Friday, 07 June 2024.
		Created by : Billy Johannes.					Updated by : Billy Johannes.
		Version : 1.0:0.
	*/

	Name?: string;
	Format?: string;
	Extension?: string;
	Size?: number;
	Data?: string;
	DeclarationDocumentToken?: string;

	Downloaded?: boolean;

	/* Attribute - END */


	/* CONSTRUCTOR - START */

	constructor()
	{
		super();
	}

	/* CONSTRUCTOR - END */


	//#region VALIDATE

	validateAddDocument(arrayFile: Array<File>): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Declaration");

		if(arrayFile == null || arrayFile === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			for (let numberIndexRow = 0; numberIndexRow < arrayFile.length; numberIndexRow++)
			{
				for (let numberIndexColumn = 0; numberIndexColumn < arrayFile.length; numberIndexColumn++)
				{
					if (numberIndexRow !== numberIndexColumn)
					{
						if (arrayFile[numberIndexRow].name === arrayFile[numberIndexColumn].name)
						{
							modelResponse.MessageContent = "Nama lampiran yang dipilih antara satu dengan yang lainnya memiliki kesamaan! Silahkan pilih kembali!";
							return modelResponse;
						}
						else
						{

						}
					}
					else
					{

					}
				}
			}

			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			for (const file of arrayFile)
			{
				if (file.name === (this.Name) + "." + (this.Extension))
				{
					modelResponse.MessageContent = "Nama lampiran yang dipilih antara yang sudah ada dan yang di upload memiliki kesamaan! Silahkan pilih kembali!";
					return modelResponse;
				}
				else
				{

				}
			}
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	validateAdd(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Declaration document");

		if (this.Name == null || this.Name === undefined || this.Name === "")
		{
			modelResponse.MessageContent = "Nama tidak boleh kosong!";
		}
		else if (this.Format == null || this.Format === undefined)
		{
			modelResponse.MessageContent = "Format attachment tidak boleh kosong!";
		}
		else if (this.Size == null || this.Size === undefined || this.Size <= 0)
		{
			modelResponse.MessageContent = "Ukuran size attachment lebih besar dari pada 0 byte.";
		}
		else if (this.Extension == null || this.Extension === undefined || this.Extension === "")
		{
			modelResponse.MessageContent = "Attachment extension tidak boleh kosong!";
		}
		else if (StringConstant.ARRAY_VALUE_FORMAT.indexOf(this.Extension.toLowerCase()) === -1)
		{
			modelResponse.MessageContent = "Format file attachment tidak diperbolehkan!";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}


		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if (this.DeclarationDocumentToken == null || this.DeclarationDocumentToken === undefined || this.DeclarationDocumentToken === "")
			{
				if (this.Data == null || this.Data === undefined || this.Data === "")
				{
					modelResponse.MessageContent = "Data attachment tidak boleh kosong!";
				}
				else
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			const pipeConverterByteForPreview: ConvertByteForPreview = new ConvertByteForPreview();

			if (this.Extension == null || this.Extension === undefined)
			{

			}
			else
			{
				if (this.Extension.toLowerCase() === StringConstant.STRING_FORMAT_COMPRESS_ZIP || this.Extension.toLowerCase() === StringConstant.STRING_FORMAT_COMPRESS_RAR)
				{
					if (this.Size === null || this.Size === undefined)
					{

					}
					else
					{
						if (this.Size <= RuleConstant.NUMBER_FILE_COMPRESS_SIZEMAXIMUM)
						{
							modelResponse.State = ENUM_RESPONSE_STATE.Success;
						}
						else
						{
							modelResponse.MessageContent = "Maksimum ukuran file dengan tipe kompres adalah " + pipeConverterByteForPreview.transform(RuleConstant.NUMBER_FILE_COMPRESS_SIZEMAXIMUM) + ".";
						}
					}
				}
				else
				{
					if (this.Size === null || this.Size === undefined)
					{

					}
					else
					{
						if (this.Size <= RuleConstant.NUMBER_FILE_NONCOMPRESS_SIZEMAXIMUM)
						{
							modelResponse.State = ENUM_RESPONSE_STATE.Success;
						}
						else
						{
							modelResponse.MessageContent = "Maksimum ukuran file dengan tipe non kompres adalah " + pipeConverterByteForPreview.transform(RuleConstant.NUMBER_FILE_NONCOMPRESS_SIZEMAXIMUM) + ".";
						}
					}
				}
			}
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if (this.Extension == null || this.Extension === undefined)
			{

			}
			else
			{
				if (this.Extension?.toLowerCase() === StringConstant.STRING_FORMAT_COMPRESS_ZIP || this.Extension?.toLowerCase() === StringConstant.STRING_FORMAT_COMPRESS_RAR)
				{
					modelResponse.MessageContent = "Form is filled correctly.";
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
				else
				{
					if (this.Format === "")
					{
						modelResponse.MessageContent = "Format tidak boleh kosong.";
					}
					else
					{
						modelResponse.MessageContent = "Form is filled correctly.";
						modelResponse.State = ENUM_RESPONSE_STATE.Success;
					}
				}
			}
		}
		else
		{

		}

		return modelResponse;
	}

	validateDocumentToken(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Pengunduhan Lampiran.");
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERIC);

		if (this.DeclarationDocumentToken == null || this.DeclarationDocumentToken === undefined || this.DeclarationDocumentToken === "")
		{
			modelResponse.MessageContent = "Token dokumen kosong! Silahkan hubungi developer!";
		}
		else if (!regularExpression.test(this.DeclarationDocumentToken))
		{
			modelResponse.MessageContent = "Token tidak dalam format yang benar!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateCheck(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Declaration Document");

		if (this.Downloaded == null || this.Downloaded === undefined || this.Downloaded === false)
		{
			modelResponse.MessageContent = "Please download file attachment before doing the approval!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion
}

//#endregion