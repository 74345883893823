//#region IMPORT

import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { HandshakeModel } from "src/app/models/handshake.model";
import { UserInterfaceFunction } from "src/app/functions/userinterface.function";
import "../../functions/extension.function";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { UserModel } from "src/app/models/user.model";
import { Observable, of } from "rxjs";
import { ResponseModel } from "src/app/models/response.model";
import { UserSessionModel } from "src/app/models/usersession.model";
import { BusinessModel } from "src/app/models/business.model";
import { NumberConstant } from "src/app/constants/number.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { EnumGeneralModel } from "src/app/models/enumgeneral.model";
import { ENUM_ANOMALY_ANALYSISMETHOD, ENUM_ANOMALY_CATEGORY, ENUM_ANOMALY_QUARTER } from "src/app/constants/enum.constant";
import { ConvertSeparateEnumSpace } from "src/app/functions/pipes/converter.pipe";
import { HandshakeService } from "../handshake.service";
import { SessionService } from "../session.service";

//#endregion



//#region INJECTABLE

@Injectable
(
	{
	providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class BaseauthorizedGuard implements CanActivate
{
	//#region DECLARATION

	protected _modelHandshakeSignIn: HandshakeModel | null;

	protected _functionUserInterface: UserInterfaceFunction;

	//#endregion


	//#region CONSTRUCTOR

	constructor(protected _serviceHandshake: HandshakeService, protected _router: Router, protected _serviceSession: SessionService)
	{
		this._modelHandshakeSignIn = new HandshakeModel();

		_serviceSession._modelHandshakeSignIn.subscribe({
			next: (modelHandshake: HandshakeModel) => { this._modelHandshakeSignIn = modelHandshake; },
			error: () => { },
			complete: () => { }
		});

		this._functionUserInterface = new UserInterfaceFunction();
	}

	//#endregion


	//#region ACTIVATE

	canActivate(): Observable<boolean>
	{
		try
		{
			let booleanSignIn: boolean = false;

			if (this.validateModelHandshake(this._modelHandshakeSignIn))
			{
				booleanSignIn = true;
			}
			else
			{
				this._modelHandshakeSignIn = this._serviceSession.getModelHandshakeSignInFromLocalStorage();

				if (this.validateModelHandshake(this._modelHandshakeSignIn))
				{
					if (this._modelHandshakeSignIn != null)
					{
						this._serviceSession.setModelHandshakeSignInToLocalStorage(this._modelHandshakeSignIn);

						booleanSignIn = true;
					}
					else
					{

					}
				}
				else
				{

				}
			}

			if (booleanSignIn)
			{
				const modelUserSession: UserSessionModel | null = this._serviceSession.getUserSession();
				if (modelUserSession != null)
				{
					if (modelUserSession.UserToken != null || modelUserSession.UserToken === undefined || modelUserSession.UserToken === "" )
					{
						return this.callRefreshTokenForBusiness();
					}
					else
					{
						this.signOut("");
						return of(false);
					}
				}
				else
				{
					this.signOut("");
					return of(false);
				}
			}
			else
			{
				const modelResponse: ResponseModel = new ResponseModel();
				modelResponse.setSessionExpired();

				this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { this.signOut(""); });

				return of(false);
			}
		}
		catch (error: any)
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.setSessionExpired();
			modelResponse.MessageContent = error.error;

			this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { this.signOut(""); });

			return of(false);
		}
	}

	//#endregion


	//#region CALL WEBSITE SERVICE

	protected callRefreshTokenForBusiness(): Observable<boolean>
	{
		if (this._modelHandshakeSignIn != null)
		{
			const componentCurrent: BaseauthorizedGuard = this;
			const modelHandshake: HandshakeModel = new HandshakeModel();
			modelHandshake.Token = this._modelHandshakeSignIn.Token;

			return this._serviceHandshake.refreshTokenForBusiness
			(
				{
					success(modelResponse: ResponseModel): boolean
					{
						if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
						{
							const modelUser: UserModel = new UserModel();

							if (modelResponse.Data !== undefined)
							{
								modelUser.setModelFromString(modelResponse.Data);
								componentCurrent._serviceSession.setModelUserSignIn(modelUser);
							}
							else
							{
								return false;
							}

							if (modelUser.modelBusiness !== undefined)
							{
								const modelBusiness: BusinessModel = new BusinessModel();
								modelBusiness.setModelFromObject(modelUser.modelBusiness);
								componentCurrent._serviceSession.setModelBusinessSignIn(modelBusiness);
							}
							else
							{
								return false;
							}

							if (modelUser.modelHandshake !== undefined)
							{
								const modelHandshakeResponse: HandshakeModel = new HandshakeModel();
								modelHandshakeResponse.setModelFromObject(modelUser.modelHandshake);
								componentCurrent._serviceSession.setModelHandshakeSignInToLocalStorage(modelHandshakeResponse);
							}
							else
							{
								return false;
							}

							return true;
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(""); });

							return false;
						}
					},
					fail(modelResponse: ResponseModel): Observable<boolean>
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.signOut(""); });

						return of(false);
					},
					failNonObservable(modelResponse: ResponseModel): boolean
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.signOut(""); });

						return false;
					},
					signOut(modelResponse: ResponseModel): Observable<boolean>
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(""); });

						return of(false);
					},
					signOutNonObservable(modelResponse: ResponseModel): boolean
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(""); });

						return false;
					}
				}, modelHandshake
			);
		}
		else
		{
			return of(false);
		}
	}

	//#endregion


	//#region FUNCTION

	protected validateModelHandshake(modelHandshake?: HandshakeModel | null): boolean
	{
		if (modelHandshake == null || modelHandshake === undefined)
		{
			return false;
		}
		else
		{
			if (modelHandshake.Token != null && modelHandshake.Token.validateEmpty())
			{
				return true;
			}
			else
			{
				return false;
			}
		}
	}

	protected clearSession(): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
	}

	// eslint-disable-next-line no-unused-vars
	protected signOut(parameter: any): void
	{

	}

	//#endregion


	//#region GENERAL

	public generate5YearLater(numberYear: number): Array<number>
	{
		const arrayNumberYear: Array<number> = [];
		const numberMinimunYear: number = numberYear - NumberConstant.NUMBER_COMPARE_VALUE_FIVE;

		for (let numberYearCalc: number = numberYear; numberYearCalc >= numberMinimunYear; numberYearCalc--)
		{
			arrayNumberYear.push(numberYearCalc);
		}

		return arrayNumberYear;
	}

	public getListEnumByName(stringEnumName: string): Array<EnumGeneralModel>
	{
		let enumSelected: any;

		if(stringEnumName === StringConstant.STRING_ENUM_AUDIT_ANOMALYQUARTER)
		{
			enumSelected = ENUM_ANOMALY_QUARTER;
		}
		else if(stringEnumName === StringConstant.STRING_ENUM_ANOMALY_ANALYSISMETHOD)
		{
			enumSelected = ENUM_ANOMALY_ANALYSISMETHOD;
		}
		else if(stringEnumName === StringConstant.STRING_ENUM_ANOMALY_CATEGORY)
		{
			enumSelected = ENUM_ANOMALY_CATEGORY;
		}

		if(enumSelected)
		{
			const arrayObjectEnumGeneral = Object.keys(enumSelected)
				.filter(
					stringKey => !Number.isNaN(Number(enumSelected[stringKey])))
				.flatMap(
					stringKey => (
						[
							{ ID: enumSelected[stringKey], Name: stringKey }
						]
					)
				);

			const arrayModelEnumGeneral: Array<EnumGeneralModel> = [];
			arrayObjectEnumGeneral.forEach(
				objectEnumGeneralLoop =>
				{
					const pipeConvertSeparateEnumSpace: ConvertSeparateEnumSpace = new ConvertSeparateEnumSpace();
					const modelEnumGeneral: EnumGeneralModel = new EnumGeneralModel();
					modelEnumGeneral.ID = objectEnumGeneralLoop.ID;
					modelEnumGeneral.Name = pipeConvertSeparateEnumSpace.transform(objectEnumGeneralLoop.Name);

					arrayModelEnumGeneral.push(modelEnumGeneral);
				}
			);

			return arrayModelEnumGeneral;
		}
		else
		{
			return [];
		}

	}

	//#endregion

}
