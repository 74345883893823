//#region IMPORT

import { ENUM_EMPLOYMENT_TYPE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class UnderwriterModel extends BaseModel
{
	/*
		attribute - START
		Description : Attribute for underwriting model.
		Author : Andri Septiawan.
		Created on  : Monday, 11 January 2021.              Updated on : Monday, 11 January 2021.
		Created by  : Andri Septiawan.                      Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

	ID?: number;
	UserID?: number;
	EmploymentType?: ENUM_EMPLOYMENT_TYPE;
	Token?: string;
	DeviceID?: string;
	Culture?: string;
	PhotoURL?: string;

	/* attribute - END */
}
//#endregion