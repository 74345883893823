//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_IDENTIFICATION_TYPE, ENUM_MARITAL_STATUS, ENUM_REGISTER_TYPE, ENUM_REGISTRATION_STATE, ENUM_RELIGION_TYPE, ENUM_RESPONSE_STATE, ENUM_SALUTATION_TYPE, ENUM_USER_ROLE, ENUM_LETTER_ROLE, ENUM_USERGROUPID, ENUM_EMPLOYMENT_GRADE } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { BranchModel } from "./branch.model";
import { BusinessModel } from "./business.model";
import { DivisionModel } from "./division.model";
import { HandshakeModel } from "./handshake.model";
import { RelationshipManagerModel } from "./relationshipmanager.model";
import { ResponseModel } from "./response.model";
import { LetterRoleModel } from "./letterrole.model";
import { UserGroupAccessModel } from "./usergroupaccess.model";

//#endregion


//#region CLASS

export class UserModel extends BaseModel
{
	//#region ATTRIBUTE

	ID?: number;
	Salutation?: ENUM_SALUTATION_TYPE;
	FirstName?: string;
	MiddleName?: string;
	LastName?: string;
	FullName?: string;
	BirthDate?: Date;
	stringBirthDate?: string;
	BirthPlace?: string;
	Gender?: ENUM_GENDER_TYPE;
	MaritalStatus?: ENUM_MARITAL_STATUS;
	Religion?: ENUM_RELIGION_TYPE;
	NationalityID?: number;

	Address?: string;
	CountryID?: number;
	ProvinceID?: number;
	CityID?: number;
	SubDistrictID?: number;
	VillageID?: number;
	HamletCode?: string;
	NeighbourhoodCode?: string;
	PostalID?: number;
	Latitude?: number;
	Longitude?: number;

	Telephone?: string;
	Fax?: string;
	MobilePhone?: string;
	MobilePhoneNew?: string;
	MobilePhoneToken?: string;
	MobilePhoneState?: ENUM_REGISTRATION_STATE;
	Email?: string;
	EmailNew?: string;
	EmailToken?: string;
	EmailState?: ENUM_REGISTRATION_STATE;
	URLValidation?: string;

	IdentificationNumber?: string;
	IdentificationType?: ENUM_IDENTIFICATION_TYPE;
	IdentificationName?: string;
	IdentificationURL?: string;
	Password?: string;
	PasswordNew?: string;
	PasswordConfirmation?: string;
	Role?: ENUM_USER_ROLE;
	DeviceID?: string;
	Key?: string;

	UserLevelID?: number;
	UserPreferenceID?: number;
	LastLoginOn?: Date;
	FailedAttemptLogin?: number;
	SignUpType?: ENUM_REGISTER_TYPE;
	SignUpID?: string;
	SignUpToken?: string;
	Signnumberype?: ENUM_REGISTER_TYPE;

	modelDivision?: DivisionModel;
	modelBranch?: BranchModel;
	modelHandshake?: HandshakeModel;
	modelBusiness?: BusinessModel;
	modelRelationshipManager?: RelationshipManagerModel;
	Culture?: string;
	Token?: string;

	search?: string;
	DivisionID?: number;
	ProfileID?: string;
	CustomerCoreID?: string;
	BranchID?: number;

	listModelUserGroupAccess?: UserGroupAccessModel;
	listModelLetterRoleAccess?: LetterRoleModel;

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		super();
		this.modelDivision = new DivisionModel();
		this.modelBranch = new BranchModel();
		this.setModelDivison();
		this.setModelBranch();
		this.setCustomerCoreID();
	}

	//#endregion


	//#region SETTER

	setModelBranch(): void
	{
		const stringModelBranch: string = JSON.stringify(this.modelBranch);
		this.modelBranch = new BranchModel();
		this.modelBranch.setModelFromString(stringModelBranch);
	}

	setModelDivison(): void
	{
		const stringModelDivision: string = JSON.stringify(this.modelDivision);
		this.modelDivision = new DivisionModel();
		this.modelDivision.setModelFromString(stringModelDivision);
	}

	setCustomerCoreID(): void
	{
		this.CustomerCoreID = this.ProfileID;
	}

	//#endregion


	//#region GETTER

	getName(): string
	{
		if (this.FirstName == null || this.FirstName === undefined || this.FirstName === "")
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			let stringName: string = this.FirstName;

			if (this.MiddleName == null || this.MiddleName === undefined || this.MiddleName === "")
			{

			}
			else
			{
				stringName += StringConstant.STRING_CHARACTER_EMPTY + this.MiddleName;
			}

			if (this.LastName == null || this.LastName === undefined || this.LastName === "")
			{

			}
			else
			{
				stringName += StringConstant.STRING_CHARACTER_EMPTY + this.LastName;
			}

			return stringName;
		}
	}

	getNameInitial(): string
	{
		const arrayName: Array<string> = this.getName().split(StringConstant.STRING_CHARACTER_DASH);

		if (arrayName != null && arrayName !== undefined)
		{
			if (arrayName.length >= 2)
			{
				return arrayName[0].substring(0, 1) + arrayName[arrayName.length - 1].substring(0, 1);
			}
			else
			{
				return arrayName[0].substring(0, 2);
			}
		}
		else
		{
			return "";
		}
	}

	//#endregion


	//#region VALIDATION

	validateEmail(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAILWITHOUTSPACE);

		if (this.Email == null || this.Email === undefined || this.Email === "" || this.Email.replace(StringConstant.STRING_FIELD_EMAIL_BRINS, "") === "undefined" || this.Email.replace(StringConstant.STRING_FIELD_EMAIL_WORK_BRINS, "") === "undefined")
		{
			modelResponse.MessageContent = "Email can't be empty";
		}
		else if (regularExpression.test(this.Email))
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
			modelResponse.MessageContent = "Enter a valid email.";
		}

		return modelResponse;
	}

	validateEmailToken(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");

		if (this.EmailToken == null || this.EmailToken === undefined || this.EmailToken === "")
		{
			modelResponse.MessageContent = "Token Email can't be empty.";
		}
		else if (this.EmailToken.length !== RuleConstant.NUMBER_EMAILTOKEN_LENGTH)
		{
			modelResponse.MessageContent = "Token Email should be " + RuleConstant.NUMBER_EMAILTOKEN_LENGTH + " characters length.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}

	//endregion


	//#region CHECK

	checkLetterRole(enumLetterRole: ENUM_LETTER_ROLE): boolean
	{
		if (this.listModelLetterRole != null && this.listModelLetterRole !== undefined)
		{
			if (this.listModelLetterRole.length > 0)
			{
				const modelLetterRoleResult: LetterRoleModel | undefined = this.listModelLetterRole.find(function(modelLetterRoleSearch: LetterRoleModel)
				{
					return modelLetterRoleSearch.Role === enumLetterRole;
				});

				return modelLetterRoleResult !== undefined;

				// return true;
			}
			else
			{
				return false;
			}
		}
		else
		{
			return false;
		}
	}

	checkGroupAccessRCSA(stringAccessUser: string): boolean
	{
		const modelUserGroupAccess: UserGroupAccessModel = new UserGroupAccessModel();
		let booleanGroupAccess: boolean = false;

		if(this.listModelUserGroupAccess != null || this.listModelUserGroupAccess !== undefined)
		{
			const arrayModelUserGroupAccessTemp: Array<UserGroupAccessModel> = this.listModelUserGroupAccess.filter(modelUserGroupAccessFilter =>
				modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.RiskChampion ||
				modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.RiskApprover);

			if(arrayModelUserGroupAccessTemp[0] === undefined)
			{
				booleanGroupAccess = false;
			}
			else
			{
				modelUserGroupAccess.setModelFromString(JSON.stringify(arrayModelUserGroupAccessTemp[0]));

				if(stringAccessUser === StringConstant.STRING_ACCESS_USER_RISKCHAMPION)
				{
					if(modelUserGroupAccess.UserGroupID === ENUM_USERGROUPID.RiskChampion)
					{
						booleanGroupAccess = true;
					}
					else
					{
						booleanGroupAccess = false;
					}
				}
				else if(stringAccessUser === StringConstant.STRING_ACCESS_USER_RISKAPPROVER)
				{
					if(modelUserGroupAccess.UserGroupID === ENUM_USERGROUPID.RiskApprover)
					{
						booleanGroupAccess = true;
					}
					else
					{
						booleanGroupAccess = false;
					}
				}
				else
				{
					booleanGroupAccess = false;
				}
			}
		}
		else
		{
			booleanGroupAccess = false;
		}

		return booleanGroupAccess;
	}

	checkGroupAccessInternalAuditForPROTECT(enumUserGroupID: ENUM_USERGROUPID): boolean
    {
        let modelUserGroupAccess: UserGroupAccessModel = new UserGroupAccessModel();

        if(this.listModelUserGroupAccess != null || this.listModelUserGroupAccess !== undefined)
        {
            const arrayModelUserGroupAccessTemp: Array<UserGroupAccessModel> =
            this.listModelUserGroupAccess.filter(
                modelUserGroupAccessFilter =>
                    modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.TechnologyAndInformationSystemMaker ||
                    modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.TechnologyAndInformationSystemChecker ||
                    modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.TechnologyAndInformationSystemSigner
                    // modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.InternalAuditCheckerForProtectAuditee
            );

            if(arrayModelUserGroupAccessTemp)
            {
                modelUserGroupAccess = arrayModelUserGroupAccessTemp.filter(modelUserGroupAccessLoop => modelUserGroupAccessLoop.UserGroupID === enumUserGroupID)[0];
                if(modelUserGroupAccess)
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }
            else
            {
                return false;
            }
        }
        else
        {
            return false;
        }
    }

	checkUserAssociate(): boolean
	{
		if(this.modelBusiness?.EmploymentGrade && (this.modelBusiness?.EmploymentGrade >= ENUM_EMPLOYMENT_GRADE.Two && this.modelBusiness?.EmploymentGrade <= ENUM_EMPLOYMENT_GRADE.Five))
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	checkUserOfficer(): boolean
	{
		if(this.modelBusiness?.EmploymentGrade && (this.modelBusiness?.EmploymentGrade >= ENUM_EMPLOYMENT_GRADE.Six && this.modelBusiness?.EmploymentGrade <= ENUM_EMPLOYMENT_GRADE.Seven))
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	checkGroupAccessComplienceAndRiskManagement(stringAccessUser: string): boolean
	{
		const modelUserGroupAccess: UserGroupAccessModel = new UserGroupAccessModel();
		let booleanGroupAccess: boolean = false;

		if(this.listModelUserGroupAccess != null || this.listModelUserGroupAccess !== undefined)
		{
			const arrayModelUserGroupAccessTemp: Array<UserGroupAccessModel> = this.listModelUserGroupAccess.filter(modelUserGroupAccessFilter =>
				modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.RiskChampion ||
				modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.RiskApprover ||
				modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.ComplienceAndRiskManagementMaker);

			const modelUserGroupApproverFound: UserGroupAccessModel | undefined = arrayModelUserGroupAccessTemp.find(modelUserGroupApprover => modelUserGroupApprover.UserGroupID === ENUM_USERGROUPID.RiskApprover);

			if(arrayModelUserGroupAccessTemp[0] === undefined)
			{
				booleanGroupAccess = false;
			}
			else
			{
				modelUserGroupAccess.setModelFromString(JSON.stringify(arrayModelUserGroupAccessTemp[0]));

				if(stringAccessUser === StringConstant.STRING_ACCESS_USER_RISKCHAMPION)
				{
					if(modelUserGroupAccess.UserGroupID === ENUM_USERGROUPID.RiskChampion)
					{
						booleanGroupAccess = true;
					}
					else
					{
						booleanGroupAccess = false;
					}
				}
				else if(stringAccessUser === StringConstant.STRING_ACCESS_USER_MANAGEMENTRISK)
				{
					if(modelUserGroupAccess.UserGroupID === ENUM_USERGROUPID.ComplienceAndRiskManagementMaker)
					{
						booleanGroupAccess = true;
					}
					else
					{
						booleanGroupAccess = false;
					}
				}
				else
				{
					booleanGroupAccess = false;
				}

				if(stringAccessUser === StringConstant.STRING_ACCESS_USER_RISKAPPROVER && modelUserGroupApproverFound !== undefined)
				{
					if(modelUserGroupApproverFound.UserGroupID === ENUM_USERGROUPID.RiskApprover)
					{
						booleanGroupAccess = true;
					}
					else
					{

					}
				}
				else
				{

				}
			}
		}
		else
		{
			booleanGroupAccess = false;
		}

		return booleanGroupAccess;
	}

	checkGroupAccessGCGRole(stringAccessUser: string): boolean
	{
		const modelUserGroupAccess: UserGroupAccessModel = new UserGroupAccessModel();
		let booleanGroupAccess: boolean = false;

		if(this.listModelUserGroupAccess != null || this.listModelUserGroupAccess !== undefined)
		{
			const arrayModelUserGroupAccessTemp: Array<UserGroupAccessModel> = this.listModelUserGroupAccess.filter(modelUserGroupAccessFilter =>
				modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.GCGAdmin ||
				modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.GCGChecker ||
				modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.GCGSigner);

			if(arrayModelUserGroupAccessTemp[0] === undefined)
			{
				booleanGroupAccess = false;
			}
			else
			{
				modelUserGroupAccess.setModelFromString(JSON.stringify(arrayModelUserGroupAccessTemp[0]));

				if(stringAccessUser === StringConstant.STRING_ACCESS_USER_GCGADMIN)
				{
					if(modelUserGroupAccess.UserGroupID === ENUM_USERGROUPID.GCGAdmin)
					{
						booleanGroupAccess = true;
					}
					else
					{
						booleanGroupAccess = false;
					}
				}
				else if(stringAccessUser === StringConstant.STRING_ACCESS_USER_GCGCHECKER)
				{
					if(modelUserGroupAccess.UserGroupID === ENUM_USERGROUPID.GCGChecker)
					{
						booleanGroupAccess = true;
					}
					else
					{
						booleanGroupAccess = false;
					}
				}
				else if(stringAccessUser === StringConstant.STRING_ACCESS_USER_GCGSIGNER)
				{
					if(modelUserGroupAccess.UserGroupID === ENUM_USERGROUPID.GCGSigner)
					{
						booleanGroupAccess = true;
					}
					else
					{
						booleanGroupAccess = false;
					}
				}
				else
				{
					booleanGroupAccess = false;
				}
			}
		}
		else
		{
			booleanGroupAccess = false;
		}

		return booleanGroupAccess;
	}

	checkGroupAccessEBudgetingRole(stringAccessUser: string): boolean
	{
		const modelUserGroupAccess: UserGroupAccessModel = new UserGroupAccessModel();
		let booleanGroupAccess: boolean = false;

		if(this.listModelUserGroupAccess != null || this.listModelUserGroupAccess !== undefined)
		{
			const arrayModelUserGroupAccessTemp: Array<UserGroupAccessModel> = this.listModelUserGroupAccess.filter(modelUserGroupAccessFilter =>
				modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.EBudgetingWholesaleMaker ||
				modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.EBudgetingWholesaleChecker ||
				modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.EBudgetingWholesaleSigner ||
				modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.EBudgetingRitmikMaker ||
				modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.EBudgetingRitmikChecker ||
				modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.EBudgetingRitmikSigner);

			if(arrayModelUserGroupAccessTemp[0] === undefined)
			{
				booleanGroupAccess = false;
			}
			else
			{
				modelUserGroupAccess.setModelFromString(JSON.stringify(arrayModelUserGroupAccessTemp[0]));

				if(stringAccessUser === StringConstant.STRING_ACCESS_USER_EBUDGETING_WHOLESALEMAKER)
				{
					if(modelUserGroupAccess.UserGroupID === ENUM_USERGROUPID.EBudgetingWholesaleMaker)
					{
						booleanGroupAccess = true;
					}
					else
					{
						booleanGroupAccess = false;
					}
				}
				else if(stringAccessUser === StringConstant.STRING_ACCESS_USER_EBUDGETING_WHOLESALECHECKER)
				{
					if(modelUserGroupAccess.UserGroupID === ENUM_USERGROUPID.EBudgetingWholesaleChecker)
					{
						booleanGroupAccess = true;
					}
					else
					{
						booleanGroupAccess = false;
					}
				}
				else if(stringAccessUser === StringConstant.STRING_ACCESS_USER_EBUDGETING_WHOLESALESIGNER)
				{
					if(modelUserGroupAccess.UserGroupID === ENUM_USERGROUPID.EBudgetingWholesaleSigner)
					{
						booleanGroupAccess = true;
					}
					else
					{
						booleanGroupAccess = false;
					}
				}
				else if(stringAccessUser === StringConstant.STRING_ACCESS_USER_EBUDGETING_RITMIKMAKER)
					{
						if(modelUserGroupAccess.UserGroupID === ENUM_USERGROUPID.EBudgetingRitmikMaker)
						{
							booleanGroupAccess = true;
						}
						else
						{
							booleanGroupAccess = false;
						}
					}
					else if(stringAccessUser === StringConstant.STRING_ACCESS_USER_EBUDGETING_RITMIKCHECKER)
					{
						if(modelUserGroupAccess.UserGroupID === ENUM_USERGROUPID.EBudgetingRitmikChecker)
						{
							booleanGroupAccess = true;
						}
						else
						{
							booleanGroupAccess = false;
						}
					}
					else if(stringAccessUser === StringConstant.STRING_ACCESS_USER_EBUDGETING_RITMIKSIGNER)
					{
						if(modelUserGroupAccess.UserGroupID === ENUM_USERGROUPID.EBudgetingRitmikSigner)
						{
							booleanGroupAccess = true;
						}
						else
						{
							booleanGroupAccess = false;
						}
					}
				else
				{
					booleanGroupAccess = false;
				}
			}
		}
		else
		{
			booleanGroupAccess = false;
		}

		return booleanGroupAccess;
	}

	checkGroupAccessTaxRegulation(stringAccessUser: string): boolean
    {
        const modelUserGroupAccess: UserGroupAccessModel = new UserGroupAccessModel();
        let booleanGroupAccess: boolean = false;

        if(this.listModelUserGroupAccess != null || this.listModelUserGroupAccess !== undefined)
        {
            const arrayModelUserGroupAccessTemp: Array<UserGroupAccessModel> = this.listModelUserGroupAccess.filter(modelUserGroupAccessFilter =>
                modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.AKUTaxRegulation);

            if(arrayModelUserGroupAccessTemp[0] === undefined)
            {
                booleanGroupAccess = false;
            }
            else
            {
                modelUserGroupAccess.setModelFromString(JSON.stringify(arrayModelUserGroupAccessTemp[0]));

                if(stringAccessUser === StringConstant.STRING_ACCESS_USER_AKUTAXREGULATION)
                {
                    if(modelUserGroupAccess.UserGroupID === ENUM_USERGROUPID.AKUTaxRegulation)
                    {
                        booleanGroupAccess = true;
                    }
                    else
                    {
                        booleanGroupAccess = false;
                    }
                }
                else
                {
                    booleanGroupAccess = false;
                }
            }
        }
        else
        {
            booleanGroupAccess = false;
        }

        return booleanGroupAccess;
    }

	checkGroupAccessBrinstaxMaker(stringAccessUser: string): boolean
	{
		const modelUserGroupAccess: UserGroupAccessModel = new UserGroupAccessModel();
		let booleanGroupAccess: boolean = false;

		if(this.listModelUserGroupAccess != null || this.listModelUserGroupAccess !== undefined)
		{
			const arrayModelUserGroupAccessTemp: Array<UserGroupAccessModel> = this.listModelUserGroupAccess.filter(modelUserGroupAccessFilter =>
				modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.BrinsTaxReportMaker);

			if(arrayModelUserGroupAccessTemp[0] === undefined)
			{
				booleanGroupAccess = false;
			}
			else
			{
				modelUserGroupAccess.setModelFromString(JSON.stringify(arrayModelUserGroupAccessTemp[0]));

				if(stringAccessUser === StringConstant.STRING_ACCESS_USER_AKUTAXREPORT)
				{
					if(modelUserGroupAccess.UserGroupID === ENUM_USERGROUPID.BrinsTaxReportMaker)
					{
						booleanGroupAccess = true;
					}
					else
					{
						booleanGroupAccess = false;
					}
				}
				else
				{
					booleanGroupAccess = false;
				}
			}
		}
		else
		{
			booleanGroupAccess = false;
		}

		return booleanGroupAccess;
	}

	checkGroupAccessBrinstaxChecker(stringAccessUser: string): boolean
	{
		const modelUserGroupAccess: UserGroupAccessModel = new UserGroupAccessModel();
		let booleanGroupAccess: boolean = false;

		if(this.listModelUserGroupAccess != null || this.listModelUserGroupAccess !== undefined)
		{
			const arrayModelUserGroupAccessTemp: Array<UserGroupAccessModel> = this.listModelUserGroupAccess.filter(modelUserGroupAccessFilter =>
				modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.BrinsTaxReportChecker);

			if(arrayModelUserGroupAccessTemp[0] === undefined)
			{
				booleanGroupAccess = false;
			}
			else
			{
				modelUserGroupAccess.setModelFromString(JSON.stringify(arrayModelUserGroupAccessTemp[0]));

				if(stringAccessUser === StringConstant.STRING_ACCESS_USER_AKUTAXREPORT)
				{
					if(modelUserGroupAccess.UserGroupID === ENUM_USERGROUPID.BrinsTaxReportChecker)
					{
						booleanGroupAccess = true;
					}
					else
					{
						booleanGroupAccess = false;
					}
				}
				else
				{
					booleanGroupAccess = false;
				}
			}
		}
		else
		{
			booleanGroupAccess = false;
		}

		return booleanGroupAccess;
	}

	checkGroupAccessBrinstaxSigner(stringAccessUser: string): boolean
	{
		const modelUserGroupAccess: UserGroupAccessModel = new UserGroupAccessModel();
		let booleanGroupAccess: boolean = false;

		if(this.listModelUserGroupAccess != null || this.listModelUserGroupAccess !== undefined)
		{
			const arrayModelUserGroupAccessTemp: Array<UserGroupAccessModel> = this.listModelUserGroupAccess.filter(modelUserGroupAccessFilter =>
				modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.BrinsTaxReportSigner);

			if(arrayModelUserGroupAccessTemp[0] === undefined)
			{
				booleanGroupAccess = false;
			}
			else
			{
				modelUserGroupAccess.setModelFromString(JSON.stringify(arrayModelUserGroupAccessTemp[0]));

				if(stringAccessUser === StringConstant.STRING_ACCESS_USER_AKUTAXREPORT)
				{
					if(modelUserGroupAccess.UserGroupID === ENUM_USERGROUPID.BrinsTaxReportSigner)
					{
						booleanGroupAccess = true;
					}
					else
					{
						booleanGroupAccess = false;
					}
				}
				else
				{
					booleanGroupAccess = false;
				}
			}
		}
		else
		{
			booleanGroupAccess = false;
		}

		return booleanGroupAccess;
	}

    //#endregion


	//#endregion


	//#region CLEAR

	clearForSearch(): void
	{
		this.modelBranch = undefined;
		this.modelDivision = undefined;
		this.modelRelationshipManager = undefined;
	}

	//#endregion
}

//#endregion