<div id="divBodyDashboard" class="DivBodyGeneral">
    <header class="HeaderGeneral">
		<h2>Reporting</h2>
		<p>Track and export closing data to excel format.</p>
    </header>

	<div id="divMainDashboard" class="DivMainAsideSearch">
		<form>
			<div class="DivForm">
				<h3>Search</h3>

				<fieldset>
					<label for="textName">Debitur's Name</label>
					<input type="text" id="textName" name="modelProductHomeOwnershipCreditInsuredName" [(ngModel)]="_modelProductGeneralRequest.modelProductHomeOwnershipCredit!.InsuredName" placeholder="input debitur's name">

					<label for="textName">Account Number</label>
					<input type="text" id="textName" name="modelProductHomeOwnershipCreditInsuredName" [(ngModel)]="_modelProductGeneralRequest.modelProductHomeOwnershipCredit!.LoanAccountNumber" placeholder="input account number">

					<label for="datePeriod">Upload Period</label>
					<div>
						<mat-form-field appearance="fill" class="aside">
							<mat-label>Select Period</mat-label>
							<mat-date-range-input [rangePicker]="picker">
								<input matStartDate placeholder="Start Date" [value]="_modelProductGeneralRequest.PolicyStartDate" (dateChange)="getStartDate($event)">
								<input matEndDate placeholder="End Date" [value]="_modelProductGeneralRequest.PolicyEndDate" (dateChange)="getEndDate($event)">
							</mat-date-range-input>
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-date-range-picker #picker></mat-date-range-picker>
						</mat-form-field>
					</div>

					<label for="selectStatus">Status</label>
					<select type="text" id="selectStatus" name="modelTicketStatus" [(ngModel)]="_modelProductGeneralRequest.Status">
						<option [selected] [ngValue]=null>Semua</option>
						<option [ngValue]="_enumProductGeneralStatus.OnProgress">Belum Disetujui</option>
						<option [ngValue]="_enumProductGeneralStatus.RejectedSurvey">Ditolak</option>
						<option [ngValue]="_enumProductGeneralStatus.Approved">Disetujui</option>
					</select>
				</fieldset>
			</div>
			<input type="button" name="buttonSearch" value="Cari" (click)="callSelectProductByAttributes(this)">
			<input type="button" name="buttonExport" value="Ekspor" (click)="callExportClosingData(this)">
		</form>

		<div class="DivTableContainer">
			<app-tablecontrolinclude [_modelTableForm]="_modelTable" (_eventEmitterSelect)="setEventEmitterSelect($event);"></app-tablecontrolinclude>

			<table>
				<thead>
					<tr>
						<th>Upload Period</th>
						<th>Debitur's Name</th>
						<th>Closing Data Start Date</th>
						<th>Address</th>
						<th>Account Number</th>
						<th>Status</th>
					</tr>
				</thead>

				<tbody>
					<tr *ngFor="let modelProductGeneral of _arrayProductGeneralModel" (click)="goToDetail(modelProductGeneral.Token);">
						<td>{{ modelProductGeneral.CreatedOn | initiateWithoutUTC | convertToDateView }}</td>
						<td>{{ modelProductGeneral.modelProductHomeOwnershipCredit!.InsuredName | convertEmptyToDash }}</td>
						<td>{{ modelProductGeneral.PolicyStartDate | initiateWithoutUTC | convertToDateView }}</td>
						<td>{{ modelProductGeneral.modelProductHomeOwnershipCredit!.RiskLocationRemarks | convertEmptyToDash }}</td>
						<td>{{ modelProductGeneral.modelProductHomeOwnershipCredit!.LoanAccountNumber | convertEmptyToDash }}</td>
						<td>{{ modelProductGeneral.getStatus() | convertSeparateEnumSpace }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>