//#region IMPORT

import { ENUM_LETTERGENERALAPPROVAL_EBUDGETINGSTATUS } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { GeneralModel } from "./bases/general.model";

//#endregion


//#region CLASS

export class EBudgetingDeclarationDetailApprovalModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for EBudgeting Declaration Detail Page model, used for e-Budgeting declaration module.
		Author: Billy Johannes.
		Created on : Friday, 07 June 2024. 				Updated on : Friday, 07 June 2024.
		Created by : Billy Johannes.					Updated by : Billy Johannes.
		Version : 1.0:0.
	*/

	ApprovalDate?: Date;
	ApproverName?: string;
	ApprovalStatus?: ENUM_LETTERGENERALAPPROVAL_EBUDGETINGSTATUS;
	ApprovalNote?: string;

	/* Attribute - END */


	/* CONSTRUCTOR - START */

	constructor()
	{
		super();
	}

	/* CONSTRUCTOR - END */


	//#region GETTER

	getApprovalStatus(): string
	{
		let stringStatus: string = StringConstant.STRING_CHARACTER_EMPTY;

		if (this.ApprovalStatus != null && this.ApprovalStatus !== undefined)
		{
			if (this.ApprovalStatus === ENUM_LETTERGENERALAPPROVAL_EBUDGETINGSTATUS.ApprovedByEBudgetingManagerSigner)
			{
				stringStatus = "Disetujui Pengelola (Signer)";
			}
			else if (this.ApprovalStatus === ENUM_LETTERGENERALAPPROVAL_EBUDGETINGSTATUS.RejectedByEBudgetingManagerSigner)
			{
				stringStatus = "Ditolak Pengelola (Signer)";
			}
			else if (this.ApprovalStatus === ENUM_LETTERGENERALAPPROVAL_EBUDGETINGSTATUS.ApprovedByEBudgetingManagerChecker)
			{
				stringStatus = "Disetujui Pengelola (Checker)";
			}
			else if (this.ApprovalStatus === ENUM_LETTERGENERALAPPROVAL_EBUDGETINGSTATUS.RejectedByEBudgetingManagerChecker)
			{
				stringStatus = "Ditolak Pengelola (Checker)";
			}
			else if (this.ApprovalStatus === ENUM_LETTERGENERALAPPROVAL_EBUDGETINGSTATUS.ApprovedByEBudgetingSubmitterAFNChecker)
			{
				stringStatus = "Disetujui Pengaju AFN (Checker)";
			}
			else if (this.ApprovalStatus === ENUM_LETTERGENERALAPPROVAL_EBUDGETINGSTATUS.RejectedByEBudgetingSubmitterAFNChecker)
			{
				stringStatus = "Ditolak Pengaju AFN (Checker)";
			}
			else if (this.ApprovalStatus === ENUM_LETTERGENERALAPPROVAL_EBUDGETINGSTATUS.ApprovedByEBudgetingSubmitterAFNSigner)
			{
				stringStatus = "Disetujui Pengaju AFN (Signer)";
			}
			else if (this.ApprovalStatus === ENUM_LETTERGENERALAPPROVAL_EBUDGETINGSTATUS.RejectedByEBudgetingSubmitterAFNSigner)
			{
				stringStatus = "Ditolak Pengaju AFN (Signer)";
			}
			else if (this.ApprovalStatus === ENUM_LETTERGENERALAPPROVAL_EBUDGETINGSTATUS.ApprovedByEBudgetingSubmitterSigner)
			{
				stringStatus = "Disetujui Pengaju (Signer)";
			}
			else if (this.ApprovalStatus === ENUM_LETTERGENERALAPPROVAL_EBUDGETINGSTATUS.RejectedByEBudgetingSubmitterSigner)
			{
				stringStatus = "Ditolak Pengaju (Signer)";
			}
			else if (this.ApprovalStatus === ENUM_LETTERGENERALAPPROVAL_EBUDGETINGSTATUS.ApprovedByEBudgetingSubmitterChecker)
			{
				stringStatus = "Disetujui Pengaju (Checker)";
			}
			else if (this.ApprovalStatus === ENUM_LETTERGENERALAPPROVAL_EBUDGETINGSTATUS.RejectedByEBudgetingSubmitterChecker)
			{
				stringStatus = "Ditolak Pengaju (Checker)";
			}
			else if (this.ApprovalStatus === ENUM_LETTERGENERALAPPROVAL_EBUDGETINGSTATUS.SubmittedByEBudgetingSubmitterMaker)
			{
				stringStatus = "Diajukan oleh Maker Pengaju";
			}
			else
			{
				stringStatus = StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			stringStatus = StringConstant.STRING_CHARACTER_EMPTY;
		}

		return stringStatus;
	}

	//#endregion
}

//#endregion