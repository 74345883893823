/* eslint-disable max-len */
//#region IMPORT

import { Component } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { SessionService } from "src/app/services/session.service";
import { ResponseModel } from "src/app/models/response.model";
import { TicketDocumentModel } from "src/app/models/ticketdocument.model";
import { TicketdocumentService } from "src/app/services/ticketdocument.service";
import { EbudgetingService } from "src/app/services/ebudgeting.service";
import { EBudgetingDeclarationDetailPageModel } from "src/app/models/ebudgetingdeclarationdetailpage.model";
import { StringConstant } from "src/app/constants/string.constant";
import { BaseAuthourizeDetailComponent } from "../bases/baseauthourizedetail.component";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-download",
	templateUrl: "./download.component.html",
	styleUrls: ["./download.component.sass"]
	})

//#endregion


//#region CLASS

export class DownloadComponent extends BaseAuthourizeDetailComponent
{
	//#region DECLARATION

	public _modelTicketDocumentRequest: TicketDocumentModel;

	//#endregion

	//#region CONSTRUCTOR

	constructor(routeActivated: ActivatedRoute, location: Location, serviceSession: SessionService, public router: Router, private _serviceTicketDocument: TicketdocumentService, private _serviceEBudgeting: EbudgetingService)
	{
		super(routeActivated, location, serviceSession, router);
		this._modelTicketDocumentRequest = new TicketDocumentModel();
	}

	//#endregion


	//#region INITIALIZER

	ngOnInit(): void
	{
		this.getDocumentTokenAndCategoryFromURLParameter();
		this.callDownload();
	}

	//#endregion


	//#region WEB SERVICE

	callDownload(): void
	{
		if (this._stringCategory.includes("downloadTicketAttachment"))
		{
			this.callDownloadTicketAttachment(this);
		}
		else if (this._stringCategory.includes("downloadDeclarationAttachment"))
		{
			this.callDownloadDeclaration(this);
		}
	}

	callDownloadTicketAttachment(componentCurrent: DownloadComponent): void
	{
		this._functionUserInterface.setLoadingProgress(3);
		this._modelTicketDocumentRequest = new TicketDocumentModel();
		this._modelTicketDocumentRequest.Token = componentCurrent._stringDocumentToken;

		this._serviceTicketDocument.downloadTicketDocument
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.Data !== undefined)
				{
					const modelTicketDocumentResponse: TicketDocumentModel = new TicketDocumentModel();
					modelTicketDocumentResponse.setModelFromString(modelResponse.Data);

					if (modelTicketDocumentResponse !== undefined)
					{
						// eslint-disable-next-line max-len
						if (modelTicketDocumentResponse.Name == null || modelTicketDocumentResponse.Name === undefined || modelTicketDocumentResponse.Format == null || modelTicketDocumentResponse.Format === undefined || modelTicketDocumentResponse.Extension == null || modelTicketDocumentResponse.Extension === undefined || modelTicketDocumentResponse.Data == null || modelTicketDocumentResponse.Data === undefined)
						{

						}
						else
						{
							componentCurrent._functionUserInterface.downloadFileBlob(modelTicketDocumentResponse.Name, modelTicketDocumentResponse.Format, modelTicketDocumentResponse.Extension, modelTicketDocumentResponse.Data);
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callDownloadTicketAttachment(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOutDownload("downloadTicketAttachment"); });
			}
		}, this._modelTicketDocumentRequest);

		componentCurrent._functionUserInterface.updateLoadingProgress();
	}

	callDownloadDeclaration(componentCurrent: DownloadComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		const modelDocumentRequest: EBudgetingDeclarationDetailPageModel = new EBudgetingDeclarationDetailPageModel();
		modelDocumentRequest.DeclarationDocumentToken = componentCurrent._stringDocumentToken;

		if(componentCurrent._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_WHOLESALEMAKER))
		{
			componentCurrent._serviceEBudgeting.downloadForEBudgetingWholesaleMakerForDeclarationDetail
			({
				success(modelResponseSuccess: ResponseModel): void
				{
					if (modelResponseSuccess.Data !== undefined)
					{
						const modelDocumentResponse: EBudgetingDeclarationDetailPageModel = new EBudgetingDeclarationDetailPageModel();
						modelDocumentResponse.setModelFromString(modelResponseSuccess.Data);

						if (modelDocumentResponse !== undefined)
						{
							if (modelDocumentResponse.DeclarationDocumentName == null || modelDocumentResponse.DeclarationDocumentName === undefined || modelDocumentResponse.DeclarationDocumentFormat == null || modelDocumentResponse.DeclarationDocumentFormat === undefined || modelDocumentResponse.DeclarationDocumentExtension == null || modelDocumentResponse.DeclarationDocumentExtension === undefined || modelDocumentResponse.DeclarationDocumentData == null || modelDocumentResponse.DeclarationDocumentData === undefined)
							{

							}
							else
							{
								componentCurrent._functionUserInterface.downloadFileBlob(modelDocumentResponse.DeclarationDocumentName, modelDocumentResponse.DeclarationDocumentFormat, modelDocumentResponse.DeclarationDocumentExtension, modelDocumentResponse.DeclarationDocumentData);
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseSuccess, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseSuccess, () => { });
					}
				},
				fail(modelResponseFail: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponseFail, () => { componentCurrent.callDownloadDeclaration(componentCurrent); });
				},
				signOut(modelResponseSignOut: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseSignOut, () => { componentCurrent.signOutDownload("downloadDeclarationAttachment"); });
				}
			}, modelDocumentRequest);
		}
		else if(componentCurrent._modelUserSignIn.checkGroupAccessEBudgetingRole(StringConstant.STRING_ACCESS_USER_EBUDGETING_RITMIKMAKER))
		{
			componentCurrent._serviceEBudgeting.downloadForEBudgetingRitmikMakerForDeclarationDetail
			({
				success(modelResponseSuccess: ResponseModel): void
				{
					if (modelResponseSuccess.Data !== undefined)
					{
						const modelDocumentResponse: EBudgetingDeclarationDetailPageModel = new EBudgetingDeclarationDetailPageModel();
						modelDocumentResponse.setModelFromString(modelResponseSuccess.Data);

						if (modelDocumentResponse !== undefined)
						{
							if (modelDocumentResponse.DeclarationDocumentName == null || modelDocumentResponse.DeclarationDocumentName === undefined || modelDocumentResponse.DeclarationDocumentFormat == null || modelDocumentResponse.DeclarationDocumentFormat === undefined || modelDocumentResponse.DeclarationDocumentExtension == null || modelDocumentResponse.DeclarationDocumentExtension === undefined || modelDocumentResponse.DeclarationDocumentData == null || modelDocumentResponse.DeclarationDocumentData === undefined)
							{

							}
							else
							{
								componentCurrent._functionUserInterface.downloadFileBlob(modelDocumentResponse.DeclarationDocumentName, modelDocumentResponse.DeclarationDocumentFormat, modelDocumentResponse.DeclarationDocumentExtension, modelDocumentResponse.DeclarationDocumentData);
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseSuccess, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseSuccess, () => { });
					}
				},
				fail(modelResponseFail: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponseFail, () => { componentCurrent.callDownloadDeclaration(componentCurrent); });
				},
				signOut(modelResponseSignOut: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseSignOut, () => { componentCurrent.signOutDownload("downloadDeclarationAttachment"); });
				}
			}, modelDocumentRequest);
		}
	}

	//#endregion
}

//#endregion