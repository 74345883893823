/* eslint-disable @typescript-eslint/no-non-null-assertion */
//#region IMPORT

import { ENUM_CLAIMPROPOSAL_STATUS, ENUM_CLAIM_CHANNEL, ENUM_GENDER_TYPE, ENUM_IDENTIFICATION_TYPE, ENUM_INCIDENT_TYPE, ENUM_POLICY_STATUS, ENUM_REVIEW_STATE } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { UserModel } from "./user.model";

//#endregion


//#region CLASS

export class ClaimProposalModel extends BaseModel
{

	ID?: number;
	BranchCreatorCode?: string;
	BranchCreatorName?: string;
	BranchCode?: string;
	BranchName?: string;
	CertificateNumber?: string;
	TicketNumber?: string;
	Token?: string;
	PolicyNumber?: string;
	ANO?: string;
	ReferenceNumber?: string;
	PolicyStatus?: ENUM_POLICY_STATUS;
	ClaimStatus?: ENUM_CLAIMPROPOSAL_STATUS;
	Channel?: ENUM_CLAIM_CHANNEL;
	LicensePlatePrefixCode?: string;
	LicensePlateInfix?: string;
	LicensePlateSuffix?: string;
	ChasisNumber?: string;
	Email?: string;
	ProductCode?: string;
	ProductName?: string;
	Currency?: string;
	Amount?: number;
	AdjusterAmount?: number;
	IsSatisfactionSurvey?: boolean;
	isQualityControl?: boolean;

	ClaimRegisterNumber?: string;

	DriverName?: string;
	DriverLicenseNumber?: string;

	ReporterName?: string;
	ReportedOn?: Date;

	IncidentCauseOfLoss?: string;
	IncidentDate?: Date;
	IncidentDamageType?: string;
	IncidentDamageDescription?: string;
	IncidentDetailLocation?: string;
	IncidentType?: ENUM_INCIDENT_TYPE;
	IncidentChronology?: string;
	IncidentNote?: string;
	IncidentLocation?: string;
	IncidentLocationCode?: string;
	IncidentLongitude?: number;
	IncidentLatitude?: number;
	IncidentTime?: string;

	VehicleSpeed?: number;
	VehicleLocation?: string;
	VehicleLocationName?: string;
	VehicleYear?: string;
	InsuredLocation?: string;
	InsuredName?: string;

	VehicleBrandID?: number;
	VehicleBrandCode?: number;
	VehicleBrandName?: string;

	VehicleTypeID?: number;
	VehicleTypeCode?: number;
	VehicleTypeName?: string;

	VehicleColorID?: number;
	VehicleColorCode?: string;
	VehicleColorName?: string;
	VehicleChasisNumber?: string;

	InsuredSurveyLocation?: string;
	InsuredVehicleChasisNumber?: string;
	InsuredLicensePlate?: string;

	LegalResponsibility?: boolean;
	PersonalAccidentPassenger?: boolean;
	PersonalAccidentDriver?: boolean;

	Status?: ENUM_CLAIMPROPOSAL_STATUS;
	VIPStatus?: boolean;

	PolicyActiveStatus?: boolean;
	PolicyPeriodeStatus?: boolean;
	PolicyPaymentStatus?: boolean;
	PolicyGracePeriodeStatus?: boolean;
	NotifyToHeadOffice?: boolean;
	Adjuster?: boolean;

	modelUserSubmit?: UserModel;
	modelUserConfirmationBranch?: UserModel;
	modelUserSubmitSurvey?: UserModel;

	BranchReviewedNote?: string;
	BranchReviewedBy?: number;
	BranchReviewedOn?: string;
	BranchReviewedStatus?: ENUM_REVIEW_STATE;
	modelUserBranchReviewed?: UserModel;

	MakerHeadOfficeReviewedNote?: string;
	MakerHeadOfficeReviewedBy?: number;
	MakerHeadOfficeReviewedOn?: string;
	MakerHeadOfficeReviewedStatus?: ENUM_REVIEW_STATE;
	modelUserMakerHeadOfficeReviewed?: UserModel;

	CheckerHeadOfficeReviewedNote?: string;
	CheckerHeadOfficeReviewedBy?: number;
	CheckerHeadOfficeReviewedOn?: string;
	CheckerHeadOfficeReviewedStatus?: ENUM_REVIEW_STATE;
	modelUserCheckerHeadOfficeReviewed?: UserModel;

	HeadOfficeReviewedNote?: string;
	HeadOfficeReviewedBy?: number;
	HeadOfficeReviewedOn?: string;
	HeadOfficeReviewedStatus?: ENUM_REVIEW_STATE;
	modelUserHeadOfficeReviewed?: UserModel;

	SignerHeadOfficeReviewedNote?: string;
	SignerHeadOfficeReviewedBy?: number;
	SignerHeadOfficeReviewedOn?: string;
	SignerHeadOfficeReviewedStatus?: ENUM_REVIEW_STATE;
	modelUserSigner?: UserModel;
	modelUserSignerHeadOfficeReviewed?: UserModel;

	ReporterSameAsPolicyholder?: boolean;
	InsuredLocationSameAsPolicyholder?: boolean;
	DriverSameAsPolicyholder?: boolean;

	//#region CORRECTION

	MakerBranchCorrectionNote?: string;
	MakerBranchCorrectionBy?: number;
	MakerBranchCorrectionOn?: Date;
	MakerBranchCorrectionStatus?: ENUM_REVIEW_STATE;
	modelUserMakerBranchCorrection?: UserModel;

	CheckerBranchCorrectionNote?: string;
	CheckerBranchCorrectionBy?: number;
	CheckerBranchCorrectionOn?: Date;
	CheckerBranchCorrectionStatus?: ENUM_REVIEW_STATE;
	modelUserCheckerBranchCorrection?: UserModel;

	SignerBranchCorrectionNote?: string;
	SignerBranchCorrectionBy?: number;
	SignerBranchCorrectionOn?: Date;
	SignerBranchCorrectionStatus?: ENUM_REVIEW_STATE;
	modelUserSignerBranchCorrection?: UserModel;

	//#endregion


	//#region ADJUSTER

	MakerBranchAdjusterNote?: string;
	MakerBranchAdjusterBy?: number;
	MakerBranchAdjusterOn?: Date;
	MakerBranchAdjusterStatus?: ENUM_REVIEW_STATE;
	modelUserMakerBranchAdjuster?: UserModel;

	MakerHeadOfficeAdjusterBy?: number;
	MakerHeadOfficeAdjusterOn?: Date;
	modelUserMakerHeadOfficeAdjuster?: UserModel;


	CheckerBranchAdjusterNote?: string;
	CheckerBranchAdjusterBy?: number;
	CheckerBranchAdjusterOn?: Date;
	CheckerBranchAdjusterStatus?: ENUM_REVIEW_STATE;
	modelUserCheckerBranchAdjuster?: UserModel;

	SignerBranchAdjusterNote?: string;
	SignerBranchAdjusterBy?: number;
	SignerBranchAdjusterOn?: Date;
	SignerBranchAdjusterStatus?: ENUM_REVIEW_STATE;
	modelUserSignerBranchAdjuster?: UserModel;

	//#endregion

	//#region REPORTER

	ReporterFirstName?: string;
	ReporterMiddleName?: string;
	ReporterLastName?: string;
	ReporterRelationship?: string;

	ReporterAddress?: string;
	ReporterLocation?: string;
	ReporterCountryID?: number;
	ReporterCountryCode?: string;
	ReporterCountryName?: string;
	ReporterCountryNameGeolocation?: string;
	ReporterProvinceID?: number;
	ReporterProvinceCode?: string;
	ReporterProvinceName?: string;
	ReporterProvinceNameGeolocation?: string;
	ReporterCityID?: number;
	ReporterCityCode?: string;
	ReporterCityName?: string;
	ReporterCityNameGeolocation?: string;
	ReporterSubDistrictID?: number;
	ReporterSubDistrictName?: string;
	ReporterSubDistrictCode?: string;
	ReporterSubDistrictNameGeolocation?: string;
	ReporterVillageID?: number;
	ReporterVillageCode?: string;
	ReporterVillageName?: string;
	ReporterVillageNameGeolocation?: string;
	ReporterHamletCode?: string;
	ReporterNeighbourhoodCode?: string;
	ReporterPostalID?: number;
	ReporterPostalCode?: string;
	ReporterPostalName?: string;
	ReporterPostalCodeGeolocation?: string;
	ReporterLongitude?: string;
	ReporterLatitude?: string;

	ReporterTelephone?: string;
	ReporterFax?: string;
	ReporterMobilePhonePrefix?: string;
	ReporterMobilePhone?: string;
	ReporterMobilePhoneSecondary?: string;
	ReporterEmail?: string;
	ReporterEmailSecondary?: string;

	Note?: string;

	//#endregion REPORTER


	//#region SURVEY

	SurveyAddress?: string;
	SurveyApprovalNote?: string;
	SurveyAddressSameAsPolicyholder?: boolean;
	SurveyDate?: Date;
	SurveyCountryID?: number;
	SurveyCountryCode?: string;
	SurveyCountryName?: string;
	SurveyCountryNameGeolocation?: string;
	SurveyLocation?: string;
	SurveyLongitude?: string;
	SurveyLatitude?: string;
	SurveyProvinceID?: number;
	SurveyProvinceCode?: string;
	SurveyProvinceName?: string;
	SurveyProvinceNameGeolocation?: string;
	SurveyCityID?: number;
	SurveyCityCode?: string;
	SurveyCityName?: string;
	SurveyCityNameGeolocation?: string;
	SurveySubDistrictID?: number;
	SurveySubDistrictName?: string;
	SurveySubDistrictCode?: string;
	SurveySubDistrictNameGeolocation?: string;
	SurveyVillageID?: number;
	SurveyVillageCode?: string;
	SurveyVillageName?: string;
	SurveyVillageNameGeolocation?: string;
	SurveyHamletCode?: string;
	SurveyNeighbourhoodCode?: string;
	SurveyPostalID?: number;
	SurveyPostalCode?: string;
	SurveyPostalName?: string;
	SurveyPostalCodeGeolocation?: string;
	SurveyNote?: string;
	SurveyReviewedOn?: string;

	SurveyNearestBranchCode?: string;
	SurveyNearestBranchName?: string;

	//#endregion SURVEY


	/* POLICYHOLDER - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderFullName?: string;
	PolicyholderBirthDate?: Date;
	PolicyholderBirthPlace?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderTelephone?: string;
	PolicyholderMobilePhone?: string;
	PolicyholderEmail?: string;
	PolicyholderIdentificationNumber?: number;

	PolicyStartDate?: Date;
	PolicyEndDate?: Date;
	PolicyPaymentDate?: Date;
	HistoryPolicy?: string;
	PremiumAmount?: number;

	PolicyholderIdentificationType?: ENUM_IDENTIFICATION_TYPE;

	PolicyholderAddress?: string;
	PolicyholderCountryID?: number;
	PolicyholderCountryCode?: number;
	PolicyholderCountryName?: number;
	PolicyholderProvinceID?: number;
	PolicyholderProvinceCode?: number;
	PolicyholderProvinceName?: number;
	PolicyholderCityID?: number;
	PolicyholderCityCode?: number;
	PolicyholderCityName?: number;
	PolicyholderSubDistrictID?: number;
	PolicyholderVillageID?: number;
	PolicyholderHamletCode?: string;
	PolicyholderNeighbourhoodCode?: string;
	PolicyholderPostalID?: number;
	PolicyholderPostalCode?: number;
	PolicyholderPostalName?: number;
	PolicyholderLatitude?: number;
	PolicyholderLongitude?: number;

	/* POLICYHOLDER - END */


	/* EMAIL NON MOTORVEHICLE - START */

	listEmailNotification?: Array<string>;
	EmailSubject?: string;
	EmailTitle?: string;
	NotificationLossAdvanceNumber?: string;
	Reserve?: string;
	ShareInLoss?: string;
	SumInsured?: number;
	Interest?: string;
	Remarks?: string;

	/* EMAIL NON MOTORVEHICLE - END */


	/* MONITORING EXCEL - START */

	SurveyDateFirst?: Date;
	SurveyDateSecond?: Date;
	SurveyDateThird?: Date;
	SumCostEstimationAmount?: number;
	SumSalvageEstimationAmount?: number;
	TotalCost?: number;
	ReportedStartDate?: Date;
	ReportedEndDate?: Date;

	/* MONITORING EXCEL - END */


	//#region MODEL


	//#endregion


	//#region LIST MODEL



	//#endregion


	//#region CHECKER SIGNER

	FirstCheckerBy?: number;
	FirstCheckerOn?: Date;
	FirstCheckerURL?: string;
	SecondCheckerBy?: number;
	SecondCheckerOn?: Date;
	SecondCheckerURL?: string;
	FirstSignerBy?: number;
	FirstSignerOn?: Date;
	FirstSignerURL?: string;
	SecondSignerBy?: number;
	SecondSignerOn?: Date;
	SecondSignerURL?: string;
	modelUserChecker?: UserModel;

	//#endregion


	constructor()
	{
		super();
	}

	//#region GETTER

	getClaimProposalChannel(): string
	{
		if(this.Channel === ENUM_CLAIM_CHANNEL.BranchAdmin)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_CHANNEL_ADMINBRANCH;
		}
		else if(this.Channel === ENUM_CLAIM_CHANNEL.Claim)
		{
			if(this.BranchCreatorName)
			{
				return this.BranchCreatorName;
			}
			else
			{
				return StringConstant.STRING_CHARACTER_DASH;
			}
		}
		else if(this.Channel === ENUM_CLAIM_CHANNEL.CustomerWebsite)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_CHANNEL_BRINSWEBSITE;
		}
		else if(this.Channel === ENUM_CLAIM_CHANNEL.OperationAndSupport)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_CHANNEL_OPERATIONANDSUPPORT;
		}
		else if(this.Channel === ENUM_CLAIM_CHANNEL.RelationshipManager)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_CHANNEL_RELATIONSHIPMANAGER;
		}
		else if(this.Channel === ENUM_CLAIM_CHANNEL.CustomerMobile)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_CHANNEL_BRINSMOBILE;
		}
		else if(this.Channel === ENUM_CLAIM_CHANNEL.CustomerSelfServiceMonitor)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_CHANNEL_CUATOMERSELFSERVICEMONITOR;
		}
		else
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
	}

	getClaimStatus(): string
	{
		if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.Verified)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_STATUS_VERIFIED;
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.NotVerified)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_STATUS_NOTVERIFIED;
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.PendingForCorrection)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_STATUS_PENDINGFORCORRECTION;
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.Submitted)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_STATUS_SUBMITTED;
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.PendingForApprove)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_STATUS_PENDINGFORAPPROVE;
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.PendingForCorrectionChecker)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_STATUS_PENDINGFORCORRECTIONCHECKER;
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.PendingForCorrectionSigner)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_STATUS_PENDINGFORCORRECTIONSIGNER;
		}
		else
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
	}

	//#endregion


	//#region FUNCTION

	//#endregion


	//#region CLEAN

	//#endregion


	//#region CHECK

	//#endregion

}

//#endregion
