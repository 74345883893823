<div id="divBodyHome" appCheckDevTools>
	<nav [ngClass]="{ 'NavShow' : _booleanNavigation, 'NavHide' : !_booleanNavigation, 'NavNone' : _booleanNavigation === null }">
		<figure>
			<div>
				<img *ngIf="_modelBusinessSignIn.PhotoURL" id="imageProfilePicture">
				<span *ngIf="!_modelBusinessSignIn.PhotoURL" id="spanProfileNameInitial">{{ _modelUserSignIn.getNameInitial() }}</span>
			</div>

			<figcaption>
				<span id="spanProfileName">{{ _modelUserSignIn.getName() }}</span>
				<span id="spanProfilePosition">{{ _modelBusinessSignIn.getDivisionName() }}</span>
			</figcaption>

			<a class="Underline SignOut" (click)="callSignOut();">Sign out</a>
		</figure>

		<ul class="ListNavigation">
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerTicket">Ticket</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerTicket" [checked]="this._booleanNavigationItem" [(ngModel)]="_booleanNavigationItem">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToTicketReceived();">Received</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToTicketSent();">Create</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToTicketReport();">Report Ticket</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToTicketSatisfactionReport();">Report  Satisfaction Ticket</a>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerDashboard">Dashboard</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerDashboard" [checked]="this._booleanNavigationDashboard" [(ngModel)]="_booleanNavigationDashboard">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToDashboardSmart();">BRINSMART</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToDashboardEBudgeting();">e-Budgeting</a>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerKPRS">KPRS-BRI</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerKPRS" [checked]="this._booleanNavigationItem2" [(ngModel)]="_booleanNavigationItem2">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToDashboardKprs();">Dashboard</a>
					</li>
					<li class="ListItemSubNavigation" >
						<a (click)="goToClosingData();">Closing Data</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToReportData();">Reporting</a>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation">
                <a (click)="goToProductInformation();">Product information</a>
            </li>
            <li class="ListItemNavigation">
                <a (click)="goToRelationshipManager();">Relationship Manager</a>
            </li>
			<li class="ListItemNavigation" *ngIf="_modelUserSignIn.DivisionID === _enumUserGroup.RetailMicro">
				<a (click)="goToTransactionMonitoring();">Transaction Monitoring</a>
			</li>
			<li class="ListItemNavigation" *ngIf="_modelUserSignIn.DivisionID === _enumUserGroup.RetailMicro">
				<a (click)="goToClaimMonitoring();">Claim Monitoring</a>
			</li>
			<!-- <ng-container *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker) || _modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
				<li class="ListItemNavigation">
					<label for="checkBoxNavigationTriggerReimbursement">Reimbursement</label>
					<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerReimbursement" [checked]="this._booleanNavigationItemForReimbursement" [(ngModel)]="_booleanNavigationItemForReimbursement">
					<ul class="ListSubNavigation" style="font-size: 15px;">
						<li class="ListItemNavigation">
							<label for="checkBoxNavigationTriggerApproval">Approval</label>
							<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerApproval" [checked]="this._booleanNavigationItemForApproval" [(ngModel)]="_booleanNavigationItemForApproval">
							<ul class="ListSubNavigation">
								<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
									<a (click)="goToReimbursementGeneralChecker();">Checker</a>
								</li>
								<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
									<a (click)="goToReimbursementGeneralSigner();">Signer</a>
								</li>
							</ul>
						</li>
					</ul>
				</li>
			</ng-container> -->
			<!-- <li class="ListItemNavigation">
				<label for="checkBoxNavigationEmeterei">e-Meterai</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationEmeterei" [checked]="this._booleanNavigationEmaterai" [(ngModel)]="_booleanNavigationEmaterai">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Maker)">
						<a (click)="goToEmateraiForMaker();">Maker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
						<a (click)="goToEmateraiForChecker();">Checker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
						<a (click)="goToEmateraiForSigner();">Signer</a>
					</li>
				</ul>
			</li> -->
			<!-- <li class="ListItemNavigation">
				<a (click)="goToBranch();">Branch</a>
			</li> -->
			<li class="ListItemNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) || _modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER)">
				<label for="checkBoxNavigationTriggerRcsa">RCSA</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerRcsa" [checked]="this._booleanNavigationItemRCSA" [(ngModel)]="_booleanNavigationItemRCSA">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION);">
						<a (click)="goToAssessment();">Assessment</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER);">
						<a (click)="goToApproval();">Approval</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) || _modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER)">
						<a (click)="goToRCSAReport();">Report</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) || _modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER)">
						<a (click)="goToRiskIssueRequest();">Risk Issue Request</a>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation" *ngIf="(_modelUserSignIn.BranchID === undefined && (_modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_MANAGEMENTRISK) || _modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) || _modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER))) || (_modelUserSignIn.BranchID === null && (_modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_MANAGEMENTRISK) || _modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) || _modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER)))">
				<label for="checkBoxNavigationTriggerRAS">RAS</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerRAS" [checked]="this._booleanNavigationItemRAS" [(ngModel)]="_booleanNavigationItemRAS">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER)">
						<a (click)="goToRASDashboard();">Dashboard</a>
					</li>
					<li class="ListItemSubNavigation"  *ngIf="((_modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_MANAGEMENTRISK) && _modelUserSignIn.DivisionID === _enumUserGroup.RetailMicro)) || ((_modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) && _modelUserSignIn.DivisionID === _enumUserGroup.RetailMicro)) || (_modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER) && _modelUserSignIn.DivisionID === _enumUserGroup.RetailMicro)">
						<label for="checkBoxNavigationTriggerRealization">Realization</label>
						<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerRealization" [checked]="this._booleanNavigationRealization" [(ngModel)]="_booleanNavigationRealization">
						<ul class="ListSubNavigation">
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) && _modelUserSignIn.DivisionID === _enumUserGroup.RetailMicro;">
								<a (click)="goToRASRealization();">Adjustment</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) && _modelUserSignIn.DivisionID === _enumUserGroup.RetailMicro;">
								<a (click)="goToRASMonitoring();">Monitoring</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER) && _modelUserSignIn.DivisionID === _enumUserGroup.RetailMicro;">
								<a (click)="goToRASRealizationApproval();">Approval</a>
							</li>
						</ul>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER) || _modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION);">
						<a (click)="goToRASFollowUpPlan();">Follow Up Plan</a>
					</li>
				</ul>
			</li>
			<!-- <li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerApuPPT">APU PPT</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerApuPPT" [checked]="this._booleanNavigationApuPPT" [(ngModel)]="_booleanNavigationApuPPT">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToAPUPPTCustomer();">Customer Data</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToRiskBasedAssessment();">Risk Based Assessment (RBA)</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToTransaction();">Transaction</a>
					</li>
				</ul>
			</li> -->
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerGCGOnline">GCG Online</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerGCGOnline" [checked]="this._booleanNavigationItemGCGOnline" [(ngModel)]="_booleanNavigationItemGCGOnline">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="!_modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGADMIN);">
						<a (click)="goToGCGOnlineProfile();">Profile</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToGCGOnlineGratification();">Gratification</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToGCGOnlineIntegrityPact();">Integrity Pact</a>
					</li>
					<li class="ListItemSubNavigation">
						<label for="checkBoxNavigationTriggerCodeOfEthics">Code of Ethics</label>
						<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerCodeOfEthics" [checked]="this._booleanNavigationCodeOfEthics" [(ngModel)]="_booleanNavigationCodeOfEthics">
						<ul class="ListSubNavigation">
							<li class="ListItemSubNavigation">
								<a (click)="goToGCGOnlineCodeOfEthics();">Submission</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGCHECKER) || _modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGSIGNER);">
								<a (click)="goToGCGOnlineCodeOfEthicsApproval();">Approval</a>
							</li>
						</ul>
					</li>
					<li class="ListItemSubNavigation">
						<label for="checkBoxNavigationTriggerAnnualDisclosure">Annual Disclosure</label>
						<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerAnnualDisclosure" [checked]="this._booleanNavigationAnnualDisclosure" [(ngModel)]="_booleanNavigationAnnualDisclosure">
						<ul class="ListSubNavigation">
							<li class="ListItemSubNavigation">
								<a (click)="goToGCGOnlineAnnualDisclosure();">Submission</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGCHECKER) || _modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGSIGNER);">
								<a (click)="goToGCGOnlineAnnualDisclosureApproval();">Approval</a>
							</li>
						</ul>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerEBudgeting">e-Budgeting</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerEBudgeting" [checked]="this._booleanNavigationEBudgeting" [(ngModel)]="_booleanNavigationEBudgeting">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<label for="checkBoxNavigationTriggerSubmission">Submission</label>
						<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerSubmission" [checked]="this._booleanNavigationSubmission" [(ngModel)]="_booleanNavigationSubmission">
						<ul class="ListSubNavigation">
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessEBudgetingRole(_stringConstant.STRING_ACCESS_USER_EBUDGETING_WHOLESALEMAKER) || _modelUserSignIn.checkGroupAccessEBudgetingRole(_stringConstant.STRING_ACCESS_USER_EBUDGETING_RITMIKMAKER)">
								<a (click)="goToSubmissionMaker();">Maker</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessEBudgetingRole(_stringConstant.STRING_ACCESS_USER_EBUDGETING_WHOLESALECHECKER) || _modelUserSignIn.checkGroupAccessEBudgetingRole(_stringConstant.STRING_ACCESS_USER_EBUDGETING_RITMIKCHECKER)">
								<a (click)="goToSubmissionChecker();">Checker</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessEBudgetingRole(_stringConstant.STRING_ACCESS_USER_EBUDGETING_WHOLESALESIGNER) || _modelUserSignIn.checkGroupAccessEBudgetingRole(_stringConstant.STRING_ACCESS_USER_EBUDGETING_RITMIKSIGNER)">
								<a (click)="goToSubmissionSigner();">Signer</a>
							</li>
						</ul>
					</li>
					<li class="ListItemSubNavigation">
						<label for="checkBoxNavigationTriggerDeclaration">Declaration</label>
						<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerDeclaration" [checked]="this._booleanNavigationDeclaration" [(ngModel)]="_booleanNavigationDeclaration">
						<ul class="ListSubNavigation">
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessEBudgetingRole(_stringConstant.STRING_ACCESS_USER_EBUDGETING_WHOLESALEMAKER) || _modelUserSignIn.checkGroupAccessEBudgetingRole(_stringConstant.STRING_ACCESS_USER_EBUDGETING_RITMIKMAKER)">
								<a (click)="goToDeclarationMaker();">Maker</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessEBudgetingRole(_stringConstant.STRING_ACCESS_USER_EBUDGETING_WHOLESALECHECKER) || _modelUserSignIn.checkGroupAccessEBudgetingRole(_stringConstant.STRING_ACCESS_USER_EBUDGETING_RITMIKCHECKER)">
								<a (click)="goToDeclarationChecker();">Checker</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessEBudgetingRole(_stringConstant.STRING_ACCESS_USER_EBUDGETING_WHOLESALESIGNER) || _modelUserSignIn.checkGroupAccessEBudgetingRole(_stringConstant.STRING_ACCESS_USER_EBUDGETING_RITMIKSIGNER)">
								<a (click)="goToDeclarationSigner();">Signer</a>
							</li>
						</ul>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToBankAccount();">Bank Account</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToReportEbudgeting();">Report</a>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerLegalDocumentRequest">Legal Document Request</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerLegalDocumentRequest" [checked]="this._booleanNavigationItemDocumentRequest" [(ngModel)]="_booleanNavigationItemDocumentRequest">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
						<a (click)="goToDigitalizationChecker();">Checker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
						<a (click)="goToDigitalizationSigner();">Signer</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="!_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer) && !_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
                        <a (click)="goToDigitalizationMaker();">Maker</a>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation">
                <label for="checkBoxNavigationReport">BRINSTax</label>
                <input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationReport" [checked]="this._booleanNavigationBrinsTax" [(ngModel)]="_booleanNavigationBrinsTax">
                <ul class="ListSubNavigation">
                    <li class="ListItemSubNavigation">
                        <a (click)="goToTaxRegulation();">Tax Regulation</a>
                    </li>
                    <li class="ListItemSubNavigation">
                        <a (click)="goToTaxReport();">Tax Report</a>
                    </li>
					<li class="ListItemSubNavigation">
						<a (click)="goToTaxRequest();">Tax Request</a>
					</li>
                </ul>
            </li>
			<!-- <li class="ListItemNavigation">
			<li class="ListItemNavigation" (click)="callSelectAnomalyNotificationCount(this)">
				<label for="checkBoxNavigationTriggerPROTECT">Protect</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerPROTECT" [checked]="this._booleanNavigationPROTECT" [(ngModel)]="_booleanNavigationPROTECT">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToAnomaly();">Anomaly</a>
					</li>
					<li class="ListItemSubNavigation" [ngClass]="{'ListItemSubNavigationRelative' : _numberAnomalyNotification }">
						<a (click)="goToAnomalyNotification();"> Notification </a>
						<div *ngIf="_numberAnomalyNotification" class="DivNotificationAlert">
							<p>{{ _numberAnomalyNotification }}</p>
						</div>
					</li>
				</ul>
			</li> -->
            <li class="ListItemNavigation">
				<a (click)="goToAbout();">About</a>
			</li>
        </ul>

		<div id="divNotificationInstallList" *ngIf="!this._servicePWA._booleanInstalled">
			<div>
				<span>SMART</span>
				<p class="ParagraphNotificationInstallOthers" *ngIf="!this._servicePWA._booleanIOS">Avoid any hassle to return this app and gives you better experience!</p>
				<p class="ParagraphNotificationInstallIOS" *ngIf="this._servicePWA._booleanIOS">To install this web app on your device tap the 'Share' button and then 'Add to Home Screen' button.</p>
			</div>

			<input type="button" value="Install" id="buttonNotificationInstallList" *ngIf="!this._servicePWA._booleanIOS" (click)="this._servicePWA.installWebsite();">
		</div>

		<a href="https://www.ssltrust.com.au/security-report?domain=bisnis.web.brinesia.app" rel="nofollow" target="new">
		<img src="https://seals.ssltrust.com.au/report_large.png" style="border: 0px;"></a>

		<iframe src="https://securityscorecard.com/security-rating/badge/brinesia.app" width="256" height="100" frameBorder="0"></iframe>
    </nav>

    <main [ngClass]="{ 'MainHide' : _booleanNavigation, 'MainShow' : !_booleanNavigation }">
        <router-outlet></router-outlet>
    </main>

    <input type="button" (click)="toggleNavigation();" id="buttonNavigation">

	<img id="imageLogoHeader" [ngClass]="{'DisplayNone': this._booleanNavigation}" src="../../assets/logos/sakina/logo_briinsurance_header.svg" alt="BRI Insurance - Logo Header">
</div>