<div id="divBodyDashboard" class="DivBodyGeneral">
    <header class="HeaderGeneral">
		<h2>Product Information</h2>
		<p>Monitoring your sales and achive your target</p>
    </header>

	<div id="divMainDashboard" class="DivMainAsideSearch">
		<form>
			<div class="DivForm">
				<h3>Search</h3>
				<fieldset>
					<label for="selectProductType">Product type</label>
					<select id="selectProductType" name="modelProductType" [(ngModel)]="_modelProduct.Type">
						<option [ngValue]="_modelNull">All</option>
						<option [ngValue]="_enumProductTpye.Conventional">Conventional</option>
						<option [ngValue]="_enumProductTpye.Special">Special</option>
						<option [ngValue]="_enumProductTpye.Syariah">Syariah</option>
						<option [ngValue]="_enumProductTpye.Customize">Customize</option>
					</select>

					<label for="textProductName">Product name</label>
					<input type="text" id="textProductName" name="modelProductName" [(ngModel)]="_modelProduct.Name" placeholder="input product name" >
				</fieldset>
			</div>
			<input type="button" name="buttonSearch" value="Search" (click)="callSelectProductByAttributes();">
		</form>

		<div class="DivTableContainer">
			<app-tablecontrolinclude [_modelTableForm]="_modelTable" (_eventEmitterSelect)="setEventEmitterSelect($event);"></app-tablecontrolinclude>

			<ul class="ListTableThumbnails">
				<li *ngFor="let modelProduct of _arrayModelProduct; let numberIndex = index" class="Clear">
					<figure>
						<img src="../../assets/icons/sakina/icon_pdf.svg" alt="{{ modelProduct.Name }}" (click)="goToPDF(modelProduct.InformationPDFURL);">
						<figcaption>{{ modelProduct.Name | trimShort }}</figcaption>
					</figure>
					<div class="DivButtonNextCalculate">
						<div [ngClass]="{ 'DivButton' : this._booleanContainerCalculate[numberIndex] === true , 'DivButtonHide' : this._booleanContainerCalculate[numberIndex] === false }">
							<input type="button" id="buttonCalculator" name="Calculator" (click)="goToCalculatorPremium(modelProduct.Code);">
						</div>
						<div class="DivButton">
							<input type="button" class="ButtonCopy" id="buttonShare" name="Share" (click)="shareProductURL(modelProduct.InformationPDFURL);">
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</div>