//#region IMPORT

import { environment } from "src/environments/environment";

//#endregion


//#region CLASS

export class WebAddressConstant
{
	//#region IPADDRESS

	static STRING_STRING_WEBADDRESS_WEBSITE_IPADRESS_BRINS: string = "www.brins.co.id";

	//#endregion


	//#region URL API

	static STRING_URL_USER_API: string = environment.URL_USER_API;
	static STRING_URL_PRODUCT_API: string = environment.URL_PRODUCT_API;
	static STRING_URL_POLICY_API: string = environment.URL_POLICY_API;
	static STRING_URL_BROADCAST_API: string = environment.URL_BROADCAST_API;
	static STRING_URL_INTEGRATION_API: string = environment.URL_INTEGRATION_API;
	static STRING_URL_INTERNAL_WEB: string = environment.URL_INTERNAL_WEB;

	//#endregion


	//#region URL WEBSITE SOCKET

	/*
	URL_USER_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_USER_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_USER;
	URLT_PRODUCT_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_PRODUCT_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_PRODUCT;
	URL_POLICY_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_POLICY_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_POLICY;
	*/

	//#endregion


	//#region HANDSHAKE SERVICE

	static STRING_PATH_CONTROLLER_HANDSHAKE: string = "/Handshake";

	static STRING_URL_HANDSHAKE_GENERATETOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/generateToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshToken`;
	static STRING_URL_HANDSHAKE_SELECTUSERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/selectUserByToken`;

	static STRING_URL_HANDSHAKE_REFRESHTOKENFORBUSINESS: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshTokenForBusiness`;

	//#endregion


	//#region USER CONTROLLER LEAD

	static STRING_PATH_CONTROLLER_LEAD: string = "/Lead";

	static STRING_URL_LEAD_SELECTLEADBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LEAD}/selectLeadByAttributes`;
	static STRING_URL_LEAD_SELECTLEADBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LEAD}/selectLeadByToken`;
	static STRING_URL_LEAD_INSERTLEAD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LEAD}/insertLead`;
	static STRING_URL_LEAD_UPDATELEADBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LEAD}/updateLeadByToken`;
	static STRING_URL_LEAD_DELETELEADBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LEAD}/deleteLeadByToken`;
	static STRING_URL_LEAD_SELECTLEADBYRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LEAD}/selectLeadByRelationshipManager`;
	static STRING_URL_LEAD_SELECTLEADBYATTRIBUTESFORRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LEAD}/selectLeadByAttributesForRelationshipManager`;

	//#endregion


	//#region RELATIONSHIP MANAGER CONTROLLER

	static STRING_PATH_CONTROLLER_RELATIONSHIPMANAGER: string = "/RelationshipManager";

	static STRING_URL_RELATIONSHIPMANAGER_SELECTRELATIONSHIPMANAGERBYATTRIBUTESFORBUSINESS: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RELATIONSHIPMANAGER}/SelectRelationshipManagerByAttributesForBusiness`;

	static STRING_URL_RELATIONSHIPMANAGER_SELECTRELATIONSHIPMANAGERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RELATIONSHIPMANAGER}/selectRelationshipManagerByToken`;

	//#endregion


	//#region BRANCH CONTROLLER

	static STRING_PATH_CONTROLLER_BRANCH: string = "/Branch";

	static STRING_URL_BRANCH_SELECTBRANCH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranch`;
	static STRING_URL_BRANCH_SELECTBRANCHFORTICKETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForTicketByAttributes`;

	//#endregion


	//#region DIVISION

	static STRING_PATH_CONTROLLER_DIVISION: string = "/Division";

	static STRING_URL_DIVISION_SELECTDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DIVISION}/selectDivision`;

	//#endregion


	//#region CONTROLLER PRODUCT ASRI

	static STRING_PATH_CONTROLLER_PRODUCTASRI: string = "/ProductAsri";

	static STRING_URL_PRODUCTASRI_SELECTPAYMENTBYATTRIBUTES = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/selectPaymentByAttributes`;
	static STRING_URL_PRODUCTASRI_SELECTPRODUCTBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/selectProductByToken`;
	static STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMASRI: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/calculatePremium`;
	static STRING_URL_PRODUCT_SENDCALCULATEPREMIUMASRIBYEMAILFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/sendCalculatePremiumByEmailForOperationAndSupport`;

	//#endregion


	//#region PRODUCT ASRI SYARIAH CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTASRISYARIAH: string = "/ProductAsriSyariah";

	static STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMASRISYARIAH: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRISYARIAH}/calculatePremium`;
	static STRING_URL_PRODUCT_SENDCALCULATEPREMIUMASRISYARIAHBYEMAILFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRISYARIAH}/sendCalculatePremiumByEmailForOperationAndSupport`;

	//#endregion


	//#region PRODUCT OTO CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTOTO: string = "/ProductOto";

	static STRING_URL_PRODUCTOTO_SELECTPAYMENTBYATTRIBUTES = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/selectPaymentByAttributes`;
	static STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMOTO: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/calculatePremium`;
	static STRING_URL_PRODUCT_SENDCALCULATEPREMIUMOTOBYEMAILFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/sendCalculatePremiumByEmailForOperationAndSupport`;

	//#endregion


	//#region PRODUCT OTO SYARIAH CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTOTOSYARIAH: string = "/ProductOtoSyariah";

	static STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMOTOSYARIAH: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTOSYARIAH}/calculatePremium`;
	static STRING_URL_PRODUCT_SENDCALCULATEPREMIUMOTOSYARIAHBYEMAILFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTOSYARIAH}/sendCalculatePremiumByEmailForOperationAndSupport`;

	//#endregion


	//#region PRODUCT PERSONAL ACCIDENT CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT: string = "/ProductPersonalAccident";

	static STRING_URL_PRODUCTPERSONALACCIDENT_SELECTPAYMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/selectPaymentByAttributes`;
	static STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMPERSONALACCIDENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/calculatePremium`;
	static STRING_URL_PRODUCT_SENDCALCULATEPREMIUMPERSONALACCIDENTBYEMAILFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/sendCalculatePremiumByEmailForOperationAndSupport`;

	//#endregion


	//#region PRODUCT PERSONAL ACCIDENT SYARIAH CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENTSYARIAH: string = "/ProductPersonalAccidentSyariah";

	static STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMPERSONALACCIDENTSYARIAH: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENTSYARIAH}/calculatePremium`;
	static STRING_URL_PRODUCT_SENDCALCULATEPREMIUMPERSONALACCIDENTSYARIAHBYEMAILFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENTSYARIAH}/sendCalculatePremiumByEmailForOperationAndSupport`;

	//#endregion


	//#region CONTROLLER VEHICLE BRAND

	static STRING_PATH_CONTROLLER_VEHICLEBRAND: string = "/VehicleBrand";

	static STRING_URL_VEHICLEBRAND_SELECTVEHICLEBRAND: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEBRAND}/selectVehicleBrand`;


	//#endregion


	//#region CONTROLLER VEHICLE MODEL

	static STRING_PATH_CONTROLLER_VEHICLEMODEL: string = "/VehicleModel";

	static STRING_URL_VEHICLEBRAND_SELECTVEHICLEMODELBYVEHICLEBRAND: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEMODEL}/selectVehicleModelByVehicleBrand`;

	//#endregion


	//#region CONTROLLER VEHICLE SUB MODEL

	static STRING_PATH_CONTROLLER_VEHICLESUBMODEL: string = "/VehicleSubModel";

	static STRING_URL_VEHICLESUBMODEL_SELECTVEHICLESUBMODELBYVEHICLEMODEL: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLESUBMODEL}/selectVehicleSubModelByVehicleModel`;

	//#endregion


	//#region CONTROLLER VEHICLE TYPE

	static STRING_PATH_CONTROLLER_VEHICLETYPE: string = "/VehicleType";

	static STRING_URL_VEHICLETYPE_SELECTVEHICLETYPEMODELBYVEHICLEMODEL: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLETYPE}/selectVehicleTypeByVehicleModel`;

	//#endregion


	//#region CONTROLLER VEHICLE COLOR

	static STRING_PATH_CONTROLLER_VEHICLECOLOR: string = "/VehicleColor";

	static STRING_URL_VEHICLECOLOR_SELECTVEHICLECOLOR: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLECOLOR}/selectVehicleColor`;

	//#endregion


	//#region CONTROLLER VEHICLE MANUFACTURE YEAR

	static STRING_PATH_CONTROLLER_VEHICLEMANUFACTUREYEAR: string = "/VehicleManufactureYear";

	static STRING_URL_VEHICLEMANUFACTUREYEAR_SELECTVEHICLEMANUFACTUREYEAR: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEMANUFACTUREYEAR}/selectVehicleManufactureYear`;

	//#endregion


	//#region CONTROLLER VEHICLE FUNCTION

	static STRING_PATH_CONTROLLER_VEHICLEFUNCTION: string = "/VehicleFunction";

	static STRING_URL_VEHICLEFUNCTION_SELECTVEHICLEFUNCTION: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEFUNCTION}/selectVehicleFunction`;

	//#endregion


	//#region CONTROLLER VEHICLE LOCATION

	static STRING_PATH_CONTROLLER_VEHICLELOCATION: string = "/VehicleLocation";

	static STRING_URL_VEHICLEBRAND_SELECTVEHICLELOCATION: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLELOCATION}/selectVehicleLocation`;

	//#endregion


	//#region CONTROLLER POSTAL

	static STRING_PATH_CONTROLLER_VISIT: string = "/Visit";

	static STRING_URL_VISIT_SELECTVISITBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VISIT}/selectVisitByToken`;
	static STRING_URL_VISIT_SELECTVISITBYATTRIBUTESFORRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VISIT}/selectVisitByAttributesForRelationshipManager`;
	static STRING_URL_VISIT_UPLOADVISIT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VISIT}/uploadVisit`;

	//#endregion


	//#region CONTROLLER OCCUPATION

	static STRING_PATH_CONTROLLER_OCCUPATION: string = "/Occupation";

	static STRING_URL_OCCUPATION_SELECTOCCUPATION: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OCCUPATION}/selectOccupation`;

	//#endregion


	//#region CONTROLLER RELATIONSHIP

	static STRING_PATH_CONTROLLER_RELATIONSHIP: string = "/Relationship";

	static STRING_URL_RELATIONSHIP_SELECTRELATIONSHIP: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RELATIONSHIP}/selectRelationship`;

	//#endregion


	//#region CONTROLLER POLICY ASRI

	static STRING_PATH_CONTROLLER_POLICYASRI: string = "/PolicyAsri";

	static STRING_URL_POLICYASRI_SELECTPOLICYBYRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYASRI}/selectPolicyByRelationshipManager`;
	static STRING_URL_POLICYASRI_SELECTPOLICYHEADERBYRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYASRI}/selectPolicyHeaderByRelationshipManager`;
	static STRING_URL_POLICYASRI_SELECTPOLICYBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYASRI}/selectPolicyByToken`;

	//#endregion


	//#region CONTROLLER POLICY PERSONALACCIDENT

	static STRING_PATH_CONTROLLER_POLICYPERSONALACCIDENT: string = "/PolicyPersonalAccident";

	static STRING_URL_POLICYPERSONALACCIDENT_SELECTPOLICYBYRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYPERSONALACCIDENT}/selectPolicyByRelationshipManager`;
	static STRING_URL_POLICYPERSONALACCIDENT_SELECTPOLICYHEADERBYRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYPERSONALACCIDENT}/selectPolicyHeaderByRelationshipManager`;
	static STRING_URL_POLICYPERSONALACCIDENT_SELECTPOLICYBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYPERSONALACCIDENT}/selectPolicyByToken`;

	//#endregion


	//#region CONTROLLER POLICY OUTSTANDING

	static STRING_PATH_CONTROLLER_POLICYOUTSTANDING: string = "/PolicyOutstanding";

	static STRING_URL_POLICYOUTSTANDING_SELECTPOLICYBYATTRIBUTESFORRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYOUTSTANDING}/selectPolicyByAttributesForRelationshipManager`;
	static STRING_URL_POLICYOUTSTANDING_SELECTPOLICYBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYOUTSTANDING}/selectPolicyByToken`;
	static STRING_URL_POLICYOUTSTANDING_SENDNOTIFICATIONBYEMAIL: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYOUTSTANDING}/sendNotificationByEmail`;
	static STRING_URL_POLICYOUTSTANDING_SHARENOTIFICATION: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYOUTSTANDING}/shareNotification`;
	static STRING_URL_POLICYOUTSTANDING_UPLOADPAYMENTRECEPT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYOUTSTANDING}/uploadPaymentReceipt`;

	//#endregion


	//#region CONTROLLER POLICY RENEWAL

	static STRING_PATH_CONTROLLER_POLICYRENEWAL: string = "/PolicyRenewal";

	static STRING_URL_POLICYRENEWAL_SELECTPOLICYBYATTRIBUTESFORRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectPolicyByAttributesForRelationshipManager`;
	static STRING_URL_POLICYRENEWAL_SELECTPOLICYBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectPolicyByToken`;
	static STRING_URL_POLICYRENEWAL_SENDNOTIFICATIONBYEMAIL: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/sendNotificationByEmail`;
	static STRING_URL_POLICYRENEWAL_SHARENOTIFICATION: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/shareNotification`;

	//#endregion

	//#region LINK

	static STRING_PATH_FOLDER_RELATIONSHIPMANAGER: string = "/RelationshipManager";
	static STRING_PATH_FOLDER_PHOTO: string = "/Photo";
	static STRING_PATH_FOLDER_VISIT: string = "/Visit";

	static STRING_URL_PHOTO_USERDEFAULT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_FOLDER_RELATIONSHIPMANAGER}/photo_user_default.jpg`;


	static STRING_PATH_FOLDER_PRODUCT: string = "/Product";

	static STRING_URL_DOCUMENT_PRODUCTASRI: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_FOLDER_PRODUCT}/document_product_asri.pdf`;
	static STRING_URL_DOCUMENT_PRODUCTOTO: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_FOLDER_PRODUCT}/document_product_oto.pdf`;
	static STRING_URL_DOCUMENT_PRODUCTPERSONALACCIDENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_FOLDER_PRODUCT}/document_product_personalaccident.pdf`;

	//#endregion


	//#region CONTROLLER DASHBOARD

	static STRING_PATH_CONTROLLER_DASHBOARD: string = "/Dashboard";

	static STRING_URL_DASHBOARD_SELECTPRODUCTFORBUSINESS: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectProductForBusiness`;
	static STRING_URL_DASHBOARD_SELECTACTIVITYFORBUSINESS: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectActivityForBusiness`;
	static STRING_URL_DASHBOARD_SELECTRELATIONSHIPMANAGERFORBUSINESS: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectRelationshipManagerForBusiness`;

	static STRING_URL_DASHBOARD_SELECTPRODUCTFORRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectProductForRelationshipManager`;
	static STRING_URL_DASHBOARD_SELECTRELATIONSHIPMANAGERFORRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectRelationshipManagerForRelationshipManager`;
	static STRING_URL_DASHBOARD_SELECTACTIVITYFORRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectActivityForRelationshipManager`;

	static STRING_URL_DASHBOARD_SELECTDASHBOARDPRODUCTHOMEOWNERSHIPCREDIT: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardProductHomeOwnershipCredit`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDDOCUMENTSTAMPCOLLECTOR: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardDocumentStampCollectorSummaryByAttributes`;

	//#endregion


	//#region CONTROLLER DOCUMENT STAMP COLLECTOR DASHBOARD

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORDASHBOARD: string = "/DocumentStampCollectorDashboard";

	static 	STRING_URL_DOCUMENTSTAMPCOLLECTORDASHBOARD_SELECTDASHBOARDDOCUMENTSTAMPCOLLECTORDASHBOARDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORDASHBOARD}/selectDocumentStampCollectorDashboardByAttributes`;

	//#endregion


	//#region CONTROLLER DOCUMENT STAMP ENTERPRISE DASHBOARD

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISEDASHBOARD: string = "/DocumentStampEnterpriseDashboard";

	static 	STRING_URL_DOCUMENTSTAMPENTERPRISEDASHBOARD_SELECTDASHBOARDDOCUMENTSTAMPCOLLECTORDASHBOARDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISEDASHBOARD}/selectDocumentStampEnterpriseDashboardByAttributes`;

	//#endregion


	//#region CONTROLLER UPDATE DOCUMENT STAMP COLLECTOR STATIC

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORSTATIC: string = "/DocumentStampCollectorStatic";

	static 	STRING_URL_DOCUMENTSTAMPCOLLECTORSTATIC_UPDATEDASHBOARDDOCUMENTSTAMPCOLLECTORSTATICBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORSTATIC}/updateDocumentStampCollectorStaticByAttributes`;

	//#endregion


	//#region CONTROLLER UPDATE DOCUMENT STAMP ENTERPRISE STATIC

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISESTATIC: string = "/DocumentStampEnterpriseStatic";

	static 	STRING_URL_DOCUMENTSTAMPENTERPRISESTATIC_SELECTDASHBOARDDOCUMENTSTAMPENTERPRISESTATICBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISESTATIC}/updateDocumentStampEnterpriseStaticByAttributes`;

	//#endregion


	//#region CONTROLLER DOCUMENT STAMP COLLECTOR DASHBOARD

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORREPORT: string = "/DocumentStampCollectorReport";

	static STRING_URL_DOCUMENTSTAMPCOLLECTORREPORT_SELECTDASHBOARDDOCUMENTSTAMPCOLLECTORREPORTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORREPORT}/selectDocumentStampCollectorReportByAttributes`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTORREPORT_SELECTDASHBOARDDOCUMENTSTAMPCOLLECTORREPORTBYATTRIBUTESFOREXCEL: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORREPORT}/selectDocumentStampCollectorReportByAttributesForExcel`;

	//#endregion




	//#region PRODUCT CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCT: string = "/Product";

	static STRING_URL_PRODUCT_SELECTPRODUCTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCT}/selectProductByAttributes`;
	static STRING_URL_PRODUCT_SELECTPRODUCTBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCT}/selectProductByToken`;

	static STRING_URL_TESTUSER: string = `${WebAddressConstant.STRING_URL_USER_API}/User/testLogin`;


	//#endregion


	//#region POLICY CONTROLLER

	static STRING_PATH_CONTROLLER_POLICYGENERAL: string = "/Policy";

	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYPOLICYNUMBER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyByPolicyNumber`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYLICENSEPLATE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyByLicensePlate`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyByPolicyAttributes`;

	//#endregion


	//#region TICKET CONTROLELR

	static STRING_PATH_CONTROLLER_TICKET: string = "/Ticket";

	static STRING_URL_TICKET_SELECTTICKETBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketByToken`;
	static STRING_URL_TICKET_SELECTTICKETINBOXBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketReceivedByAttributes`;
	static STRING_URL_TICKET_SELECTTICKETOUTBOXBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketSentByAttributes`;
	static STRING_URL_TICKET_SELECTREPORTTICKETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectReportTicketByAttributes`;
	static STRING_URL_TICKET_INSERTTICKET: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/insertTicket`;
	static STRING_URL_TICKET_INSERTTICKETFORDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/insertTicketForDivision`;
	static STRING_URL_TICKET_UPDATETICKETBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/updateTicketByToken`;
	static STRING_URL_TICKET_UPDATETICKETBYTOKENFORCLOSEWITHOUTCONFIRMATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/updateTicketByTokenForCloseWithoutConfirmation`;

	//#endregion


	//#region TICKET CONVERSATION CONTROLLER

	static STRING_PATH_CONTROLLER_TICKETCONVERSATION: string = "/TicketConversation";

	static STRING_URL_TICKET_INSERTTICKETCONVERSATIONFORDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/insertTicketConversationForDivision`;
	static STRING_URL_TICKET_SELECTTICKETCONVERSATIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/selectTicketConversationByAttributes`;
	static STRING_URL_TICKET_UPDATETICKETCONVERSATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/updateTicketConversationByToken`;
	static STRING_URL_TICKET_UPLOADCONVERSATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/uploadConversation`;

	//#endregion


	//#region TICKET DOCUMENT CONTROLLER

	static STRING_PATH_CONTROLLER_TICKETDOCUMENT: string = "/TicketDocument";

	static STRING_URL_TICKET_DOWNLOADCONVERSATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETDOCUMENT}/downloadConversationByDocumentToken`;

	//#endregion


	//#region BUSINESS

	static STRING_PATH_CONTROLLER_BUSINESS: string = "/Business";

	static STRING_URL_BUSINESS_SELECTBUSINESSRBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BUSINESS}/selectBusinessByEmail`;
	static STRING_URL_BUSINESS_VERIFYBYBUSINESS: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BUSINESS}/verifyBusinessByEmail`;
	static STRING_URL_BUSINESS_SIGNOUT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BUSINESS}/signOut`;

	//#endregion

	//#region PRODUCT HOME OWNERSHIP CONTROLLER

	static STRING_PATH_CONTROLLER_PRODCUTHOMEOWNERSHIPCREDIT: string = "/ProductHomeOwnershipCredit";

	static STRING_URL_PRODUCTHOMEOWNERSHIPCREDIT_SELECTPRODUCTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTHOMEOWNERSHIPCREDIT}/selectProductByAttributes`;
	static STRING_URL_PRODUCTHOMEOWNERSHIPCREDIT_SELECTPRODUCTAPPROVEDFORREPORT: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTHOMEOWNERSHIPCREDIT}/selectProductApprovedForReport`;
	static STRING_URL_PRODCUTHOMEOWNERSHIPCREDIT_SELECTPRODUCTFORREPORT: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTHOMEOWNERSHIPCREDIT}/selectProductForReport`;
	static STRING_URL_PRODUCTHOMEOWNERSHIPCREDIT_SELECTPRODUCTBYTOKEN: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTHOMEOWNERSHIPCREDIT}/selectProductByToken`;
	static STRING_URL_PRODUCTHOMEOWNERSHIPCREDIT_CALLAPPROVEPRODUCTBYTOKEN: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTHOMEOWNERSHIPCREDIT}/approveProductByToken`;
	static STRING_URL_PRODUCTHOMEOWNERSHIPCREDIT_CALLREJECTPRODUCTBYTOKEN: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTHOMEOWNERSHIPCREDIT}/rejectProductByToken`;

	//#endregion


	//#region TREATY

	static STRING_PATH_CONTROLLER_POLICYREGISTRATION: string = "/PolicyRegistration";

	static STRING_URL_TREATY_SELECTPOLICYREGISTRATIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYREGISTRATION}/selectPolicyRegistrationByAttributes`;
	static STRING_URL_TREATY_INSERTPOLICYREGISTRATION: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYREGISTRATION}/insertPolicyRegistration`;

	//#endregion


	//#region REIMBURSEMENT GENERAL

	static STRING_PATH_CONTROLLER_REIMBURSEMENTGENERAL: string = "/reimbursementgeneral";

	static STRING_URL_REIMBURSEMENTGENERAL_SELECTREIMBURSEMENTGENERALBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_REIMBURSEMENTGENERAL}/selectReimbursementGeneralByAttributesForChecker`;
	static STRING_URL_REIMBURSEMENTGENERAL_SELECTREIMBURSEMENTGENERALBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_REIMBURSEMENTGENERAL}/selectReimbursementGeneralByAttributesForSigner`;

	//#endregion


	//#region DENTAL REIMBURSEMENT

	static STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT: string = "/dentalreimbursement";

	static STRING_URL_DENTALREIMBURSEMENT_SELECTDENTALREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/selectDentalReimbursementByAttributesForMaker`;
	static STRING_URL_DENTALREIMBURSEMENT_SELECTDENTALREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/selectDentalReimbursementByAttributesForChecker`;
	static STRING_URL_DENTALREIMBURSEMENT_SELECTDENTALREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/selectDentalReimbursementByAttributesForSigner`;
	static STRING_URL_DENTALREIMBURSEMENT_CHECKDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/checkDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_SELECTDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/selectDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_SIGNDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/signDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_REJECTDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/rejectDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_REVISEDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/reviseDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_UPDATEDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/updateDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_CHECKDENTALREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/checkDentalReimbursementPlafondByUserID`;
	static STRING_URL_DENTALREIMBURSEMENT_DOWNLOADDENTALREIMBURSEMENTDOCUMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/downloadLogisticDeclarationByDocumentToken?stringHandshakeToken=[HandshakeToken]&stringDeclarationDocumentToken=[DocumentToken]`;
	static STRING_URL_DENTALREIMBURSEMENT_DOWNLOADDENTALREIMBURSEMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/downloadDeclarationDocumentByTokenForLogistic?stringHandshakeToken=[HandshakeToken]&stringLetterGeneralToken=[DocumentToken]`;

	//#endregion


	//#region OPTIC REIMBURSEMENT

	static STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT: string = "/OpticReimbursement";

	static STRING_URL_OPTICREIMBURSEMENT_SELECTOPTICREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/selectOpticReimbursementByAttributesForMaker`;
	static STRING_URL_OPTICREIMBURSEMENT_SELECTOPTICREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/selectOpticReimbursementByAttributesForChecker`;
	static STRING_URL_OPTICREIMBURSEMENT_SELECTOPTICREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/selectOpticReimbursementByAttributesForSigner`;
	static STRING_URL_OPTICREIMBURSEMENT_CHECKOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/checkOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_SELECTOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/selectOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_SIGNOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/signOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_REJECTOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/rejectOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_REVISEOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/reviseOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_UPDATEOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/updateOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_CHECKOPTICREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/checkOpticReimbursementPlafondByUserID`;
	static STRING_URL_OPTICREIMBURSEMENT_DOWNLOADOPTICREIMBURSEMENTGALLERYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/downloadOpticReimbursementGalleryByToken`;

	//#endregion


	//#region PREMATERNITY REIMBURSEMENT

	static STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT: string = "/PreMaternityReimbursement";

	static STRING_URL_PREMATERNITYREIMBURSEMENT_SELECTPREMATERNITYREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/selectPreMaternityReimbursementByAttributesForMaker`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_SELECTPREMATERNITYREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/selectPreMaternityReimbursementByAttributesForChecker`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_SELECTPREMATERNITYREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/selectPreMaternityReimbursementByAttributesForSigner`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_CHECKPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/checkPreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_SELECTPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/selectPreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_SIGNPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/signPreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_REJECTPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/rejectPreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_REVISEPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/revisePreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_UPDATEPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/updatePreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_CHECKPREMATERNITYREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/checkPreMaternityReimbursementPlafondByUserID`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_DOWNLOADPREMATERNITYREIMBURSEMENTGALLERYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/downloadPreMaternityReimbursementGalleryByToken`;

	//#endregion


	//#region POSTMATERNITY REIMBURSEMENT

	static STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT: string = "/PostMaternityReimbursement";

	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SELECTPOSTMATERNITYREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/selectPostMaternityReimbursementByAttributesForMaker`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SELECTPOSTMATERNITYREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/selectPostMaternityReimbursementByAttributesForChecker`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SELECTPOSTMATERNITYREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/selectPostMaternityReimbursementByAttributesForSigner`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_CHECKPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/checkPostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SELECTPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/selectPostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SIGNPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/signPostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_REJECTPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/rejectPostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_REVISEPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/revisePostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_UPDATEPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/updatePostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_CHECKPOSTMATERNITYREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/checkPostMaternityReimbursementPlafondByUserID`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_DOWNLOADPOSTMATERNITYREIMBURSEMENTGALLERYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/downloadPostMaternityReimbursementGalleryByToken`;

	//#endregion


	//#region POSITION CONTROLLER

	static STRING_PATH_CONTROLLER_POSITION: string = "/Position";

	static STRING_URL_BRANCH_SELECTPOSITION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSITION}/selectPosition`;

	//#endregion


	//#region MATERNITY REIMBURSEMENT

	static STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT: string = "/MaternityReimbursement";

	static STRING_URL_MATERNITYREIMBURSEMENT_SELECTMATERNITYREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/selectMaternityReimbursementByAttributesForMaker`;
	static STRING_URL_MATERNITYREIMBURSEMENT_SELECTMATERNITYREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/selectMaternityReimbursementByAttributesForChecker`;
	static STRING_URL_MATERNITYREIMBURSEMENT_SELECTMATERNITYREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/selectMaternityReimbursementByAttributesForSigner`;
	static STRING_URL_MATERNITYREIMBURSEMENT_CHECKMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/checkMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_SELECTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/selectMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_SIGNMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/signMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_REJECTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/rejectMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_REVISEMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/reviseMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_UPDATEMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/updateMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_CHECKMATERNITYREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/checkMaternityReimbursementPlafondByUserID`;
	static STRING_URL_MATERNITYREIMBURSEMENT_DOWNLOADMATERNITYREIMBURSEMENTGALLERYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/downloadMaternityReimbursementGalleryByToken`;

	//#endregion


	//#region SUPERVISION

	static STRING_PATH_CONTROLLER_SUPERVISION: string = "/Supervision";

	static STRING_URL_SUPERVISION_SELECTUSERBYDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUPERVISION}/selectUserByDivision`;
	static STRING_URL_SUPERVISION_SELECTSUPERVISIONBYID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUPERVISION}/selectSupervisionByID`;
	static STRING_URL_SUPERVISION_INSERTSUPERVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUPERVISION}/insertSupervision`;
	static STRING_URL_SUPERVISION_SELECTSUPERVISIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUPERVISION}/selectSupervisionByAttributes`;
	static STRING_URL_SUPERVISION_SELECTSUPERVISIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUPERVISION}/selectSupervisionByToken`;
	static STRING_URL_SUPERVISION_UPDATESUPERVISIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUPERVISION}/updateSupervisionByToken`;
	static STRING_URL_SUPERVISION_DELETESUPERVISIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUPERVISION}/deleteSupervisionByToken`;
	static STRING_URL_SUPERVISION_SELECTSUPERVISIONBYDIVISIONID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUPERVISION}/selectSupervisionByDivisionID`;
	static STRING_URL_SUPERVISION_SELECTCHECKERBYDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUPERVISION}/selectCheckerByDivision`;
	static STRING_URL_SUPERVISION_SELECTSIGNERBYDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUPERVISION}/selectSignerByDivision`;

	//#endregion


	//#region DOCUMENT STAMP COLLECTOR TYPE

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORTYPE: string = "/DocumentStampCollectorType";

	static STRING_URL_DOCUMENTSTAMPCOLLECTORTYPE_SELECTDOCUMENTSTAMPCONTROLLERTYPEBYATRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORTYPE}/selectDocumentStampCollectorTypeByAttributes`;

	//#endregion


	//#region DOCUMENT STAMP COLLECTOR TYPE

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISETYPE: string = "/DocumentStampEnterpriseType";

	static STRING_URL_DOCUMENTSTAMPENTERPRISETYPE_SELECTDOCUMENTSTAMPENTERPRISETYPEBYATRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISETYPE}/selectDocumentStampEnterpriseTypeByAttributes`;

	//#endregion


	//#region DOCUMENT STAMP COLLECTOR

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR: string = "/DocumentStampCollector";

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributes`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_UPLOADDOCUMENTSTAMPCOLLECTOR: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/uploadDocumentStampCollector`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_DOWNLOADDOCUMENTSTAMPCOLLECTORBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/downloadDocumentStampCollectorByToken`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SIGNDOCUMENTSTAMPCOLLECTORBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/signDocumentStampCollectorByToken`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_RESIGNDOCUMENTSTAMPCOLLECTORBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/resignDocumentStampCollectorByToken`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_CANCELRESIGNDOCUMENTSTAMPCOLLECTORBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/cancelResignDocumentStampCollectorByToken`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SIGNDOCUMENTSTAMPCOLLECTOR: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/signDocumentStampCollector`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCOLLECTORDOCUMENTTYPE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorDocumentType`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTESFORREPORTING: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributesForReporting`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTESFORREPORTINGEXCEL: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributesForReportingExcel`;

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributesForMaker`;

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributesForChecker`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributesForSigner`;

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByToken`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SUBMITDOCUMENTSTAMPCONTROLLERFORMAKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/submitDocumentStampCollectorForMaker`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_REVISEDOCUMENTSTAMPCOLLECTORFORMAKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/reviseDocumentStampCollectorForMaker`;

	//#region CHECKER

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_APPROVEDOCUMENTSTAMPCOLLECTORBYTOKENFORCHECKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/approveDocumentStampCollectorByTokenForChecker`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_REJECTDOCUMENTSTAMPCOLLECTORBYTOKENFORCHECKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/rejectDocumentStampCollectorByTokenForChecker`;

	//#endregion


	//#region SIGNER

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_APPROVEDOCUMENTSTAMPCOLLECTORBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/approveDocumentStampCollectorByTokenForSigner`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_REJECTDOCUMENTSTAMPCOLLECTORBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/rejectDocumentStampCollectorByTokenForSigner`;

	//#endregion

	//#endregion


	//#region RISK LIBRARY ACTIVITY

	static STRING_PATH_CONTROLLER_RISKACTIVITY: string = "/RiskActivity";

	static STRING_URL_RISKACTIVITY_SELECTRISKACTIVITYBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKACTIVITY}/selectRiskActivityByAttributes`;
	static STRING_URL_RISKACTIVITY_SELECTRISKACTIVITYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKACTIVITY}/selectRiskActivityByToken`;

	//#endregion


	//#region RISK ASSIGN

	static STRING_PATH_CONTROLLER_RISKASSIGN: string = "/RiskAssign";

	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYPARTICIPANT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByParticipant`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByToken`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYPARTICIPANTTOKENFORPARTICIPANT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByParticipantTokenForParticipant`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYPARTICIPANTTOKENFORASSESSMENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByParticipantTokenForAssessment`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYPARTICIPANTTOKENFORAPPROVAL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByParticipantTokenForApproval`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNCONTENTBYRISKASSIGNTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignContentByRiskAssignToken`;
	static STRING_URL_RISKASSIGN_SAVEALLRISKASSESSMENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/saveAllRiskAssessment`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYTOKENFORAPPROVAL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByTokenForApproval`;
	static STRING_URL_RISKASSIGN_APPROVERISKASSIGNBYTOKENFORPARTICIPANT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/approveRiskAssignByTokenForParticipant`;
	static STRING_URL_RISKASSIGN_REJECTRISKASSIGNBYTOKENFORPARTICIPANT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/rejectRiskAssignByTokenForParticipant`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNQUARTERLYREPORTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignQuarterlyReportByAttributes`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNQUARTERLYREPORTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignQuarterlyReportByToken`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNCONSOLIDATIONREPORTBYPERIOD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignForConsolidationReportByPeriod`;

	//#endregion


	//#region RISK PLAN

	static STRING_PATH_CONTROLLER_RISKPLAN: string = "/RiskPlan";

	static STRING_URL_RISKPLAN_SELECTRISKPLANBYRISKASSIGNCONTENTTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKPLAN}/selectRiskPlanByRiskAssignContentToken`;
	static STRING_URL_RISKPLAN_INSERTRISKPLANBYRISKPLANLIST: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKPLAN}/insertRiskPlanByRiskPlanList`;

	//#endregion


	//#region RISK ASSIGN CONTENT

	static STRING_PATH_CONTROLLER_RISKASSIGNCONTENT: string = "/RiskAssignContent";

	static STRING_URL_RISKASSIGNCONTENT_SELECTRISKASSIGNCONTENTBYRISKASSIGNTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGNCONTENT}/selectRiskAssignContentByRiskAssignToken`;
	static STRING_URL_RISKASSIGNCONTENT_SELECTRISKASSIGNCONTENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGNCONTENT}/selectRiskAssignContentByToken`;
	static STRING_URL_RISKASSIGNCONTENT_CALCULATERISKASSIGNCONTENTSCORE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGNCONTENT}/calculateRiskAssignContentScore`;
	static STRING_URL_RISKASSIGNCONTENT_SAVERISKASSIGNCONTENTFORASSESSMENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGNCONTENT}/saveRiskAssignContentForAssessment`;

	//#endregion


	//#region RISK ASSESSMENT GUIDE DOCUMENT

	static STRING_PATH_CONTROLLER_RISKASSESSMENTGUIDEDOCUMENT: string = "/RiskAssessmentGuideDocument";

	static STRING_URL_RISKASSESSMENTGUIDEDOCUMENT_SELECTRISKASSESSMENTGUIDEDOCUMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSESSMENTGUIDEDOCUMENT}/selectRiskAssessmentGuideDocumentByAttributes`;
	static STRING_URL_RISKASSESSMENTGUIDEDOCUMENT_DOWNLOADRISKASSESSMENTGUIDEDOCUMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSESSMENTGUIDEDOCUMENT}/downloadRiskAssessmentGuideDocumentByToken`;

	//#endregion


	//#region RISK REQUEST

	static STRING_PATH_CONTROLLER_RISKREQUEST: string = "/RiskRequest";

	static STRING_URL_RISKREQUEST_SELECTRISKREQUESTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/selectRiskRequestByAttributes`;
	static STRING_URL_RISKREQUEST_SELECTRISKREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/selectRiskRequestByToken`;
	static STRING_URL_RISKREQUEST_SUBMITRISKREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/submitRiskRequestByToken`;
	static STRING_URL_RISKREQUEST_SAVEALLREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/saveAllRiskRequestByToken`;
	static STRING_URL_RISKREQUEST_INSERTRISKREQUEST: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/insertRiskRequest`;
	static STRING_URL_RISKREQUEST_SENTRISKREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/sentRiskRequestByToken`;
	static STRING_URL_RISKREQUEST_DELETERISKREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/deleteRiskRequestByToken`;

	//#endregion


	//#region RISK APPETITE STATEMENT

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK: string = "/RiskAppetiteStatementRisk";
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementByAttributes`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementByToken`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTRISKBYATTRIBUTESFORREALIZATIONDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementRiskByAttributesForRealizationDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTRISKBYATTRIBUTESFORREALIZATIONAPPROVALDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementRiskByAttributesForRealizationApprovalDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTRISKBYATTRIBUTESFORFOLLOWUPPLANDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementRiskByAttributesForFollowUpPlanDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTRISKBYATTRIBUTESFORFOLLOWUPPLANAPPROVALDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementRiskByAttributesForFollowUpPlanApprovalDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTRISKBYATTRIBUTESFORDASHBOARDDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementRiskByAttributesForDashboardDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTRISKBYATTRIBUTESFORREPORTDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementRiskByAttributesForReportDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTRISKBYATTRIBUTESFORREALIZATIONMONITORINGDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementRiskByAttributesForRealizationMonitoringDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTRISKBYATTRIBUTESFORREALIZATIONCONFIRMATIONDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementRiskByAttributesForRealizationConfirmationDropdown`;

	//#endregion


	//#region RISK APPETITE STATEMENT PARAMETER

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER: string = "/RiskAppetiteStatementParameter";

	static STRING_URL_RISKAPPETITESTATEMENTRISKPARAMETER_SELECTRISKAPPETITESTATEMENTPARAMETERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByToken`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORREALIZATIONDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForRealizationDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORREALIZATIONAPPROVALDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForRealizationApprovalDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByToken`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForRealization`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORFOLLOWUPPLANAPPROVALDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForFollowUpPlanApprovalDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORFOLLOWUPPLANDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForFollowUpPlanDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORDASHBOARDDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForDashboardDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORDASHBOARD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForDashboard`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYTOKENFORDASHBOARD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByTokenForDashboard`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORREPORTDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForReportDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORREALIZATIONMONITORINGDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForRealizationMonitoringDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORREALIZATIONCONFIRMATIONDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForRealizationConfirmationDropdown`;

	//#endregion


	//#region RISK APPETITE STATEMENT PARAMETER REALIZATION

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION: string = "/RiskAppetiteStatementParameterRealization";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_INSERTRISKAPPETITESTATEMENTPARAMETERREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/insertRiskAppetiteStatementParameterRealization`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_UPDATERISKAPPETITESTATEMENTPARAMETERREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/updateRiskAppetiteStatementParameterRealization`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTPARAMETERREALIZATIONBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/selectRiskAppetiteStatementParameterRealizationByAttributesForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SUBMITRISKAPPETITESTATEMENTPARAMETERREALIZATIONFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/submitRiskAppetiteStatementParameterRealizationForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTPARAMETERREALIZATIONBYTOKENFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/selectRiskAppetiteStatementParameterRealizationByTokenForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTPARAMETERREALIZATIONBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/selectRiskAppetiteStatementParameterRealizationByTokenForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_REVISERISKAPPETITESTATEMENTPARAMETERREALIZATIONFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/reviseRiskAppetiteStatementParameterRealizationForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTPARAMETERREALIZATIONBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/selectRiskAppetiteStatementParameterRealizationByAttributesForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_APPROVERISKAPPETITESTATEMENTPARAMETERREALIZATIONFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/approveRiskAppetiteStatementParameterRealizationForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_REJECTRISKAPPETITESTATEMENTPARAMETERREALIZATIONFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/rejectRiskAppetiteStatementParameterRealizationForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTPARAMETERREALIZATIONBYATTRIBUTESFORMONITORING: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/selectRiskAppetiteStatementParameterRealizationByAttributesForMonitoring`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTPARAMETERREALIZATIONBYTOKENFORMONITORING: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/selectRiskAppetiteStatementParameterRealizationByTokenForMonitoring`;

	//#endregion


	//#region RISK APPETITE STATEMENT PARAMETER REALIZATION APPROVAL

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATIONAPPROVAL: string = "/RiskAppetiteStatementParameterRealizationApproval";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTPARAMETERREALIZATIONAPPROVALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATIONAPPROVAL}/selectRiskAppetiteStatementParameterRealizationApprovalByAttributes`;

	//#endregion


	//#region RISK APPETITE STATEMENT PARAMETER REALIZATION DOCUMENT

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATIONDOCUMENT: string = "/RiskAppetiteStatementParameterRealizationDocument";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONDOCUMENT_DOWNLOADRISKAPPETITESTATEMENTPARAMETERREALIZATIONDOCUMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATIONDOCUMENT}/downloadRiskAppetiteStatementParameterRealizationDocumentByAttributes`;

	//#endregion


	//#region RISK APPETITE STATEMENT PARAMETER REALIZATION FOLLOW UP PLAN

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN: string = "/RiskAppetiteStatementParameterFollowUpPlan";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/selectRiskAppetiteStatementParameterFollowUpPlanByAttributesForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANBYTOKENFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/selectRiskAppetiteStatementParameterFollowUpPlanByTokenForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_SUBMITRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/submitRiskAppetiteStatementParameterFollowUpPlanForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_REVISERISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/reviseRiskAppetiteStatementParameterFollowUpPlanForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/selectRiskAppetiteStatementParameterFollowUpPlanByAttributesForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/selectRiskAppetiteStatementParameterFollowUpPlanByTokenForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_APPROVERISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/approveRiskAppetiteStatementParameterFollowUpPlanForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_REJECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/rejectRiskAppetiteStatementParameterFollowUpPlanForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANBYATTRIBUTESFORREVIEWER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/selectRiskAppetiteStatementParameterFollowUpPlanByAttributesForReviewer`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANBYTOKENFORREVIEWER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/selectRiskAppetiteStatementParameterFollowUpPlanByTokenForReviewer`;

	//#endregion


	//#region RISK APPETITE STATEMENT PARAMETER REALIZATION FOLLOW UP PLAN ITEM

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANITEM: string = "/RiskAppetiteStatementParameterFollowUpPlanItem";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLANITEM_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANITEMBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANITEM}/selectRiskAppetiteStatementParameterFollowUpPlanItemByAttributesForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLANITEM_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANITEMBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANITEM}/selectRiskAppetiteStatementParameterFollowUpPlanItemByAttributesForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLANITEM_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANITEMBYATTRIBUTESFORREVIEWER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANITEM}/selectRiskAppetiteStatementParameterFollowUpPlanItemByAttributesForReviewer`;

	//#endregion


	//#region RISK APPETITE STATEMENT PARAMETER REALIZATION FOLLOW UP PLAN APPROVAL

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANAPPROVAL: string = "/RiskAppetiteStatementParameterFollowUpPlanApproval";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLANAPPROVAL_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANAPPROVALBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANAPPROVAL}/selectRiskAppetiteStatementParameterFollowUpPlanApprovalByAttributesForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLANAPPROVAL_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANAPPROVALBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANAPPROVAL}/selectRiskAppetiteStatementParameterFollowUpPlanApprovalByAttributesForSigner`;

	//#endregion


	//#region RISK APPETITE STATEMENT SUB PARAMETER

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETER: string = "/RiskAppetiteStatementSubParameter";

	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTSUBPARAMETERBYATTRIBUTESFORREALIZATIONDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETER}/selectRiskAppetiteStatementSubParameterByAttributesForRealizationDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTSUBPARAMETERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETER}/selectRiskAppetiteStatementSubParameterByToken`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTSUBPARAMETERBYATTRIBUTESFORREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETER}/selectRiskAppetiteStatementSubParameterByAttributesForRealization`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTSUBPARAMETERBYATTRIBUTESFORDASHBOARD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETER}/selectRiskAppetiteStatementSubParameterByAttributesForDashboard`;

	//#endregion


	//#region RISK APPETITE STATEMENT SUB PARAMETER REALIZATION

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION: string = "/RiskAppetiteStatementSubParameterRealization";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_INSERTRISKAPPETITESTATEMENTSUBPARAMETERREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/insertRiskAppetiteStatementSubParameterRealization`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_UPDATERISKAPPETITESTATEMENTSUBPARAMETERREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/updateRiskAppetiteStatementSubParameterRealization`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubParameterRealizationByAttributesForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SUBMITRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/submitRiskAppetiteStatementSubParameterRealizationForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONBYTOKENFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubParameterRealizationByTokenForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubParameterRealizationByTokenForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_REVISERISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/reviseRiskAppetiteStatementSubParameterRealizationForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubParameterRealizationByAttributesForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_APPROVERISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/approveRiskAppetiteStatementSubParameterRealizationForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_REJECTRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/rejectRiskAppetiteStatementSubParameterRealizationForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONBYATTRIBUTESFORMONITORING: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubParameterRealizationByAttributesForMonitoring`;

	//#endregion


	//#region RISK APPETITE STATEMENT SUB PARAMETER REALIZATION APPROVAL

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONAPPROVAL: string = "/RiskAppetiteStatementSubParameterRealizationApproval";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONAPPROVALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONAPPROVAL}/selectRiskAppetiteStatementSubParameterRealizationApprovalByAttributes`;

	//#endregion


	//#region RISK APPETITE STATEMENT SUB PARAMETER REALIZATION DOCUMENT

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONDOCUMENT: string = "/RiskAppetiteStatementSubParameterRealizationDocument";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONDOCUMENT_DOWNLOADRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONDOCUMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONDOCUMENT}/downloadRiskAppetiteStatementSubParameterRealizationDocumentByAttributes`;

	//#endregion


	//#region RISK APPETITE STATEMENT SUB SUB PARAMETER

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETER: string = "/RiskAppetiteStatementSubSubParameter";

	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERBYATTRIBUTESFORREALIZATIONDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETER}/selectRiskAppetiteStatementSubSubParameterByAttributesForRealizationDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETER}/selectRiskAppetiteStatementSubSubParameterByToken`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERBYATTRIBUTESFORREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETER}/selectRiskAppetiteStatementSubSubParameterByAttributesForRealization`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERBYATTRIBUTESFORDASHBOARD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETER}/selectRiskAppetiteStatementSubSubParameterByAttributesForDashboard`;

	//#endregion


	//#region RISK APPETITE STATEMENT SUB SUB PARAMETER REALIZATION

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION: string = "/RiskAppetiteStatementSubSubParameterRealization";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_INSERTRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/insertRiskAppetiteStatementSubSubParameterRealization`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_UPDATERISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/updateRiskAppetiteStatementSubSubParameterRealization`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubSubParameterRealizationByAttributesForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SUBMITRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/submitRiskAppetiteStatementSubSubParameterRealizationForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONBYTOKENFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubSubParameterRealizationByTokenForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubSubParameterRealizationByTokenForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_REVISERISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/reviseRiskAppetiteStatementSubSubParameterRealizationForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubSubParameterRealizationByAttributesForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_APPROVERISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/approveRiskAppetiteStatementSubSubParameterRealizationForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_REJECTRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/rejectRiskAppetiteStatementSubSubParameterRealizationForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONBYATTRIBUTESFORMONITORING: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubSubParameterRealizationByAttributesForMonitoring`;

	//#endregion


	//#region RISK APPETITE STATEMENT SUB SUB PARAMETER REALIZATION DOCUMENT

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONDOCUMENT: string = "/RiskAppetiteStatementSubSubParameterRealizationDocument";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONDOCUMENT_DOWNLOADRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONDOCUMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONDOCUMENT}/downloadRiskAppetiteStatementSubSubParameterRealizationDocumentByAttributes`;

	//#endregion


	//#region RISK APPETITE STATEMENT SUB SUB PARAMETER REALIZATION APPROVAL

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONAPPROVAL: string = "/RiskAppetiteStatementSubSubParameterRealizationApproval";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONAPPROVALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONAPPROVAL}/selectRiskAppetiteStatementSubSubParameterRealizationApprovalByAttributes`;

	//#endregion


	//#region DOCUMENT APPLICATION CONTROLLER

	static STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION: string = "/DocumentApplication";

	static STRING_URL_DOCUMENTAPPLICATION_INSERTDOCUMENTAPPLICATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/insertDocumentApplication`;
	static STRING_URL_DOCUMENTAPPLICATION_SAVEDOCUMENTAPPLICATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/saveDocumentApplication`;
	static STRING_URL_DOCUMENTAPPLICATION_UPDATEDOCUMENTAPPLICATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/updateDocumentApplication`;
	static STRING_URL_DOCUMENTAPPLICATION_SUBMITDOCUMENTAPPLICATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/submitDocumentApplication`;
	static STRING_URL_DOCUMENTAPPLICATION_REJECTDOCUMENTAPPLICATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/rejectDocumentApplicationByTokenForDivision`;
	static STRING_URL_DOCUMENTAPPLICATION_APPROVALDOCUMENTAPPLICATIONBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/approvalDocumentApplicationByTokenForSignerDivision`;
	static STRING_URL_DOCUMENTAPPLICATION_APPROVALDOCUMENTAPPLICATIONBYTOKENFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/approvalDocumentApplicationByTokenForCheckerDivision`;
	static STRING_URL_DOCUMENTAPPLICATION_DELETEDOCUMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/deleteDocumentApplicationByToken`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYATTRIBUTEFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByAttributesForMaker`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYATTRIBUTEFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByAttributesForChecker`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYATTRIBUTEFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByAttributesForSigner`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYATTRIBUTE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByAttributes`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByToken`;
	static STRING_URL_DOCUMENTAPPLICATION_DOWNLOADDOCUMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/downloadDocumentRequestAttachmentByToken`;

	//#endregion


	//#region LEGAL

	static STRING_PATH_CONTROLLER_LEGAL: string = "/Legal";

	static STRING_URL_SELECTCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LEGAL}/selectChecker`;
	static STRING_URL_SELECTSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LEGAL}/selectSigner`;

	//#endregion


	//#region ANOMALY BATCH

	static STRING_PATH_CONTROLLER_ANOMALYBATCH: string = "/AnomalyBatch";

	static STRING_URL_ANOMALYBATCH_INSERTANOMALYBATCH: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/insertAnomalyBatch`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORMAKERASQ: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForMakerASQ`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByToken`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORCHECKERASQ: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForCheckerASQ`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORSIGNERASQ: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForSignerASQ`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORMAKERAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForMakerAuditor`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORCHECKERAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForCheckerAuditor`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORSIGNERAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForSignerAuditor`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORMAKERAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForMakerAuditee`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORCHECKERAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForCheckerAuditee`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORSIGNERAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForSignerAuditee`;
	static STRING_URL_ANOMALYBATCH_UPDATEASSIGNEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/updateAssignee`;
	static STRING_URL_ANOMALYBATCH_UPDATEAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/updateAuditor`;
	static STRING_URL_ANOMALYBATCH_UPDATEFOLLOWUPANALYSIS: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/updateFollowUpAnalysis`;
	static STRING_URL_ANOMALYBATCH_UPDATEAUDITORFORAPPROVE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/updateAuditorForApprove`;
	static STRING_URL_ANOMALYBATCH_UPDATEFOLLOWUPANALYSISFORAPPROVE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/updateFollowUpAnalysisForApprove`;
	static STRING_URL_ANOMALYBATCH_APPROVECHECKERASQ: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/approveCheckerASQ`;
	static STRING_URL_ANOMALYBATCH_APPROVESIGNERASQ: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/approveSignerASQ`;
	static STRING_URL_ANOMALYBATCH_REJECTASQ: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/rejectASQ`;
	static STRING_URL_ANOMALYBATCH_APPROVECHECKERAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/approveCheckerAuditor`;
	static STRING_URL_ANOMALYBATCH_APPROVESIGNERAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/approveSignerAuditor`;
	static STRING_URL_ANOMALYBATCH_REJECTAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/rejectAuditor`;
	static STRING_URL_ANOMALYBATCH_UPLOADAUDITORFEEDBACK: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/uploadAuditorFeedback`;
	static STRING_URL_ANOMALYBATCH_APPROVECHECKERAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/approveCheckerAuditee`;
	static STRING_URL_ANOMALYBATCH_APPROVESIGNERAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/approveSignerAuditee`;
	static STRING_URL_ANOMALYBATCH_REJECTAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/rejectAuditee`;
	static STRING_URL_ANOMALYBATCH_DOWNLOADANOMALYDOCUMENTAUDITORFEEDBACKBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/downloadAnomalyDocumentAuditorFeedbackByToken`;

	//#endregion


	//#region EMPLOYEE

	static STRING_PATH_CONTROLLER_ANOMALYGENERAL: string = "/AnomalyGeneral";

	static STRING_URL_ANOMALYGENERAL_SELECTANOMALYGENERALBYANOMALYBATCH: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYGENERAL}/selectAnomalyGeneralByAnomalyBatch`;
	static STRING_URL_ANOMALYGENERAL_UPLOADFOLLOWUP: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYGENERAL}/uploadFollowUp`;
	static STRING_URL_ANOMALYGENERAL_DOWNLOADFOLLOWUP: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYGENERAL}/downloadFollowUp`;
	static STRING_URL_ANOMALYGENERAL_DOWNLOADFOLLOWUPEXPORT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYGENERAL}/downloadFollowUpExport`;
	static STRING_URL_ANOMALYGENERAL_DOWNLOADANOMALYGENERALSPREADSHEET: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYGENERAL}/downloadAnomalyGeneralSpreadsheet`;

	//#endregion


	//#region AUDIT TEAM

	static STRING_PATH_CONTROLLER_AUDITTEAM: string = "/AuditTeam";

	static STRING_URL_AUDITTEAM_SELECTAUDITTEAMBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AUDITTEAM}/selectAuditTeamByAttributes`;

	//#endregion


	//#region AUDIT TEAM MEMBER

	static STRING_PATH_CONTROLLER_AUDITTEAMMEMBER: string = "/AuditTeamMember";

	static STRING_URL_AUDITTEAM_SELECTAUDITTEAMMEMBERBYUSERID: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AUDITTEAMMEMBER}/selectAuditTeamMemberByUserID`;
	static STRING_URL_AUDITTEAMMEMBER_SELECTAUDITTEAMLEADERBYAUDITGENERALTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AUDITTEAMMEMBER}/selectAuditTeamLeaderByAuditGeneralToken`;
	static STRING_URL_AUDITTEAMMEMBER_SELECTAUDITTEAMMEMBERBYAUDITGENERALTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AUDITTEAMMEMBER}/selectAuditTeamMemberByAuditGeneralToken`;

	//#endregion


	//#region ACCOUNTING AND FINANCE CONTROLLER

	static STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE: string = "/AccountingAndFinance";

	static STRING_URL_ACCOUNTINGANDFINANCE_SELECTACCOUNTINGANDFINANCEBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/selectAccountingAndFinanceByEmail`;
	static STRING_URL_ACCOUNTINGANDFINANCE_VERIFYACCOUNTINGANDFINANCEBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/verifyAccountingAndFinanceByEmail`;
	static STRING_URL_ACCOUNTINGANDFINANCE_SIGNOUT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/signOut`;
	static STRING_URL_ACCOUNTINGANDFINANCE_SELECTACCOUNTINGANDFINANCEBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/selectAccountingAndFinanceByToken`;
	static STRING_URL_ACCOUNTINGANDFINANCE_SELECTCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/selectChecker`;
	static STRING_URL_ACCOUNTINGANDFINANCE_SELECTSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/selectSigner`;

	//#endregion


	//#region BANK ACCOUNT

	static STRING_PATH_CONTROLLER_BANKACCOUNT: string = "/BankAccount";

	static STRING_URL_BANKACCOUNT_SELECTBANKACCOUNTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/selectBankAccountByAttributes`;

	//#endregion


	//#region ACCOUNT CHARGE

	static STRING_PATH_CONTROLLER_ACCOUNTCHARGE: string = "/AccountCharge";

	static STRING_URL_ACCOUNTCHARGE_SELECTACCOUNTCHARGEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTCHARGE}/selectAccountChargeByAttributes`;

	//#endregion


	//#region CURRENCY

	static STRING_PATH_CONTROLLER_CURRENCY: string = "/Currency";

	static STRING_URL_CURRENCY_SELECTCURRENCY: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CURRENCY}/selectCurrency`;

	//#endregion


	//#region ANOMALYNOTIFICATION

	static STRING_PATH_CONTROLLER_ANOMALYNOTIFICATION: string = "/AnomalyNotification";

	static STRING_URL_ANOMALYNOTIFICATION_SELECTANOMALYNOTIFICATIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYNOTIFICATION}/selectAnomalyNotificationByAttributes`;
	static STRING_URL_ANOMALYNOTIFICATION_SELECTANOMALYNOTIFICATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYNOTIFICATION}/selectAnomalyNotificationByToken`;
	static STRING_URL_ANOMALYNOTIFICATION_SELECTANOMALYNOTIFICATIONCOUNT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYNOTIFICATION}/selectAnomalyNotificationCount`;

	//#endregion


	//#region PRODUCT GENERAL

	static STRING_PATH_CONTROLLER_PRODUCTGENERAL: string = "/ProductGeneral";

	static STRING_URL_PRODUCTGENERAL_SELECTPRODUCTFORPNMMONITORINGBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERAL}/selectProductForPNMMonitoringByAttributes`;
	static STRING_URL_PRODUCTGENERAL_SELECTPRODUCTFORPNMMONITORINGBYATTRIBUTESFOREXCEL: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERAL}/selectProductForPNMMonitoringByAttributesForExcel`;
	static STRING_URL_PRODUCTGENERAL_DOWNLOADPRODUCTFORPNMMONITORINGBYTOKEN: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERAL}/downloadProductForPNMMonitoringByToken`;

	//#endregion


	//#region CLAIM PROPOSAL

	static STRING_PATH_CONTROLLER_CLAIMPROPOSAL: string = "/ClaimProposal";

	static STRING_URL_PRODUCTGENERAL_SELECTCLAIMFORPNMMONITORINGBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSAL}/selectClaimForPNMMonitoringByAttributes`;

	//#endregion


	//#region TICKET SERVICE

	static STRING_PATH_CONTROLLER_TICKETSERVICE: string = "/TicketService";

	static STRING_URL_TICKETSERVICE_SELECTTICKETSERVICEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/selectTicketServiceByAttributes`;
	static STRING_URL_TICKETSERVICE_SELECTTICKETSERVICEBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/selectTicketServiceByToken`;
	static STRING_URL_TICKETSERVICE_INSERTTICKETSERVICE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/insertTicketService`;
	static STRING_URL_TICKETSERVICE_INSERTLISTTICKETSERVICE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/insertListTicketService`;
	static STRING_URL_TICKETSERVICE_DELETETICKETSERVICEBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/deleteTicketServiceByToken`;
	static STRING_URL_TICKETSERVICE_UPDATETICKETSERVICEBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/updateTicketServiceByToken`;

	//#endregion


	//#region TICKET SERVICE AGENT

	static STRING_PATH_CONTROLLER_TICKETSERVICEAGENT: string = "/TicketServiceAgent";

	static STRING_URL_TICKETSERVICEAGENT_SELECTTICKETSERVICEAGENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/selectTicketServiceAgentByAttributes`;
	static STRING_URL_TICKETSERVICEAGENT_SELECTTICKETSERVICEAGENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/selectTicketServiceAgentByToken`;
	static STRING_URL_TICKETSERVICEAGENT_INSERTTICKETSERVICEAGENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/insertTicketServiceAgent`;
	static STRING_URL_TICKETSERVICEAGENT_INSERTLISTTICKETSERVICEAGENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/insertListTicketServiceAgent`;
	static STRING_URL_TICKETSERVICEAGENT_DELETETICKETSERVICEAGENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/deleteTicketServiceAgentByToken`;
	static STRING_URL_TICKETSERVICEAGENT_UPDATETICKETSERVICEAGENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/updateTicketServiceAgentByToken`;

	//#endregion


	//#region EMPLOYEE

	static STRING_PATH_CONTROLLER_EMPLOYEE: string = "/Employee";

	static STRING_URL_EMPLOYEE_SELECTEMPLOYEEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EMPLOYEE}/selectEmployeeByAttributes`;
	static STRING_URL_EMPLOYEE_SELECTEMPLOYEEBYBRANCH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EMPLOYEE}/selectEmployeeByBranch`;
	static STRING_URL_EMPLOYEE_SELECTEMPLOYEEBYDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EMPLOYEE}/selectEmployeeByDivison`;

	//#endregion


	//#region FORMATION

	static STRING_PATH_CONTROLLER_FORMATION: string = "/Formation";

	static STRING_URL_FORMATION_SELECTFORMATIONBYDIVISIONID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FORMATION}/selectFormationByDivisionID`;

	//#endregion

	//#region GCG ONLINE

	/* PROFILE COMPONENT - START */

	static STRING_PATH_CONTROLLER_GCGPROFILEPAGE: string = "/GCGProfilePage";

	static STRING_URL_GCGONLINEPROFILEPAGE_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GCGPROFILEPAGE}/SelectForEmployee`;

	/* PROFILE COMPONENT - END */

	/* GRATIFICATION LIST COMPONENT - START */

	static STRING_PATH_CONTROLLER_GRATIFICATIONLISTPAGE: string = "/GratificationListPage";

	static STRING_URL_GCGONLINEGRATIFICATIONLIST_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONLISTPAGE}/SelectForEmployee`;

	static STRING_PATH_CONTROLLER_GRATIFICATIONEXCELPAGE: string = "/GratificationExcelPage";

	/* GRATIFICATION LIST COMPONENT - END */

	/* GRATIFICATION DETAIL COMPONENT - START */

	static STRING_PATH_CONTROLLER_GRATIFICATIONSUBMISSIONPAGE: string = "/GratificationSubmissionPage";

	static STRING_URL_GCGONLINEGRATIFICATIONSUBMISSION_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONSUBMISSIONPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONSUBMISSION_SUBMITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONSUBMISSIONPAGE}/SubmitForEmployee`;

	static STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE: string = "/GratificationDetailPage";

	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_SELECTREVIEWFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/SelectReviewForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_SUBMITREVIEWFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/SubmitReviewForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_SELECTDOCUMENTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/SelectDocumentForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_DOWNLOADFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/DownloadForEmployee`;

	/* GRATIFICATION DETAIL COMPONENT - END */

	/* INTEGRITY PACT LIST COMPONENT - START */

	static STRING_PATH_CONTROLLER_INTEGRITYPACTLISTPAGE: string = "/IntegrityPactListPage";

	static STRING_URL_GCGONLINEINTEGRITYPACTLIST_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTLISTPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEINTEGRITYPACTLIST_SELECTWORKINGUNITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTLISTPAGE}/SelectWorkingUnitForEmployee`;
	static STRING_URL_GCGONLINEINTEGRITYPACTLIST_DOWNLOADFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTLISTPAGE}/DownloadForEmployee`;

	static STRING_PATH_CONTROLLER_INTEGRITYPACTEXCELPAGE: string = "/IntegrityPactExcelPage";

	/* INTEGRITY PACT LIST COMPONENT - END */

	/* INTEGRITY PACT DETAIL COMPONENT - START */

	static STRING_PATH_CONTROLLER_INTEGRITYPACTSUBMISSIONPAGE: string = "/IntegrityPactSubmissionPage";

	static STRING_URL_GCGONLINEINTEGRITYPACTSUBMISSION_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTSUBMISSIONPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEINTEGRITYPACTSUBMISSION_SUBMITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTSUBMISSIONPAGE}/SubmitForEmployee`;

	static STRING_PATH_CONTROLLER_INTEGRITYPACTDETAILPAGE: string = "/IntegrityPactDetailPage";

	static STRING_URL_GCGONLINEINTEGRITYPACTDETAIL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTDETAILPAGE}/SelectForEmployee`;

	/* INTEGRITY PACT DETAIL COMPONENT - END */

	/* CODE OF ETHICS LIST COMPONENT - START */

	static STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE: string = "/CodeOfEthicsListPage";

	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTWORKINGUNITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectWorkingUnitForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTWORKINGUNITFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectWorkingUnitForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTWORKINGUNITFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectWorkingUnitForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_DOWNLOADFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/DownloadForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_DOWNLOADFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/DownloadForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_DOWNLOADFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/DownloadForGCGSigner`;

	static STRING_PATH_CONTROLLER_CODEOFETHICSEXCELPAGE: string = "/CodeOfEthicsExcelPage";

	/* CODE OF ETHICS LIST COMPONENT - END */

	/* CODE OF ETHICS DETAIL COMPONENT - START */

	static STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE: string = "/CodeOfEthicsSubmissionPage";

	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_SELECTCHECKERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/SelectCheckerForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_SELECTSIGNERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/SelectSignerForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_SUBMITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/SubmitForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_REVISEFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/ReviseForEmployee`;

	static STRING_PATH_CONTROLLER_CODEOFETHICSDETAILPAGE: string = "/CodeOfEthicsDetailPage";

	static STRING_URL_GCGONLINECODEOFETHICSDETAIL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSDETAILPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSDETAIL_SELECTAPPROVALFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSDETAILPAGE}/SelectApprovalForEmployee`;

	static STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE: string = "/CodeOfEthicsApprovalPage";

	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTAPPROVALFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectApprovalForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTAPPROVALFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectApprovalForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTAPPROVALFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectApprovalForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTCHECKERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectCheckerForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTSIGNERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectSignerForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_REJECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/RejectForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_APPROVEFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/ApproveForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_REJECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/RejectForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_APPROVEFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/ApproveForGCGSigner`;

	/* CODE OF ETHICS DETAIL COMPONENT - END */

	/* ANNUAL DISCLOSURE LIST COMPONENT - START */

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE: string = "/AnnualDisclosureListPage";

	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTWORKINGUNITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectWorkingUnitForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTWORKINGUNITFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectWorkingUnitForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTWORKINGUNITFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectWorkingUnitForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_DOWNLOADFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/DownloadForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_DOWNLOADFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/DownloadForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_DOWNLOADFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/DownloadForGCGSigner`;

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSUREEXCELPAGE: string = "/AnnualDisclosureExcelPage";

	/* ANNUAL DISCLOSURE LIST COMPONENT - END */

	/* ANNUAL DISCLOSURE DETAIL COMPONENT - START */

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE: string = "/AnnualDisclosureSubmissionPage";

	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_SELECTCHECKERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/SelectCheckerForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_SELECTSIGNERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/SelectSignerForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_SUBMITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/SubmitForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_REVISEFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/ReviseForEmployee`;

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSUREDETAILPAGE: string = "/AnnualDisclosureDetailPage";

	static STRING_URL_GCGONLINEANNUALDISCLOSUREDETAIL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREDETAILPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREDETAIL_SELECTQUESTIONFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREDETAILPAGE}/SelectQuestionForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREDETAIL_SELECTAPPROVALFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREDETAILPAGE}/SelectApprovalForEmployee`;

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE: string = "/AnnualDisclosureApprovalPage";

	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTAPPROVALFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectApprovalForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTAPPROVALFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectApprovalForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTAPPROVALFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectApprovalForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTQUESTIONFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectQuestionForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTQUESTIONFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectQuestionForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTQUESTIONFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectQuestionForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTCHECKERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectCheckerForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTSIGNERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectSignerForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_REJECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/RejectForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_APPROVEFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/ApproveForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_REJECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/RejectForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_APPROVEFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/ApproveForGCGSigner`;

	/* ANNUAL DISCLOSURE DETAIL COMPONENT - END */


	//#region CUSTOMER SATISFACTION

	static STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION: string = "/CustomerSatisfaction";

	static STRING_URL_CUSTOMERSATISFACTION_SELECTCUSTOMERSATISFACTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/selectCustomerSatisfactionByAttributes`;
	static STRING_URL_CUSTOMERSATISFACTION_SELECTCUSTOMERSATISFACTIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/selectCustomerSatisfactionByToken`;
	static STRING_URL_CUSTOMERSATISFACTION_INSERTCUSTOMERSATISFACTION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/insertCustomerSatisfaction`;
	static STRING_URL_CUSTOMERSATISFACTION_DELETECUSTOMERSATISFACTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/deleteCustomerSatisfactionByToken`;
	static STRING_URL_CUSTOMERSATISFACTION_SELECTREPORTCUSTOMERSATISFACTIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/selectReportCustomerSatisfactionByAttributes`;

	//#endregion


	//#region E-BUDGETING

	/* REPORT - START */

	static STRING_PATH_CONTROLLER_EBUDGETINGREPORTPAGE: string = "/EBudgetingReportPage";

	static STRING_URL_EBUDGETINGREPORTPAGE_SELECTACCOUNTFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTPAGE}/selectAccountForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGREPORTPAGE_SELECTACCOUNTFOREBUDGETINGWHOLESALECHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTPAGE}/selectAccountForEBudgetingWholesaleChecker`;
	static STRING_URL_EBUDGETINGREPORTPAGE_SELECTACCOUNTFOREBUDGETINGWHOLESALESIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTPAGE}/selectAccountForEBudgetingWholesaleSigner`;
	static STRING_URL_EBUDGETINGREPORTPAGE_SELECTLISTFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTPAGE}/selectListForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGREPORTPAGE_SELECTLISTFOREBUDGETINGWHOLESALECHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTPAGE}/selectListForEBudgetingWholesaleChecker`;
	static STRING_URL_EBUDGETINGREPORTPAGE_SELECTLISTFOREBUDGETINGWHOLESALESIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTPAGE}/selectListForEBudgetingWholesaleSigner`;
	static STRING_URL_EBUDGETINGREPORTPAGE_SELECTACCOUNTFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTPAGE}/selectAccountForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGREPORTPAGE_SELECTACCOUNTFOREBUDGETINGRITMIKCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTPAGE}/selectAccountForEBudgetingRitmikChecker`;
	static STRING_URL_EBUDGETINGREPORTPAGE_SELECTACCOUNTFOREBUDGETINGRITMIKSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTPAGE}/selectAccountForEBudgetingRitmikSigner`;
	static STRING_URL_EBUDGETINGREPORTPAGE_SELECTLISTFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTPAGE}/selectListForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGREPORTPAGE_SELECTLISTFOREBUDGETINGRITMIKCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTPAGE}/selectListForEBudgetingRitmikChecker`;
	static STRING_URL_EBUDGETINGREPORTPAGE_SELECTLISTFOREBUDGETINGRITMIKSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTPAGE}/selectListForEBudgetingRitmikSigner`;

	static STRING_PATH_CONTROLLER_EBUDGETINGREPORTEXCELPAGE: string = "/EBudgetingReportExcelPage";

	static STRING_URL_EBUDGETINGREPORTEXCELPAGE_SELECTLISTFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTEXCELPAGE}/selectListForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGREPORTEXCELPAGE_SELECTLISTFOREBUDGETINGWHOLESALECHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTEXCELPAGE}/selectListForEBudgetingWholesaleChecker`;
	static STRING_URL_EBUDGETINGREPORTEXCELPAGE_SELECTLISTFOREBUDGETINGWHOLESALESIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTEXCELPAGE}/selectListForEBudgetingWholesaleSigner`;
	static STRING_URL_EBUDGETINGREPORTEXCELPAGE_SELECTLISTFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTEXCELPAGE}/selectListForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGREPORTEXCELPAGE_SELECTLISTFOREBUDGETINGRITMIKCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTEXCELPAGE}/selectListForEBudgetingRitmikChecker`;
	static STRING_URL_EBUDGETINGREPORTEXCELPAGE_SELECTLISTFOREBUDGETINGRITMIKSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGREPORTEXCELPAGE}/selectListForEBudgetingRitmikSigner`;

	/* REPORT - END */

	/* DASHBOARD - START */

	static STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE: string = "/EBudgetingDashboardPage";

	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTDETAILFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectDetailForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTDETAILFOREBUDGETINGWHOLESALECHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectDetailForEBudgetingWholesaleChecker`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTDETAILFOREBUDGETINGWHOLESALESIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectDetailForEBudgetingWholesaleSigner`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTLISTFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectListForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTLISTFOREBUDGETINGWHOLESALECHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectListForEBudgetingWholesaleChecker`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTLISTFOREBUDGETINGWHOLESALESIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectListForEBudgetingWholesaleSigner`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTCHARTFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectChartForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTCHARTFOREBUDGETINGWHOLESALECHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectChartForEBudgetingWholesaleChecker`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTCHARTFOREBUDGETINGWHOLESALESIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectChartForEBudgetingWholesaleSigner`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTDETAILFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectDetailForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTDETAILFOREBUDGETINGRITMIKCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectDetailForEBudgetingRitmikChecker`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTDETAILFOREBUDGETINGRITMIKSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectDetailForEBudgetingRitmikSigner`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTLISTFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectListForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTLISTFOREBUDGETINGRITMIKCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectListForEBudgetingRitmikChecker`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTLISTFOREBUDGETINGRITMIKSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectListForEBudgetingRitmikSigner`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTCHARTFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectChartForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTCHARTFOREBUDGETINGRITMIKCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectChartForEBudgetingRitmikChecker`;
	static STRING_URL_EBUDGETINGDASHBOARDPAGE_SELECTCHARTFOREBUDGETINGRITMIKSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDASHBOARDPAGE}/selectChartForEBudgetingRitmikSigner`;

	/* DASHBOARD - END */

	/* BANK ACCOUNT LIST - START */

	static STRING_PATH_CONTROLLER_EBUDGETINGBANKACCOUNTLISTPAGE: string = "/EBudgetingBankAccountListPage";

	static STRING_URL_EBUDGETINGBANKACCOUNTLISTPAGE_SELECTLISTFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBANKACCOUNTLISTPAGE}/selectListForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGBANKACCOUNTLISTPAGE_SELECTLISTFOREBUDGETINGWHOLESALECHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBANKACCOUNTLISTPAGE}/selectListForEBudgetingWholesaleChecker`;
	static STRING_URL_EBUDGETINGBANKACCOUNTLISTPAGE_SELECTLISTFOREBUDGETINGWHOLESALESIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBANKACCOUNTLISTPAGE}/selectListForEBudgetingWholesaleSigner`;
	static STRING_URL_EBUDGETINGBANKACCOUNTLISTPAGE_SELECTLISTFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBANKACCOUNTLISTPAGE}/selectListForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGBANKACCOUNTLISTPAGE_SELECTLISTFOREBUDGETINGRITMIKCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBANKACCOUNTLISTPAGE}/selectListForEBudgetingRitmikChecker`;
	static STRING_URL_EBUDGETINGBANKACCOUNTLISTPAGE_SELECTLISTFOREBUDGETINGRITMIKSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBANKACCOUNTLISTPAGE}/selectListForEBudgetingRitmikSigner`;

	/* BANK ACCOUNT LIST - END */

	/* BANK ACCOUNT DETAIL - START */

	static STRING_PATH_CONTROLLER_EBUDGETINGBANKACCOUNTDETAILPAGE: string = "/EBudgetingBankAccountDetailPage";

	static STRING_URL_EBUDGETINGBANKACCOUNTDETAILPAGE_SUBMITFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBANKACCOUNTDETAILPAGE}/submitForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGBANKACCOUNTDETAILPAGE_SUBMITFOREBUDGETINGWHOLESALECHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBANKACCOUNTDETAILPAGE}/submitForEBudgetingWholesaleChecker`;
	static STRING_URL_EBUDGETINGBANKACCOUNTDETAILPAGE_SUBMITFOREBUDGETINGWHOLESALESIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBANKACCOUNTDETAILPAGE}/submitForEBudgetingWholesaleSigner`;
	static STRING_URL_EBUDGETINGBANKACCOUNTDETAILPAGE_SUBMITFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBANKACCOUNTDETAILPAGE}/submitForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGBANKACCOUNTDETAILPAGE_SUBMITFOREBUDGETINGRITMIKCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBANKACCOUNTDETAILPAGE}/submitForEBudgetingRitmikChecker`;
	static STRING_URL_EBUDGETINGBANKACCOUNTDETAILPAGE_SUBMITFOREBUDGETINGRITMIKSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGBANKACCOUNTDETAILPAGE}/submitForEBudgetingRitmikSigner`;

	/* BANK ACCOUNT DETAIL - END */

	/* DECLARATION LIST - START */

	static STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONLISTPAGE: string = "/EBudgetingDeclarationListPage";

	static STRING_URL_EBUDGETINGDECLARATIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONLISTPAGE}/selectSubmitterListForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONLISTPAGE}/selectSubmitterListForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGWHOLESALECHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONLISTPAGE}/selectSubmitterListForEBudgetingWholesaleChecker`;
	static STRING_URL_EBUDGETINGDECLARATIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGRITMIKCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONLISTPAGE}/selectSubmitterListForEBudgetingRitmikChecker`;
	static STRING_URL_EBUDGETINGDECLARATIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGWHOLESALESIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONLISTPAGE}/selectSubmitterListForEBudgetingWholesaleSigner`;
	static STRING_URL_EBUDGETINGDECLARATIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGRITMIKSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONLISTPAGE}/selectSubmitterListForEBudgetingRitmikSigner`;
	static STRING_URL_EBUDGETINGDECLARATIONLISTPAGE_DOWNLOADDECLARATIONDOCUMENTFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONLISTPAGE}/downloadDeclarationDocumentForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONLISTPAGE_DOWNLOADTRANSFERRECEIPTFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONLISTPAGE}/downloadTransferReceiptForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONLISTPAGE_DOWNLOADDECLARATIONDOCUMENTFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONLISTPAGE}/downloadDeclarationDocumentForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONLISTPAGE_DOWNLOADTRANSFERRECEIPTFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONLISTPAGE}/downloadTransferReceiptForEBudgetingRitmikMaker`;

	/* DECLARATION LIST - END */

	/* DECLARATION DETAIL - START */

	static STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE: string = "/EBudgetingDeclarationDetailPage";

	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTSUBMISSIONLISTFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectSubmissionListForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTSUBMISSIONLISTFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectSubmissionListForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTSUBMITTERCHECKERFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectSubmitterCheckerForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTSUBMITTERCHECKERFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectSubmitterCheckerForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTSUBMITTERSIGNERFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectSubmitterSignerForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTSUBMITTERSIGNERFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectSubmitterSignerForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTMANAGERCHECKERFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectManagerCheckerForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTMANAGERCHECKERFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectManagerCheckerForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTMANAGERSIGNERFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectManagerSignerForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTMANAGERSIGNERFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectManagerSignerForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTBANKACCOUNTFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectBankAccountForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTBANKACCOUNTFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectBankAccountForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTCURRENCYFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectCurrencyForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTCURRENCYFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectCurrencyForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTBRANCHFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectBranchForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTBRANCHFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectBranchForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTACCOUNTCHARGEFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectAccountChargeForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTACCOUNTCHARGEFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectAccountChargeForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTSUBMISSIONDETAILFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectSubmissionDetailForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTSUBMISSIONDETAILFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectSubmissionDetailForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SUBMITFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/submitForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SUBMITFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/submitForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTDETAILFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectDetailForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTDETAILFOREBUDGETINGWHOLESALECHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectDetailForEBudgetingWholesaleChecker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTDETAILFOREBUDGETINGWHOLESALESIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectDetailForEBudgetingWholesaleSigner`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTDETAILFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectDetailForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTDETAILFOREBUDGETINGRITMIKCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectDetailForEBudgetingRitmikChecker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_SELECTDETAILFOREBUDGETINGRITMIKSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/selectDetailForEBudgetingRitmikSigner`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_DOWNLOADFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/downloadForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_DOWNLOADFOREBUDGETINGWHOLESALECHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/downloadForEBudgetingWholesaleChecker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_DOWNLOADFOREBUDGETINGWHOLESALESIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/downloadForEBudgetingWholesaleSigner`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_DOWNLOADFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/downloadForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_DOWNLOADFOREBUDGETINGRITMIKCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/downloadForEBudgetingRitmikChecker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_DOWNLOADFOREBUDGETINGRITMIKSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/downloadForEBudgetingRitmikSigner`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_REVISEFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/reviseForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_REVISEFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/reviseForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_REJECTSUBMITTERFOREBUDGETINGWHOLESALECHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/rejectSubmitterForEBudgetingWholesaleChecker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_REJECTSUBMITTERFOREBUDGETINGRITMIKCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/rejectSubmitterForEBudgetingRitmikChecker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_REJECTSUBMITTERFOREBUDGETINGWHOLESALESIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/rejectSubmitterForEBudgetingWholesaleSigner`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_REJECTSUBMITTERFOREBUDGETINGRITMIKSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/rejectSubmitterForEBudgetingRitmikSigner`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_APPROVESUBMITTERFOREBUDGETINGWHOLESALECHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/approveSubmitterForEBudgetingWholesaleChecker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_APPROVESUBMITTERFOREBUDGETINGRITMIKCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/approveSubmitterForEBudgetingRitmikChecker`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_APPROVESUBMITTERFOREBUDGETINGWHOLESALESIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/approveSubmitterForEBudgetingWholesaleSigner`;
	static STRING_URL_EBUDGETINGDECLARATIONDETAILPAGE_APPROVESUBMITTERFOREBUDGETINGRITMIKSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGDECLARATIONDETAILPAGE}/approveSubmitterForEBudgetingRitmikSigner`;

	/* DECLARATION DETAIL - END */

	/* SUBMISSION LIST - START */

	static STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONLISTPAGE: string = "/EBudgetingSubmissionListPage";

	static STRING_URL_EBUDGETINGSUBMISSIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONLISTPAGE}/selectSubmitterListForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONLISTPAGE}/selectSubmitterListForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGWHOLESALECHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONLISTPAGE}/selectSubmitterListForEBudgetingWholesaleChecker`;
	static STRING_URL_EBUDGETINGSUBMISSIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGRITMIKCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONLISTPAGE}/selectSubmitterListForEBudgetingRitmikChecker`;
	static STRING_URL_EBUDGETINGSUBMISSIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGWHOLESALESIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONLISTPAGE}/selectSubmitterListForEBudgetingWholesaleSigner`;
	static STRING_URL_EBUDGETINGSUBMISSIONLISTPAGE_SELECTSUBMITTERLISTFOREBUDGETINGRITMIKSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONLISTPAGE}/selectSubmitterListForEBudgetingRitmikSigner`;
	static STRING_URL_EBUDGETINGSUBMISSIONLISTPAGE_DOWNLOADSUBMISSIONDOCUMENTFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONLISTPAGE}/downloadSubmissionDocumentForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONLISTPAGE_DOWNLOADTRANSFERRECEIPTFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONLISTPAGE}/downloadTransferReceiptForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONLISTPAGE_DOWNLOADSUBMISSIONDOCUMENTFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONLISTPAGE}/downloadSubmissionDocumentForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONLISTPAGE_DOWNLOADTRANSFERRECEIPTFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONLISTPAGE}/downloadTransferReceiptForEBudgetingRitmikMaker`;

	/* SUBMISSION LIST - END */

	/* SUBMISSION DETAIL - START */

	static STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE: string = "/EBudgetingSubmissionDetailPage";

	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTSUBMITTERCHECKERFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/selectSubmitterCheckerForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTSUBMITTERCHECKERFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/selectSubmitterCheckerForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTSUBMITTERSIGNERFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/selectSubmitterSignerForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTSUBMITTERSIGNERFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/selectSubmitterSignerForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTMANAGERCHECKERFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/selectManagerCheckerForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTMANAGERCHECKERFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/selectManagerCheckerForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTMANAGERSIGNERFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/selectManagerSignerForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTMANAGERSIGNERFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/selectManagerSignerForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTBANKACCOUNTFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/selectBankAccountForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTBANKACCOUNTFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/selectBankAccountForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTCURRENCYFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/selectCurrencyForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTCURRENCYFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/selectCurrencyForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTACCOUNTCHARGEFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/selectAccountChargeForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTACCOUNTCHARGEFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/selectAccountChargeForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SUBMITFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/submitForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SUBMITFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/submitForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTDETAILFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/selectDetailForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTDETAILFOREBUDGETINGWHOLESALECHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/selectDetailForEBudgetingWholesaleChecker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTDETAILFOREBUDGETINGWHOLESALESIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/selectDetailForEBudgetingWholesaleSigner`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTDETAILFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/selectDetailForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTDETAILFOREBUDGETINGRITMIKCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/selectDetailForEBudgetingRitmikChecker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_SELECTDETAILFOREBUDGETINGRITMIKSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/selectDetailForEBudgetingRitmikSigner`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_DOWNLOADFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/downloadForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_DOWNLOADFOREBUDGETINGWHOLESALECHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/downloadForEBudgetingWholesaleChecker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_DOWNLOADFOREBUDGETINGWHOLESALESIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/downloadForEBudgetingWholesaleSigner`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_DOWNLOADFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/downloadForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_DOWNLOADFOREBUDGETINGRITMIKCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/downloadForEBudgetingRitmikChecker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_DOWNLOADFOREBUDGETINGRITMIKSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/downloadForEBudgetingRitmikSigner`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_REVISEFOREBUDGETINGWHOLESALEMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/reviseForEBudgetingWholesaleMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_REVISEFOREBUDGETINGRITMIKMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/reviseForEBudgetingRitmikMaker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_REJECTSUBMITTERFOREBUDGETINGWHOLESALECHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/rejectSubmitterForEBudgetingWholesaleChecker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_REJECTSUBMITTERFOREBUDGETINGRITMIKCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/rejectSubmitterForEBudgetingRitmikChecker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_REJECTSUBMITTERFOREBUDGETINGWHOLESALESIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/rejectSubmitterForEBudgetingWholesaleSigner`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_REJECTSUBMITTERFOREBUDGETINGRITMIKSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/rejectSubmitterForEBudgetingRitmikSigner`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_APPROVESUBMITTERFOREBUDGETINGWHOLESALECHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/approveSubmitterForEBudgetingWholesaleChecker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_APPROVESUBMITTERFOREBUDGETINGRITMIKCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/approveSubmitterForEBudgetingRitmikChecker`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_APPROVESUBMITTERFOREBUDGETINGWHOLESALESIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/approveSubmitterForEBudgetingWholesaleSigner`;
	static STRING_URL_EBUDGETINGSUBMISSIONDETAILPAGE_APPROVESUBMITTERFOREBUDGETINGRITMIKSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EBUDGETINGSUBMISSIONDETAILPAGE}/approveSubmitterForEBudgetingRitmikSigner`;

	/* SUBMISSION DETAIL - END */

	//#endregion


	//#region RISK PRODUCT SCORING

	static STRING_PATH_CONTROLLER_RISKCUSTOMERDATA: string = "/RiskCustomerData";

	static STRING_URL_RISKCUSTOMERDATA_SELECTRISKCUSTOMERDATABYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectRiskCustomerDataByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTRISKCUSTOMERDATABYCIPNUMBER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectRiskCustomerDataByCIPNumber`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTRISKBASEDASSESSMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectRiskBasedAssessmentByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTRISKTRANSACTIONCUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectRiskTransactionCustomerByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTJOBPROFILECUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectJobProfileCustomerByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTGEOGRAPHICCUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectGeographicCustomerByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTPRODUCTCUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectProductCustomerByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTDISTRIBUTIONNETWORKBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectDistributionNetworkByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_DOWNLOADRISKTRANSACTIONCUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/downloadRiskTransactionCustomerByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_DOWNLOADRISKBASEDASSESSMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/downloadRiskBasedAssessmentByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SENDRISKTRANSACTIONCUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/sendRiskTransactionCustomerByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SENDRISKBASEDASSESSMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/sendRiskBasedAssessmentByAttributes`;

	//#endregion


	//#region TAX REGULATION

	static STRING_PATH_CONTROLLER_TAXREGULATION: string = "/TaxRegulation";

	static STRING_URL_TAXREGULATION_SELECTTAXREGULATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREGULATION}/selectTaxRegulationByToken`;
	static STRING_URL_TAXREGULATION_SELECTTAXREGULATIOBYATTRIBUTE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREGULATION}/selectTaxRegulationsByAttribute`;
	static STRING_URL_TAXREGULATION_INSERTTAXREGULATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREGULATION}/insertTaxRegulation`;
	static STRING_URL_TAXREGULATION_UPDATETAXREGULATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREGULATION}/updateTaxRegulation`;
	static STRING_URL_TAXREGULATION_DOWNLOADTAXREGULATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREGULATION}/downloadTaxRegulationByToken`;
	static STRING_URL_TAXREGULATION_UPDATESTATETAXREGULATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREGULATION}/updateStateTaxRegulation`;

	//#endregion


	//#region TAX REPORT

	static STRING_PATH_CONTROLLER_TAXREPORT: string = "/TaxReport";
	static STRING_URL_TAXREPORT_SELECTTAXREPORTFORMCSBYATTRIBUTE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/selectTaxReportForMCSByAttribute`;
	static STRING_URL_TAXREPORT_SELECTTAXREPORTFORPICBYATTRIBUTE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/selectTaxReportForPICByAttribute`;
	static STRING_URL_TAXREPORT_INSERTTAXREPORT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/insertTaxReport`;
	static STRING_URL_TAXREPORT_SELECTTAXREPORTBYTOKENFORPIC: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/selectTaxReportByTokenForPIC`;
	static STRING_URL_TAXREPORT_UPDATETAXREPORTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/updateTaxReportByToken`;
	static STRING_URL_TAXREPORT_SELECTLISTCHECKERTAXREPORT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/selectListCheckerTaxReport`;
	static STRING_URL_TAXREPORT_SELECTLISTSIGNERTAXREPORT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/selectListSignerTaxReport`;
	static STRING_URL_TAXREPORT_SELECTRECEIVERTYPEBYPIC: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/selectReceiverTypeByPIC`;
	static STRING_URL_TAXREPORT_SELECTCUSTOMERTAXBYATTRIBUTE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/selectCustomerTaxByAttribute`;
	static STRING_URL_TAXREPORT_APPROVECHECKERSTATUSTAXREPORT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/approveCheckerStatusTaxReport`;
	static STRING_URL_TAXREPORT_APPROVESIGNERSTATUSTAXREPORT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/approveSignerStatusTaxReport`;
	static STRING_URL_TAXREPORT_REJECTCHECKERSTATUSTAXREPORT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/rejectCheckerStatusTaxReport`;
	static STRING_URL_TAXREPORT_REJECTSIGNERSTATUSTAXREPORT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/rejectSignerStatusTaxReport`;
	static STRING_URL_TAXREPORT_DOWNLOADTAXREPORTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/downloadTaxReportByToken`;

	//#endregion

	//#region TAX REQUEST

	static STRING_PATH_CONTROLLER_TAXREQUEST: string = "/TaxRequest";

	static STRING_URL_TAXREQUEST_SELECTTAXREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREQUEST}/selectTaxRequestByToken`;
	static STRING_URL_TAXREQUEST_SELECTTAXREQUESTBYATTRIBUTEFORPIC: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREQUEST}/selectTaxRequestByAttributeForPIC`;
	static STRING_URL_TAXREQUEST_SELECTRECEIVERTYPEBYPIC: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREQUEST}/selectReceiverTypeByPIC`;
	static STRING_URL_TAXREQUEST_SELECTCUSTOMERTAXBYATTRIBUTE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREQUEST}/selectCustomerTaxByAttribute`;
	static STRING_URL_TAXREQUEST_INSERTTAXREQUEST: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREQUEST}/insertTaxRequest`;
	static STRING_URL_TAXREQUEST_UPDATETAXREQUESTFORPIC: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREQUEST}/updateTaxRequestForPIC`;
	static STRING_URL_TAXREQUEST_DOWNLOADTAXREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREQUEST}/downloadTaxRequestByToken`;
	static STRING_URL_TAXREQUEST_UPDATEDOCUMENTFORAFN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREQUEST}/updateDocumentForAFN`;

	//#endregion
}

//#endregion