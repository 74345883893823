//#region IMPORT

import { Pipe, PipeTransform } from "@angular/core";
import { StringConstant } from "src/app/constants/string.constant";

//#endregion


//#region PIPE

@Pipe
(
	{
	name: "trimShort"
	}
)

//#endregion


//#region CLASS

export class TrimShort implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: string): string
	{
		if (stringValue == null || stringValue === undefined)
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			if (stringValue.length <= 32)
			{
				return stringValue;
			}
			else
			{
				return stringValue.substring(0, 32) + "...";
			}
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "trimShortest"
	}
)

//#endregion


//#region CLASS

export class TrimShortest implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: string): string
	{
		if (stringValue == null || stringValue === undefined)
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			if (stringValue.length <= 12)
			{
				return stringValue;
			}
			else
			{
				return stringValue.substring(0, 12) + "...";
			}
		}
	}

	//#endregion
}

//#endregion