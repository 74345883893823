//#region IMPORT

import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class ProductGeneralDocumentDownloadModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for product GENERAL.
		Author: Abdullah Fahmi.
		Created on : Tuesday, 28 February 2023.				Updated on : -.
		Created by : Abdullah Fahmi.						Updated by : -.
		Version : 1.0:0.
	*/

	FilePath?: string;
	FileName?: string;

	/* Attribute - END */


	//#region CONSTRUCTOR

	constructor()
	{
		super();
	}

	//#endregion


	//#region  VALIDATION

	//#endregion
}

//#endregion
