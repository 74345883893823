//#region IMPORT

import { ENUM_OCCUPATIONREMARKS_TYPE } from "../constants/enum.constant";
import { GeneralModel } from "./bases/general.model";

//#endregion


//#region CLASS

export class ProductHomeOwnershipCreditModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for product home ownership credit model.
		Author: Ibrahim Aziz.
		Created on : Thrusday, 22 July 2021.			    Updated on : Thrusday, 22 July 2021.
		Created by : Billy Johannes.						Updated by : Billy Johannes.
		Version : 1.0:0.
	*/

	ID?: number;
	ProductGeneralID?: number;
	InsuredName?: string;
	RiskLocationRemarks?: string;
	Token?: string;
	OccupationRemarks?: ENUM_OCCUPATIONREMARKS_TYPE;
	BRIBranchName?: string;
	LoanAccountNumber?: string;

	/* INSURED - START */

	BuildingAddress?: string;
	BuildingCountryID?: number;
	BuildingCountryCode?: string;
	BuildingCountryName?: string;
	BuildingProvinceID?: number;
	BuildingProvinceCode?: string;
	BuildingProvinceName?: string;
	BuildingCityID?: number;
	BuildingCityCode?: string;
	BuildingCityName?: string;
	BuildingSubDistrictID?: number;
	BuildingVillageID?: number;
	BuildingHamletCode?: string;
	BuildingNeighbourhoodCode?: string;
	BuildingPostalID?: number;
	BuildingPostalCode?: string;
	BuildingPostalName?: string;
	BuildingLatitude?: number;
	BuildingLongitude?: number;

	BuildingPrice?: number;
	InteriorPrice?: number;
	BuildingNearMarket?: boolean;
	BuildingWorkPlace?: boolean;

	Construction?: string;
	RiskOccupation?: string;
	RiskCategory?: string;
	ZoneCategory?: string;
	FloodZone?: string;

	/* INSURED - END */

	PolicyHolder?: string;
	HolderName?: string;
	InsuredID?: string;
	CI?: string;
	PolicyNo?: string;
	TOC?: string;
	MO?: string;
	Branch?: number;
	Segment?: string;
	MasterF?: number;
	ISType?: string;
	SType?: string;
	RFLDID?: string;
	Lighting?: string;
	PolicyWording?: string;
	RateCode1?: string;
	AddUnit1?: string;
	PCALC1?: string;
	RateCode2?: string;
	Rate2?: string;
	AddUnit2?: string;
	PCALC2?: string;
	RateCode3?: string;
	Rate3?: string;
	AddUnit3?: string;
	PCALC3?: string;
	RateCode4?: string;
	Rate4?: string;
	AddUnit4?: string;
	PCALC4?: string;
	RateCode5?: string;
	Rate5?: string;
	AddUnit5?: string;
	InterestCode1?: string;
	InterestRemark1?: string;
	AddCurrency1?: string;
	InterestCode2?: string;
	InterestRemark2?: string;
	AddCurrency2?: string;
	TSI2?: string;
	InterestCode3?: string;
	InterestRemark3?: string;
	AddCurrency3?: string;
	TSI3?: string;
	InterestCode4?: string;
	InterestRemark4?: string;
	AddCurrency4?: string;
	TSI4?: string;
	InterestCode5?: string;
	InterestRemark5?: string;
	AddCurrency5?: string;
	TSI5?: string;
	FeeID?: string;
	FeeCurrency?: string;
	FeeAmount?: string;
	DutyID?: string;
	DutyRemark?: string;
	DutyCurrency?: string;
	DutyAmount?: string;
	BrokerAgent1?: string;
	BrokerAgentType1?: string;
	BrokerAgentFee1?: number;
	Deductible1?: string;
	Dremarks1?: string;
	DedCur1?: string;
	DedAmountMin1?: string;
	DedAmountMax1?: string;
	DedOfTSI1?: string;
	DedOfCL1?: string;
	Deductible2?: string;
	Dremarks2?: string;
	DedCur2?: string;
	DedAmountMin2?: string;
	DedAmountMax2?: string;
	DedOfTSI2?: string;
	DedOfCL2?: string;
	Deductible3?: string;
	Dremarks3?: string;
	DedCur3?: string;
	DedAmountMin3?: string;
	DedAmountMax3?: string;
	DedOfTSI3?: string;
	DedOfCL3?: string;
	Deductible4?: string;
	Dremarks4?: string;
	DedCur4?: string;
	DedAmountMin4?: string;
	DedAmountMax4?: string;
	DedOfTSI4?: string;
	DedOfCL4?: string;
	Deductible5?: string;
	Dremarks5?: string;
	DedCur5?: string;
	DedAmountMin5?: string;
	DedAmountMax5?: string;
	DedOfTSI5?: string;
	DedOfCL5?: string;

	/* Attribute - END */


	//#region GETTER

	getOccupationRemarks(): string
	{
		if (this.OccupationRemarks == null || this.OccupationRemarks === undefined)
		{
			return "";
		}
		else if (this.OccupationRemarks === ENUM_OCCUPATIONREMARKS_TYPE.FlatHouse)
		{
			return "Rumah Susun";
		}
		else if (this.OccupationRemarks === ENUM_OCCUPATIONREMARKS_TYPE.LandedHouse)
		{
			return "Rumah Tinggal";
		}
		else
		{
			return "";
		}
	}

	//#endregion


	//#region SETTER

	setOccupationRemarks(stringOccupationRemarks: string): number
	{
		if (stringOccupationRemarks == null || stringOccupationRemarks === undefined)
		{
			return ENUM_OCCUPATIONREMARKS_TYPE.LandedHouse;
		}
		else
		{
			stringOccupationRemarks = stringOccupationRemarks.toLowerCase();

			if (stringOccupationRemarks === "rumah susun")
			{
				return ENUM_OCCUPATIONREMARKS_TYPE.FlatHouse;
			}
			else
			{
				return ENUM_OCCUPATIONREMARKS_TYPE.LandedHouse;
			}
		}
	}

	//#endregion
}

//#endregion