<div class="DivTableControl">
	<div class="DivTableSubControl">
		<div [ngClass]="{ 'Enable' : !_booleanButtonFirst, 'Disable' : _booleanButtonFirst }" class="DivButtonFirst"><input type="button" (click)="goToFirst();" [disabled]="_booleanButtonFirst"></div>
		<div [ngClass]="{ 'Enable' : !_booleanButtonPrevious, 'Disable' : _booleanButtonPrevious }" class="DivButtonPrevious"><input type="button" (click)="goToPrevious();" [disabled]="_booleanButtonPrevious"></div>
		<span class="SpanTablePage">Page {{ _modelTableForm.Page }} out of {{ _modelTableForm.PageTotal }}</span>
		<div [ngClass]="{ 'Enable' : !_booleanButtonNext, 'Disable' : _booleanButtonNext }" class="DivButtonLast"><input type="button" (click)="goToNext();" [disabled]="_booleanButtonNext"></div>
		<div [ngClass]="{ 'Enable' : !_booleanButtonLast, 'Disable' : _booleanButtonLast }" class="DivButtonNext"><input type="button" (click)="goToLast();" [disabled]="_booleanButtonLast"></div>
	</div>

	<div class="DivTableSubControl">
		<label for="selectRowPerPage">Row per page</label>
		<select id="selectRowPerPage" [(ngModel)]="_modelTableForm.RowPerPage" (change)="listenRowPerPage();">
			<option [ngValue]="20">20</option>
			<option [ngValue]="40">40</option>
		</select>
	</div>
</div>