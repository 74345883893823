//#region IMPORT

import { ENUM_LETTER_TYPE, ENUM_PAYMENT_TYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { GeneralModel } from "./bases/general.model";
import { EBudgetingDeclarationDetailTransactionVideModel } from "./ebudgetingdeclarationdetailtransactionvide.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class EBudgetingDeclarationDetailTransactionModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for EBudgeting Declaration Detail Page model, used for e-Budgeting declaration module.
		Author: Billy Johannes.
		Created on : Friday, 07 June 2024. 				Updated on : Friday, 07 June 2024.
		Created by : Billy Johannes.					Updated by : Billy Johannes.
		Version : 1.0:0.
	*/

	Title?: string;
	Description?: string;
	BeneficiaryName?: string;
	BankAccountToken?: string;
	SubmissionAmount?: number;
	CurrencyID?: number;
	ListVide?: Array<EBudgetingDeclarationDetailTransactionVideModel>;
	BankAccountBankName?: string;
	BankAccountNumber?: string;
	Currency?: string;

	/* Attribute - END */


	/* CONSTRUCTOR - START */

	constructor()
	{
		super();
		this.ListVide = [];
	}

	/* CONSTRUCTOR - END */


	//#region VALIDATE

	validateAddTransaction(enumPaymentType?: ENUM_PAYMENT_TYPE, enumLetterType?: ENUM_LETTER_TYPE): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Add List Declaration");

		if(this.Title === undefined || this.Title === null || this.Title === "")
		{
			modelResponse.MessageContent = "Transaction title can't be empty! Please fill in the blanks!";
		}
		else if(this.Description === undefined || this.Description === null || this.Description === "")
		{
			modelResponse.MessageContent = "Transaction description can't be empty! Please fill in the blanks!";
		}
		else if (enumLetterType === ENUM_LETTER_TYPE.DeclarationRealizationDebt || enumLetterType === ENUM_LETTER_TYPE.DeclarationRealizationDownPayment)
		{
			if (this.SubmissionAmount == null || this.SubmissionAmount === undefined || this.SubmissionAmount <= 0)
			{
				modelResponse.MessageContent = "Submission Amount can't be empty! Please fill in the blanks!";
			}
			else if (this.CurrencyID == null || this.CurrencyID === undefined)
			{
				modelResponse.MessageContent = "Currency can't be empty! Please fill in the blanks!";
			}
			//Duplicate this and line 104 because once condition enter the if-elseif it will choose the inner if and can't be escape
			else if (enumPaymentType === ENUM_PAYMENT_TYPE.Cash)
			{
				if (this.BeneficiaryName == null || this.BeneficiaryName === undefined || this.BeneficiaryName === "")
				{
					modelResponse.MessageContent = "Beneficiary Name can't be empty! Please fill in the blanks!";
				}
				else
				{
					modelResponse.MessageContent = "Form is filled correctly.";
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			}
			else if (enumPaymentType === ENUM_PAYMENT_TYPE.Transfer)
			{
				if (this.BankAccountToken == null || this.BankAccountToken === undefined || this.BankAccountToken === "")
				{
					modelResponse.MessageContent = "Bank Account can't be empty! Please fill in the blanks!";
				}
				else
				{
					modelResponse.MessageContent = "Form is filled correctly.";
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			}
			else
			{

			}
		}
		else if (enumPaymentType === ENUM_PAYMENT_TYPE.Cash)
		{
			if (this.BeneficiaryName == null || this.BeneficiaryName === undefined || this.BeneficiaryName === "")
			{
				modelResponse.MessageContent = "Beneficiary Name can't be empty! Please fill in the blanks!";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else if (enumPaymentType === ENUM_PAYMENT_TYPE.Transfer)
		{
			if (this.BankAccountToken == null || this.BankAccountToken === undefined || this.BankAccountToken === "")
			{
				modelResponse.MessageContent = "Bank Account can't be empty! Please fill in the blanks!";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}

		return modelResponse;
	}

	//#endregion
}

//#endregion