//#region IMPORT

import { ENUM_BRIDEBIT_STATUS, ENUM_GENDER_TYPE, ENUM_IDENTIFICATION_TYPE, ENUM_POLICY_STATUS, ENUM_PROJECT_TYPE, ENUM_RESPONSE_STATE, ENUM_SPPA_STATUS } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { BRIAccountModel } from "./briaccount.model";
import { LeadModel } from "./lead.model";
import { ProductAsriModel } from "./productasri.model";
import { ProductAsriSyariahModel } from "./productasrisyariah.model";
import { ProductHomeOwnershipCreditModel } from "./producthomeownershipcredit.model";
import { ProductOtoModel } from "./productoto.model";
import { ProductOtoSyariahModel } from "./productotosyariah.model";
import { ProductPersonalAccidentModel } from "./productpersonalaccident.model";
import { ProductPersonalAccidentSyariahModel } from "./produtpersonalaccidentsyariah.model";
import { ResponseModel } from "./response.model";
import { UserModel } from "./user.model";
import { VirtualAccountModel } from "./virtualaccount.model";

//#endregion


//#region CLASS

export class ProductGeneralModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for product general model, used for contain general information to cover specific product.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020.		    	Updated on : Thursday, 20 june 2024.
		Created by : Andri Septiawan.						Updated by : Tri Aji Ramadhan.
		Version : 1.0:2.
	*/

	/* POLICY DETAIL - START */

	ID?: number;
	ProductCode?: string;
	ProductName?: string;
	ReferenceNumber?: string;
	PolicyNumber?: string;
	PolicyID?: number;
	Status?: ENUM_SPPA_STATUS;
	PolicyStartDate?: Date;
	PolicyEndDate?: Date;
	LeadToken?: string;

	CustomerID?: number;
	RelationshipManagerID?: number;
	Token?: string;

	Disclaimer?: boolean;
	Note?: string;
	ProjectSource?: ENUM_PROJECT_TYPE;

	CreatedStartDate?: Date;
	CreatedEndDate?: Date;

	/* POLICY DETAIL - END */

	/* POLICYHOLDER - START */

	PolicyholderFullName?: string;
	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderBirthDate?: Date;
	PolicyholderBirthPlace?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderTelephone?: string;
	PolicyholderMobilePhone?: string;
	PolicyholderEmail?: string;

	PolicyholderIdentificationNumber?: string;
	PolicyholderIdentificationType?: ENUM_IDENTIFICATION_TYPE;

	PolicyholderAddress?: string;
	PolicyholderCountryID?: number;
	PolicyholderProvinceID?: number;
	PolicyholderCityID?: number;
	PolicyholderSubDistrictID?: number;
	PolicyholderVillageID?: number;
	PolicyholderHamletCode?: string;
	PolicyholderNeighbourhoodCode?: string;
	PolicyholderPostalID?: number;
	PolicyholderLatitude?: number;
	PolicyholderLongitude?: number;

	PolicyholderPNMBranchName?: string;
	PolicyholderPNMBranchArea?: string;
	BranchName?: string;

	/* POLICYHOLDER - END */

	/* PAYMENT - START */

	PremiumCurrency?: string;
	PremiumAmount?: number;

	DiscountCode?: string;
	DiscountCurrency?: string;
	DiscountPercent?: string;
	DiscountAmount?: number;

	CommissionCode?: string;
	CommissionPercent?: number;
	CommissionCurrency?: string;
	CommmisionAmount?: number;

	AdministrationAmount?: number;
	StampAmount?: number;
	PremiumTotalAmount?: number;

	InvoiceURL?: string;
	InvoiceExpiredDate?: Date;
	InvoiceID?: string;
	CoverageCode?: string;
	PaymentAccountID?: number;

	VirtualAccountID?: number;

	/* PAYMENT - END */

	/* TRACKING - START */

	SavedBy?: number;
	SavedOn?: Date;
	SubmittedBy?: number;
	SubmittedOn?: Date;
	SurveyedBy?: number;
	SurveyedOn?: Date;
	ReviewedBy?: number;
	ReviewedOn?: Date;
	PaidBy?: number;
	PaidOn?: Date;
	CompletedBy?: number;
	CompletedOn?: Date;
	DebitDate?: Date;

	/* TRACKING - END */

	/* STATUS ATTRIBUTE - START */

	PolicyStatus?: ENUM_POLICY_STATUS;
	BRIDebitStatus?: ENUM_BRIDEBIT_STATUS;
	ApprovedOn?: Date;
	ApprovedBy?: string;
	RejectedOn?: Date;
	RejectedBy?: number;
	RejectedNote?: string;
	UpdatedOn?: Date;

	/* STATUS ATTRIBUTE - END */

	/* CARE TECH - START */

	ANO?: string;
	INO?: string;
	SourceID?: string;

	/* CARE TECH - END */

	/* NOT MAPPED - START */

	modelUser?: UserModel;
	modelProductAsri?: ProductAsriModel;
	modelProductAsriSyariah?: ProductAsriSyariahModel;
	modelProductOto?: ProductOtoModel;
	modelProductOtoSyariah?: ProductOtoSyariahModel;
	modelProductPersonalAccident?: ProductPersonalAccidentModel;
	modelProductPersonalAccidentSyariah?: ProductPersonalAccidentSyariahModel;
	modelProductHomeOwnershipCredit?: ProductHomeOwnershipCreditModel;
	modelLead?: LeadModel;
	modelVirtualAccount?: VirtualAccountModel;
	modelBRIAccount?: BRIAccountModel;

	stringBirthDate?: string;

	/* NOT MAPPED - END */

	/* Attribute - END */


	//#region CONSTRUCTOR

	constructor()
	{
		super();

		this.modelProductAsri = new ProductAsriModel();
		this.modelProductAsriSyariah = new ProductAsriSyariahModel();
		this.modelProductOto = new ProductOtoModel();
		this.modelProductOtoSyariah = new ProductOtoSyariahModel();
		this.modelProductPersonalAccident = new ProductPersonalAccidentModel();
		this.modelProductPersonalAccidentSyariah = new ProductPersonalAccidentSyariahModel();
		this.modelProductHomeOwnershipCredit = new ProductHomeOwnershipCreditModel();
	}

	//#endregion


	//#region CLEAR

	clearProductGeneralForProductHomeOwnershipCredit(): void
	{
		this.modelProductAsri = undefined;
		this.modelProductAsriSyariah = undefined;
		this.modelProductOto = undefined;
		this.modelProductOtoSyariah = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductPersonalAccidentSyariah = undefined;
	}

	clearProductGeneralOtherModel(): void
	{
		this.modelProductAsri = undefined;
		this.modelProductAsriSyariah = undefined;
		this.modelProductOto = undefined;
		this.modelProductOtoSyariah = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductPersonalAccidentSyariah = undefined;
		this.modelProductHomeOwnershipCredit = undefined;
	}

	//#endregion


	//#region SETTER

	setModelProductHomeOwnershipCredit(): void
	{
		const stringProductHomeOwnershipCreditModel: string = JSON.stringify(this.modelProductHomeOwnershipCredit);
		this.modelProductHomeOwnershipCredit = new ProductHomeOwnershipCreditModel();
		this.modelProductHomeOwnershipCredit.setModelFromString(stringProductHomeOwnershipCreditModel);
	}

	//#endregion


	//#region GETTER

	getStatus(): string
	{
		if (this.Status == null || this.Status === undefined)
		{
			return "On Progress";
		}
		else if (this.Status === ENUM_SPPA_STATUS.RejectedSurvey)
		{
			return "Rejected";
		}
		else
		{
			return ENUM_SPPA_STATUS[this.Status];
		}
	}

	//#endregion


	//#region FUNCTION

	getClearCalculate(): void
	{
		this.PremiumAmount = 0;
		this.AdministrationAmount = 0;
		this.StampAmount = 0;
		this.PremiumTotalAmount = 0;
	}

	premiumTotalAmount(): void
	{
		if (this.AdministrationAmount !== undefined && this.StampAmount !== undefined && this.PremiumAmount !== undefined)
		{
			this.PremiumTotalAmount = this.AdministrationAmount + this.StampAmount + this.PremiumAmount;
		}
		else
		{

		}
	}

	getProductCodeName(): string
	{
		let stringProductCodeName = "";

		if(this.ProductCode === StringConstant.STRING_VALUE_PRODUCTCODE_DASHBOARD_MYHOUSE)
		{
			stringProductCodeName = "PNM - Rumahku";
		}
		else if(this.ProductCode === StringConstant.STRING_VALUE_PRODUCTCODE_DASHBOARD_DAMAGEOFBUSINESSPLACE)
		{
			stringProductCodeName = "PNM - KTU";
		}
		else
		{
			stringProductCodeName = "-";
		}

		return stringProductCodeName;
	}

	getStatusPolicy(): string
	{
		let stringStatusPolicy = "";

		if(this.PolicyStatus === ENUM_POLICY_STATUS.Active)
		{
			stringStatusPolicy = "Active";
		}
		else if(this.PolicyStatus === ENUM_POLICY_STATUS.Inactive)
		{
			stringStatusPolicy = "Inactive";
		}
		else if(this.PolicyStatus === ENUM_POLICY_STATUS.Pending)
		{
			stringStatusPolicy = "Tertunda";
		}
		else
		{
			stringStatusPolicy = "-";
		}

		return stringStatusPolicy;
	}

	getBRIDebitStatus(): string
	{
		let stringBRIDebitStatus = "";

		if(this.BRIDebitStatus === ENUM_BRIDEBIT_STATUS.Success)
		{
			stringBRIDebitStatus = "Berhasil";
		}
		else if(this.BRIDebitStatus === ENUM_BRIDEBIT_STATUS.PendingTransaction)
		{
			stringBRIDebitStatus = "Pending";
		}
		else if(this.BRIDebitStatus === ENUM_BRIDEBIT_STATUS.Failed)
		{
			stringBRIDebitStatus = "Gagal";
		}
		else
		{
			stringBRIDebitStatus = "-";
		}

		return stringBRIDebitStatus;
	}

	//#endregion


	//#region VALIDATE

	validateForProduct(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if (this.VehicleBrandCode == null || this.VehicleBrandCode === undefined || this.VehicleBrandCode === "")
		{
			modelResponse.MessageContent = "Model Vehicle Code can't be empty.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validatePolicyholderEmail(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);

		if (this.PolicyholderEmail == null || this.PolicyholderEmail === undefined || this.PolicyholderEmail === "")
		{
			modelResponse.MessageContent = "Email can't be empty";
		}
		else if (regularExpression.test(this.PolicyholderEmail))
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
			modelResponse.MessageContent = "Enter a valid email.";
		}

		return modelResponse;
	}

	validateProductForSearch(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product General");

		if (this.PolicyStartDate == null || this.PolicyStartDate === undefined || this.PolicyEndDate == null || this.PolicyEndDate === undefined)
		{
			modelResponse.MessageContent = "Product general is filled correctly";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
			if (this.PolicyStartDate > this.PolicyEndDate)
			{
				modelResponse.MessageContent = "Start date can't be greater than end date! Please fill this field!";
			}
			else if (this.PolicyEndDate === this.PolicyStartDate)
			{
				modelResponse.MessageContent = "Start date and end date can't be same! Please fill this field!";
			}
			else if (this.PolicyEndDate.calculateDayDifference(this.PolicyStartDate) > 31)
			{
				modelResponse.MessageContent = "Period date range can't be greater than 31 days! Please fill this field";
			}
			else
			{
				modelResponse.MessageContent = "Product general is filled correctly";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}

		return modelResponse;
	}

	validateRejectByToken(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product General");

		if (this.RejectedNote == null || this.RejectedNote === undefined || this.RejectedNote === "")
		{
			modelResponse.MessageContent = "Catatan kosong! Harus disertai catatan! Silahkan isi";
		}
		else
		{
			modelResponse.MessageContent = "Product general is filled correctly";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}

	validateDocumentToken(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Pengunduhan Lampiran.");
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERIC);

		if (this.Token == null || this.Token === undefined)
		{
			modelResponse.MessageContent = "Token dokumen kosong! Silahkan hubungi developer!";
		}
		else if (!regularExpression.test(this.Token))
		{
			modelResponse.MessageContent = "Token tidak dalam format yang benar!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validatePeriod(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product General Report");

		if (this.CreatedStartDate == null || this.CreatedStartDate === undefined)
		{
			modelResponse.MessageContent = "Start date can't be empty.";
		}
		else if (this.CreatedEndDate == null || this.CreatedEndDate === undefined)
		{
			modelResponse.MessageContent = "End date can't be empty.";
		}
		else if (this.CreatedEndDate < this.CreatedStartDate)
		{
			modelResponse.MessageContent = "End date can't be less than Start date.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}

		return modelResponse;
	}

	//#endregion
}

//#endregion