//#region IMPORT

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { UserModel } from "../models/user.model";
import { StringConstant } from "../constants/string.constant";
import { HandshakeModel } from "../models/handshake.model";
import { TableModel } from "../models/bases/table.model";
import { UserSessionModel } from "../models/usersession.model";
import { BusinessModel } from "../models/business.model";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
	providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class SessionService
{
	//#region DECLARATION

	public _modelUserSignIn: Observable<UserModel>;
	public _modelBusinessSignIn: Observable<BusinessModel>;
	public _modelHandshakeSignIn: Observable<HandshakeModel>;

	private _behaviourSubjectModelUser: BehaviorSubject<UserModel>;
	private _behaviourSubjectModelBusiness: BehaviorSubject<BusinessModel>;
	private _behaviourSubjectModelHandshake: BehaviorSubject<HandshakeModel>;

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		this._behaviourSubjectModelUser = new BehaviorSubject(new UserModel());
		this._behaviourSubjectModelBusiness = new BehaviorSubject(new BusinessModel());
		this._behaviourSubjectModelHandshake = new BehaviorSubject(new HandshakeModel());

		this._modelUserSignIn = this._behaviourSubjectModelUser.asObservable();
		this._modelBusinessSignIn = this._behaviourSubjectModelBusiness.asObservable();
		this._modelHandshakeSignIn = this._behaviourSubjectModelHandshake.asObservable();
	}

	//#endregion


	//#region SETTER

	setModelUserSignIn(modelUser: UserModel): void
	{
		this._behaviourSubjectModelUser.next(modelUser);
	}

	setModelBusinessSignIn(modelRelationshipManager: BusinessModel): void
	{
		this._behaviourSubjectModelBusiness.next(modelRelationshipManager);
	}

	setModelHandshakeSignIn(modelHandshake: HandshakeModel): void
	{
		this._behaviourSubjectModelHandshake.next(modelHandshake);
	}

	setModelHandshakeSignInToLocalStorage(modelHandshake: HandshakeModel): void
	{
		this.setModelHandshakeSignIn(modelHandshake);

		const stringModelHandshake: string = btoa(JSON.stringify(modelHandshake));
		let arrayStringModelHandshake: Array<string> = stringModelHandshake.split("");
		arrayStringModelHandshake = arrayStringModelHandshake.reverse();
		localStorage.setItem(StringConstant.STRING_LOCALSTORAGE_KEY_HANDSHAKEMODEL, arrayStringModelHandshake.join(""));
	}

	private setModelTableToLocalStorage(stringLocalStorageKey: string, modelTable: TableModel): void
	{
		const stringModelTable: string = btoa(JSON.stringify(modelTable));
		let arrayStringModelTable: Array<string> = stringModelTable.split("");
		arrayStringModelTable = arrayStringModelTable.reverse();
		localStorage.setItem(stringLocalStorageKey, arrayStringModelTable.join(""));
	}

	private setUserSessionToLocalStorage(stringLocalStorageKey: string, modelUserSessionContent: UserSessionModel): void
	{
		const stringUserSessionContent: string = btoa(JSON.stringify(modelUserSessionContent));
		let arrayStringUserSessionContent: Array<string> = stringUserSessionContent.split("");
		arrayStringUserSessionContent = arrayStringUserSessionContent.reverse();
		localStorage.setItem(stringLocalStorageKey, arrayStringUserSessionContent.join(""));
	}

	setUserSession(modelUserSession: UserSessionModel): void
	{
		this.setUserSessionToLocalStorage(StringConstant.STRING_LOCALSTORAGE_KEY_USERSESSION, modelUserSession);
	}

	setModelForApprovalAsriList(modelTable: TableModel): void
	{
		this.setModelTableToLocalStorage(StringConstant.STRING_LOCALSTORAGE_KEY_TABLEMODELFORAPPROVALASRILIST, modelTable);
	}

	setModelForApprovalOtoList(modelTable: TableModel): void
	{
		this.setModelTableToLocalStorage(StringConstant.STRING_LOCALSTORAGE_KEY_TABLEMODELFORAPPROVALOTOLIST, modelTable);
	}

	setModelForPurchaseAsriList(modelTable: TableModel): void
	{
		this.setModelTableToLocalStorage(StringConstant.STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEASRILIST, modelTable);
	}

	setModelForPurchaseOtoList(modelTable: TableModel): void
	{
		this.setModelTableToLocalStorage(StringConstant.STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEOTOLIST, modelTable);
	}

	setModelForPurchasePersonalAccidentList(modelTable: TableModel): void
	{
		this.setModelTableToLocalStorage(StringConstant.STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEPERSONALACCIDENTLIST, modelTable);
	}

	//#endregion


	//#region GETTER

	getModelHandshakeSignInFromLocalStorage(): HandshakeModel | null
	{
		try
		{
			const stringModelHandshake: string | null = localStorage.getItem(StringConstant.STRING_LOCALSTORAGE_KEY_HANDSHAKEMODEL);

			if (stringModelHandshake != null && stringModelHandshake.validateEmpty())
			{
				let arrayStringModelHandshake: Array<string> = stringModelHandshake.split("");
				arrayStringModelHandshake = arrayStringModelHandshake.reverse();

				const modelHandshake: HandshakeModel = new HandshakeModel();
				modelHandshake.setModelFromString(atob(arrayStringModelHandshake.join("")));

				return modelHandshake;
			}
			else
			{
				return null;
			}
		}
		catch (error)
		{
			return null;
		}
	}

	private getModelTableFromLocalStorage(stringLocalStorageKey: string): TableModel | null
	{
		try
		{
			const stringModelTable: string | null = localStorage.getItem(stringLocalStorageKey);

			if (stringModelTable != null && stringModelTable.validateEmpty())
			{
				let arrayStringModelTable: Array<string> = stringModelTable.split("");
				arrayStringModelTable = arrayStringModelTable.reverse();

				const modelTable: TableModel = new TableModel();
				modelTable.setModelFromString(atob(arrayStringModelTable.join("")));

				return modelTable;
			}
			else
			{
				return null;
			}
		}
		catch (error)
		{
			return null;
		}
	}

	private getUserSessionFromLocalStorage(stringLocalStorageKey: string): UserSessionModel | null
	{
		try
		{
			const stringUserSession: string | null = localStorage.getItem(stringLocalStorageKey);

			if (stringUserSession != null && stringUserSession.validateEmpty())
			{
				let arrayStringUserSession: Array<string> = stringUserSession.split("");
				arrayStringUserSession = arrayStringUserSession.reverse();

				const modelUserSession: UserSessionModel = new UserSessionModel();
				modelUserSession.setModelFromString(atob(arrayStringUserSession.join("")));

				return modelUserSession;
			}
			else
			{
				return null;
			}
		}
		catch (error)
		{
			return null;
		}
	}

	getUserSession(): UserSessionModel | null
	{
		return this.getUserSessionFromLocalStorage(StringConstant.STRING_LOCALSTORAGE_KEY_USERSESSION);
	}

	getModelForApprovalAsriList(): TableModel | null
	{
		return this.getModelTableFromLocalStorage(StringConstant.STRING_LOCALSTORAGE_KEY_TABLEMODELFORAPPROVALASRILIST);
	}

	getModelForApprovalOtoList(): TableModel | null
	{
		return this.getModelTableFromLocalStorage(StringConstant.STRING_LOCALSTORAGE_KEY_TABLEMODELFORAPPROVALOTOLIST);
	}

	getModelForPurchaseAsriList(): TableModel | null
	{
		return this.getModelTableFromLocalStorage(StringConstant.STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEASRILIST);
	}

	getModelForPurchaseOtoList(): TableModel | null
	{
		return this.getModelTableFromLocalStorage(StringConstant.STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEOTOLIST);
	}

	getModelForPurchasePersonalAccidentList(): TableModel | null
	{
		return this.getModelTableFromLocalStorage(StringConstant.STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEPERSONALACCIDENTLIST);
	}

	//#endregion


	//#region CLEAR

	clearSession(): void
	{
		this._behaviourSubjectModelUser.next(new UserModel());
		this._behaviourSubjectModelBusiness.next(new BusinessModel());
		this._behaviourSubjectModelHandshake.next(new HandshakeModel());
		this.clearSessionQuery();
	}

	clearKey(): void
	{
		localStorage.removeItem(StringConstant.STRING_LOCALSTORAGE_KEY_HANDSHAKEMODEL);
		localStorage.removeItem(StringConstant.STRING_LOCALSTORAGE_KEY_USERSESSION);
	}

	clearSessionQuery(): void
	{
		this.clearUserSession();
		this.clearTableModelForApprovalAsriList();
		this.clearTableModelForApprovalOtoList();
		this.clearTableModelForPurchaseAsriList();
		this.clearTableModelForPurchaseOtoList();
		this.clearTableModelForPurchasePersonalAccidentList();
	}

	clearUserSession(): void
	{
		localStorage.removeItem(StringConstant.STRING_LOCALSTORAGE_KEY_USERSESSION);
	}

	clearTableModelForApprovalAsriList(): void
	{
		localStorage.removeItem(StringConstant.STRING_LOCALSTORAGE_KEY_TABLEMODELFORAPPROVALASRILIST);
	}

	clearTableModelForApprovalOtoList(): void
	{
		localStorage.removeItem(StringConstant.STRING_LOCALSTORAGE_KEY_TABLEMODELFORAPPROVALOTOLIST);
	}

	clearTableModelForPurchaseAsriList(): void
	{
		localStorage.removeItem(StringConstant.STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEASRILIST);
	}

	clearTableModelForPurchaseOtoList(): void
	{
		localStorage.removeItem(StringConstant.STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEOTOLIST);
	}

	clearTableModelForPurchasePersonalAccidentList(): void
	{
		localStorage.removeItem(StringConstant.STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEPERSONALACCIDENTLIST);
	}

	//#endregion
}

//#endregion