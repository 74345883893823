/* eslint-disable max-len */
//#region IMPORT

import { ENUM_LETTERGENERALAPPROVAL_EBUDGETINGSTATUS, ENUM_LETTER_TYPE, ENUM_PAYMENTPRIORITY_TYPE, ENUM_PAYMENT_TYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { NumberConstant } from "../constants/number.constant";
import { GeneralModel } from "./bases/general.model";
import { EBudgetingDeclarationDetailApprovalModel } from "./ebudgetingdeclarationdetailapproval.model";
import { EBudgetingDeclarationDetailDocumentModel } from "./ebudgetingdeclarationdetaildocument.model";
import { EBudgetingDeclarationDetailTransactionModel } from "./ebudgetingdeclarationdetailtransaction.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class EBudgetingDeclarationDetailPageModel extends GeneralModel
{
	//#region ATTRIBUTE
	/*
		Description : Attribute for EBudgeting Declaration Detail Page model, used for e-Budgeting declaration module.
		Author: Billy Johannes.
		Created on : Friday, 07 June 2024. 				Updated on : Friday, 07 June 2024.
		Created by : Billy Johannes.					Updated by : Billy Johannes.
		Version : 1.0:0.
	*/

	DeclarationTitle?: string;
	LetterType?: ENUM_LETTER_TYPE;
	PaymentPriorityType?: ENUM_PAYMENTPRIORITY_TYPE;
	PaymentType?: ENUM_PAYMENT_TYPE;
	BudgetType?: string;
	BudgetYear?: number;
	SubmissionID?: number;
	SubmitterCheckerID?: number;
	SubmitterSignerID?: number;
	ManagerCheckerID?: number;
	ManagerSignerID?: number;
	ListDocument?: Array<EBudgetingDeclarationDetailDocumentModel>;
	ListTransaction?: Array<EBudgetingDeclarationDetailTransactionModel>;
	SubmissionNumber?: string;
	ApproverID?: number;
	ApproverName?: string;
	BankAccountToken?: string;
	BeneficiaryName?: string;
	BankAccountBankName?: string;
	BankAccountNumber?: string;
	CurrencyID?: number;
	Currency?: string;
	BranchCode?: string;
	BranchName?: string;
	AccountChargeID?: number;
	AccountCharge?: string;
	SubmissionAmount?: number;
	LetterGeneralToken?: string;
	DeclarationNumber?: string;
	SubmitterCheckerName?: string;
	SubmitterSignerName?: string;
	ManagerCheckerName?: string;
	ManagerSignerName?: string;
	ListApproval?: Array<EBudgetingDeclarationDetailApprovalModel>;
	DeclarationDocumentToken?: string;
	DeclarationDocumentName?: string;
	DeclarationDocumentFormat?: string;
	DeclarationDocumentExtension?: string;
	DeclarationDocumentData?: string;
	ApprovalNote?: string;

	//#endregion


	//#regiion CONSTRUCTOR

	constructor()
	{
		super();
		this.ListDocument = [];
		this.ListTransaction = [];
		this.ListApproval = [];
	}

	//#endregion


	//#region CLEAN

	cleanAll(): void
	{
		this.ListDocument = undefined;
		this.ListTransaction = undefined;
		this.ListApproval = undefined;
	}

	//#endregion


	//#region GENERATE

	generateBudgetYear(): Array<number>
	{
		const arrayNumberBudgetYear: Array<number> = [];
		const numberYear: number = new Date().getFullYear();

		arrayNumberBudgetYear.push(numberYear - 1);
		arrayNumberBudgetYear.push(numberYear);

		return arrayNumberBudgetYear;
	}

	//#endregion


	//#region VALIDATE

	validateSubmissionID(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Select Submission");

		if(this.SubmissionID === undefined || this.SubmissionID === null)
		{
			modelResponse.MessageContent = "Submission ID can't be empty! Please contact the developer!";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}

		return modelResponse;
	}

	validateAddDetailForTransaction(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Add Transaction");

		if(this.DeclarationTitle === undefined || this.DeclarationTitle === null || this.DeclarationTitle === "")
		{
			modelResponse.MessageContent = "Declaration title can't be empty! Please fill in the blanks!";
		}
		else if(this.LetterType === undefined || this.LetterType === null)
		{
			modelResponse.MessageContent = "Type can't be empty! Please fill in the blanks!";
		}
		else if(this.PaymentPriorityType === undefined || this.PaymentPriorityType === null)
		{
			modelResponse.MessageContent = "Payment Priority Type can't be empty! Please fill in the blanks!";
		}
		else if(this.PaymentType === undefined || this.PaymentType === null)
		{
			modelResponse.MessageContent = "Payment Type can't be empty! Please fill in the blanks!";
		}
		else if(this.BudgetYear === undefined || this.BudgetYear === null)
		{
			modelResponse.MessageContent = "Budget Type can't be empty! Please fill in the blanks!";
		}
		else if(this.SubmitterCheckerID === undefined || this.SubmitterCheckerID === null)
		{
			modelResponse.MessageContent = "Submitter Checker can't be empty! Please fill in the blanks!";
		}
		else if(this.SubmitterSignerID === undefined || this.SubmitterSignerID === null)
		{
			modelResponse.MessageContent = "Submitter Signer can't be empty! Please fill in the blanks!";
		}
		else if(this.LetterType === ENUM_LETTER_TYPE.DeclarationRealizationDownPayment || this.LetterType === ENUM_LETTER_TYPE.DeclarationRealizationDebt)
		{
			if(this.SubmissionID === undefined || this.SubmissionID === null || this.SubmissionID < 0)
			{
				modelResponse.MessageContent = "Submission Number can't be empty! Please fill in the blanks!";
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
				modelResponse.MessageContent = "Form is filled correctly.";
			}
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}

		return modelResponse;
	}

	validateInsert(numberTotalAmount: number): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateAddDetailForTransaction();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if(this.ListDocument !== undefined && this.ListDocument !== null && this.ListDocument.length <= 0)
			{
				modelResponse.MessageContent = "Document can't be empty! Please fill in the blanks!";
			}
			else if(this.ListTransaction !== undefined && this.ListTransaction !== null && this.ListTransaction.length <= 0)
			{
				modelResponse.MessageContent = "Transcation can't be empty! Please fill in the blanks!";
			}
			else if(this.ListTransaction !== undefined && this.ListTransaction !== null && this.ListTransaction.length > 0)
			{
				for(const modelListTransaction of this.ListTransaction)
				{
					if(modelListTransaction.ListVide !== undefined && modelListTransaction.ListVide !== null && modelListTransaction.ListVide.length <= 0)
					{
						modelResponse.MessageContent = "Vide can't be empty! Please fill in the blanks!";
					}
					else if(this.PaymentType === ENUM_PAYMENT_TYPE.Cash && modelListTransaction.BeneficiaryName === undefined || this.PaymentType === ENUM_PAYMENT_TYPE.Cash && modelListTransaction.BeneficiaryName === null || this.PaymentType === ENUM_PAYMENT_TYPE.Cash && modelListTransaction.BeneficiaryName === "")
					{
						modelResponse.MessageContent = "Beneficary Name for Cash Payment Type can't be empty! Please fill in the blanks!";
					}
					else if(this.PaymentType === ENUM_PAYMENT_TYPE.Transfer && modelListTransaction.BankAccountToken === undefined || this.PaymentType === ENUM_PAYMENT_TYPE.Transfer && modelListTransaction.BankAccountToken === null || this.PaymentType === ENUM_PAYMENT_TYPE.Transfer && modelListTransaction.BankAccountToken === "")
					{
						modelResponse.MessageContent = "Bank Account for Transfer Payment Type can't be empty! Please fill in the blanks!";
					}
					else if
					(
						(this.LetterType === ENUM_LETTER_TYPE.DeclarationRealizationDownPayment && modelListTransaction.SubmissionAmount === undefined) ||
						(this.LetterType === ENUM_LETTER_TYPE.DeclarationRealizationDownPayment && modelListTransaction.SubmissionAmount === null) ||
						(this.LetterType === ENUM_LETTER_TYPE.DeclarationRealizationDownPayment && modelListTransaction.SubmissionAmount !== undefined && modelListTransaction.SubmissionAmount !== null && modelListTransaction.SubmissionAmount <= 0) ||
						(this.LetterType === ENUM_LETTER_TYPE.DeclarationRealizationDebt && modelListTransaction.SubmissionAmount === undefined) ||
						(this.LetterType === ENUM_LETTER_TYPE.DeclarationRealizationDebt && modelListTransaction.SubmissionAmount === null) ||
						(this.LetterType === ENUM_LETTER_TYPE.DeclarationRealizationDebt && modelListTransaction.SubmissionAmount !== undefined && modelListTransaction.SubmissionAmount !== null && modelListTransaction.SubmissionAmount <= 0)
					)
					{
						modelResponse.MessageContent = "Submission Amount for current letter type can't be empty! Please fill in the blanks!";
					}
					else if
					(
						(this.LetterType === ENUM_LETTER_TYPE.DeclarationRealizationDownPayment && modelListTransaction.CurrencyID === undefined) ||
						(this.LetterType === ENUM_LETTER_TYPE.DeclarationRealizationDownPayment && modelListTransaction.CurrencyID === null) ||
						(this.LetterType === ENUM_LETTER_TYPE.DeclarationRealizationDownPayment && modelListTransaction.CurrencyID !== undefined && modelListTransaction.CurrencyID !== null && modelListTransaction.CurrencyID <= 0) ||
						(this.LetterType === ENUM_LETTER_TYPE.DeclarationRealizationDebt && modelListTransaction.CurrencyID === undefined) ||
						(this.LetterType === ENUM_LETTER_TYPE.DeclarationRealizationDebt && modelListTransaction.CurrencyID === null) ||
						(this.LetterType === ENUM_LETTER_TYPE.DeclarationRealizationDebt && modelListTransaction.CurrencyID !== undefined && modelListTransaction.CurrencyID !== null && modelListTransaction.CurrencyID <= 0)
					)
					{
						modelResponse.MessageContent = "Currency for current letter type can't be empty! Please fill in the blanks!";
					}
					else if(modelListTransaction.ListVide !== undefined && modelListTransaction.ListVide !== null && modelListTransaction.ListVide.length > 0)
					{
						for(const modelListVide of modelListTransaction.ListVide)
						{
							if
							(
								(this.LetterType === ENUM_LETTER_TYPE.DeclarationPayment && modelListVide.SubmissionAmount === undefined) ||
								(this.LetterType === ENUM_LETTER_TYPE.DeclarationPayment && modelListVide.SubmissionAmount === null) ||
								(this.LetterType === ENUM_LETTER_TYPE.DeclarationPayment && modelListVide.SubmissionAmount !== undefined && modelListVide.SubmissionAmount !== null && modelListVide.SubmissionAmount <= 0) ||
								(this.LetterType === ENUM_LETTER_TYPE.DeclarationReimburse && modelListVide.SubmissionAmount === undefined) ||
								(this.LetterType === ENUM_LETTER_TYPE.DeclarationReimburse && modelListVide.SubmissionAmount === null) ||
								(this.LetterType === ENUM_LETTER_TYPE.DeclarationReimburse && modelListVide.SubmissionAmount !== undefined && modelListVide.SubmissionAmount !== null && modelListVide.SubmissionAmount <= 0)
							)
							{
								modelResponse.MessageContent = "Submission Amount for current letter type can't be empty! Please fill in the blanks!";
							}
							else if
							(
								(this.LetterType === ENUM_LETTER_TYPE.DeclarationRealizationDownPayment && modelListVide.RealizationAmount === undefined) ||
								(this.LetterType === ENUM_LETTER_TYPE.DeclarationRealizationDownPayment && modelListVide.RealizationAmount === null) ||
								(this.LetterType === ENUM_LETTER_TYPE.DeclarationRealizationDownPayment && modelListVide.RealizationAmount !== undefined && modelListVide.RealizationAmount !== null && modelListVide.RealizationAmount <= 0) ||
								(this.LetterType === ENUM_LETTER_TYPE.DeclarationRealizationDebt && modelListVide.RealizationAmount === undefined) ||
								(this.LetterType === ENUM_LETTER_TYPE.DeclarationRealizationDebt && modelListVide.RealizationAmount === null) ||
								(this.LetterType === ENUM_LETTER_TYPE.DeclarationRealizationDebt && modelListVide.RealizationAmount !== undefined && modelListVide.RealizationAmount !== null && modelListVide.RealizationAmount <= 0)
							)
							{
								modelResponse.MessageContent = "Realization Amount for current letter type can't be empty! Please fill in the blanks!";
							}
							else if(numberTotalAmount > NumberConstant.NUMBER_VALUE_MAX_TOTALAMOUNT)
							{
								if(this.ManagerCheckerID === undefined || this.ManagerCheckerID === null)
								{
									modelResponse.MessageContent = "Manager Checker can't be empty! Please fill in the blanks!";
								}
								else if(this.ManagerSignerID === undefined || this.ManagerSignerID === null)
								{
									modelResponse.MessageContent = "Manager Signer can't be empty! Please fill in the blanks!";
								}
								else
								{
									modelResponse.State = ENUM_RESPONSE_STATE.Success;
									modelResponse.MessageContent = "Form is filled correctly.";
								}
							}
							else
							{
								modelResponse.State = ENUM_RESPONSE_STATE.Success;
								modelResponse.MessageContent = "Form is filled correctly.";
							}
						}
					}
					else
					{

					}
				}
			}
			else
			{

			}

		}
		else
		{

		}

		return modelResponse;
	}

	validateToken(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Token");

		if (this.LetterGeneralToken == null || this.LetterGeneralToken === undefined || this.LetterGeneralToken === "")
		{
			modelResponse.MessageContent = "Token can't be empty! Please contact the developer!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateForReject(): ResponseModel
	{
		let modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Reject Letter");

		if (this.LetterGeneralToken == null || this.LetterGeneralToken === undefined || this.LetterGeneralToken === "")
		{
			modelResponse.MessageContent = "Token can't be empty! Please contact the developer!";
		}
		else if (this.ApprovalNote == null || this.ApprovalNote === undefined || this.ApprovalNote === "")
		{
			modelResponse.MessageContent = "Approval Note can't be empty! Please fill in the blanks!";
		}
		else if(this.ListDocument !== undefined && this.ListDocument !== null && this.ListDocument.length > 0)
		{
			let modelDeclarationDocumentCheck: EBudgetingDeclarationDetailDocumentModel;

			for (const modelListDocument of this.ListDocument)
			{
				modelDeclarationDocumentCheck = new EBudgetingDeclarationDetailDocumentModel();
				modelDeclarationDocumentCheck.setModelFromObject(modelListDocument);
				modelResponse = modelDeclarationDocumentCheck.validateCheck();

				if (modelResponse.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponse;
				}
				else
				{
					modelResponse.MessageContent = "Form is filled correctly.";
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			}
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateForApprove(): ResponseModel
	{
		let modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Approve Letter");

		if (this.LetterGeneralToken == null || this.LetterGeneralToken === undefined || this.LetterGeneralToken === "")
		{
			modelResponse.MessageContent = "Token can't be empty! Please contact the developer!";
		}
		else if(this.ListDocument !== undefined && this.ListDocument !== null && this.ListDocument.length > 0)
		{
			let modelDeclarationDocumentCheck: EBudgetingDeclarationDetailDocumentModel;

			for (const modelListDocument of this.ListDocument)
			{
				modelDeclarationDocumentCheck = new EBudgetingDeclarationDetailDocumentModel();
				modelDeclarationDocumentCheck.setModelFromObject(modelListDocument);
				modelResponse = modelDeclarationDocumentCheck.validateCheck();

				if (modelResponse.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponse;
				}
				else
				{
					modelResponse.MessageContent = "Form is filled correctly.";
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			}
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion


	//#region GETTER

	getStatusReturnForInsert(): boolean
	{
		if(this.ListApproval !== undefined && this.ListApproval.length <= 0)
		{
			return true;
		}
		else if(this.ListApproval !== undefined && this.ListApproval[this.ListApproval.length-1].ApprovalStatus !== undefined)
		{
			if(this.ListApproval[this.ListApproval.length-1].ApprovalStatus === ENUM_LETTERGENERALAPPROVAL_EBUDGETINGSTATUS.RejectedByEBudgetingManagerChecker || this.ListApproval[this.ListApproval.length-1].ApprovalStatus === ENUM_LETTERGENERALAPPROVAL_EBUDGETINGSTATUS.RejectedByEBudgetingManagerSigner || this.ListApproval[this.ListApproval.length-1].ApprovalStatus === ENUM_LETTERGENERALAPPROVAL_EBUDGETINGSTATUS.RejectedByEBudgetingSubmitterAFNChecker || this.ListApproval[this.ListApproval.length-1].ApprovalStatus === ENUM_LETTERGENERALAPPROVAL_EBUDGETINGSTATUS.RejectedByEBudgetingSubmitterAFNSigner || this.ListApproval[this.ListApproval.length-1].ApprovalStatus === ENUM_LETTERGENERALAPPROVAL_EBUDGETINGSTATUS.RejectedByEBudgetingSubmitterChecker || this.ListApproval[this.ListApproval.length-1].ApprovalStatus === ENUM_LETTERGENERALAPPROVAL_EBUDGETINGSTATUS.RejectedByEBudgetingSubmitterSigner)
			{
				return false;
			}
			else
			{
				return true;
			}
		}
		else
		{
			return true;
		}
	}

	getLetterType(): string
	{
		if(this.LetterType === ENUM_LETTER_TYPE.DeclarationPayment)
		{
			return "Pembayaran";
		}
		else if(this.LetterType === ENUM_LETTER_TYPE.DeclarationReimburse)
		{
			return "Reimburse";
		}
		else if(this.LetterType === ENUM_LETTER_TYPE.DeclarationRealizationDownPayment)
		{
			return "Realisasi Uang Muka";
		}
		else if(this.LetterType === ENUM_LETTER_TYPE.DeclarationRealizationDebt)
		{
			return "Realisasi Kasbon";
		}
		else
		{
			return "-";
		}
	}

	//#endregion

	//#region SETTER

	setListDocument(): void
	{
		if(this.ListDocument !== undefined && this.ListDocument !== null && this.ListDocument.length > 0)
		{
			const arrayListDocument = JSON.parse(JSON.stringify(this.ListDocument));
			this.ListDocument = [];

			let modelListDocument: EBudgetingDeclarationDetailDocumentModel = new EBudgetingDeclarationDetailDocumentModel();

			for(const modelListDocumentTemp of arrayListDocument)
			{
				modelListDocument = new EBudgetingDeclarationDetailDocumentModel();
				modelListDocument.setModelFromObject(modelListDocumentTemp);
				this.ListDocument.push(modelListDocument);
			}
		}
	}

	setListTransaction(): void
	{
		if(this.ListTransaction !== undefined && this.ListTransaction !== null && this.ListTransaction.length > 0)
		{
			const arrayListTransaction = JSON.parse(JSON.stringify(this.ListTransaction));
			this.ListTransaction = [];

			let modelListTransaction: EBudgetingDeclarationDetailTransactionModel = new EBudgetingDeclarationDetailTransactionModel();

			for(const modelListTransactionTemp of arrayListTransaction)
			{
				modelListTransaction = new EBudgetingDeclarationDetailTransactionModel();
				modelListTransaction.setModelFromObject(modelListTransactionTemp);
				this.ListTransaction.push(modelListTransaction);
			}
		}
	}

	setListApproval(): void
	{
		if(this.ListApproval !== undefined && this.ListApproval !== null && this.ListApproval.length > 0)
		{
			const arrayListApproval = JSON.parse(JSON.stringify(this.ListApproval));
			this.ListApproval = [];

			let modelListApproval: EBudgetingDeclarationDetailApprovalModel = new EBudgetingDeclarationDetailApprovalModel();

			for(const modelListApprovalTemp of arrayListApproval)
			{
				modelListApproval = new EBudgetingDeclarationDetailApprovalModel();
				modelListApproval.setModelFromObject(modelListApprovalTemp);
				this.ListApproval.push(modelListApproval);
			}
		}
	}

	//#endregion
}

//#endregion