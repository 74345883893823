//#region CLASS

import { ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { GeneralModel } from "./bases/general.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class EBudgetingReportPageModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for E-Budgeting Report Page model, used for E-Budgeting Report Page Component.
		Author: Billy Johannes.
		Created on : Wednesday, 08 May 2024.     			Updated on : Wednesday, 08 May 2024.
		Created by : Billy Johannes.						Updated by : Billy Johannes.
		Version : 1.0:0.
	 */

	Division?: string;
	Account?: string;
	Period?: Date;
	StartPeriod?: Date;
	EndPeriod?: Date;
	BudgetAllocation?: number;
	UsedBudget?: number;
	AdvanceRequest?: number;
	RemainingShortfallPayment?: number;
	BudgetBalance?: number;

	/* Attribute - END */


	/* CONSTRUCTOR - START */

	constructor()
	{
		super();
	}

	/* CONSTRUCTOR - END */


	/* GETTER - START */

	/* GETTER - END */


	/* VALIDATION - START */

	validateSelectAndExport(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Report List");

		if(this.Division === undefined || this.Division === null || this.Division === "")
		{
			modelResponse.MessageContent = "Division can't be empty! Please choose division!";
		}
		else if(this.Account === undefined || this.Account === null || this.Account === "")
		{
			modelResponse.MessageContent = "Account can't be empty! Please choose account!";
		}
		else if(this.StartPeriod === undefined || this.StartPeriod === null)
		{
			modelResponse.MessageContent = "Start date can't be empty! Please choose start date!";
		}
		else if(this.EndPeriod === undefined || this.EndPeriod === null)
		{
			modelResponse.MessageContent = "End date can't be empty! Please choose start date!";
		}
		else if(this.StartPeriod !== undefined && this.StartPeriod !== null && this.StartPeriod.getFullYear() !== new Date().getFullYear())
		{
			modelResponse.MessageContent = "Start date must be within the same year!";
		}
		else if(this.EndPeriod !== undefined && this.EndPeriod !== null && this.EndPeriod.getFullYear() !== new Date().getFullYear())
		{
			modelResponse.MessageContent = "End date must be within the same year!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	/* VALIDATION - END */


	/* SETTER - START */


	/* SETTER - END */


	/* CLEAR - START */


	/* CLEAR - END */

}

//#endregion