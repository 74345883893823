//#region CLASS

import { GeneralModel } from "./bases/general.model";

//#endregion


//#region CLASS

export class EBudgetingReportExcelPageModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for E-Budgeting Report Excel Page model, used for E-Budgeting Report Excel Page Component.
		Author: Billy Johannes.
		Created on : Wednesday, 08 May 2024.     			Updated on : Wednesday, 08 May 2024.
		Created by : Billy Johannes.						Updated by : Billy Johannes.
		Version : 1.0:0.
	 */

	Division?: string;
	Account?: string;
	Period?: Date;
	StartPeriod?: Date;
	EndPeriod?: Date;
	BudgetAllocation?: number;
	UsedBudget?: number;
	AdvanceRequest?: number;
	RemainingShortfallPayment?: number;
	BudgetBalance?: number;

	/* Attribute - END */


	/* CONSTRUCTOR - START */

	constructor()
	{
		super();
	}

	/* CONSTRUCTOR - END */


	/* GETTER - START */

	/* GETTER - END */


	/* VALIDATION - START */

	/* VALIDATION - END */


	/* SETTER - START */


	/* SETTER - END */


	/* CLEAR - START */


	/* CLEAR - END */

}

//#endregion