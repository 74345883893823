<div id="divBodyDashboard" class="DivBodyGeneral">
    <header class="HeaderGeneral">
		<h2>Claim Monitoring</h2>
		<p>Dashboard claim PNM</p>
    </header>

	<div id="divMainFinancialRatioKonsolidasi" class="DivContainerListHorizontal FlexColumn">
		<form>
			<div class="DivForm">
				<h3>Pencarian</h3>

                <fieldset>
					<div class="DivFormHorizontalContainer">
						<div class="DivForm2Column">
							<label for="selectPerformance">Nomor Tiket</label>
							<input type="text" name="modelNumberPolicy" placeholder="Masukkan Nomor Polis" maxlength="10" [(ngModel)]="_modelClaimProposalRequest.TicketNumber">

							<label for="selectPerformance">Nomor Referensi</label>
							<input type="text" name="modelNumberPolicy" placeholder="Masukkan Nomor Referensi" maxlength="25" [(ngModel)]="_modelClaimProposalRequest.ReferenceNumber">
						</div>

						<div class="DivForm2Column">
							<label for="selectPerformance">Nama Tertanggung</label>
							<input type="text" name="modelNumberPolicy" placeholder="Masukkan Nama Tertanggung" maxlength="35" [(ngModel)]="_modelClaimProposalRequest.InsuredName">

							<label for="selectPerformance">Status Klaim</label>
							<select type="text" id="selectStatus" name="modelSelectStatus" required [(ngModel)]="_modelClaimProposalRequest.Status">
								<ng-container>
									<option [ngValue]="undefined" hidden>Pilih Status</option>
									<option [ngValue]="undefined">Semua</option>
									<option [ngValue]="_enuClaimStatus.Verified">{{ _stringConstant.STRING_CLAIMPROPOSAL_STATUS_VERIFIED }}</option>
									<option [ngValue]="_enuClaimStatus.NotVerified">{{ _stringConstant.STRING_CLAIMPROPOSAL_STATUS_NOTVERIFIED }}</option>
									<option [ngValue]="_enuClaimStatus.PendingForCorrection">{{ _stringConstant.STRING_CLAIMPROPOSAL_STATUS_PENDINGFORCORRECTION }}</option>
									<option [ngValue]="_enuClaimStatus.Submitted">{{ _stringConstant.STRING_CLAIMPROPOSAL_STATUS_SUBMITTED }}</option>
									<option [ngValue]="_enuClaimStatus.PendingForApprove">{{ _stringConstant.STRING_CLAIMPROPOSAL_STATUS_PENDINGFORAPPROVE }}</option>
									<option [ngValue]="_enuClaimStatus.PendingForCorrectionChecker">{{ _stringConstant.STRING_CLAIMPROPOSAL_STATUS_PENDINGFORCORRECTIONCHECKER }}</option>
									<option [ngValue]="_enuClaimStatus.PendingForCorrectionSigner">{{ _stringConstant.STRING_CLAIMPROPOSAL_STATUS_PENDINGFORCORRECTIONSIGNER }}</option>
								</ng-container>
							</select>
						</div>
					</div>
				</fieldset>
			</div>

            <div class="DivFormButtonCenter">
				<input type="button" name="buttonSearch" value="Cari" (click)="callSelectClaimForPNMMonitoringByAttributes(this, true)">
			</div>
		</form>

		<div id="divStatusDiscount" class="DivTableContainerHorizontal">
			<div class="DivTableContainerHorizontal">
				<div id="divMenuAllProductListContainer" class="DivTableContainer MarginLeft0">
					<app-tablecontrolinclude [_modelTableForm]="_modelTable" (_eventEmitterSelect)="setEventEmitterSelect($event);"></app-tablecontrolinclude>
					<table class="TableListDetailsAttachment">
						<thead>
							<tr>
								<th>Nomor Tiket</th>
								<th>Nomor Referensi</th>
								<th>Channel</th>
								<th>Produk</th>
								<th>Nama Tertanggung</th>
								<th>Tanggal Kejadian</th>
								<th>Cabang PNM</th>
								<th>Area PNM</th>
								<th>Cabang BRINS</th>
								<th>Status Klaim</th>
								<th>Reporter</th>
							</tr>
						</thead>

						<tbody>
							<tr class="TableRowCenter" *ngFor="let modelClaimProposal of _arrayModelClaimProposal; let numberIndex = index">
								<td>{{ modelClaimProposal.TicketNumber | convertEmptyToDash }}</td>
								<td>{{ modelClaimProposal.ReferenceNumber  | convertEmptyToDash }}</td>
								<td>{{ modelClaimProposal.getClaimProposalChannel() | convertEmptyToDash }}</td>
								<td>{{ modelClaimProposal.ProductName | convertEmptyToDash }}</td>
								<td>{{ modelClaimProposal.InsuredName | convertEmptyToDash }}</td>
								<td>{{ modelClaimProposal.IncidentDate | convertEmptyToDash | convertToDateShort }}</td>
								<td>{{ modelClaimProposal.PolicyholderPNMBranchName | convertEmptyToDash }}</td>
								<td>{{ modelClaimProposal.PolicyholderPNMBranchName | convertEmptyToDash }}</td>
								<td>{{ modelClaimProposal.BranchName | convertEmptyToDash }}</td>
								<td>{{ modelClaimProposal.getClaimStatus() | convertEmptyToDash }}</td>
								<td>{{ modelClaimProposal.ReporterFullName | convertEmptyToDash }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>
