//#region IMPORT

import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ENUM_PRODUCT_TYPE, ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { TableModel } from "src/app/models/bases/table.model";
import { ProductModel } from "src/app/models/product.model";
import { ResponseModel } from "src/app/models/response.model";
import { ProductService } from "src/app/services/product.service";
import { SessionService } from "src/app/services/session.service";
import { environment } from "src/environments/environment";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";
import { TableControlIncludeComponent } from "../includes/tablecontrol.include/tablecontrol.include.component";

//#endregion


//#region COMPONENT

@Component
(
	{
	selector: "app-productinformation",
	templateUrl: "./productinformation.component.html",
	styleUrls: ["./productinformation.component.sass"]
	}
)

//#endregion


//#region CLASS

export class ProductinformationComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	@ViewChild(TableControlIncludeComponent) private _componentTableControlInclude!: TableControlIncludeComponent;

	public _modelProduct: ProductModel;
	public _modelTable: TableModel = new TableModel();

	public _arrayModelProduct: Array<ProductModel>;

	public _enumProductTpye = ENUM_PRODUCT_TYPE;
	public _enumStringConstant = StringConstant;
	public _booleanContainerCalculate: Array<boolean | null>;
	public _modelNull = null;

	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceProduct: ProductService, serviceSession: SessionService, public router: Router)
	{
		super(serviceSession, router);

		this._modelProduct = new ProductModel();
		this._arrayModelProduct = Array<ProductModel>();
		this._booleanContainerCalculate = Array<null>();
	}

	//#endregion


	//#region INITIALIZE

	ngOnInit(): void
	{
		this.callSelectProductByAttributes();
	}

	//#endregion


	//#region WEB SERVICE

	callSelectProductByAttributes(): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		const componentCurrent: ProductinformationComponent = this;
		this._modelTable.Search = JSON.stringify(this._modelProduct);

		this._serviceProduct.selectProductByAttributes
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTable.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelTable.Result !== undefined)
						{
							componentCurrent._arrayModelProduct = JSON.parse(componentCurrent._modelTable.Result);

							componentCurrent._arrayModelProduct.forEach((modelProduct) =>
							{
								if (modelProduct.Code !== undefined)
								{
									componentCurrent._booleanContainerCalculate.push(componentCurrent.containerCalculator(modelProduct.Code));
								}
								else
								{

								}

							});
							componentCurrent._modelTable.Result = undefined;
							componentCurrent._componentTableControlInclude.setButtonState();
							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
						else
						{

						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectProductByAttributes(); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelTable);
	};

	//#endregion


	//#region FUNCTION

	shareProductURL(stringURL?: string): void
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Public URL";
		modelResponse.MessageContent = "Public url copied!";

		if (stringURL != null || stringURL !== undefined)
		{
			stringURL = stringURL.replace("../..", environment.URL_PRODUCT_API);
			const componentCopy = document.createElement("textarea");
			componentCopy.value = stringURL;
			componentCopy.setAttribute("readonly","");
			componentCopy.style.position = "absolute";
			componentCopy.style.left = "-9999px";
			document.body.appendChild(componentCopy);
			componentCopy.select();
			document.execCommand("copy");
			document.body.removeChild(componentCopy);

			this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
		}
		else
		{
			modelResponse.MessageContent = "Public url empty!";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
		}
	}

	containerCalculator(stringCode?: string): boolean
	{
		if
		(
			stringCode === this._enumStringConstant.STRING_VALUE_PRODUCT_ASRI ||
			stringCode === this._enumStringConstant.STRING_VALUE_PRODUCT_OTO ||
			stringCode === this._enumStringConstant.STRING_VALUE_PRODUCT_PERSONALACCIDENT ||
			stringCode === this._enumStringConstant.STRING_VALUE_PRODUCT_ASRI_SYARIAH ||
			stringCode === this._enumStringConstant.STRING_VALUE_PRODUCT_OTO_SYARIAH ||
			stringCode === this._enumStringConstant.STRING_VALUE_PRODUCT_PERSONALACCIDENT_SYARIAH
		)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	//#endregion


	//#region NAVIGATION

	goToPDF(stringURL?: string): void
	{
		if (stringURL == null || stringURL === undefined || stringURL === "")
		{

		}
		else
		{
			stringURL = stringURL.replace("../..", environment.URL_PRODUCT_API);
			window.open(stringURL, "_blank");
		}
	}

	goToCalculatorPremium(stringCoverage?: string): void
	{
		if(stringCoverage === this._enumStringConstant.STRING_VALUE_PRODUCT_ASRI)
		{
			this._router.navigate(["home", "calculatepremium", "asri"]);
		}
		else if (stringCoverage === this._enumStringConstant.STRING_VALUE_PRODUCT_OTO)
		{
			this._router.navigate(["home", "calculatepremium", "oto"]);
		}
		else if (stringCoverage === this._enumStringConstant.STRING_VALUE_PRODUCT_PERSONALACCIDENT)
		{
			this._router.navigate(["home", "calculatepremium", "personalaccident"]);
		}
		else if (stringCoverage === this._enumStringConstant.STRING_VALUE_PRODUCT_ASRI_SYARIAH)
		{
			this._router.navigate(["home", "calculatepremium", "asrisyariah"]);
		}
		else if (stringCoverage === this._enumStringConstant.STRING_VALUE_PRODUCT_OTO_SYARIAH)
		{
			this._router.navigate(["home", "calculatepremium", "otosyariah"]);
		}
		else if (stringCoverage === this._enumStringConstant.STRING_VALUE_PRODUCT_PERSONALACCIDENT_SYARIAH)
		{
			this._router.navigate(["home", "calculatepremium", "personalaccidentsyariah"]);
		}
		else
		{

		}
	}

	//#endregion


	//#region SETTER

	setEventEmitterSelect(modelTableUpdate: TableModel): void
	{
		this._modelTable = modelTableUpdate;

		this.callSelectProductByAttributes();
	}

	//#endregion
}

//#endregion