//#region IMPORT

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { QuicklinkModule, QuicklinkStrategy } from "ngx-quicklink";
import { AboutComponent } from "./components/about/about.component";
import { HomeComponent } from "./components/home/home.component";
import { SigninComponent } from "./components/signin/signin.component";
import { PublicGuardService } from "./services/guards/public.guard.service";
import { ProductinformationComponent } from "./components/productinformation/productinformation.component";
import { StringConstant } from "./constants/string.constant";
import { BranchComponent } from "./components/branch/branch.component";
import { ReportlistComponent } from "./components/report/reportlist/reportlist.component";
import { DownloadComponent } from "./components/download/download.component";
import { GeneralAuthorizedGuardService } from "./services/guards/generalauthorized.guard";
import { AnomalyDownloadComponent } from "./components/protect/anomaly/anomalydownload/anomalydownload.component";
import { TransactionmonitoringComponent } from "./components/transactionmonitoring/transactionmonitoring.component";
import { ClaimmonitoringComponent } from "./components/claimmonitoring/claimmonitoring.component";
import { ReportebudgetingComponent } from "./components/ebudgeting/reportebudgeting/reportebudgeting.component";

//#endregion


//#region DECLARATION

const arrayRoutes: Routes =
[
	{
		path: "",
		redirectTo: "/",
		pathMatch: "full"
	},
	{
		path: "",
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "signin",
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "signin/downloadTicketAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "downloadTicketAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: DownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	},
	{
		path: "home",
		redirectTo: "home/dashboard/smart",
	},
	{
		path: "home/relationshipmanager",
		redirectTo: "home/relationshipmanager/list",
	},
	{
		path: "home",
		component: HomeComponent,
		canActivate: [GeneralAuthorizedGuardService],
		children:
		[
			{
				path: "dashboard",
				loadChildren: () => import("./components/dashboards/dashboards.module").then(pageModule => pageModule.DashboardsModule)
			},
			{
				path: "productinformation",
				component: ProductinformationComponent,
			},
			{
				path: "About",
				component: AboutComponent,
			},
			{
				path: "branch",
				component: BranchComponent,
			},
			{
				path: "product",
				loadChildren: () => import("./components/productkpr/productkpr.module").then(pageModule => pageModule.ProductkprModule)
			},
			{
				path: "calculatepremium",
				loadChildren: () => import("./components/calculatepremiums/calculatepremiums.module").then(pageModule => pageModule.CalculatepremiumsModule)
			},
			{
				path: "relationshipmanager",
				loadChildren: () => import("./components/relationshipmanagers/relationshipmanagers.module").then(pageModule => pageModule.RelationshipmanagersModule)
			},
			{
				path: "ticket",
				loadChildren: () => import("./components/tickets/tickets.module").then(pageModule => pageModule.TicketsModule)
			},
			{
				path: "ematerai",
				loadChildren: () => import("./components/emeterai/ematerai.module").then(pageModule => pageModule.EmateraiModule)
			},
			{
				path: "export",
				children:
				[
					{
						path: "list",
						component: ReportlistComponent,
					}
				]
			},
			{
				path: "reimbursement",
				children:
				[
					{
						path: "approval",
						loadChildren: () => import("./components/approvalreimbursement/approval.module").then(pageModule => pageModule.ApprovalModule)
					},
				]
			},
			{
				path: "rcsa",
				children:
				[
					{
						path: "assessment",
						loadChildren: () => import("./components/rcsa/assessment/assessment.module").then(pageModule => pageModule.AssessmentModule)
					},
					{
						path: "approval",
						loadChildren: () => import("./components/rcsa/approval/approval.module").then(pageModule => pageModule.ApprovalModule)
					},
					{
						path: "report",
						loadChildren: () => import("./components/rcsa/report/report.module").then(pageModule => pageModule.ReportModule)
					},
					{
						path: "riskissuerequest",
						loadChildren: () => import("./components/rcsa/riskissuerequest/riskissuerequest.module").then(pageModule => pageModule.RiskissuerequestModule)
					},
				],
			},
			{
				path: "ras",
				children:
				[
					{
						path: "dashboard",
						loadChildren: () => import("./components/ras/dashboardras/dashboardras.module").then(pageModule => pageModule.DashboardrasModule)
					},
					{
						path: "realization",
						loadChildren: () => import("./components/ras/realization/realization.module").then(pageModule => pageModule.RealizationModule)
					},
					{
						path: "followupplan",
						loadChildren: () => import("./components/ras/followupplan/followupplan.module").then(pageModule => pageModule.FollowupplanModule)
					},
				],
			},
			{
				path: "ebudgeting",
				children:
				[
					{
						path: "declaration",
						loadChildren: () => import("./components/ebudgeting/declarations/declarations.module").then(pageModule => pageModule.DeclarationsModule)
					},
					{
						path: "submission",
						loadChildren: () => import("./components/ebudgeting/submissions/submissions.module").then(pageModule => pageModule.SubmissionsModule)
					},
					{
						path: "bankaccount",
						loadChildren: () => import("./components/ebudgeting/bankaccount/bankaccount.module").then(pageModule => pageModule.BankaccountModule)
					},
					{
						path: "report",
						component: ReportebudgetingComponent
					},
				],
			},
			{
				path: StringConstant.STRING_ROUTING_MENU_PROTECT,
				loadChildren: () => import("./components/protect/protect.module").then(pageModule => pageModule.ProtectModule)
			},
			{
				path: "digitalization",
				loadChildren: () => import("./components/digitalization/submissions/submission.module").then(pageModule => pageModule.SubmissionModule)
			},
			{
				path: "transactionmonitoring",
				component: TransactionmonitoringComponent
			},
			{
				path: "claimmonitoring",
				component: ClaimmonitoringComponent
			},
			{
				path: "gcgonline",
				loadChildren: () => import("./components/gcgonline/gcgonline.module").then(pageModule => pageModule.GcgOnlineModule)
			},
			{
				path: StringConstant.STRING_PATH_ROUTING_APUPPT,
				loadChildren: () => import("./components/apu-ppt/apu-ppt.module").then(pageModule => pageModule.APUPPTModule)
			},
			{
                path: "brinstax",
                children:
                [
                    {
                        path: "taxregulation",
                        loadChildren: () => import("./components/brinstax/taxregulation/taxregulation.module").then(pageModule => pageModule.TaxRegulationModule)
                    },
					{
						path: "taxreport",
						loadChildren: () => import("./components/brinstax/taxreport/taxreport.module").then(pageModule => pageModule.TaxReportModule)
					},
					{
						path: "taxrequest",
						loadChildren: () => import("./components/brinstax/taxrequest/taxrequest.module").then(pageModule => pageModule.TaxRequestModule)
					}
                ]
            },
		]
	},
	{
		path: StringConstant.STRING_ROUTING_MENU_DOWNLOADANOMALYATTACHMENT_PARAM + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: AnomalyDownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	}
];

//#endregion


//#region MODULE

@NgModule
(
	{
	imports:
	[
	QuicklinkModule,
	RouterModule.forRoot(arrayRoutes,
		{
		preloadingStrategy: QuicklinkStrategy
		})
	],
	exports: [RouterModule]
	}
)

//#endregion


//#region CLASS

export class AppRoutingModule { }

//#endregion
