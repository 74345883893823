//#region IMPORT

import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ENUM_CLAIMPROPOSAL_STATUS, ENUM_SPPA_STATUS } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { TableModel } from "src/app/models/bases/table.model";
import { ClaimProposalModel } from "src/app/models/claimproposal.model";
import { ResponseModel } from "src/app/models/response.model";
import { ClaimproposalService } from "src/app/services/claimproposal.service";
import { SessionService } from "src/app/services/session.service";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";
import { TableControlIncludeComponent } from "../includes/tablecontrol.include/tablecontrol.include.component";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-claimmonitoring",
	templateUrl: "./claimmonitoring.component.html",
	styleUrls: ["./claimmonitoring.component.sass"]
})

//#endregion


//#region CLASS

export class ClaimmonitoringComponent extends BaseAuthourizeComponent
{

	//#region DECLARATION

	@ViewChild(TableControlIncludeComponent) private _componentTableControlInclude!: TableControlIncludeComponent;

	public _arrayModelClaimProposal: Array<ClaimProposalModel>;

	public _modelTable: TableModel;
	public _modelClaimProposal: ClaimProposalModel;
	public _modelClaimProposalRequest: ClaimProposalModel;

	public _stringConstant = StringConstant;
	public _enuClaimStatus = ENUM_CLAIMPROPOSAL_STATUS;

	public _elementHTMLDivMenuAllProductListContainer: HTMLElement | any;

	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceClaimProposal: ClaimproposalService, serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);

		this._modelClaimProposal = new ClaimProposalModel();
		this._modelClaimProposalRequest = new ClaimProposalModel();
		this._modelTable = new TableModel();

		this._arrayModelClaimProposal = [];

		this._elementHTMLDivMenuAllProductListContainer = document.getElementById("divMenuAllProductListContainer");
	}

	//#endregion


	//#region

	ngOnInit(): void
	{
		this.callSelectClaimForPNMMonitoringByAttributes(this, true);
		this._elementHTMLDivMenuAllProductListContainer = document.getElementById("divMenuAllProductListContainer");
		this._elementHTMLDivMenuAllProductListContainer.style.display = "block";
	}

	//#endregion


	//#region NAVIGATION

	//#endregion


	//#region WEB SERVICE

	public callSelectClaimForPNMMonitoringByAttributes(componentCurrent: ClaimmonitoringComponent, booleanFirstSearch: boolean): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		const modelClaimProposalRequest = new ClaimProposalModel();
		modelClaimProposalRequest.Channel = componentCurrent._modelClaimProposalRequest.Channel;
		modelClaimProposalRequest.PolicyNumber = componentCurrent._modelClaimProposalRequest.PolicyNumber;
		modelClaimProposalRequest.ReferenceNumber = componentCurrent._modelClaimProposalRequest.ReferenceNumber;
		modelClaimProposalRequest.Status = componentCurrent._modelClaimProposalRequest.Status;

		componentCurrent._modelTable.Search = JSON.stringify(modelClaimProposalRequest);

		if(booleanFirstSearch)
		{
			componentCurrent._modelTable.Page = 1;
		}

		componentCurrent._serviceClaimProposal.selectClaimForPNMMonitoringByAttributes
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTable.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelTable.Result !== undefined)
						{
							componentCurrent._arrayModelClaimProposal = [];

							let arrayModelClaimProposalTemp: Array<ClaimProposalModel> = [];
							arrayModelClaimProposalTemp = JSON.parse(componentCurrent._modelTable.Result);
							componentCurrent._modelTable.Result = undefined;

							let modelClaimProposal: ClaimProposalModel;

							for(const modelClaimProposalTemp of arrayModelClaimProposalTemp)
							{
								modelClaimProposal = new ClaimProposalModel();
								modelClaimProposal.setModelFromObject(modelClaimProposalTemp);
								componentCurrent._arrayModelClaimProposal.push(modelClaimProposal);
							}

							componentCurrent._elementHTMLDivMenuAllProductListContainer = document.getElementById("divMenuAllProductListContainer");
							componentCurrent._elementHTMLDivMenuAllProductListContainer.style.display = "block";

							componentCurrent._functionUserInterface.updateLoadingProgress();
							componentCurrent._componentTableControlInclude.setButtonState();
						}
						else
						{
							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							componentCurrent._elementHTMLDivMenuAllProductListContainer = document.getElementById("divMenuAllProductListContainer");
							componentCurrent._elementHTMLDivMenuAllProductListContainer.style.display = "none";
							componentCurrent._modelTable.Page = 1;
							componentCurrent._modelTable.RowPerPage = 20;
						});
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectClaimForPNMMonitoringByAttributes(componentCurrent, true); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelTable);
	}

	//#endregion


	//#region SETTER

	setEventEmitterSelect(modelTableUpdate: TableModel): void
	{
		this._modelTable = modelTableUpdate;
		this.callSelectClaimForPNMMonitoringByAttributes(this, false);
	}

	//#endregion


	//#region GETTER


	//#endregion

}

//#endregion
