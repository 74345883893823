//#region IMPORT

import { Component, Inject, Injectable, LOCALE_ID, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ENUM_BRIDEBIT_STATUS, ENUM_POLICY_STATUS, ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { TableModel } from "src/app/models/bases/table.model";
import { ResponseModel } from "src/app/models/response.model";
import { SessionService } from "src/app/services/session.service";
import * as xlsx from "xlsx";
import { ProductGeneralModel } from "src/app/models/productgeneral.model";
import { ProductGeneralDocumentDownloadModel } from "src/app/models/productgeneraldocumentdownload.model";
import { ProductgeneralService } from "src/app/services/productgeneral.service";
import { Platform } from "@angular/cdk/platform";
import { MatDateFormats, NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";
import { TableControlIncludeComponent } from "../includes/tablecontrol.include/tablecontrol.include.component";

//#endregion


//#region CONST

export const appDateFormats: MatDateFormats =
{
	parse:
	{
		dateInput: { month: "short", year: "numeric", day: "numeric" },
	},
	display:
	{
		dateInput: "input",
		monthYearLabel: { year: "numeric", month: "numeric" },
		dateA11yLabel: { year: "numeric", month: "long", day: "numeric"
		},
		monthYearA11yLabel: { year: "numeric", month: "long" },
	}
};

//#endregion


//#region CLASS DECLARATION
@Injectable() export class AppDateAdapter extends NativeDateAdapter
{
	constructor(@Inject(LOCALE_ID) public locale: string)
	{
		super(locale, new Platform(locale));
	}

	// eslint-disable-next-line @typescript-eslint/ban-types
	format(date: Date, displayFormat: Object): string
	{
		if (displayFormat === "input")
		{
			let stringDay: string = date.getUTCDate().toString();
			stringDay = +stringDay < 10 ? "0" + stringDay : stringDay;
			let stringMonth: string = (date.getUTCMonth() + 1).toString();
			stringMonth = +stringMonth < 10 ? "0" + stringMonth : stringMonth;
			const numberYear = date.getFullYear();
			return `${stringDay}/${stringMonth}/${numberYear}`;
		}
		return date.toDateString();
	}
}

//#endregion



//#region COMPONENT

@Component({
	selector: "app-transactionmonitoring",
	templateUrl: "./transactionmonitoring.component.html",
	styleUrls: ["./transactionmonitoring.component.sass"],
	providers:
		[
			{provide: DateAdapter, useClass: AppDateAdapter},
			{provide: MAT_DATE_FORMATS, useValue: appDateFormats}
		]
})

//#endregion


//#region CLASS

export class TransactionmonitoringComponent extends BaseAuthourizeComponent
{

	//#region DECLARATION

	@ViewChild(TableControlIncludeComponent) private _componentTableControlInclude!: TableControlIncludeComponent;

	public _arrayModelProductGeneral: Array<ProductGeneralModel>;
	public _arrayModelProductGeneralReport: Array<ProductGeneralModel>;

	public _modelTable: TableModel;
	public _modelProductGeneral: ProductGeneralModel;
	public _modelProductGeneralRequest: ProductGeneralModel;

	public _stringConstant = StringConstant;
	public _enumPolicyStatus = ENUM_POLICY_STATUS;
	public _enumBRIDebitStatus = ENUM_BRIDEBIT_STATUS;

	public _dateStart: Date | undefined;
	public _dateEnd: Date | undefined;

	public _matrixExportExcelDashboard: any = [];

	public _fileWorkBookExcel: xlsx.WorkBook;

	public _elementHTMLDivMenuAllProductListContainer: HTMLElement | any;

	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceProductGeneral: ProductgeneralService, serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);

		this._dateStart = undefined;
		this._dateEnd = undefined;

		this._modelProductGeneral = new ProductGeneralModel();
		this._modelProductGeneralRequest = new ProductGeneralModel();
		this._modelTable = new TableModel();

		this._arrayModelProductGeneral = [];
		this._arrayModelProductGeneralReport = [];

		this._fileWorkBookExcel = xlsx.utils.book_new();

		this._elementHTMLDivMenuAllProductListContainer = document.getElementById("divMenuAllProductListContainer");
	}

	//#endregion


	//#region

	ngOnInit(): void
	{
		this.callSelectDashboardPNMByAttributes(this, true);
	}

	//#endregion


	//#region NAVIGATION

	goToTransactionMonitoringDetail(stringToken: string)
	{
		this._router.navigate(["home", "transactionmonitoring", "detail", stringToken]);
	}

	//#endregion


	//#region WEB SERVICE

	public callSelectDashboardPNMByAttributes(componentCurrent: TransactionmonitoringComponent, booleanFirstSearch: boolean): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		const modelProductGeneralRequest = new ProductGeneralModel();
		modelProductGeneralRequest.ProductCode = componentCurrent._modelProductGeneralRequest.ProductCode;
		modelProductGeneralRequest.PolicyStatus = componentCurrent._modelProductGeneralRequest.PolicyStatus;
		modelProductGeneralRequest.BRIDebitStatus = componentCurrent._modelProductGeneralRequest.BRIDebitStatus;
		modelProductGeneralRequest.ReferenceNumber = componentCurrent._modelProductGeneralRequest.ReferenceNumber;
		modelProductGeneralRequest.CreatedStartDate = componentCurrent._dateStart;
		modelProductGeneralRequest.CreatedEndDate = componentCurrent._dateEnd;
		modelProductGeneralRequest.clearProductGeneralOtherModel();

		componentCurrent._modelTable.Search = JSON.stringify(modelProductGeneralRequest);

		if(booleanFirstSearch)
		{
			componentCurrent._modelTable.Page = 1;
		}

		componentCurrent._serviceProductGeneral.selectProductForPNMMonitoringByAttributes
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTable.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelTable.Result !== undefined)
						{
							componentCurrent._arrayModelProductGeneral = [];

							let arrayModelProductGeneralTemp: Array<ProductGeneralModel> = [];
							arrayModelProductGeneralTemp = JSON.parse(componentCurrent._modelTable.Result);
							componentCurrent._modelTable.Result = undefined;

							let modelProductGeneral: ProductGeneralModel;

							for(const modelProductGeneralTemp of arrayModelProductGeneralTemp)
							{
								modelProductGeneral = new ProductGeneralModel();
								modelProductGeneral.setModelFromObject(modelProductGeneralTemp);
								componentCurrent._arrayModelProductGeneral.push(modelProductGeneral);
							}

							componentCurrent._elementHTMLDivMenuAllProductListContainer = document.getElementById("divMenuAllProductListContainer");
							componentCurrent._elementHTMLDivMenuAllProductListContainer.style.display = "block";

							componentCurrent._functionUserInterface.updateLoadingProgress();
							componentCurrent._componentTableControlInclude.setButtonState();
						}
						else
						{
							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							componentCurrent._elementHTMLDivMenuAllProductListContainer = document.getElementById("divMenuAllProductListContainer");
							componentCurrent._elementHTMLDivMenuAllProductListContainer.style.display = "none";
							componentCurrent._modelTable.Page = 1;
							componentCurrent._modelTable.RowPerPage = 20;
						});
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectDashboardPNMByAttributes(componentCurrent, true); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelTable);
	}

	public callSelectProductGeneralReportByAttributesForExcel(componentCurrent: TransactionmonitoringComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent.setArrayAllProductReportEmpty();

		const modelProductGeneralRequest: ProductGeneralModel = new ProductGeneralModel();
		modelProductGeneralRequest.CreatedStartDate = componentCurrent._dateStart;
		modelProductGeneralRequest.CreatedEndDate = componentCurrent._dateEnd;
		modelProductGeneralRequest.ProductCode = componentCurrent._modelProductGeneralRequest.ProductCode;
		modelProductGeneralRequest.ReferenceNumber = componentCurrent._modelProductGeneralRequest.ReferenceNumber;
		modelProductGeneralRequest.PolicyStatus = componentCurrent._modelProductGeneralRequest.PolicyStatus;
		modelProductGeneralRequest.BRIDebitStatus = componentCurrent._modelProductGeneralRequest.BRIDebitStatus;
		modelProductGeneralRequest.clearProductGeneralOtherModel();

		const modelResponseValidation: ResponseModel = modelProductGeneralRequest.validatePeriod();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			componentCurrent._serviceProductGeneral.selectProductForPNMMonitoringByAttributesForExcel
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							let arrayModelProductGeneralReportTemp: Array<ProductGeneralModel> = [];
							arrayModelProductGeneralReportTemp = JSON.parse(modelResponse.Data);

							let modelProductGeneral: ProductGeneralModel;
							for(const modelProductGeneralTemp of arrayModelProductGeneralReportTemp)
							{
								modelProductGeneral = new ProductGeneralModel();
								modelProductGeneral.setModelFromObject(modelProductGeneralTemp);
								componentCurrent._arrayModelProductGeneralReport.push(modelProductGeneral);
							}

							componentCurrent.callGenerateExcelForDashboardTransactionReport(componentCurrent);
						}
						else
						{
							componentCurrent.setArrayAllProductReportEmpty();
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}

					componentCurrent._functionUserInterface.updateLoadingProgress();
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectProductGeneralReportByAttributesForExcel(componentCurrent); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, modelProductGeneralRequest);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => {});
		}
	};

	public callGenerateExcelForDashboardTransactionReport(componentCurrent: TransactionmonitoringComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		this._fileWorkBookExcel = xlsx.utils.book_new();

		componentCurrent.getDashboardExportToExcel(() =>
		{
			//#region SAVE TO FILE

			const stringDatePeriod: string = ""+componentCurrent._dateStart?.getDate()+componentCurrent._dateStart?.getMonth()+componentCurrent._dateStart?.getFullYear()+
			" s/d "+componentCurrent._dateEnd?.getDate()+componentCurrent._dateEnd?.getMonth()+componentCurrent._dateEnd?.getFullYear();

			let stringProduct: string = "";
			if(componentCurrent._modelProductGeneralRequest.ProductCode === StringConstant.STRING_VALUE_PRODUCTCODE_DASHBOARD_MYHOUSE)
			{
				stringProduct = "ASMIK Rumahku";
			}
			else if(componentCurrent._modelProductGeneralRequest.ProductCode === StringConstant.STRING_VALUE_PRODUCTCODE_DASHBOARD_DAMAGEOFBUSINESSPLACE)
			{
				stringProduct = "ASMIK KTU";
			}
			else
			{
				stringProduct = "ALL";
			}

			const stringFileName: string = "Laporan Data Transaksi BRINS PNM "+stringProduct+" Produk Periode "+stringDatePeriod+".xlsx";

			xlsx.writeFile(componentCurrent._fileWorkBookExcel, stringFileName);

			const modelResponseExcel: ResponseModel = new ResponseModel();
			modelResponseExcel.MessageTitle = "Export Excel";
			modelResponseExcel.MessageContent = "File excel download success! Please wait.";
			this._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { this._functionUserInterface.updateLoadingProgress(); });

			//#endregion

			this._functionUserInterface.updateLoadingProgress();
		});

	}

	downloadDashboardPegadaianByToken(componentCurrent: TransactionmonitoringComponent, stringToken: string)
	{
		this._functionUserInterface.setLoadingProgress(1);

		const modelProductGeneralRequest: ProductGeneralModel = new ProductGeneralModel();
		modelProductGeneralRequest.Token = stringToken;

		let modelResponseValidation: ResponseModel = new ResponseModel();
		modelResponseValidation = modelProductGeneralRequest.validateDocumentToken();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceProductGeneral.downloadProductForPNMMonitoringByToken
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.Data !== undefined)
					{
						const modelProductGeneralDocumentDownload: ProductGeneralDocumentDownloadModel = new ProductGeneralDocumentDownloadModel();
						modelProductGeneralDocumentDownload.setModelFromString(modelResponse.Data);

						if (modelProductGeneralDocumentDownload !== undefined)
						{
							componentCurrent._functionUserInterface.updateLoadingProgress();
							// eslint-disable-next-line max-len
							if (modelProductGeneralDocumentDownload.FileName == null || modelProductGeneralDocumentDownload.FilePath === undefined)
							{

							}
							else
							{
								componentCurrent._functionUserInterface.downloadFileBlob(modelProductGeneralDocumentDownload.FileName ?? "", "application/pdf", "pdf", modelProductGeneralDocumentDownload.FilePath ?? "");
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.downloadDashboardPegadaianByToken(componentCurrent, stringToken); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, modelProductGeneralRequest);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => {});
		}
	}

	//#endregion


	//#region SETTER

	setEventEmitterSelect(modelTableUpdate: TableModel): void
	{
		this._modelTable = modelTableUpdate;
		this.callSelectDashboardPNMByAttributes(this, false);
	}

	private setStartDate(dateStart?: Date): void
	{
		if (dateStart !== undefined && dateStart !== null)
		{
			this._dateStart = dateStart.clearUTC();
			this._modelProductGeneralRequest.CreatedStartDate = dateStart.clearUTC();
		}
		else
		{

		}
	}

	private setEndDate(dateEnd?: Date): void
	{
		if (dateEnd !== undefined && dateEnd !== null)
		{
			this._dateEnd = dateEnd.clearUTC();
			this._modelProductGeneralRequest.CreatedEndDate = dateEnd.clearUTC();
		}
		else
		{

		}
	}

	private setArrayAllProductReportEmpty(): void
	{
		this._arrayModelProductGeneralReport = [];
	}

	//#endregion


	//#region GETTER

	getStartDate(event): void
	{
		this.setStartDate(event.value);
	};

	getEndDate(event): void
	{
		this.setEndDate(event.value);
	};

	//#endregion


	//#region FUNCTION MATRIX EXCEL

	public getDashboardExportToExcel(success: () => void): void
	{
		//#region INITIALIZATION DECLARATION VARIABLE

		this._matrixExportExcelDashboard = [];
		this._matrixExportExcelDashboard[0] = [];
		let numberIndexRow: number = 0;

		//#endregion


		//#region SET HEADER EXCEL

		this._matrixExportExcelDashboard[numberIndexRow] = [];
		this._matrixExportExcelDashboard[numberIndexRow].push("Nomor Referensi");
		this._matrixExportExcelDashboard[numberIndexRow].push("NIK");
		this._matrixExportExcelDashboard[numberIndexRow].push("Nama");
		this._matrixExportExcelDashboard[numberIndexRow].push("Tanggal Pendebetan");
		this._matrixExportExcelDashboard[numberIndexRow].push("Tanggal Pendaftaran Rekening");
		this._matrixExportExcelDashboard[numberIndexRow].push("Jenis Produk");
		this._matrixExportExcelDashboard[numberIndexRow].push("Nilai Premi");
		this._matrixExportExcelDashboard[numberIndexRow].push("Status Polis");
		this._matrixExportExcelDashboard[numberIndexRow].push("Status Debet");
		this._matrixExportExcelDashboard[numberIndexRow].push("Cabang PNM");
		this._matrixExportExcelDashboard[numberIndexRow].push("Area PNM");
		this._matrixExportExcelDashboard[numberIndexRow].push("Cabang BRINS");

		//#endregion


		//#region BINDING DATA TO EXCEL

		if(this._arrayModelProductGeneralReport !== undefined)
		{
			for (const modelProductGeneralReport of this._arrayModelProductGeneralReport)
			{
				numberIndexRow++;

				this._matrixExportExcelDashboard[numberIndexRow] = [];
				this._matrixExportExcelDashboard[numberIndexRow].push(modelProductGeneralReport.ReferenceNumber ?? StringConstant.STRING_CHARACTER_DASH);
				this._matrixExportExcelDashboard[numberIndexRow].push(modelProductGeneralReport.PolicyholderIdentificationNumber ?? StringConstant.STRING_CHARACTER_DASH);
				this._matrixExportExcelDashboard[numberIndexRow].push(modelProductGeneralReport.PolicyholderFullName ?? StringConstant.STRING_CHARACTER_DASH);
				this._matrixExportExcelDashboard[numberIndexRow].push(modelProductGeneralReport.DebitDate ? new Date(modelProductGeneralReport.DebitDate) : StringConstant.STRING_CHARACTER_DASH);
				this._matrixExportExcelDashboard[numberIndexRow].push(modelProductGeneralReport.CreatedOn ? new Date(modelProductGeneralReport.CreatedOn) : StringConstant.STRING_CHARACTER_DASH);
				this._matrixExportExcelDashboard[numberIndexRow].push(modelProductGeneralReport.ProductName ?? StringConstant.STRING_CHARACTER_DASH);
				this._matrixExportExcelDashboard[numberIndexRow].push(modelProductGeneralReport.PremiumTotalAmount ?? StringConstant.STRING_CHARACTER_DASH);
				this._matrixExportExcelDashboard[numberIndexRow].push(modelProductGeneralReport.getStatusPolicy() ?? StringConstant.STRING_CHARACTER_DASH);
				this._matrixExportExcelDashboard[numberIndexRow].push(modelProductGeneralReport.getBRIDebitStatus() ?? StringConstant.STRING_CHARACTER_DASH);
				this._matrixExportExcelDashboard[numberIndexRow].push(modelProductGeneralReport.PolicyholderPNMBranchName ?? StringConstant.STRING_CHARACTER_DASH);
				this._matrixExportExcelDashboard[numberIndexRow].push(modelProductGeneralReport.PolicyholderPNMBranchArea ?? StringConstant.STRING_CHARACTER_DASH);
				this._matrixExportExcelDashboard[numberIndexRow].push(modelProductGeneralReport.BranchName ?? StringConstant.STRING_CHARACTER_DASH);
			}

			//#region GENERATE WORKSHEET

			const workSheet: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(this._matrixExportExcelDashboard, { cellDates: true, dateNF: "dd/mm/yyyy" });

			for (const stringIndex in workSheet)
			{
				if (typeof workSheet[stringIndex] !== "object")
				{
					continue;
				}
				else
				{
					const cell = xlsx.utils.decode_cell(workSheet[stringIndex]);
					if (cell.c === 4 || cell.c === 5)
					{
						workSheet[stringIndex].s.numFmt = "dd/mm/yyyy";
						workSheet[stringIndex].z = "dd/mm/yyyy";
					}
				}
			}

			//#endregion


			//#region GENERATE WORKBOOK AND ADD THE WORKSHEET

			xlsx.utils.book_append_sheet(this._fileWorkBookExcel, workSheet, "Report Transaction Monitoring");

			//#endregion
		}
		else
		{

		}

		success();

		//#endregion
	}

	//#endregion

}

//#endregion
