<div id="divBodyDashboard" class="DivBodyGeneral">
	<header class="HeaderGeneral">
		<h2>Report</h2>
		<p>List Report</p>
	</header>

	<div id="divMainDashboard">
		<div class="DivSingleForm">
			<form>
				<div class="DivForm">
					<h3>Search</h3>
					<fieldset>
						<div class="DivFormHorizontalContainer">
							<div class="DivForm2Column">
								<label for="selectEbudgetingReportAccount">Account</label>
								<div class="DivFormSingleDropdown">
									<div class="DivFormLongTextDropdown">
										<ng-select id="selectEbudgetingReportAccount" [(ngModel)]="_modelEBudgetingReportPageRequest.Account" name="modelEbudgetingApprovalAccount">
											<ng-option *ngFor="let modelEBudgetingReportPage of _arrayModelEBudgetingAccountReportPage"
												[value]="modelEBudgetingReportPage.Account">{{ modelEBudgetingReportPage.Account }}
											</ng-option>
										</ng-select>
									</div>
								</div>
							</div>

							<div class="DivForm2Column">
								<label for="selectEbudgetingReportPeriod">Period</label>
								<div class="DivMatFormFieldYearAndMonth">
									<mat-form-field appearance="fill" (click)="picker.open()">
										<mat-label>Time Range</mat-label>
										<mat-date-range-input [rangePicker]="picker" [min]="_dateMin"
										[max]="_dateMax">
											<input matStartDate placeholder="Start date" [value]="_modelEBudgetingReportPageRequest.StartDate"
												(dateChange)="getStartDate($event)" readonly>
											<input matEndDate placeholder="End date" [value]="_modelEBudgetingReportPageRequest.EndDate"
												(dateChange)="getEndDate($event)" readonly>
										</mat-date-range-input>
										<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
										<mat-date-range-picker #picker></mat-date-range-picker>
									</mat-form-field>
								</div>
							</div>
						</div>
					</fieldset>
				</div>
				<div class="DivButtonContainer">
					<input type="button" name="buttonExport" value="Export to Excel" (click)="callSelectListForEBudgetingExport()">
					<input type="button" name="buttonSearch" value="Search" (click)="callSelectListForEbudgeting()">
				</div>
			</form>

			<div class="DivTableContainer">
				<app-tablecontrolinclude #TableControlIncludeComponentReport [_modelTableForm]="_modelTableReport" (_eventEmitterSelect)="setEventEmitterSelect($event);"></app-tablecontrolinclude>
	
				<table class="DivLetterTable">
					<thead>
						<tr>
							<th>No</th>
							<th>Period</th>
							<th>Budget Allocation</th>
							<th>Used Budget</th>
							<th>Advance Request</th>
							<th>Remaining/Shortfall Payment</th>
							<th>Budget Balance</th>
						</tr>
					</thead>
	
					<tbody>
						<tr *ngFor="let modelEBudgetingBudgetReport of _arrayModelEBudgetingBudgetReportTable; let numberIndex = index">
							<td>{{ numberIndex + 1 }}</td>
							<td>{{ modelEBudgetingBudgetReport.Period | convertToDateShort }}</td>
							<td>Rp {{ modelEBudgetingBudgetReport.BudgetAllocation | currencyValueWithNull | convertEmptyNumberToDash }}</td>
							<td>Rp {{ modelEBudgetingBudgetReport.UsedBudget | currencyValueWithNull | convertEmptyNumberToDash }}</td>
							<td>Rp {{ modelEBudgetingBudgetReport.AdvanceRequest | currencyValueWithNull | convertEmptyNumberToDash }}</td>
							<td>Rp {{ modelEBudgetingBudgetReport.RemainingShortfallPayment | currencyValueWithNull | convertEmptyNumberToDash }}</td>
							<td>Rp {{ modelEBudgetingBudgetReport.BudgetBalance | currencyValueWithNull | convertEmptyNumberToDash }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>