//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { ProductGeneralModel } from "../models/productgeneral.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable({
	providedIn: "root"
	})

//#endregion


//#region CLASS

export class ProducthomeownershipcreditService extends BaseService
{

	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT

	selectProductByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_PRODUCTHOMEOWNERSHIPCREDIT_SELECTPRODUCTBYATTRIBUTES);
	}

	selectProductByToken(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTHOMEOWNERSHIPCREDIT_SELECTPRODUCTBYTOKEN);
	}

	selectProductForReport(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODCUTHOMEOWNERSHIPCREDIT_SELECTPRODUCTFORREPORT);
	}

	//#endregion


	//#region CALL

	callRejectProductByToken(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTHOMEOWNERSHIPCREDIT_CALLREJECTPRODUCTBYTOKEN);
	}

	callApproveProductByToken(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTHOMEOWNERSHIPCREDIT_CALLAPPROVEPRODUCTBYTOKEN);
	}

	//#endregion
}

//#endregion