//#region IMPORT

import { NgModule } from "@angular/core";
import { ConvertToUserURL, ConvertToProductURL } from "./hyperlink.pipe";
// eslint-disable-next-line max-len
import { ConvertByteForPreview, ConvertEmptyToDash, ConvertExtensionToIcon, ConvertNoDivisionToCustomer, ConvertSeparateEnumSpace as ConvertSeparateEnumSpace, ConvertToDateMedium, ConvertToDateShort, ConvertToDateView, InitiateWithoutUTC, CurrencyValue, ConvertToTime, ConvertEmptyNumberToDash, ConvertEmtpyNumberToZero, ConvertToMonth, ConvertCapitalizeEachWord, CurrencyValueWithNull, ConvertToMaskingValue, ConvertDateToDay, StripHtmlPipe } from "./converter.pipe";
import { TrimShort, TrimShortest } from "./trim.pipe";

//#endregion


//#region MODULE

@NgModule
(
	{
		declarations:
		[
			ConvertToProductURL,
			ConvertToDateMedium,
			ConvertToDateShort,
			ConvertToTime,
			ConvertToMonth,
			ConvertEmptyToDash,
			ConvertEmptyNumberToDash,
			ConvertEmtpyNumberToZero,
			TrimShort,
			TrimShortest,
			InitiateWithoutUTC,
			ConvertSeparateEnumSpace,
			ConvertNoDivisionToCustomer,
			ConvertToUserURL,
			ConvertByteForPreview,
			ConvertExtensionToIcon,
			ConvertToDateView,
			CurrencyValue,
			ConvertCapitalizeEachWord,
			CurrencyValueWithNull,
			ConvertToMaskingValue,
			ConvertDateToDay,
			StripHtmlPipe
	],
		exports:
		[
			ConvertToProductURL,
			ConvertToDateMedium,
			ConvertToDateShort,
			ConvertToTime,
			ConvertToMonth,
			ConvertEmptyToDash,
			ConvertEmptyNumberToDash,
			ConvertEmtpyNumberToZero,
			TrimShort,
			TrimShortest,
			InitiateWithoutUTC,
			ConvertSeparateEnumSpace,
			ConvertNoDivisionToCustomer,
			ConvertToUserURL,
			ConvertByteForPreview,
			ConvertExtensionToIcon,
			ConvertToDateView,
			CurrencyValue,
			ConvertCapitalizeEachWord,
			CurrencyValueWithNull,
			ConvertToMaskingValue,
			ConvertDateToDay,
			StripHtmlPipe
		]
	}
)

//#endregion


//#region CLASS

export class PipeModule { }

//#endregion