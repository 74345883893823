//#region CLASS

export class ProductModel
{
	/*
		Attribute - START
		Description : Attribute for product model, used for product information and link to coverage.
		Author: Ibrahim Aziz.
		Created on : Monday, 2 November 2020.			Updated on : Wednesday, 6 January 2021.
		Created by : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
		Version : 1.0:4.
	*/

	ID?: number;
	Name?: string;
	Description?: string;
	Benefit?: string;
	Code?: string;
	Token?: string;

	StartDate?: Date;
	ExpiredDate?: Date;
	Type?: number;
	ImageResourceID?: number;
	InformationPDFURL?: string;

	CoreCode?: string;
	CoreName?: string;

	DescriptionIDN?: string;
	BenefitIDN?: string;
	DescriptionUSA?: string;
	BenefitUSA?: string;

	DiscountAmountMaximum?: number;
	DiscountAmountMinimum?: number;
	DiscountCurrency?: string;
	DiscountPercetageMaximum?: number;
	DiscountPercentageMinimum?: number;

	/* Attribute - END */
}

//#endregion