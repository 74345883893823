//#region IMPORT

import { DragDropModule } from "@angular/cdk/drag-drop";
import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { NgSelectModule } from "@ng-select/ng-select";
import { CKEditorModule } from "ng2-ckeditor";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { NgxEchartsModule } from "ngx-echarts";
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from "ngx-mask";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { ApprovalReimbursementListComponent } from "./components/approvalreimbursement/approvalreimbursementlist/approvalreimbursementlist.component";
import { DentalreimbursementdetailComponent } from "./components/approvalreimbursement/dentalreimbursementdetail/dentalreimbursementdetail.component";
import { MaternityreimbursementdetailComponent } from "./components/approvalreimbursement/maternityreimbursementdetail/maternityreimbursementdetail.component";
import { OpticreimbursementdetailComponent } from "./components/approvalreimbursement/opticreimbursementdetail/opticreimbursementdetail.component";
import { PostmaternityreimbursementdetailComponent } from "./components/approvalreimbursement/postmaternityreimbursementdetail/postmaternityreimbursementdetail.component";
import { PrematernityreimbursementdetailComponent } from "./components/approvalreimbursement/prematernityreimbursementdetail/prematernityreimbursementdetail.component";
import { CustomerdataComponent } from "./components/apu-ppt/customerdata/customerdata.component";
import { RiskbasedassessmentComponent } from "./components/apu-ppt/riskbasedassessment/riskbasedassessment.component";
import { TransactiondetailComponent } from "./components/apu-ppt/transaction/transactiondetail/transactiondetail.component";
import { TransactionlistComponent } from "./components/apu-ppt/transaction/transactionlist/transactionlist.component";
import { CheckDevToolsDirective } from "./components/bases/basecheckdevtools.directive";
import { BranchComponent } from "./components/branch/branch.component";
import { TaxRegulationDetailComponent } from "./components/brinstax/taxregulation/taxregulationdetail/taxregulationdetail.component";
import { TaxRegulationListComponent } from "./components/brinstax/taxregulation/taxregulationlist/taxregulationlist.component";
import { CalculatepremiasriComponent } from "./components/calculatepremiums/calculatepremiasri/calculatepremiasri.component";
import { CalculatepremiasrisyariahComponent } from "./components/calculatepremiums/calculatepremiasrisyariah/calculatepremiasrisyariah.component";
import { CalculatepremiotoComponent } from "./components/calculatepremiums/calculatepremioto/calculatepremioto.component";
import { CalculatepremiotosyariahComponent } from "./components/calculatepremiums/calculatepremiotosyariah/calculatepremiotosyariah.component";
import { CalculatepremipersonalaccidentComponent } from "./components/calculatepremiums/calculatepremipersonalaccident/calculatepremipersonalaccident.component";
import { CalculatepremipersonalaccidentsyariahComponent } from "./components/calculatepremiums/calculatepremipersonalaccidentsyariah/calculatepremipersonalaccidentsyariah.component";
import { ClaimmonitoringComponent } from "./components/claimmonitoring/claimmonitoring.component";
import { DashboardebudgetingComponent } from "./components/dashboards/dashboardebudgeting/dashboardebudgeting.component";
import { DashboardkprsComponent } from "./components/dashboards/dashboardkprs/dashboardkprs.component";
import { DashboardsmartComponent } from "./components/dashboards/dashboardsmart/dashboardsmart.component";
import { SubmissionDetailComponent } from "./components/digitalization/submissions/submissiondetail/submissiondetail.component";
import { SubmissionInsertComponent } from "./components/digitalization/submissions/submissioninsert/submissioninsert.component";
import { SubmissionListComponent } from "./components/digitalization/submissions/submissionlist/submissionlist.component";
import { DownloadComponent } from "./components/download/download.component";
import { BankaccountdetailComponent } from "./components/ebudgeting/bankaccount/bankaccountdetail/bankaccountdetail.component";
import { BankAccountlistComponent } from "./components/ebudgeting/bankaccount/bankaccountlist/bankaccountlist.component";
import { DeclarationCheckerSignerDetailComponent } from "./components/ebudgeting/declarations/declarationcheckersignerdetail/declarationcheckersignerdetail.component";
import { DeclarationCheckerSignerListComponent } from "./components/ebudgeting/declarations/declarationcheckersignerlist/declarationcheckersignerlist.component";
import { DeclarationMakerDetailComponent } from "./components/ebudgeting/declarations/declarationmakerdetail/declarationmakerdetail.component";
import { DeclarationMakerListComponent } from "./components/ebudgeting/declarations/declarationmakerlist/declarationmakerlist.component";
import { ReportebudgetingComponent } from "./components/ebudgeting/reportebudgeting/reportebudgeting.component";
import { SubmissionCheckerSignerDetailComponent } from "./components/ebudgeting/submissions/submissioncheckersignerdetail/submissioncheckersignerdetail.component";
import { SubmissionCheckerSignerListComponent } from "./components/ebudgeting/submissions/submissioncheckersignerlist/submissioncheckersignerlist.component";
import { SubmissionMakerDetailComponent } from "./components/ebudgeting/submissions/submissionmakerdetail/submissionmakerdetail.component";
import { SubmissionMakerListComponent } from "./components/ebudgeting/submissions/submissionmakerlist/submissionmakerlist.component";
import { EmateraicheckersignerdetailComponent } from "./components/emeterai/emateraicheckersignerdetail/emateraicheckersignerdetail.component";
import { EmateraicheckersignerlistComponent } from "./components/emeterai/emateraicheckersignerlist/emateraicheckersignerlist.component";
import { EmateraimakerdetailComponent } from "./components/emeterai/emateraimakerdetail/emateraimakerdetail.component";
import { EmateraimakerlistComponent } from "./components/emeterai/emateraimakerlist/emateraimakerlist.component";
import { AnnualdisclosurecheckersignerdetailComponent } from "./components/gcgonline/annualdisclosure/annualdisclosurecheckersignerdetail/annualdisclosurecheckersignerdetail.component";
import { AnnualdisclosurecheckersignerlistComponent } from "./components/gcgonline/annualdisclosure/annualdisclosurecheckersignerlist/annualdisclosurecheckersignerlist.component";
import { AnnualdisclosuredetailComponent } from "./components/gcgonline/annualdisclosure/annualdisclosuredetail/annualdisclosuredetail.component";
import { AnnualdisclosureinsertComponent } from "./components/gcgonline/annualdisclosure/annualdisclosureinsert/annualdisclosureinsert.component";
import { AnnualdisclosurelistComponent } from "./components/gcgonline/annualdisclosure/annualdisclosurelist/annualdisclosurelist.component";
import { CodeofethicscheckersignerdetailComponent } from "./components/gcgonline/codeofethics/codeofethicscheckersignerdetail/codeofethicscheckersignerdetail.component";
import { CodeofethicscheckersignerlistComponent } from "./components/gcgonline/codeofethics/codeofethicscheckersignerlist/codeofethicscheckersignerlist.component";
import { CodeofethicsdetailComponent } from "./components/gcgonline/codeofethics/codeofethicsdetail/codeofethicsdetail.component";
import { CodeofethicsinsertComponent } from "./components/gcgonline/codeofethics/codeofethicsinsert/codeofethicsinsert.component";
import { CodeofethicslistComponent } from "./components/gcgonline/codeofethics/codeofethicslist/codeofethicslist.component";
import { GratificationdetailComponent } from "./components/gcgonline/gratification/gratificationdetail/gratificationdetail.component";
import { GratificationlistComponent } from "./components/gcgonline/gratification/gratificationlist/gratificationlist.component";
import { IntegritypactdetailComponent } from "./components/gcgonline/integritypact/integritypactdetail/integritypactdetail.component";
import { IntegritypactinsertComponent } from "./components/gcgonline/integritypact/integritypactinsert/integritypactinsert.component";
import { IntegritypactlistComponent } from "./components/gcgonline/integritypact/integritypactlist/integritypactlist.component";
import { ProfilegcgonlineComponent } from "./components/gcgonline/profilegcgonline/profilegcgonline.component";
import { HomeComponent } from "./components/home/home.component";
import { BarchartIncludeComponent } from "./components/includes/barchart.include/barchart.include.component";
import { GroupbarchartIncludeComponent } from "./components/includes/groupbarchart.include/groupbarchart.include.component";
import { PiechartIncludeComponent } from "./components/includes/piechart.include/piechart.include.component";
import { TableControlIncludeComponent } from "./components/includes/tablecontrol.include/tablecontrol.include.component";
import { ProductinformationComponent } from "./components/productinformation/productinformation.component";
import { ProductkprdetailComponent } from "./components/productkpr/productkprdetail/productkprdetail.component";
import { ProductkprlistComponent } from "./components/productkpr/productkprlist/productkprlist.component";
import { AnomalyDetailComponent } from "./components/protect/anomaly/anomalydetail/anomalydetail.component";
import { AnomalyDownloadComponent } from "./components/protect/anomaly/anomalydownload/anomalydownload.component";
import { AnomalyInsertComponent } from "./components/protect/anomaly/anomalyinsert/anomalyinsert.component";
import { AnomalyListComponent } from "./components/protect/anomaly/anomalylist/anomalylist.component";
import { AnomalyNotificationComponent } from "./components/protect/anomalynotification/anomalynotification.component";
import { DashboardrasdetailComponent } from "./components/ras/dashboardras/dashboardrasdetail/dashboardrasdetail.component";
import { DashboardraslistComponent } from "./components/ras/dashboardras/dashboardraslist/dashboardraslist.component";
import { FollowupplandetailComponent } from "./components/ras/followupplan/followupplandetail/followupplandetail.component";
import { FollowupplanlistComponent } from "./components/ras/followupplan/followupplanlist/followupplanlist.component";
import { RealizationapprovaldetailComponent } from "./components/ras/realization/realizationapprovaldetail/realizationapprovaldetail.component";
import { RealizationapprovallistComponent } from "./components/ras/realization/realizationapprovallist/realizationapprovallist.component";
import { RealizationlistComponent } from "./components/ras/realization/realizationlist/realizationlist.component";
import { RealizationmonitoringdetailComponent } from "./components/ras/realization/realizationmonitoringdetail/realizationmonitoringdetail.component";
import { RealizationmonitoringlistComponent } from "./components/ras/realization/realizationmonitoringlist/realizationmonitoringlist.component";
import { RealizationparameterdetailComponent } from "./components/ras/realization/realizationparameterdetail/realizationparameterdetail.component";
import { RealizationsignerapprovaldetailComponent } from "./components/ras/realization/realizationsignerapprovaldetail/realizationsignerapprovaldetail.component";
import { RealizationsignerapprovallistComponent } from "./components/ras/realization/realizationsignerapprovallist/realizationsignerapprovallist.component";
import { ApprovaldetailComponent } from "./components/rcsa/approval/approvaldetail/approvaldetail.component";
import { ApprovallistComponent } from "./components/rcsa/approval/approvallist/approvallist.component";
import { AssessmentdetailComponent } from "./components/rcsa/assessment/assessmentdetail/assessmentdetail.component";
import { AssessmentlistComponent } from "./components/rcsa/assessment/assessmentlist/assessmentlist.component";
import { ReportdetailComponent } from "./components/rcsa/report/reportdetail/reportdetail.component";
import { ReportrcsalistComponent } from "./components/rcsa/report/reportrcsalist/reportrcsalist.component";
import { RiskissuerequestdetailComponent } from "./components/rcsa/riskissuerequest/riskissuerequestdetail/riskissuerequestdetail.component";
import { RiskissuerequestlistComponent } from "./components/rcsa/riskissuerequest/riskissuerequestlist/riskissuerequestlist.component";
import { RelationshipmanagerdetailComponent } from "./components/relationshipmanagers/relationshipmanagerdetail/relationshipmanagerdetail.component";
import { RelationshipmanagerlistComponent } from "./components/relationshipmanagers/relationshipmanagerlist/relationshipmanagerlist.component";
import { ReportlistComponent } from "./components/report/reportlist/reportlist.component";
import { AppComponent } from "./components/root/app.component";
import { SigninComponent } from "./components/signin/signin.component";
import { TicketdetailComponent } from "./components/tickets/ticketdetail/ticketdetail.component";
import { TicketlistinboxComponent } from "./components/tickets/ticketlistinbox/ticketlistinbox.component";
import { TicketlistoutboxComponent } from "./components/tickets/ticketlistoutbox/ticketlistoutbox.component";
import { TicketReportComponent } from "./components/tickets/ticketreport/ticketreport/ticketreport.component";
import { TicketReportSatisfactionComponent } from "./components/tickets/ticketreport/ticketreportsatisfaction/ticketreportsatisfaction.component";
import { TicketSatisfactionComponent } from "./components/tickets/ticketsatisfaction/ticketsatisfaction.component";
import { TransactionmonitoringComponent } from "./components/transactionmonitoring/transactionmonitoring.component";
import { PipeModule } from "./functions/pipes/pipe.module";
import { PwaService } from "./services/pwa.service";
import { TaxReportListComponent } from "./components/brinstax/taxreport/taxreportlist/taxreportlist.component";
import { TaxReportDetailComponent } from "./components/brinstax/taxreport/taxreportdetail/taxreportdetail.component";
import { TaxRequestAddComponent } from "./components/brinstax/taxrequest/taxrequestadd/taxrequestadd.component";
import { TaxRequestDetailComponent } from "./components/brinstax/taxrequest/taxrequestdetail/taxrequestdetail.component";
import { TaxRequestListComponent } from "./components/brinstax/taxrequest/taxrequestlist/taxrequestlist.component";

//#endregion


//#region MODULE

const initializer = (pwaService: PwaService) => () => pwaService.setupInstaller();
const maskConfig: Partial<IConfig> = {
	validation: false,
};

@NgModule
(
	{
		declarations:
		[
			AppComponent,
			SigninComponent,
			HomeComponent,
			ProductinformationComponent,
			TableControlIncludeComponent,
			BarchartIncludeComponent,
			GroupbarchartIncludeComponent,
			PiechartIncludeComponent,
			RelationshipmanagerlistComponent,
			RelationshipmanagerdetailComponent,
			TicketdetailComponent,
			TicketlistinboxComponent,
			TicketlistoutboxComponent,
			TicketReportComponent,
			TicketReportSatisfactionComponent,
			TicketSatisfactionComponent,
			CalculatepremiasriComponent,
			CalculatepremiasrisyariahComponent,
			CalculatepremiotoComponent,
			CalculatepremiotosyariahComponent,
			CalculatepremipersonalaccidentComponent,
			CalculatepremipersonalaccidentsyariahComponent,
			DashboardsmartComponent,
			DashboardkprsComponent,
			ProductkprlistComponent,
			ProductkprdetailComponent,
			BranchComponent,
			ReportlistComponent,
			DownloadComponent,
			ApprovalReimbursementListComponent,
			DentalreimbursementdetailComponent,
			OpticreimbursementdetailComponent,
			PostmaternityreimbursementdetailComponent,
			PrematernityreimbursementdetailComponent,
			MaternityreimbursementdetailComponent,
			AssessmentlistComponent,
			AssessmentdetailComponent,
			ApprovallistComponent,
			ApprovaldetailComponent,
			ReportdetailComponent,
			ReportrcsalistComponent,
			RiskissuerequestlistComponent,
			RiskissuerequestdetailComponent,
			SubmissionInsertComponent,
			SubmissionListComponent,
			SubmissionDetailComponent,
			AnomalyListComponent,
			AnomalyInsertComponent,
			AnomalyDetailComponent,
			AnomalyDownloadComponent,
			AnomalyNotificationComponent,
			CheckDevToolsDirective,
			DashboardraslistComponent,
			DashboardrasdetailComponent,
			RealizationlistComponent,
			RealizationapprovallistComponent,
			RealizationapprovaldetailComponent,
			RealizationparameterdetailComponent,
			RealizationsignerapprovallistComponent,
			RealizationsignerapprovaldetailComponent,
			FollowupplanlistComponent,
			FollowupplandetailComponent,
			RealizationmonitoringlistComponent,
			RealizationmonitoringdetailComponent,
			EmateraicheckersignerlistComponent,
			EmateraicheckersignerdetailComponent,
			EmateraimakerlistComponent,
			EmateraimakerdetailComponent,
			DeclarationMakerListComponent,
			DeclarationMakerDetailComponent,
			DeclarationCheckerSignerListComponent,
			DeclarationCheckerSignerDetailComponent,
			SubmissionMakerListComponent,
			SubmissionMakerDetailComponent,
			SubmissionCheckerSignerListComponent,
			SubmissionCheckerSignerDetailComponent,
			BankAccountlistComponent,
			BankaccountdetailComponent,
			DashboardebudgetingComponent,
			ReportebudgetingComponent,
			TransactionmonitoringComponent,
			ClaimmonitoringComponent,
			ProfilegcgonlineComponent,
			GratificationlistComponent,
			GratificationdetailComponent,
			IntegritypactlistComponent,
			IntegritypactinsertComponent,
			IntegritypactdetailComponent,
			CodeofethicslistComponent,
			CodeofethicsinsertComponent,
			CodeofethicsdetailComponent,
			CodeofethicscheckersignerlistComponent,
			CodeofethicscheckersignerdetailComponent,
			AnnualdisclosurelistComponent,
			AnnualdisclosureinsertComponent,
			AnnualdisclosuredetailComponent,
			AnnualdisclosurecheckersignerlistComponent,
			AnnualdisclosurecheckersignerdetailComponent,
			CustomerdataComponent,
			TransactiondetailComponent,
			TransactionlistComponent,
			RiskbasedassessmentComponent,
			TaxRegulationListComponent,
            TaxRegulationDetailComponent,
			TaxReportListComponent,
			TaxReportDetailComponent,
			TaxRequestAddComponent,
			TaxRequestDetailComponent,
			TaxRequestListComponent
		],
		imports:
		[
			BrowserModule,
			AppRoutingModule,
			FormsModule,
			HttpClientModule,
			PipeModule,
			BrowserAnimationsModule,
			MatNativeDateModule,
			MatInputModule,
			MatDatepickerModule,
			NgSelectModule,
			PdfViewerModule,
			DragDropModule,
			CKEditorModule,
			NgxEchartsModule.forRoot
			(
				{
					echarts: () => import("echarts")
				}
			),
			ServiceWorkerModule.register("ngsw-worker.js",
			{
				enabled: environment.serviceWorker,
				// Register the ServiceWorker as soon as the app is stable
				// or after 30 seconds (whichever comes first).
				registrationStrategy: "registerWhenStable:30000"
			}),
			NgxMaskDirective,
			NgxMaskPipe
		],
		providers:
		[
			{
				provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true
			},
			provideEnvironmentNgxMask(maskConfig)
		],
		bootstrap: [AppComponent]
	}
)

//#endregion


//#region CLASS

export class AppModule { }

//#endregion