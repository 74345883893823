<div id="divBodyDashboard" class="DivBodyGeneral">
	<header class="HeaderGeneral">
		<h2>Branch Location</h2>
		<p>Select Branch and Service Center Location</p>
	</header>

	<div id="divMainDashboard" class="DivMainAsideSearch">
		<form>
			<div  class="DivForm">
				<div class="DivFormSingleDropdown">
					<ng-select class="custom" id="selectBranch" [(ngModel)]="_modelBranch" name="modelBranchCode"
						(ngModelChange)="selectBranch(_modelBranch.Latitude,
						_modelBranch.Longitude, _modelBranch.Name, _modelBranch.Code)">
						<ng-option class="custom" *ngFor="let modelBranch of _arrayModelBranch" [value]="modelBranch">
							{{modelBranch.Name}}</ng-option>
					</ng-select>
				</div>
			</div>
			<ng-container *ngIf="stringBranchName !== ''">
				<div class="DivForm">
					<fieldset>
						<label for="branchName">
							<p>Branch</p>{{stringBranchName}}
						</label>
						<label for="branchCode">
							<p>Code</p>{{numberBranchCode}}
						</label>
					</fieldset>
				</div>

			</ng-container>
		</form>
		<!-- <div class="DivDynamicContainer">
			<form>
				<div class="DivForm">
					<agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" [minZoom]="minzoom">
						<agm-marker *ngFor="let maker of _arrayModelBranch; let numberIndex = index"
							[latitude]="maker.Latitude" [longitude]="maker.Longitude" [label]="maker.label">
							<agm-info-window [disableAutoPan]="true">
								Cabang <strong>{{maker.Name}} </strong>
							</agm-info-window>
						</agm-marker>

						<agm-circle [latitude]="latitude + 0.3" [longitude]="longitude" [radius]="5000"
							[fillColor]="'red'" [circleDraggable]="true" [editable]="true">
						</agm-circle>
					</agm-map>

				</div>
			</form>
		</div> -->
	</div>
</div>