//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { LicensePlatePrefixModel } from "./licenseplateprefix.model";
import { ProductGeneralModel } from "./productgeneral.model";
import { ResponseModel } from "./response.model";
import { VehicleBrandModel } from "./vehiclebrand.model";
import { VehicleColorModel } from "./vehiclecolor.model";
import { VehicleFunctionModel } from "./vehiclefunction.model";
import { VehicleLocationModel } from "./vehiclelocation.model";
import { VehicleManufactureYearModel } from "./vehiclemanufactureyear.model";
import { VehicleModelModel } from "./vehiclemodel.model";
import { VehicleSubModelModel } from "./vehiclesubmodel.model";
import { VehicleTypeModel } from "./vehicletype.model";

//#endregion


//#region CLASS

export class ProductOtoModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for product oto model, used for SPPA and policy.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020. 			Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:2.
	*/

	/* POLICY DETAIL - START */

	ID?: number;
	ProductGeneralID?: number;
	CoverageCode?: string;
	CoverageName?: string;

	Disclaimer?: boolean;
	SurveyOtoID?: number;
	Note?: string;
	Token?: string;

	/* POLICY DETAIL - END */

	/* POLICYHOLDER - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderBirthDate?: Date;
	PolicyholderEmail?: string;

	/* POLICYHOLDER - END */

	/* INSURED - START */

	VehiclePrice?: number;
	EquipmentNonStandardPrice?: number;
	VehicleRegionCode?: string;
	LicensePlatePrefixID?: number;
	LicensePlateCode?: string;
	LicensePlateInfix?: string;
	LicensePlateSuffix?: string;

	VehicleManufactureYearID?: number;
	VehicleManufactureYearCode?: string;
	VehicleManufactureYearName?: string;
	VehicleBrandID?: number;
	VehicleBrandCode?: string;
	VehicleModelID?: number;
	VehicleModelCode?: number;
	VehicleSubModelID?: number;
	VehicleSubModelCode?: number;
	VehicleTypeID?: number;
	VehicleTypeCode?: number;
	VehicleColorID?: number;
	VehicleColorCode?: number;
	VehicleFunctionID?: number;
	VehicleFunctionCode?: number;
	VehicleLocationID?: number;
	VehicleLocationCode?: number;

	VehicleSerie?: string;
	VehicleChasisNumber?: string;
	VehicleEngineNumber?: string;
	VehicleSeatCapacity?: string;
	AuthorizedWorkshop?: boolean;

	/* INSURED - END */

	/* NOT MAPPED - START */

	modelProductGeneral?: ProductGeneralModel;
	modelVehicleBrand?: VehicleBrandModel;
	modelVehicleModel?: VehicleModelModel;
	modelVehicleSubModel?: VehicleSubModelModel;
	modelVehicleType?: VehicleTypeModel;
	modelVehicleColor?: VehicleColorModel;
	modelVehicleManufactureYear?: VehicleManufactureYearModel;
	modelVehicleFunction?: VehicleFunctionModel;
	modelVehicleLocation?: VehicleLocationModel;
	modelLicensePlatePrefix?: LicensePlatePrefixModel;

	/* NOT MAPPED - END */

	/* Attribute - END */


	//#region VALIDATION

	validateforVehiclePrice(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.VehiclePrice == null || this.VehiclePrice === undefined)
		{
			modelResponse.MessageContent = "Vehicle Price can't be empty.";
			return modelResponse;
		}
		else if (this.VehiclePrice > 2000000000)
		{
			modelResponse.MessageContent = " Maximun Vehicle Price is 2 billion";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateforVehicleManufactureYearCode(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.VehicleManufactureYearCode == null || this.VehicleManufactureYearCode === undefined)
		{
			modelResponse.MessageContent = "Vehicle Manufacture Year can't be empty.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateforCoverageCode(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.CoverageCode == null || this.CoverageCode === undefined)
		{
			modelResponse.MessageContent = "Coverage can't be empty.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateforVehicleBrandCode(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.VehicleBrandCode == null || this.VehicleBrandCode === undefined)
		{
			modelResponse.MessageContent = "Brand can't be empty.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateforVehicleModelCode(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.VehicleModelCode == null || this.VehicleModelCode === undefined)
		{
			modelResponse.MessageContent = "Model can't be empty.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateforVehicleLocationCode(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.VehicleLocationCode == null || this.VehicleLocationCode === undefined)
		{
			modelResponse.MessageContent = "Location can't be empty.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateCheckForCalculatePremiumOto(): ResponseModel
	{
		let modelResponse: ResponseModel = this.validateforCoverageCode();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateforVehicleBrandCode();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateforVehicleModelCode();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateforVehicleLocationCode();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateforVehicleManufactureYearCode();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateforVehiclePrice();
		}
		else
		{

		}

		return modelResponse;
	}

	//#endregion


	//#region GENERATE

	generateManufactureYear(): Array<number>
	{
		const arrayNumberManufactureYear: Array<number> = [];
		const numberYear: number = new Date().getFullYear();
		const numberMinimunYear: number = numberYear - 10;

		for (let numberYearCalc: number = numberYear; numberYearCalc >= numberMinimunYear; numberYearCalc--)
		{
			arrayNumberManufactureYear.push(numberYearCalc);
		}

		return arrayNumberManufactureYear;
	}
}

//#endregion
